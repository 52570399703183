import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PartyMenuComponent } from "./PartyMenuComponent";
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../../core/common-material/core-common-material.module';
import { PartyMenuService } from "./PartyMenuService";
import { MenuModule } from 'app/core/menu/MenuModule';
import { PartySubmenuComponent } from "./PartySubmenuComponent";
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		CommonMaterialModule,
		MenuModule
	],
	declarations: [PartyMenuComponent, PartySubmenuComponent],
	exports: [PartyMenuComponent, PartySubmenuComponent],
	providers: [PartyMenuService]
})
export class PartyMenuModule {
}
