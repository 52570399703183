import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CoreMapService {
  private childrenSubject: Subject<string>;
  private parentSubject: Subject<string>;

  constructor() {
    this.childrenSubject = new Subject<string>();
    this.parentSubject = new Subject<string>();
  }

  public getChildrenMessages() {
    return this.childrenSubject.asObservable();
  }

  public getParentMessages() {
    return this.parentSubject.asObservable();
  }

  public sendToParent(value: string) {
    this.parentSubject.next(value);
  }

  public sendToChildren(value: string) {
    this.childrenSubject.next(value);
  }
}