import { Component, Input } from '@angular/core';
import { ContentTreeModel } from 'app/generated/backend/content/api/content-tree-model';
@Component({
	selector: 'public-menu',
	templateUrl: './MenuComponent.html'
})
export class MenuComponent {
	@Input()
	model: ContentTreeModel;
	@Input()
	activeItemClass: string;
	@Input()
	itemClass: string;
	@Input()
	caret = false;
}
