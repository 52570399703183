import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Subscription } from 'rxjs';
import { CoreCustomizationService } from '../customization/CoreCustomizationService';
import { CustomizationSettings } from '../customization/CustomizationSettings';
import { IsoDuration } from './IsoDuration';

const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreTimeInputComponent),
	multi: true
};

@Component({
	selector: 'app-time-input',
	templateUrl: './CoreTimeInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CoreTimeInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

	public timeValue: string;

	private subscriptions = new Array<Subscription>();
	@Input()
	required = false;

	@Input()
	placeholder: string = '';

	@Input()
	label: string;

	@Input()
	helpUri: string;

	@Input()
	disabled = false;

	@Input()
	iconClass: string = 'fa-clock';

	@Input()
	hint: string;

	@Output()
	blur: EventEmitter<any> = new EventEmitter<any>();

	//Placeholders for the callbacks which are later providesd
	//by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	toIsoDuration(timeValue: string): string {
		if (!timeValue) {
			return null;
		}
		const p = timeValue.indexOf(':');
		if (p < 0) {
			if (timeValue.length > 2) {
				return null;
			}
			return 'PT' + timeValue + 'H';
		}
		const hours = timeValue.substr(0, p);
		if (hours.length > 2) {
			return null;
		}
		const minutes = timeValue.substr(p + 1);
		if (minutes.length > 2) {
			return null;
		}
		if (minutes) {
			return 'PT' + hours + 'H' + minutes + 'M';
		}
		return 'PT' + hours + 'H';
	}


	//get accessor
	get value(): string {
		return this.toIsoDuration(this.timeValue);
	};

	//set accessor including call the onchange callback
	set value(value: string) {
		const timeValue = this.parseIsoDuration(value);
		if (timeValue !== this.timeValue) {
			this.timeValue = timeValue;
		}
		this.validate(null);
	}

	//From ControlValueAccessor interface
	writeValue(value: string) {
		this.timeValue = this.parseIsoDuration(value);
		this.validate(null);
	}

	parseIsoDuration(input: string): string {
		if (!input) {
			return null;
		}
		const isoDuration = new IsoDuration(input);

		let timeValue = '';
		if (isoDuration.hours > 23) {
			timeValue = '23:59'
			return timeValue;
		}
		if (isoDuration.hours < 10) {
			timeValue = '0'
		}
		timeValue += isoDuration.hours.toString();
		timeValue += ':';
		if (isoDuration.minutes > 59) {
			timeValue += '59'
		} else {
			if (isoDuration.minutes < 10) {
				timeValue += '0';
			}
			timeValue += isoDuration.minutes.toString();
		}
		return timeValue;
	}

	replaceAt(index1: number, index2: number, value: string, string: string): string {
		return string.substring(0, index1).concat(value, string.substring(index2, string.length));
	}

	clear() {
		this.timeValue = null;
		this.validate(null);
		this.onChangeCallback(null);
	}

	onBlur() {
		const isoDuration = this.toIsoDuration(this.timeValue);
		this.timeValue = this.parseIsoDuration(isoDuration);
		this.validate(null);
		this.onChangeCallback(isoDuration);
		this.blur.emit(this.value);
	}

	keyUp() {
		if (this.timeValue) {
			for (let i = 0; i < this.timeValue.length; i++) {
				const char = this.timeValue.charAt(i);
				if (!(char === '0' || char === '1' || char === '2' || char === '3' ||
					char === '4' || char === '5' || char === '6' || char === '7' ||
					char === '8' || char === '9' || char === ':')) {
					this.timeValue = '00:00';
				}
			}
			if (this.timeValue.indexOf(':') === -1) {
				if (this.timeValue && this.timeValue.length === 2) {
					this.timeValue = this.timeValue.concat(':');
				}
			} else {
				if (this.timeValue.split(":").length - 1 > 1) {
					this.timeValue = '00:00';
				}
			}
		}
	}

	keyPress(event: any): boolean {
		if (event.key === ':' && this.timeValue.indexOf(':') !== -1) {
			return false;
		}
		if (event.key === '0' || event.key === '1' || event.key === '2' || event.key === '3' ||
			event.key === '4' || event.key === '5' || event.key === '6' || event.key === '7' ||
			event.key === '8' || event.key === '9' || event.key === ':') {
			return true;
		}
		return false;
	}

	//From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	//From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	updateSettings(settings: CustomizationSettings) {
		this._adapter.setLocale(settings.locale.identifier);
	}

	public errorStateMatcher = new SimpleErrorStateMatcher();
	public validate(c: UntypedFormControl): ValidationErrors | null {
		if (!this.value && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				required: true
			};
		}
		this.errorStateMatcher.valid = true;
		this.errorStateMatcher.errorKey = null;
		return null;
	}

	constructor(
		private _adapter: DateAdapter<any>,
		private customizationService: CoreCustomizationService
	) {
	}

	ngOnInit() {
		this.subscriptions.push(this.customizationService.changed.subscribe(settings => this.updateSettings(settings)));
		this.validate(null);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
	}

}
