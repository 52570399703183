import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TimerService } from '../timer/timer.service';
import { AlertBanner } from './alert-banner';
import { AlertPopupComponent } from './alert-popup-component';
import { AlertMessage } from './alert.message';
import { AlertService } from './alert.service';

@Component({
	moduleId: module.id,
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit {
	public message: AlertMessage;
	verticalPosition: MatSnackBarVerticalPosition = 'top';
	horizontalPosition: MatSnackBarHorizontalPosition = 'center';
	public showLoading = false;
	public banner: AlertBanner;

	constructor(
		private alertService: AlertService,
		public snackBar: MatSnackBar,
		private timerService: TimerService,
		public translateService: TranslateService) {

	}

	onBannerTick(banner: AlertBanner) {
		if (banner.secondsLeft > 0) {
			banner.secondsLeft = banner.secondsLeft - 1;
		} else {
			this.onBannerAction(banner);
		}
	}

	onBannerAction(banner: AlertBanner) {
		if (banner.timer) {
			banner.timer.unsubscribe();
		}
		if (banner.action) {
			banner.action();
		}
	}

	ngOnInit() {
		this.alertService.subject.subscribe(next => {
			this.message = next;
			let type = '';
			 if (this.message.type === 'error') {
			 	type = 'error';
			 }
			 if (this.message.type === 'success') {
			 	type = 'success';
			 }
			 if (this.message.type === 'info') {
			 	type = 'info';
			 }
			if (this.message && this.message.text) {
					this.snackBar.openFromComponent(AlertPopupComponent, {
						duration: 10000,
						verticalPosition: this.verticalPosition,
						horizontalPosition: this.horizontalPosition,
						panelClass: ['alert-container', type],
						data: {
							message: this.message
						}
					});
			}
			this.showLoading = this.message.loading;
		});
		this.alertService.bannerAdded.subscribe(banner => {
			if (this.banner == null) {
				this.banner = banner;
				if (this.banner) {
					if (this.banner.autoAction) {
						this.banner.secondsLeft = 60;
						this.banner.timer = this.timerService.everySecond.subscribe(val => { this.onBannerTick(this.banner) });
					}
				}
			}
		});
	}
}