import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class GoToService {
	constructor(
		protected router: Router,
		protected translate: TranslateService) {

	}

	goTo(navigationRoutes: string[]) {
		this.router.navigate(navigationRoutes);
	}

	goToExternalUrl(url: string) {
		window.location.href = url;
	}

	goToPipedExernalUrl(keyToTranslationUrl: string) {
		if (!keyToTranslationUrl) {
			return;
		}

		window.location.href = this.translate.instant(keyToTranslationUrl);
	}
}