import { CrmType } from 'app/generated/backend/types/crm-type';
export class ContactUsRequestModel {
	name: string;
	company: string;
	email: string;
	message: string;
	type: CrmType;
	countryId: number;
	phone: string;
	jobTitle: string;
	originCountryId: number;
	destinationCountryId: number;
	annualVolumeTeu: string;
	captcha: string;
}
