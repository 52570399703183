import { Component, ContentChild, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AutocompleteContentDirective } from './AutocompleteContentDirective';
import { OptionComponent } from './option/OptionComponent';

@Component({
  selector: 'app-autocomplete',
  template: `
    <ng-template #root>
      <div class="autocomplete">
        <ng-container *ngTemplateOutlet="content.tpl"></ng-container>
      </div>
    </ng-template>
  `,
  exportAs: 'appAutocomplete',
  styleUrls: ['./AutocompleteComponent.css']
})
export class AutocompleteComponent {

  @ViewChild('root') rootTemplate: TemplateRef<any>;

  @ContentChild(AutocompleteContentDirective)
  content: AutocompleteContentDirective;

  @ContentChildren(OptionComponent) options: QueryList<OptionComponent>;

  @Output()
  selectItem: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  closeOption: EventEmitter<boolean> = new EventEmitter<boolean>();

  optionsClick() {
    return this.options.changes.pipe(
      switchMap(options => {
        const clicks$ = options.map(option => option.click$);
        return merge(...clicks$);
      })
    );
  }

  private optionSelectedBeforeClose: boolean = false;

  selectOption(value: number) {
    this.optionSelectedBeforeClose = true;
    this.selectItem.emit(value);
  }

  closeDropdown(isSelectionNeeded: boolean) {
    if (this.optionSelectedBeforeClose) {
      this.optionSelectedBeforeClose = false;
    } else {
      this.closeOption.emit(isSelectionNeeded);
    }

  }


}
