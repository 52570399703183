<app-panel parentTitleTranslateKey="nav.setup" titleTranslateKey="nav.place" helpUri="help/party/place/detail">

  <form *ngIf="editItem!=null" #editForm="ngForm">
    <div class="form-group row">
      <app-text-input class="col-sm-2" label="{{'field.code.label' | translate}}"
        hint="{{'field.code.hint' | translate}}" name="code" [maxlength]="15" [(ngModel)]="editItem.code"
        [disabled]="!canUpdate" helpUri="help/field/ourCode">
      </app-text-input>
      <app-text-input class="col-sm-6" label="{{'field.name.label' | translate}}"
        hint="{{'field.name.hint' | translate}}" name="name" [required]="true" [maxlength]="255"
        [(ngModel)]="editItem.name" [disabled]="!canUpdate" helpUri="help/field/ourName">
      </app-text-input>
      <app-container-equipment-input class="col-sm-4" name="containerEquipment" [(ngModel)]="editItem.equipment"
        [required]="true" label="{{'field.containerEquipment.label' | translate}}" [disabled]="!canUpdate"
        hint="{{'field.containerEquipment.hint' | translate}}" helpUri="help/field/containerEquipment">
      </app-container-equipment-input>
    </div>
    <div class="form-group row">
      <div class="col-sm-11">
        <app-text-input name="intlAddress" [(ngModel)]="editItem.intlAddress" [rows]="4" [maxLengthPerLine]="35"
          placeholder="{{'field.internationalAddress.maxLength' | translate}}" [disabled]="!canUpdate"
          hint="{{'field.internationalAddress.hint' | translate}}"
          label="{{'field.internationalAddress.label' | translate}}">
        </app-text-input>
      </div>
      <div class="col-sm-1">
        <ul class="list-inline float-right">
          <li class="list-inline-item">
            <button type="button" mat-fab color="accent" (click)="onLookupIntlAddress()"
              matTooltip="{{'field.internationalAddress.lookupHint' | translate}}">
              <span class="fal fa-fw fa-search"></span>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <div class="adn-control-label">{{'field.defaultShipmentLocation.label' | translate}}</div>
        <ul class="list-inline">
          <li class="list-inline-item">
            <mat-checkbox name="autoSelect" [(ngModel)]="editItem.autoSelect"
              title="{{'field.defaultShipmentLocation.hint' | translate}}" [disabled]="false">
              {{'field.defaultShipmentLocation.label' | translate}}
            </mat-checkbox>
          </li>
        </ul>
      </div>
    </div>

    <app-core-map *ngIf="editItem && editItem.latitude && editItem.longitude" class="adn-map-fixed-ratio" [zoom]="zoom"
      [latitude]="editItem.latitude" [longitude]="editItem.longitude" (onMapLoadedEvent)="onMapLoaded()">
      <app-core-map-marker [latitude]="editItem.latitude" [longitude]="editItem.longitude" [markerDraggable]="true"
        (dragEnd)="onDragPlaceEnd($event)">
      </app-core-map-marker>
      <app-core-map-marker *ngFor="let partyPlacePoint of partyPlacePoints" [latitude]="partyPlacePoint.latitude"
        [longitude]="partyPlacePoint.longitude" [title]="partyPlacePoint.name" [iconUrl]="partyPlacePoint.iconUrl"
        [markerDraggable]="true" (dragEnd)="onDragPointEnd(partyPlacePoint, $event)">
      </app-core-map-marker>

    </app-core-map>

    <div class="form-group row">
      <app-core-country-lookup class="col-sm-3" label="{{'field.country.label' | translate}}" name="countryId"
        [(ngModel)]="editItem.countryId" [disabled]="true"></app-core-country-lookup>
      <app-text-input class="col-sm-2" label="{{'field.postalCode.label' | translate}}" name="postalCode"
        [(ngModel)]="editItem.postalCode" [disabled]="true">
      </app-text-input>
      <app-text-input class="col-sm-7" name="nearestLocality" [ngModel]="editItem.nearestLocality?.path"
        [disabled]="true" label="{{'field.locality.label' | translate}}"></app-text-input>
    </div>

    <div *ngIf="editItem.mapAddress" class="form-group row">
      <app-text-input class="col-sm-11" label="{{'field.mapAddress.label' | translate}}" name="mapAddress"
        [disabled]="true" [rows]="4" [(ngModel)]="editItem.mapAddress"></app-text-input>

      <div class="col-sm-1">
        <ul class="list-inline float-right">
          <li class="list-inline-item">
            <button type="button" mat-fab color="accent" (click)="onCopyIntlAddress()"
              matTooltip="{{'field.mapAddress.copyToInternationalHint' | translate}}">
              <span class="fal fa-fw fa-copy"></span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group row">
      <app-text-input class="col-sm-12" label="{{'field.localAddress.label' | translate}}" name="localAddress"
        [disabled]="!canUpdate" placeholder="{{'field.localAddress.maxLength' | translate}}" [maxLengthPerLine]="35"
        [rows]="4" [(ngModel)]="editItem.localAddress"></app-text-input>
    </div>
    <div class="form-group row">
      <app-text-input class="col-sm-12" label="{{'field.placeInstructions.label' | translate}}" name="instructions"
        [disabled]="!canUpdate" [rows]="4" [(ngModel)]="editItem.instructions"></app-text-input>
    </div>


    <mat-card *ngIf="editItem.partyOperatingHours" class="card-margin adn-form-contrast">
      <mat-card-title>{{'field.operatingHours.label' | translate}}</mat-card-title>


      <div class="row align-items-center">
        <div class="col-12 col-lg-12 col-xl-6">
          <div class="form-group row">
            <div class="col-12 col-lg-12 col-xl-12 work-week">
              <app-weekdays-input name="partyStandardWorkingDays" [(ngModel)]="editItem.partyOperatingHours.days"
                label="{{'field.standardWorkingDays.label' | translate}}"
                helpUri="help/party/profile/standardWorkingDays" [disabled]="true">
              </app-weekdays-input>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-12 col-xl-6 align-self-end">
          <div class="form-group row">
            <div class="col-12 col-lg-4 col-xl-4">
              <app-time-input name="partyStandardWorkingDayStartTime" label="{{'field.fromTime.label' | translate}}"
                [(ngModel)]="editItem.partyOperatingHours.startTime"
                helpUri="help/party/profile/standardWorkingDayStartTime" [disabled]="true"></app-time-input>
            </div>
            <div class="col-12 col-lg-4 col-xl-4">
              <app-time-input name="partyStandardWorkingDayEndTime" label="{{'field.toTime.label' | translate}}"
                [(ngModel)]="editItem.partyOperatingHours.endTime"
                helpUri="help/party/profile/standardWorkingDayEndTime" [disabled]="true">
              </app-time-input>
            </div>
            <div class="col-12 col-lg-4 col-xl-4">
              <app-core-country-time-zone-lookup name="partyCountryTimeZone"
                label="{{'field.countryTimeZone.label' | translate}}"
                [(ngModel)]="editItem.partyOperatingHours.countryTimeZoneId" [disabled]="true">
              </app-core-country-time-zone-lookup>
            </div>
          </div>
        </div>
      </div>

      <mat-card-title>
        <mat-checkbox name="customOperatingHours" [checked]="editItem.placeOperatingHours"
          (change)="togglePlaceOperatingHours()">
          {{'field.customOperatingHours.label' | translate}}
        </mat-checkbox>
      </mat-card-title>

      <div *ngIf="editItem.placeOperatingHours" class="row align-items-center">
        <div class="col-12 col-lg-12 col-xl-6">
          <div class="form-group row">
            <div class="col-12 col-lg-12 col-xl-12 work-week">
              <app-weekdays-input name="placeStandardWorkingDays" [(ngModel)]="editItem.placeOperatingHours.days"
                label="{{'field.standardWorkingDays.label' | translate}}"
                helpUri="help/party/profile/standardWorkingDays" [required]="true">
              </app-weekdays-input>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-12 col-xl-6 align-self-end">
          <div class="form-group row">
            <div class="col-12 col-lg-4 col-xl-4">
              <app-time-input name="placeStandardWorkingDayStartTime" label="{{'field.fromTime.label' | translate}}"
                [(ngModel)]="editItem.placeOperatingHours.startTime"
                helpUri="help/party/profile/standardWorkingDayStartTime" [required]="true"></app-time-input>
            </div>
            <div class="col-12 col-lg-4 col-xl-4">
              <app-time-input name="placeStandardWorkingDayEndTime" label="{{'field.toTime.label' | translate}}"
                [(ngModel)]="editItem.placeOperatingHours.endTime"
                helpUri="help/party/profile/standardWorkingDayEndTime" [required]="true">
              </app-time-input>
            </div>
            <div class="col-12 col-lg-4 col-xl-4">
              <app-core-country-time-zone-lookup name="placeCountryTimeZone"
                label="{{'field.countryTimeZone.label' | translate}}"
                [(ngModel)]="editItem.placeOperatingHours.countryTimeZoneId" [required]="true">
              </app-core-country-time-zone-lookup>
            </div>
          </div>
        </div>
      </div>


    </mat-card>

    <div class="=row">
      <label>{{'nav.points' | translate}}</label>
    </div>

    <div class="form-group alert alert-danger" *ngIf="!partyPlacePoints.length">
      <div class="row">
        <div class="col-sm-12">
          <span class="fal fa-1x fa-triangle-exclamation adn-color-alert notification-icon"></span>
          <span class="notification-message">{{'error.needPartyPlacePoint' | translate}}</span>
        </div>
      </div>
    </div>

    <mat-card *ngFor="let partyPlacePoint of partyPlacePoints; let partyPlacePointIndex = index; trackBy:itemIdentity"
      class="card-margin">
      <div class="row-without-margin">
        <div class="col-sm-7 row-margin"> {{'field.movePoint.label' | translate}} </div>
        <div *ngIf="partyPlacePointIndex===0" class="col-sm-5 row-margin"> {{'field.containerEquipment.hint' |
          translate}} </div>
      </div>
      <div class="row-without-margin">
        <app-text-input class="col-sm-2 adn-form-contrast" [attr.id]="'partyPlacePointCode'+partyPlacePointIndex"
          [required]="true" name="partyPlacePointCode{{partyPlacePointIndex}}"
          label="{{'field.code.label' | translate}}" [(ngModel)]="partyPlacePoint.code" [maxlength]="15"
          pattern="^[A-Z0-9\-\/]+$" (blur)="loadIconUrl()">
        </app-text-input>
        <app-text-input class="col-sm-5 adn-form-contrast" [attr.id]="'partyPlacePointName'+partyPlacePointIndex"
          [required]="true" name="partyPlacePointName{{partyPlacePointIndex}}"
          label="{{'field.name.label' | translate}}" [(ngModel)]="partyPlacePoint.name" [maxlength]="255">
        </app-text-input>
        <app-container-equipment-input class="col-sm-3 adn-form-contrast"
          [attr.id]="'partyPlacePointEquipment'+partyPlacePointIndex"
          name="partyPlacePointEquipment{{partyPlacePointIndex}}" [(ngModel)]="partyPlacePoint.equipment"
          [required]="true" label="{{'field.containerEquipment.label' | translate}}">
        </app-container-equipment-input>
        <div class="col-sm-1 adn-buttons-horizontal text-right">
          <button mat-raised-button color="accent" (click)="moveUp(partyPlacePointIndex)"
            *ngIf="partyPlacePointIndex > 0" title="{{'button.moveUp' | translate}}">
            <i class="fal fa-fw fa-chevron-up"></i>
          </button>&nbsp;
          <button mat-raised-button color="accent" (click)="moveDown(partyPlacePointIndex)"
            *ngIf="partyPlacePointIndex < partyPlacePoints?.length-1" title="{{'button.moveDown' | translate}}">
            <i class="fal fa-fw fa-chevron-down"></i>
          </button>
        </div>
        <div class="col-sm-1 adn-buttons-horizontal text-right">
          <button type="button" mat-mini-fab color="warn" (click)="onDeletePartyPlacePoint(partyPlacePointIndex)"
            [disabled]="!canDelete">
            <span class="fal fa-fw fa-trash"></span>
          </button>
        </div>
      </div>
    </mat-card>
    <mat-card>
      <div class="row-without-margin align-items-center" *ngIf="canCreate">
        <div class="col-sm-11"> {{'field.addPoint.label' | translate}}</div>
        <div class="col-sm-1 adn-buttons-horizontal text-right">
          <app-button-add-icon (action)="onAddPartyPlacePoint()"></app-button-add-icon>
        </div>
      </div>
    </mat-card>

    <div class="form-group row">&nbsp;</div>
    <app-party-detail-footer [model]="editItem"></app-party-detail-footer>

    <div class="form-group row">
      <div class="col-sm-12 adn-buttons-horizontal text-right">
        <app-button-cancel (action)="onCancel()"></app-button-cancel>
        <app-delete-button [disabled]="!itemId || !canDelete" (action)="onDelete()"></app-delete-button>
        <app-button-save [iconClass]="'fa-object-group'" (action)="onMerge()" titleTranslateKey='button.merge'
          [disabled]="!canUpdate || !editForm.valid"></app-button-save>
        <app-button-save (action)="save()"
          [disabled]="!editForm.valid || !editItem.mapAddress || !canUpdate  || !partyPlacePoints.length">
        </app-button-save>
      </div>
    </div>
  </form>

</app-panel>