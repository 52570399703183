import { Injectable } from '@angular/core';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { CountryTimeZoneLookupModel } from 'app/generated/backend/localization/api/country-time-zone-lookup-model';
import { CountryTimeZonesHandlerService } from 'app/generated/backend/localization/service/country-time-zones-handler';
@Injectable()
export class CoreCountryTimeZoneLookupService extends CachedEntityService<CountryTimeZoneLookupModel> {
	constructor(
		countryTimeZonesHandlerService: CountryTimeZonesHandlerService,
		cacheService: CoreCacheService
	) {
		super(countryTimeZonesHandlerService, cacheService.getCountryTimeZones());
	}
	transformItem(item: CountryTimeZoneLookupModel): CountryTimeZoneLookupModel {
		if (item) {
			item.match = '';
			if (item.olson) {
				item.match += item.olson.toLowerCase();
			}
		}
		return item;
	}
}
