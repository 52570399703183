import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum PartyCategory {
	None = 0,
	Customer = 1,
	Supplier = 2,
	Carrier = 4,
	Data = 8,
	Internal = 16,
	Edi = 32,
}

@Pipe({
name: 'partyCategory'
})
export class PartyCategoryPipe extends BasePipe<PartyCategory> implements PipeTransform {
	static prefix = 'Backend.Types.PartyCategory.';
	static keys = [
		PartyCategory.None,
		PartyCategory.Customer,
		PartyCategory.Supplier,
		PartyCategory.Carrier,
		PartyCategory.Data,
		PartyCategory.Internal,
		PartyCategory.Edi];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: PartyCategory): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case PartyCategory.None: return 'None';
			case PartyCategory.Customer: return 'Customer';
			case PartyCategory.Supplier: return 'Supplier';
			case PartyCategory.Carrier: return 'Carrier';
			case PartyCategory.Data: return 'Data';
			case PartyCategory.Internal: return 'Internal';
			case PartyCategory.Edi: return 'Edi';
			default: return null;
		}
	}
	transform(value: PartyCategory, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(PartyCategoryPipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(PartyCategoryPipe.keys, PartyCategoryPipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		PartyCategoryPipe
	],
	exports: [
		PartyCategoryPipe
	],
	providers: [
		PartyCategoryPipe
	]
})
export class PartyCategoryModule { }
