import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { CoreMeasureBaseComponent, MeasureUnit } from './CoreMeasureBaseComponent';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreLengthInputComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreLengthInputComponent),
	multi: true
};

@Component({
	selector: 'app-length-input',
	templateUrl: './CoreMeasureBaseComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreLengthInputComponent extends CoreMeasureBaseComponent implements OnInit, Validator {

	units: MeasureUnit[] = [
		{ factor: 0.01, name: 'cm', offset: 0, metric: true },
		{ factor: 1, name: 'm', offset: 0, metric: true },
		{ factor: 1000, name: 'km', offset: 0, metric: true },
		{ factor: 0.0254, name: 'in', offset: 0, metric: false },
		{ factor: 0.3048, name: 'ft', offset: 0, metric: false },
		{ factor: 1609, name: 'mi', offset: 0, metric: false }
	];
	unit: MeasureUnit = this.units[1];
}
