import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreTaskSummaryModule } from 'app/core/task-summary/CoreTaskSummaryModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { MenuComponent } from './MenuComponent';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		CommonMaterialModule,
		TranslateModule,
		RouterModule,
		CoreTaskSummaryModule
	],
	exports: [MenuComponent],
	declarations: [MenuComponent]
})
export class MenuModule { }
