import { LocalityLookupModel } from 'app/generated/backend/types/locality-lookup-model';
import { ProcessVariant } from 'app/generated/backend/types/process-variant';
import { ShipmentCargoDetail } from 'app/generated/backend/shipment/api/shipment-cargo-detail';
import { ShipmentCargoGroup } from 'app/generated/backend/shipment/api/shipment-cargo-group';
import { ShipmentCharge } from 'app/generated/backend/shipment/api/shipment-charge';
import { ShipmentContainer } from 'app/generated/backend/shipment/api/shipment-container';
import { ShipmentDocument } from 'app/generated/backend/shipment/api/shipment-document';
import { ShipmentLocation } from 'app/generated/backend/shipment/api/shipment-location';
import { ShipmentPackage } from 'app/generated/backend/shipment/api/shipment-package';
import { ShipmentPackingList } from 'app/generated/backend/shipment/api/shipment-packing-list';
import { ShipmentParty } from 'app/generated/backend/shipment/api/shipment-party';
import { ShipmentService } from 'app/generated/backend/shipment/api/shipment-service';
import { ShipmentStatus } from 'app/generated/backend/types/shipment-status';
import { ShipmentType } from 'app/generated/backend/types/shipment-type';
import { TransportMode } from 'app/generated/backend/types/transport-mode';
export class Shipment {
	id: number;
	updatedUserId: number;
	version: number;
	updatedAt: string;
	createdAt: string;
	createdUserId: number;
	firstSubmittedAt: string;
	firstSubmittedUserId: number;
	submittedAt: string;
	submittedUserId: number;
	bookerPartyId: number;
	bookerReference: string;
	buyerPartyId: number;
	buyerReference: string;
	sellerPartyId: number;
	sellerReference: string;
	senderPartyId: number;
	senderReference: string;
	receiverPartyId: number;
	receiverReference: string;
	carriagePartyId: number;
	carrierPartyId: number;
	pickupFrom: string;
	pickupBefore: string;
	deliverFrom: string;
	deliverBefore: string;
	estimatedDepartureAt: string;
	estimatedArrivalAt: string;
	actualDepartureAt: string;
	actualArrivalAt: string;
	departureGateInAt: string;
	departureLoadedAt: string;
	actualDepartureAtReportedBy: number;
	actualArrivalAtReportedBy: number;
	partiesSubmittedAt: string;
	partiesSubmittedUserId: number;
	cargoDetailsSubmittedAt: string;
	cargoDetailsSubmittedUserId: number;
	cargoValueSubmittedAt: string;
	cargoValueSubmittedUserId: number;
	cargoGroupsSubmittedAt: string;
	cargoGroupsSubmittedUserId: number;
	containersSubmittedAt: string;
	containersSubmittedUserId: number;
	packagesSubmittedAt: string;
	packagesSubmittedUserId: number;
	number: string;
	status: ShipmentStatus;
	type: ShipmentType;
	mode: TransportMode;
	process: ProcessVariant;
	incotermsId: number;
	hasNamedOrigin: boolean;
	hasNamedDestination: boolean;
	namedOriginLocality: LocalityLookupModel;
	namedDestinationLocality: LocalityLookupModel;
	departureLocationId: number;
	arrivalLocationId: number;
	teu: number;
	cube: number;
	coEmission: number;
	weight: number;
	distance: number;
	scheduleDurationDays: number;
	actualDurationDays: number;
	pendingTaskCount: number;
	overdueTaskCount: number;
	services: ShipmentService[];
	deliveryLocation: ShipmentLocation;
	pickupLocation: ShipmentLocation;
	parties: ShipmentParty[];
	packingLists: ShipmentPackingList[];
	containers: ShipmentContainer[];
	cargoGroups: ShipmentCargoGroup[];
	cargoDetails: ShipmentCargoDetail[];
	packages: ShipmentPackage[];
	charges: ShipmentCharge[];
	documents: ShipmentDocument[];
	canCancel: boolean;
	canModifyParty: boolean;
	canRecalculate: boolean;
	canChangeShipmentType: boolean;
	canModifyPickupDate: boolean;
	canModifyCargo: boolean;
	canModifyCargoValue: boolean;
	canModifyContainer: boolean;
	canModifyContainerReference: boolean;
	canModifyPackage: boolean;
	canAddPackingList: boolean;
	canSupplierConfirmDelivery: boolean;
	canModifySenderReferenceNumber: boolean;
	canModifyReceiverReferenceNumber: boolean;
	canReadCargoValue: boolean;
}
