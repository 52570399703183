<div class="adn-form-field"
  [ngClass]="{'adn-form-field-invalid': !errorStateMatcher.valid, 'adn-form-field-disabled': disabled}">
  <label *ngIf="label">
    <span *ngIf="iconClass" class="fal fa-fw adn-color-primary" [ngClass]="iconClass" aria-hidden="true"></span>
    {{label}}<span *ngIf="required">&nbsp;*</span>
  </label>
  <div class="adn-form-field-content">
    <span class="input-prefix" *ngIf="country != null">
      <img alt="" src="/assets/flags/4x3/{{country.iso2 | lowercase}}.svg" width="20" height="15"
        style="margin-right: 8px" />
    </span>
    <span class="input-prefix" *ngIf="value">+</span>
    <input class="input-style" type="text" [(ngModel)]="value" [placeholder]="placeholder" (blur)="onBlur()"
      [required]="required" [disabled]="disabled" (focus)="onFocus()" pattern="^[0-9]*$" autocomplete="none" />
    <div *ngIf="loading" class="adn-input-icon-spinner-container">
      <i class="adn-input-icon-spinner-loader"></i>
    </div>
    <div class="input-icon" *ngIf="value">
      <button mat-button mat-icon-button matSuffix *ngIf="value && !required && !disabled" type="button"
        (click)="value=''" title="{{ 'field.clear.label' | translate}}" tabindex="-1">
        <span class="fal fa-fw fa-times-circle"></span>
      </button>
    </div>
    <div class="input-icon">
      <a href="tel:+{{value}}" *ngIf="value && errorStateMatcher.valid" mat-button matSuffix mat-icon-button
        title="{{'button.call' | translate}}" target="_blank" rel="noopener">
        <span class="fal fa-fw fa-phone adn-color-primary" aria-hidden="true"></span></a>
    </div>
    <app-help-popup-button class="input-icon" *ngIf="helpUri" [uri]="helpUri"></app-help-popup-button>
  </div>
</div>
<div class="adn-form-field-hint" *ngIf="errorStateMatcher.valid && hint">{{hint}}</div>
<div class="adn-form-field-error" *ngIf="!errorStateMatcher.valid">
  <span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;<span
    class="error-text">{{errorStateMatcher.errorKey | translate}}</span>
</div>