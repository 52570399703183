import { Component, OnInit } from '@angular/core';
import { Menu } from 'app/core/menu/Menu';
import { Subscription } from 'rxjs';
import { AdminMenuService } from "./AdminMenuService";
@Component({
	selector: 'app-admin-menu',
	templateUrl: './AdminMenuComponent.html'
})
export class AdminMenuComponent implements OnInit {
	public menu: Menu;
	private subscriptions = new Array<Subscription>();
	constructor(private adminMenuService: AdminMenuService) {
	}
	ngOnInit() {
		this.subscriptions.push(this.adminMenuService.menuUpdated.subscribe(menu => this.menu = menu));
	}
	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
