import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum ServiceCode {
	None = 0,
	Pickup = 1,
	ExportClearance = 2,
	OriginHandling = 6,
	Carriage = 3,
	ImportClearance = 4,
	DestinationHandling = 7,
	Delivery = 5,
}

@Pipe({
name: 'serviceCode'
})
export class ServiceCodePipe extends BasePipe<ServiceCode> implements PipeTransform {
	static prefix = 'Backend.Types.ServiceCode.';
	static keys = [
		ServiceCode.None,
		ServiceCode.Pickup,
		ServiceCode.ExportClearance,
		ServiceCode.OriginHandling,
		ServiceCode.Carriage,
		ServiceCode.ImportClearance,
		ServiceCode.DestinationHandling,
		ServiceCode.Delivery];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ServiceCode): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ServiceCode.None: return 'None';
			case ServiceCode.Pickup: return 'Pickup';
			case ServiceCode.ExportClearance: return 'ExportClearance';
			case ServiceCode.OriginHandling: return 'OriginHandling';
			case ServiceCode.Carriage: return 'Carriage';
			case ServiceCode.ImportClearance: return 'ImportClearance';
			case ServiceCode.DestinationHandling: return 'DestinationHandling';
			case ServiceCode.Delivery: return 'Delivery';
			default: return null;
		}
	}
	transform(value: ServiceCode, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ServiceCodePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ServiceCodePipe
	],
	exports: [
		ServiceCodePipe
	],
	providers: [
		ServiceCodePipe
	]
})
export class ServiceCodeModule { }
