import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LocalityIconComponent } from './LocalityIconComponent';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MatIconModule,
		TranslateModule
	],
	declarations: [LocalityIconComponent],
	exports: [LocalityIconComponent]
})
export class LocalityIconModule { };
