import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { CoreCurrencyLookupService } from 'app/core/currency/CoreCurrencyLookupService';
import { CoreLocaleLookupService } from 'app/core/locale/CoreLocaleLookupService';
import { CurrencyLookupModel } from 'app/generated/backend/currency/api/currency-lookup-model';
import { LocaleLookupModel } from 'app/generated/backend/localization/api/locale-lookup-model';
import { SettingsModel } from 'app/generated/backend/user/api/settings-model';
import { SettingsHandlerService } from 'app/generated/backend/user/service/settings-handler';
import { DateOrderStyle } from 'app/generated/system/date-time/date-order-style';
import { BehaviorSubject } from 'rxjs';
import { CoreAuthenticationService } from '../authentication/CoreAuthenticationService';
import { CustomizationSettings } from './CustomizationSettings';
import { LocaleSource } from './LocaleSource';
import { MapVendor } from './MapVendor';

interface T {
	title: string
}
@Injectable()
export class CoreCustomizationService {
	private _decimalPipe = new DecimalPipe('en-US');
	private _showKeys = false;
	private _localeSource = LocaleSource.Default;
	private _localStorageEnabled = false;
	// Used until the local storage service is enabled
	private _storageCache = new Map<string, any>();

	public set showKeys(showKeys: boolean) {
		if (showKeys !== this._showKeys) {
			this._showKeys = showKeys;
			if (showKeys) {
				this.translateService.setDefaultLang('keys');
				this.translateService.use('keys');
				this.settings.locale.isActive = false;
			} else {
				this.translateService.setDefaultLang('en-gb');
				this.translateService.use(this.settings.locale.identifier);
			}
			this.changed.next(this.settings);
		}
	}
	public get showKeys(): boolean {
		return this._showKeys;
	}
	// Fallback default settings before the real locale has been detected
	// Do not rely on id numbers in the code, set to null here to prevent conflict between different databases
	private settings: CustomizationSettings = {
		locale: {
			id: null,
			flag: 'us',
			name: 'English (US)',
			languageId: null,
			languageIso2: 'en',
			topLevelDomain: 'com',
			countryId: null,
			countryIso2: 'us',
			countryLocalName: 'United States',
			identifier: 'en-us',
			columnSeparator: ',',
			decimalSeparator: '.',
			digitGroupingSymbol: ',',
			metric: false,
			isDefault: false,
			dateOrderStyle: DateOrderStyle.MDY,
			dateFormat: 'MM/dd/yyyy',
			dateTimeFormat: 'MM/dd/yyyy h:mm a',
			preciseDateTimeFormat: 'MM/dd/yyyy h:mm:ss a',
			twelveHourSymbolAm: 'AM',
			twelveHourSymbolPm: 'PM',
			isActive: true,
			match: '',
			moderatedLanguage: false
		},
		mapVendor: MapVendor.Google,
		pageSize: 10,
		limit: 10,
		showInfo: true
	};

	getCurrencyIso3(): string {
		if (this._currency) {
			return this._currency.iso3;
		}
		return null;
	}

	getLanguageIso2(): string {
		return this.settings.locale.languageIso2;
	}

	getLocale(): LocaleLookupModel {
		return this.settings.locale;
	}
	public changed: BehaviorSubject<CustomizationSettings>;
	public currencyChanged: BehaviorSubject<CurrencyLookupModel>;
	private _currency: CurrencyLookupModel;
	public get currency(): CurrencyLookupModel {
		return this._currency;
	}
	public set currency(currency: CurrencyLookupModel) {
		this._currency = currency;
		this.currencyChanged.next(currency);
	}
	constructor(
		private localStorageService: LocalStorageService,
		private userSettingsService: SettingsHandlerService,
		private authenticationService: CoreAuthenticationService,
		private localeService: CoreLocaleLookupService,
		private currencyService: CoreCurrencyLookupService,
		private translateService: TranslateService
	) {
		this._currency = new CurrencyLookupModel();
		this._currency.iso3 = 'USD';
		this._currency.unitPerUsd = 1;
		this.currencyChanged = new BehaviorSubject<CurrencyLookupModel>(this._currency);
		this.changed = new BehaviorSubject(this.settings);
		const tld = window.location.hostname.split('.').pop();
		if (tld === 'cn') {
			this.settings.mapVendor = MapVendor.Baidu;
		}
		this.changed.next(this.settings);

		this.authenticationService.authenticationChanged.subscribe(auth => {
			if (auth) {
				if (auth.user && auth.user.settings) {
					this.updateSettingsFromUserSettings(auth.user.settings);
				} else if (auth.clientLocale && auth.clientLocale.length >= 2) {

					this.setLocaleFromClientLocale(auth.clientLocale, tld);
				} else {
					this.setLocaleFromTopLevelDomain(tld);
				}
			}
		});
	}
	getDecimalPipe(): DecimalPipe {
		return this._decimalPipe;
	}

	private setLocaleFromClientLocale(clientLocale: string, tld: string) {
		this.localeService.getItems().subscribe(locales => {
			let selectedLocale: LocaleLookupModel = null;
			if (locales) {
				locales.forEach(locale => {
					if (!selectedLocale && locale.identifier === clientLocale) {
						this.setLocale(locale, LocaleSource.UserAgent);
						selectedLocale = locale;
					}
				});
				if (!selectedLocale) {
					if (clientLocale.length > 2) {
						clientLocale = clientLocale.substr(0, 2);
					}
					let matchCount = 0;
					locales.forEach(locale => {
						if (locale.identifier.length >= 2 && locale.identifier.substr(0, 2) === clientLocale) {
							matchCount++;
							selectedLocale = locale;
						}
					});
					if (matchCount > 1) {
						locales.forEach(locale => {
							if (locale.identifier.length >= 2
								&& locale.identifier.substr(0, 2) === clientLocale
								&& locale.isDefault) {
								selectedLocale = locale;
							}
						});
					}
					if (selectedLocale && !this.setLocaleFromLocalesAndTopLevelDomain(locales, tld)) {
						this.setLocale(selectedLocale, LocaleSource.UserAgent);
					}
				}
			}
			if (!selectedLocale) {
				this.setLocaleFromLocalesAndTopLevelDomain(locales, tld);
			}
		},
			error => {
				console.error(error);
				this.setLocaleFromTopLevelDomain(tld);
			}
		);
	}
	private setLocaleFromLocalesAndTopLevelDomain(locales: LocaleLookupModel[], tld: string): boolean {
		let localeFound = false;
		locales.forEach(locale => {
			if (locale.topLevelDomain && locale.topLevelDomain === tld) {
				this.setLocale(locale, LocaleSource.DomainName);
				localeFound = true;
			}
		}
		);
		return localeFound;
	}
	private setLocaleFromTopLevelDomain(tld: string) {
		this.localeService.getItems().subscribe(locales => {
			if (locales) {
				this.setLocaleFromLocalesAndTopLevelDomain(locales, tld);
			}
		},
			error => {
				console.error(error);
			});
	}
	public updateSettingsFromUserSettings(userSettings: SettingsModel) {
		if (userSettings) {
			if (userSettings.localeId) {
				this.localeService.getItem(userSettings.localeId).subscribe(locale => this.setLocale(locale, LocaleSource.UserSettings));
			}
			if (userSettings.currencyId) {
				this.currencyService.getItem(userSettings.currencyId).subscribe(currency => this.setCurrency(currency));
			}
			this.settings.showInfo = userSettings.showInfo;
		}
	}
	setCurrency(currency: CurrencyLookupModel) {
		if (currency) {
			this._currency = currency;
			this.currencyChanged.next(currency);
		}
	}
	set(key: string, value: any) {
		if (this._localStorageEnabled) {
			this.localStorageService.set(key, value);
		} else {
			this._storageCache.set(key, value);
		}
	}
	get(key: string, fallback: any = null): any {
		let value = null;
		if (this._localStorageEnabled) {
			value = this.localStorageService.get(key);
		} else {
			value = this._storageCache.get(key);
		}
		if (value == null) {
			value = fallback;
		}
		return value;
	}
	public enableLocalStorage(): void {

		if (!this._localStorageEnabled) {
			if (this.localStorageService.isSupported) {
				this._localStorageEnabled = true;
				this._storageCache.clear();
			}

		}
	}
	public disableLocalStorage(): void {
		this.clear();
		if (this._localStorageEnabled) {
			this._localStorageEnabled = false;
		}
	}
	clear() {
		if (this._localStorageEnabled) {
			this.localStorageService.clearAll();
			this._storageCache.clear();
		}
	}
	setLocale(locale: LocaleLookupModel, source: LocaleSource) {
		if (source < this._localeSource || !locale || locale.id === this.settings.locale.id) {
			return;
		}
		this._localeSource = source;
		this._showKeys = false;
		this.settings.locale.isActive = false;
		this.settings.locale = locale;
		this.settings.locale.isActive = true;
		this.translateService.use(locale.identifier);
		this._decimalPipe = new DecimalPipe(locale.identifier);
		const auth = this.authenticationService.getAuthentication();
		if (auth) {
			const user = auth.user;
			if (user) {
				const request = new SettingsModel();
				request.localeId = locale.id;
				this.userSettingsService.patch(request, user.id).subscribe();
			}
		}
		this.changed.next(this.settings);
	}
	getSettings(): CustomizationSettings {
		return this.settings;
	}
	public getLocaleIdentifier(): string {
		return this.settings.locale.identifier;
	}
	sortTitleByAlphabet(list: T[]) {
		if (this.settings.locale.languageIso2 === 'zh') {
			list.sort((a, b) => {
				return a.title.localeCompare(b.title, ["zh-CN-u-co-pinyin"]);
			});
		} else {
			list.sort((a, b) => {
				return a.title.localeCompare(b.title, this.settings.locale.identifier, { sensitivity: 'base' });
			});
		}
	}

	addCampaign(campaign: string) {
		this.set("utm_campaign", campaign);
	}

	getCampaign(): string {
		return this.get("utm_campaign");
	}
}
