import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SearchResultResponse } from 'app/generated/backend/search/api/search-result-response';

@Injectable()
export class SearchHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		text: string,
		limit: number,
		languageIso2: string
	): Observable<JsonResourceResponse<SearchResultResponse>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (text!==null && text!==undefined) {
			params = params.set('text', text);
		}
		if (limit!==null && limit!==undefined) {
			params = params.set('limit', limit.toString());
		}
		if (languageIso2!==null && languageIso2!==undefined) {
			params = params.set('languageIso2', languageIso2);
		}
		return this.http.get<JsonResourceResponse<SearchResultResponse>>('api/v1/search/search', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		SearchHandlerService
	]
})
export class SearchHandlerModule { }
