import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum ServiceClass {
	None = 0,
	Priority = 1,
	Economy = 2,
	Multimodal = 4,
	All = 7,
}

@Pipe({
name: 'serviceClass'
})
export class ServiceClassPipe extends BasePipe<ServiceClass> implements PipeTransform {
	static prefix = 'Backend.Types.ServiceClass.';
	static keys = [
		ServiceClass.None,
		ServiceClass.Priority,
		ServiceClass.Economy,
		ServiceClass.Multimodal,
		ServiceClass.All];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ServiceClass): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ServiceClass.None: return 'None';
			case ServiceClass.Priority: return 'Priority';
			case ServiceClass.Economy: return 'Economy';
			case ServiceClass.Multimodal: return 'Multimodal';
			case ServiceClass.All: return 'All';
			default: return null;
		}
	}
	transform(value: ServiceClass, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ServiceClassPipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(ServiceClassPipe.keys, ServiceClassPipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ServiceClassPipe
	],
	exports: [
		ServiceClassPipe
	],
	providers: [
		ServiceClassPipe
	]
})
export class ServiceClassModule { }
