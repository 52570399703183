import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum EntityClass {
	None = 0,
	Company = 1,
	Party = 3,
	Shipment = 4,
	ShipmentContainer = 5,
	ShipmentBooking = 6,
	Content = 7,
	ShipmentService = 8,
}

@Pipe({
name: 'entityClass'
})
export class EntityClassPipe extends BasePipe<EntityClass> implements PipeTransform {
	static prefix = 'Backend.Types.EntityClass.';
	static keys = [
		EntityClass.None,
		EntityClass.Company,
		EntityClass.Party,
		EntityClass.Shipment,
		EntityClass.ShipmentContainer,
		EntityClass.ShipmentBooking,
		EntityClass.Content,
		EntityClass.ShipmentService];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: EntityClass): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case EntityClass.None: return 'None';
			case EntityClass.Company: return 'Company';
			case EntityClass.Party: return 'Party';
			case EntityClass.Shipment: return 'Shipment';
			case EntityClass.ShipmentContainer: return 'ShipmentContainer';
			case EntityClass.ShipmentBooking: return 'ShipmentBooking';
			case EntityClass.Content: return 'Content';
			case EntityClass.ShipmentService: return 'ShipmentService';
			default: return null;
		}
	}
	transform(value: EntityClass, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(EntityClassPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		EntityClassPipe
	],
	exports: [
		EntityClassPipe
	],
	providers: [
		EntityClassPipe
	]
})
export class EntityClassModule { }
