<app-panel titleTranslateKey="nav.selectLanguage" helpUri="help/public/selectLanguage" [panelWidth]="4" [middle]="true">
    <div class="form-group row">
        <div class="col-sm-2 adn-locale-button-container" *ngFor="let locale of moderatedLocales">
            <button class="adn-locale-button" mat-raised-button (click)="setLocale(locale)"
                [color]="locale.isActive?'accent':null">
                <div class="adn-locale-button-text">
                    <b>{{locale.name}} </b>
                </div>
                <div class="adn-locale-button-text ">
                    <img alt="" src="/assets/flags/4x3/{{locale.flag}}.svg" width="20" height="15"
                        class="adn-menu-flags" />&nbsp;{{locale.countryLocalName }}
                </div>
            </button>
        </div>
        <div class="col-sm-12"></div>
    </div>
    <div class="form-group row top-border">
        <app-content uri="help/public/otherLanguage"></app-content>
    </div>
    <div class="form-group row">
        <div class="col-sm-2 adn-locale-button-container" *ngFor="let locale of otherLocales">
            <button class="adn-locale-button" mat-raised-button (click)="setLocale(locale)"
                [color]="locale.isActive?'accent':null">
                <div class="adn-locale-button-text">
                    <b>{{locale.name}} </b>
                </div>
                <div class="adn-locale-button-text ">
                    <img alt="" src="/assets/flags/4x3/{{locale.flag}}.svg" width="20" height="15"
                        class="adn-menu-flags" />&nbsp;{{locale.countryId | countryName | async }}
                </div>
            </button>
        </div>
        <div class="col-sm-2 adn-locale-button-container" *ngIf="isAdmin">
            <button class="adn-locale-button" mat-raised-button (click)="setLocaleKeys()"
            [color]="getShowKeys() ? 'accent' : null">
                <div class="adn-locale-button-text">
                    <span class="fal fa-fw fa-material fa-language adn-menu-icons" aria-hidden="true"></span>Keys
                </div>
            </button>
        </div>
    </div>
</app-panel>
