import { Injectable } from '@angular/core';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { CoreCacheService } from '../cache/CoreCacheService';
import { IncotermsLookupHandlerService } from 'app/generated/backend/trade/service/incoterms-lookup-handler';
import { IncotermsLookupModel } from 'app/generated/backend/trade/api/incoterms-lookup-model';

@Injectable()
export class CoreIncotermsLookupService extends CachedEntityService<IncotermsLookupModel> {
	constructor(
		lookupHandlerService: IncotermsLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(lookupHandlerService, cacheService.getIncotermsLookups());
	}
	transformItem(item: IncotermsLookupModel): IncotermsLookupModel {
		if (item) {
			item.match = '';
			if (item.code) {
				item.match += item.code.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}
}
