import { Injectable } from '@angular/core';
import { addDays, differenceInMinutes } from "date-fns";

@Injectable()
export class CoreDateTimeService {

	getDate(input: string): string {
		// get date from a dateTime isoString
		return input.substring(0, 10);
	}

	addDays(input: string, offset: number): string {
		const dateTime = new Date(input);
		const newDateTime = addDays(dateTime, offset);
		const newDateString = newDateTime.toISOString();
		return newDateString.substring(0, 10) + input.substring(10);
	}

	removeTimeZone(input: string): string {
		// remove timeZone from isoString
		return input.substring(0, 19);
	}

	getDurationInMinutes(to: string, from: string): number {
		// only accepts datetime isoString without timezone
		if (to.length != 19 || from.length != 19) {
			return null;
		}
		const dateTo = new Date(to);
		const dateFrom = new Date(from);
		return differenceInMinutes(dateTo, dateFrom);
	}

	getHour(input: string): number {
		return parseInt(input.substring(11, 13));
	}

	getMinute(input: string): number {
		return parseInt(input.substring(14, 16));
	}

	getJsDateInBrowserTimezone(input: string): Date {
		// !! use this function carefully !!
		// ignore timezone of iso-string and create a JS date object with browser timezone
		if (!input) {
			return new Date();
		}
		const date = new Date(this.removeTimeZone(input));
		return date;
	}

	getLocalStringFromJsDate(input: Date): string {
		// !! use this function carefully !!
		// ignore timezone of JS Date object and create iso-string without timezone
		let hour = '';
		if (input.getHours() >= 10 && input.getHours() < 24) {
			hour = input.getHours().toString();
		} else if (input.getHours() >= 0 && input.getHours() < 10) {
			hour = '0' + input.getHours().toString();
		} else {
			hour = '00';
		}
		let minute = '';
		if (input.getMinutes() >= 10 && input.getHours() < 60) {
			minute = input.getMinutes().toString();
		} else if (input.getMinutes() >= 0 && input.getMinutes() < 10) {
			minute = '0' + input.getMinutes().toString();
		} else {
			minute = '00';
		}
		let second = '';
		if (input.getSeconds() >= 10 && input.getSeconds() < 60) {
			second = input.getSeconds().toString();
		} else if (input.getSeconds() >= 0 && input.getSeconds() < 10) {
			second = '0' + input.getSeconds().toString();
		} else {
			second = '00';
		}
		// please do not use to toISOString for getting date part only. e.g. 2022-9-9 7:30 Singapore browser would fall into 2022-9-8
		return this.formatDate(input) + 'T' + hour + ':' + minute + ':' + second;
	}

	formatDate(date: Date): string {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	}

}
