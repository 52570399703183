import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CoreDateInputComponent } from 'app/core/date-time/CoreDateInputComponent';
import { CoreLocalityDateComponent } from 'app/core/date-time/CoreLocalityDateComponent';
import { CoreTimeInputComponent } from 'app/core/date-time/CoreTimeInputComponent';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CalendarClickOutDirective } from './CalendarClickOutDirective';
import { CoreDateTimeService } from './CoreDateTimeService';
import { CoreLocalDatePipe } from './CoreLocalDatePipe';
import { CoreLocalDateTimePipe } from './CoreLocalDateTimePipe';
import { CoreLocalityDateTimeComponent } from './CoreLocalityDateTimeComponent';
import { CoreLocalizedDatePipe } from './CoreLocalizedDatePipe';
import { CoreLocalizedDateTimePipe } from './CoreLocalizedDateTimePipe';
import { CoreLocalMonthDayPipe } from './CoreLocalMonthDayPipe';

@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		FormsModule,
		HelpPopupModule,
		MatDatepickerModule,
		NgbModule,
		OverlayModule,
		TranslateModule,
	],
	declarations: [
		CoreDateInputComponent,
		CoreLocalDatePipe,
		CoreLocalDateTimePipe,
		CoreLocalMonthDayPipe,
		CoreLocalityDateComponent,
		CoreLocalityDateTimeComponent,
		CoreLocalizedDatePipe,
		CoreLocalizedDateTimePipe,
		CoreTimeInputComponent,
		CalendarClickOutDirective,
	],
	exports: [
		CoreDateInputComponent,
		CoreLocalDatePipe,
		CoreLocalDateTimePipe,
		CoreLocalMonthDayPipe,
		CoreLocalityDateComponent,
		CoreLocalityDateTimeComponent,
		CoreLocalizedDatePipe,
		CoreLocalizedDateTimePipe,
		CoreTimeInputComponent,
	],
	providers: [CoreDateTimeService]
})
export class CoreDateTimeModule { }
