import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-party-detail-footer',
	templateUrl: './PartyDetailFooterComponent.html'
})
export class PartyDetailFooterComponent {
	@Input()
	model: any;
}
