import { ModuleWithProviders, NgModule } from '@angular/core';
import { DynamicHtmlComponent } from './DynamicHtmlComponent';
import { DynamicHtmlOptions } from './DynamicHtmlOptions';
import { DynamicHtmlRenderer } from './DynamicHtmlRenderer';
@NgModule({
    declarations: [DynamicHtmlComponent],
    exports: [DynamicHtmlComponent],
})
export class DynamicHtmlModule {
    static forRoot(options: DynamicHtmlOptions): ModuleWithProviders<DynamicHtmlModule> {
        return {
            ngModule: DynamicHtmlModule,
            providers: [
                DynamicHtmlRenderer,
                { provide: DynamicHtmlOptions, useValue: options }
            ],
        };
    }
}