import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { Constants } from 'app/core/Constants';
import { ShipmentCargoGroup } from 'app/generated/backend/shipment/api/shipment-cargo-group';
import { ContainerLookupHandlerService } from 'app/generated/backend/shipment/service/container-lookup-handler';
import { PackageTypeLookupModel } from 'app/generated/backend/trade/api/package-type-lookup-model';
import { Feature } from 'app/generated/backend/types/feature';
import { ShipmentPackageProperties } from 'app/generated/backend/types/shipment-package-properties';
import { ShipmentType } from 'app/generated/backend/types/shipment-type';
import { Subscription } from 'rxjs';
import { CargoType } from '../../generated/backend/types/cargo-type';
import { ContainerSize } from '../../generated/backend/types/container-size';
import { ContainerType } from '../../generated/backend/types/container-type';

@Component({
	selector: 'app-shipment-cargo-groups',
	templateUrl: './ShipmentCargoGroupsComponent.html',
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ShipmentCargoGroupsComponent implements OnInit, OnDestroy {
	private _cargoGroups: ShipmentCargoGroup[];
	public get cargoGroups(): ShipmentCargoGroup[] {
		return this._cargoGroups;
	}
	@Input()
	public set cargoGroups(value: ShipmentCargoGroup[]) {
		if (value !== this._cargoGroups) {
			this._cargoGroups = value;
			this.cargoGroupsChange.emit(value);
		}
	}
	@Output()
	public cargoGroupsChange = new EventEmitter<ShipmentCargoGroup[]>();


	private _shipmentType: ShipmentType;
	public get shipmentType(): ShipmentType {
		return this._shipmentType;
	}
	@Input()
	public set shipmentType(value: ShipmentType) {
		if (value && this._shipmentType !== value) {
			if (this._shipmentType && this._shipmentType !== value) {
				this.shipmentTypeChange.emit(value);
			}
			this._shipmentType = value;
		}
	}
	@Input()
	public disabled = false;
	@Output()
	public shipmentTypeChange = new EventEmitter<ShipmentType>();

	protected subscriptions = new Array<Subscription>();

	public showCargo = false;
	public valid: boolean;
	public isPublic = true;
	public showLcl = false;
	public ShipmentType = ShipmentType;
	public packagePropertyStackable = ShipmentPackageProperties.Stackable;
	public packagePropertyNonStackable = ShipmentPackageProperties.NonStackable;
	public packageProperties = [ShipmentPackageProperties.Stackable];
	private containerMaxWeights = new Map<ContainerSize, number>();
	private containerMaxTemperature = new Map<ContainerSize, number>();
	private containerMinTemperature = new Map<ContainerSize, number>();
	public minLengthValue: number;
	public minWidthValue: number;
	public minHeightValue: number;
	public isLengthReadonly: boolean = false;
	public isWidthReadonly: boolean = false;
	public isHeightReadonly: boolean = false;
	public widthGreaterThanLength: boolean = false;
	public exceededMaxWeight: boolean = false;
	public greaterThanMaxTemperature: boolean = false;
	public lessThanMinTemperature: boolean = false;
	public ContainerType = ContainerType;
	constructor(
		private authenticationService: CoreAuthenticationService,
		private containerLookupService: ContainerLookupHandlerService
	) {
	}

	processDimension() {
		this.widthGreaterThanLength = false;
		if (this.cargoGroups && this.cargoGroups.length) {
			this.cargoGroups.forEach(cargoGroup => {
				if (cargoGroup.width && cargoGroup.length && cargoGroup.width > cargoGroup.length) {
					this.widthGreaterThanLength = true;
				}
			});
		}
	}

	personalize() {
		this.showLcl = this.authenticationService.hasFeature(Feature.Lcl);
		this.isPublic = !this.authenticationService.isValidated();
	}

	ngOnInit() {
		this.personalize();
		this.prepareModel();
		this.subscriptions.push(this.authenticationService.authenticationChanged.subscribe(change => {
			this.personalize();
			this.prepareModel();
		}));
		this.setContainerMaxes(ContainerSize.Iso22);
		this.setContainerMaxes(ContainerSize.Iso42);
		this.setContainerMaxes(ContainerSize.Iso45);
	}
	prepareModel() {
		this.minLengthValue = Constants.MinLengthValue;
		this.minWidthValue = Constants.MinWidthValue;
		this.minHeightValue = Constants.MinHeightValue;
		this.checkWeightExceed();
		this.checkTemperatureExceed();
	}

	public setContainerMaxes(containerSize: ContainerSize) {
		this.subscriptions.push(this.containerLookupService.get(containerSize).subscribe(
			response => {
				if (response) {
					this.containerMaxWeights.set(containerSize, response.data.maxWeight);
					this.containerMaxTemperature.set(containerSize, response.data.maxTemperature);
					this.containerMinTemperature.set(containerSize, response.data.minTemperature);
				}
			}
		));
	}

	public getContainerMaxWeight(containerSize: ContainerSize, weight: number, i: number) {
		if (weight > this.containerMaxWeights.get(containerSize)) {
			this.cargoGroups[i].exceededMaxWeight = true;
			this.exceededMaxWeight = true;
		} else {
			this.cargoGroups[i].exceededMaxWeight = false;
		}
		this.checkWeightExceed();
	}

	public getContainerMinMaxTemperature(containerSize: ContainerSize, temperature: number, i: number) {
		if (temperature > this.containerMaxTemperature.get(containerSize)) {
			this.cargoGroups[i].greaterThanMaxTemperature = true;
		} else {
			this.cargoGroups[i].greaterThanMaxTemperature = false;
		}
		if (temperature < this.containerMinTemperature.get(containerSize)) {
			this.cargoGroups[i].lessThanMinTemperature = true;
		} else {
			this.cargoGroups[i].lessThanMinTemperature = false;
		}
	}

	checkWeightExceed() {
		if (this.cargoGroups) {
			this.exceededMaxWeight = false;
			let find = (this.cargoGroups.find(cg => cg.exceededMaxWeight === true));
			if (find) {
				this.exceededMaxWeight = true;
			}
		}
	}

	checkTemperatureExceed() {
		if (this.cargoGroups) {
			this.greaterThanMaxTemperature = (this.cargoGroups.find(cg => cg.containerType === ContainerType.Reefer && cg.greaterThanMaxTemperature === true)) != null;
			this.lessThanMinTemperature = (this.cargoGroups.find(cg => cg.containerType === ContainerType.Reefer && cg.lessThanMinTemperature === true)) != null;
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public setDims(packageType: PackageTypeLookupModel, cargoGroup: ShipmentCargoGroup) {
		this.isLengthReadonly = false;
		this.isWidthReadonly = false;
		this.isHeightReadonly = false;
		if (packageType && cargoGroup) {
			if (packageType.length) {
				cargoGroup.length = packageType.length;
				this.isLengthReadonly = true;
			} else {
				cargoGroup.length = null;
			}
			if (packageType.width) {
				cargoGroup.width = packageType.width;
				this.isWidthReadonly = true;
			} else {
				cargoGroup.width = null;
			}
			if (packageType.height) {
				cargoGroup.height = packageType.height;
				this.isHeightReadonly = true;
			} else {
				cargoGroup.height = null;
			}
		}
	}

	onModelChange(event: any, i: number) {
		this.cargoGroups[i] = event;
		this.cargoGroupsChange.emit(this.cargoGroups);
	}

	getMask(value: number, mask: number): boolean {
		return (value & mask) !== 0;
	}

	itemIdentity(index) {
		return index;
	}
	onAdd() {
		let minId = 0;
		this.widthGreaterThanLength = false;
		this.cargoGroups.forEach(cargoGroup => {
			if (cargoGroup.id < minId) {
				minId = cargoGroup.id;
			}
		});
		const cargoGroup = new ShipmentCargoGroup();
		cargoGroup.id = minId - 1;
		cargoGroup.quantity = 1;
		cargoGroup.cargoType = CargoType.General;
		cargoGroup.cargoGroupProperties = ShipmentPackageProperties.NonStackable;
		if (this.shipmentType === ShipmentType.Lcl) {
			cargoGroup.weight = Constants.DefaultNetWeightPerPackage;

		}
		else {
			cargoGroup.containerSize = ContainerSize.Iso22;
			cargoGroup.weight = Constants.DefaultNetWeightPerContainer;
			cargoGroup.containerType = ContainerType.GeneralPurpose;
		}
		cargoGroup.exceededMaxWeight = false;
		this.cargoGroups.push(cargoGroup);
		this.cargoGroupsChange.emit(this.cargoGroups);
	}

	onContainerTypeChange(groupIndex: number) {
		if (this.cargoGroups[groupIndex].containerType == ContainerType.Reefer) {
			this.subscriptions.push(this.containerLookupService.get(this.cargoGroups[groupIndex].containerSize).subscribe(
				response => {
					if (response) {
						this.cargoGroups[groupIndex].temperature = response.data.maxTemperature;
					}
				}
			));
		}
	}

	onDelete(groupIndex: number) {
		if (this.cargoGroups.length > 1) {
			this.cargoGroups.splice(groupIndex, 1);
		}
		this.checkWeightExceed();
		this.processDimension();
		this.cargoGroupsChange.emit(this.cargoGroups);
	}


}
