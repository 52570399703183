import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CoreTaskSummaryService } from 'app/core/task-summary/CoreTaskSummaryService';
import { TaskSummaryModel } from 'app/generated/backend/task/api/task-summary-model';
import { ObjectClass } from 'app/generated/backend/types/object-class';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-task-summary',
	templateUrl: 'CoreTaskSummaryComponent.html',
})
export class CoreTaskSummaryComponent implements OnInit, OnDestroy {

	private _subscriptions = new Array<Subscription>();
	public count: number;
	public isOverdue = false;
	public isSoonOverdue = false;
	constructor(
		private taskSummaryService: CoreTaskSummaryService
	) {
	}
	@Input()
	public class: ObjectClass;

	update(taskSummaries: TaskSummaryModel[]) {
		if (taskSummaries) {
			let count = 0;
			let isOverdue = false;
			let isSoonOverdue = false;
			const now = new Date().toISOString();
			const date = new Date();
			const oneWeekFromNow = new Date(date.setDate(date.getDate() + 7)).toISOString();
			taskSummaries.forEach(taskSummary => {
				if (!this.class || this.class == taskSummary.class) {
					count += taskSummary.count;
					if (!isOverdue && taskSummary.minResolveBefore && taskSummary.minResolveBefore < now) {
						isOverdue = true;
					}
					if (!isSoonOverdue && taskSummary.minResolveBefore && taskSummary.minResolveBefore < oneWeekFromNow) {
						isSoonOverdue = true;
					}
				}
			});
			this.count = count;
			this.isOverdue = isOverdue;
			this.isSoonOverdue = isSoonOverdue && !isOverdue;
		} else {
			this.count = null;
			this.isOverdue = false;
			this.isSoonOverdue = false;
		}
	}

	ngOnInit() {
		this._subscriptions.push(this.taskSummaryService.updated.subscribe(taskSummaries => {
			this.update(taskSummaries);
		}));
	}
	ngOnDestroy() {
		this._subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
