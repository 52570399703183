import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SettingsModel } from 'app/generated/backend/user/api/settings-model';

@Injectable()
export class SettingsHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		userId: number
	): Observable<JsonResourceResponse<SettingsModel>> {
		return this.http.get<JsonResourceResponse<SettingsModel>>(`api/v1/user/user/${userId}/settings`).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	put(
		request: SettingsModel,
		userId: number
	): Observable<JsonResourceResponse<SettingsModel>> {
		return this.http.put<JsonResourceResponse<SettingsModel>>(`api/v1/user/user/${userId}/settings`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	patch(
		request: SettingsModel,
		userId: number
	): Observable<JsonResourceResponse<SettingsModel>> {
		return this.http.patch<JsonResourceResponse<SettingsModel>>(`api/v1/user/user/${userId}/settings`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		SettingsHandlerService
	]
})
export class SettingsHandlerModule { }
