import { HttpParameterCodec } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface JsonResourceResponseMeta {
	canSave: boolean;
	canDelete: boolean;
	canCreate: boolean;
	canDownload: boolean;
	totalCount: number;
}

export interface JsonResourceResponse<T> {
	data: T;
	meta: JsonResourceResponseMeta;
}

export class ProperHttpParamEncoder implements HttpParameterCodec {
	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}
	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
	decodeKey(key: string): string {
		return decodeURIComponent(key);
	}
	decodeValue(value: string): string {
		return decodeURIComponent(value);
	}
}

export abstract class BasePipe<T> {
	constructor(protected translateService: TranslateService) {

	}
	abstract toString(key: T): string;
	protected toKeys(keys: T[], prefix: string, value: T, suffix: string): string[] {
		const result = new Array<string>();
		keys.forEach(key => {
			if (key && (((<any>value) & (<any>key)) === (<any>key))) {
				result.push(this.getKey(prefix + this.toString(key), suffix));
			}
		});
		return result;
	}
	protected getKey(key: string, suffix: string): string {
		if (suffix && suffix.length) {
			key += '.';
			key += suffix;
		}
		return key;
	}
	protected translateOne(key: string, suffix: string, interpolateParams?: Object): Observable<any> {
		return this.translateService.stream(this.getKey(key, suffix), interpolateParams);
	}
	protected translateArray(keys: string[]): Observable<any> {
		const ob = this.translateService.stream(keys);
		if (!ob) {
			return ob;
		}
		return ob.pipe(map(
			d => {
				let str: string = null;
				for (const p in d) {
					if (d.hasOwnProperty(p)) {
						if (str) {
							str = str + ', ' + d[p];
						} else {
							str = d[p];
						}
					}
				}
				return str;
			}
		)
		);
	}
}
