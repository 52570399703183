import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContainerEquipment, ContainerEquipmentPipe } from 'app/generated/backend/types/container-equipment';
import { BaseEnumInputComponent } from 'app/_templates/BaseEnumInputComponent';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ContainerEquipmentInputComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => ContainerEquipmentInputComponent),
	multi: true
};
const noop = () => {
	// This is intentional
};
@Component({
	selector: 'app-container-equipment-input',
	templateUrl: '../../_templates/BaseEnumInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class ContainerEquipmentInputComponent extends BaseEnumInputComponent<ContainerEquipment> {

	@Input()
	labelKey: string;

	@Input()
	helpUri: string;

	constructor(
		containerEquipmentPipe: ContainerEquipmentPipe) {
		super([ContainerEquipment.Chassis, ContainerEquipment.SideLoader, ContainerEquipment.Crane], containerEquipmentPipe);
	}

}
