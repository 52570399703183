import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CorePackageTypeLookupComponent } from './CorePackageTypeLookupcomponent';
import { CorePackageTypeNamePipe } from './CorePackageTypeNamePipe';
import { CorePackageTypeCodePipe } from './CorePackageTypeCodePipe';
import { CorePackageTypeLengthPipe } from './CorePackageTypeLengthPipe';
import { CorePackageTypeWidthPipe } from './CorePackageTypeWidthPipe';
import { CorePackageTypeHeightPipe } from './CorePackageTypeHeightPipe';
import { CorePackageTypeLookupService } from './CorePackageTypeLookupService';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { PackageTypeLookupHandlerModule } from 'app/generated/backend/trade/service/package-type-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';


@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		FormsModule,
		CommonMaterialModule,
		HelpPopupModule,
		TranslateModule,
		PackageTypeLookupHandlerModule
	],
	exports: [CorePackageTypeLookupComponent, CorePackageTypeNamePipe,CorePackageTypeCodePipe,CorePackageTypeLengthPipe,CorePackageTypeWidthPipe,CorePackageTypeHeightPipe],
	declarations: [CorePackageTypeLookupComponent, CorePackageTypeNamePipe,CorePackageTypeCodePipe,CorePackageTypeLengthPipe,CorePackageTypeWidthPipe,CorePackageTypeHeightPipe],
	providers: [CorePackageTypeLookupService]
})
export class CorePackageTypeModule { }
