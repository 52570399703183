import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'autocompleteFilter'
})
export class AutocompleteFilterPipe implements PipeTransform {
  
  transform(items: any[], searchTerm: string, maxOptions?: number, labelKey?: string): any {
    let filterItems = [];
    if (!items || !searchTerm) {
      filterItems = items;
    } else {
      filterItems = items.filter(
        item => item[labelKey || 'match']
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) === true);
    }
    if (maxOptions && maxOptions > 0) {
      let limitedFilterItems = [];
      for (let i = 0; i < maxOptions; i++) {
        if (filterItems[i]) {
          limitedFilterItems.push(filterItems[i]);
        }
      }
      return limitedFilterItems;
    }
    return filterItems;
  }
}
