import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { CoreCustomizationService } from 'app/core/customization/CoreCustomizationService';
import { CoreNavigationService } from 'app/core/navigation/CoreNavigationService';
import { SearchResultModel } from 'app/generated/backend/search/api/search-result-model';
import { SearchHandlerService } from 'app/generated/backend/search/service/search-handler';
import { EntityClass } from 'app/generated/backend/types/entity-class';
import { Subscription } from 'rxjs';
import { CoreLanguageService } from '../language/CoreLanguageService';

enum State {
	Idle = 0,
	Loading = 1,
	Error = 2
}
@Component({
	selector: 'app-core-mega-search',
	templateUrl: './CoreMegaSearchComponent.html'
})
export class CoreMegaSearchComponent implements OnInit {
	public noResults = false;
	public limit = 30;
	public state = State.Idle;
	private _innerValue: SearchResultModel;
	protected subscriptions = new Array<Subscription>();
	public allItems: SearchResultModel[] = [];
	public model: string;
	public currentIndex = null;
	public currentSelectedId = null;

	// get accessor
	get value(): SearchResultModel {
		return this._innerValue;
	};

	// set accessor including call the onchange callback
	set value(v: SearchResultModel) {
		if (v !== this._innerValue) {
			this._innerValue = v;
			this.model = this.toDisplayText(this._innerValue);
		}
	}


	toDisplayText(item: SearchResultModel): string {
		if (!item) {
			return null;
		} else {
			return item.label;
		}
	}

	toTitleText(item: SearchResultModel): string {
		return this.toDisplayText(item);
	}

	toOptionText(item: SearchResultModel): string {
		if (!item) {
			return null;
		} else {
			return item.label;
		}
	}

	onItemSelected(option: SearchResultModel) {
		if (option) {
			const earlier: SearchResultModel[] = this.customizationService.get("megasearch/latest");
			const latest = new Array<SearchResultModel>();
			latest.push(option);
			if (earlier) {
				earlier.forEach(o => {
					if (o.path !== option.path && latest.length < 10) {
						latest.push(o);
					}
				});
			}
			this.customizationService.set("megasearch/latest", latest);
			this.allItems = [];
			this.model = null;
			if (option.class == EntityClass.Party) {
				this.authenticationService.switchParty(Number.parseInt(option.path)).subscribe(
					data => {
						this.router.navigate(['/party/dashboard']);
					},
					error => {
						console.error(error);
					});
			} else if (option.class == EntityClass.ShipmentContainer) {
				const parts = option.path.split('/');
				this.navigation.navigateShipmentContainerInfo(parts[0], Number.parseInt(parts[1]));
			} else if (option.class == EntityClass.ShipmentBooking) {
				const parts = option.path.split('/');
				this.navigation.navigateShipmentBooking(parts[0], Number.parseInt(parts[1]), Number.parseInt(parts[2]));
			} else if (option.class == EntityClass.Shipment) {
				this.navigation.navigateShipment(option.path);
			} else if (option.class == EntityClass.ShipmentService) {
				const parts = option.path.split('/');
				this.navigation.navigateShipmentService(parts[0], Number.parseInt(parts[1]));
			}else {
				this.router.navigate([option.path]);
			}
			this.currentIndex = null;
			this.currentSelectedId = null;
		}
	}

	modelChange() {
		this.load();
	}

	constructor(
		private service: SearchHandlerService,
		private router: Router,
		private navigation: CoreNavigationService,
		private authenticationService: CoreAuthenticationService,
		private customizationService: CoreCustomizationService,
		private languageService: CoreLanguageService,
	) { }

	load() {
		this.currentIndex = null;
		this.currentSelectedId = null;
		this.subscriptions.push(this.languageService.getItems().subscribe(
			data => {
				if (data) {
					data.forEach(language => {
						if (language.iso2 === this.customizationService.getLocale().languageIso2 && this.model && this.model.length >= language.searchCharacterLimit) {
							this.state = State.Loading;
							this.subscriptions.push(this.service.get(this.model, this.limit, this.customizationService.getLanguageIso2()).subscribe(response => {
								if (response && response.data) {
									// If this text does not match, the reply could be old and we ignore it
									if (response.data.text === this.model) {
										this.allItems = response.data.result;
										this.noResults = !(this.allItems && this.allItems.length);
									}
								} else {
									this.noResults = true;
									this.allItems = [];
								}
								this.state = State.Idle;
							},
								error => {
									console.error(error);
									this.allItems = [];
									this.state = State.Error;
								}));
						} else {
							this.noResults = false;
						}
					});
				}
			}
		));
	}
	onFocus() {
		if (!this.model || !this.model.length) {
			const latest = this.customizationService.get("megasearch/latest");
			if (latest) {
				this.currentSelectedId = null;
				this.currentIndex = null;
				this.allItems = latest;
				this.noResults = false;
			}
		}

	}
	closeOption() {
		if (this.model && this.model.trim() !== '' && this.allItems && this.allItems.length) {
			if (this.value) {
			}
			this.value = this.allItems[0];
		}
	}

	ngOnInit() {
		this.load();
	}

	onKeyDownEvent(event: any) {
		let items: SearchResultModel[];
		items = this.allItems;
		if (event.code === 'ArrowDown') {
			if (this.currentIndex === null) {
				this.currentIndex = 0;
					this.currentSelectedId = null;
			} else if (items.length && items.length - 1 > this.currentIndex) {
				this.currentIndex = this.currentIndex + 1;
			}
		} else if (event.code === 'ArrowUp') {
			if (this.currentIndex > 0) {
				this.currentIndex = this.currentIndex - 1;
				this.currentSelectedId = null;
			} 
		}
		this.HandleItemSelection(event, items);
	}

	private HandleItemSelection(event: any, items: SearchResultModel[]) {
		if (this.currentIndex !== null) {
			const currenctObject = items[this.currentIndex];
			if (!currenctObject) {
				return;
			}
			if ((event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Tab')) {
				this.onItemSelected(items[this.currentIndex]);
			}
			if (currenctObject) {
				this.currentSelectedId = currenctObject.path;
				const element = document.getElementById(this.currentSelectedId);
				if (element) {
					element.scrollIntoView({ block: 'nearest' });
				}
			}
		}
	}
}
