import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreButtonModule } from 'app/core/button/CoreButtonModule';
import { CoreTextInputModule } from 'app/core/text-input/CoreTextInputModule';
import { TopicInfoHandlerModule } from 'app/generated/backend/content/service/topic-info-handler';
import { CommonMaterialModule } from '../core/common-material/core-common-material.module';
import { PanelModule } from '../core/panel/PanelModule';
import { GlossaryPopupComponent } from './GlossaryPopupComponent';
import { GlossaryTagComponent } from './GlossaryTagComponent';


@NgModule({
    imports: [
        CommonMaterialModule,
        CommonModule,
        CoreButtonModule,
        PanelModule,
        TopicInfoHandlerModule,
        CoreTextInputModule,
        TranslateModule,
        RouterModule
    ],
    declarations: [GlossaryTagComponent, GlossaryPopupComponent],
    providers: [GlossaryTagComponent, GlossaryPopupComponent]
})
export class GlossaryTagModule { }
