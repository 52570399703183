import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from 'app/core/common-material/core-common-material.module';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { CountryPostalCodeLookupHandlerModule } from 'app/generated/backend/locality/service/country-postal-code-lookup-handler';
import { LocalityLookupHandlerModule } from 'app/generated/backend/locality/service/locality-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { LocalityIconModule } from '../icon/LocalityIconModule';
import { CoreCountryPostalCodeLocalityLookupComponent } from './CoreCountryPostalCodeLocalityLookupComponent';
import { CoreLocalityLookupComponent } from './CoreLocalityLookupComponent';

@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		CommonMaterialModule,
		FormsModule,
		HelpPopupModule,
		LocalityIconModule,
		LocalityLookupHandlerModule,
		CountryPostalCodeLookupHandlerModule,
		TranslateModule
	],
	declarations: [CoreLocalityLookupComponent, CoreCountryPostalCodeLocalityLookupComponent],
	exports: [CoreLocalityLookupComponent, CoreCountryPostalCodeLocalityLookupComponent]
})
export class CoreLocalityLookupModule { };
