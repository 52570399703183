import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreDateTimeModule } from 'app/core/date-time/CoreDateTimeModule';
import { CoreFormModule } from 'app/core/form/CoreFormModule';
import { CoreNumberInputModule } from 'app/core/number/CoreNumberInputModule';
import { EventTypeModule } from 'app/generated/backend/types/event-type';
import { PartyUserLookupModule } from '../user/lookup/PartyUserLookupModule';
import { PartyDetailFooterComponent } from './PartyDetailFooterComponent';

@NgModule({
	imports: [
		CommonModule,
		CoreDateTimeModule,
		CoreFormModule,
		EventTypeModule,
		FormsModule,
		CoreNumberInputModule,
		PartyUserLookupModule,
		TranslateModule,
	],
	exports: [PartyDetailFooterComponent],
	declarations: [PartyDetailFooterComponent]
})
export class PartyDetailModule { }
