import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonBaseComponent } from './button-base.component';


@Component({
	selector: 'app-button-submit',
	templateUrl: './CoreButtonRaised.html'
})
export class ButtonSubmitComponent extends ButtonBaseComponent {
	@HostBinding('class') class = 'adn-material-button';

	@Input()
	public iconClass = 'fa-share';
	@Input()
	public titleTranslateKey = 'button.submit';
	@Input()
	public color = 'primary';
	@Input()
	public disabled: boolean = false;

	@Input()
	public type = 'button';


	@Input()
	public hintTranslateKey: string;

	@Output()
	action: EventEmitter<ButtonBaseComponent> = new EventEmitter<ButtonBaseComponent>();
}