import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum CrmType {
	None = 0,
	Customer = 1,
	Supplier = 2,
	Investor = 4,
	Partner = 8,
	Employee = 16,
	Media = 32,
	Carrier = 64,
}

@Pipe({
name: 'crmType'
})
export class CrmTypePipe extends BasePipe<CrmType> implements PipeTransform {
	static prefix = 'Backend.Types.CrmType.';
	static keys = [
		CrmType.None,
		CrmType.Customer,
		CrmType.Supplier,
		CrmType.Investor,
		CrmType.Partner,
		CrmType.Employee,
		CrmType.Media,
		CrmType.Carrier];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: CrmType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case CrmType.None: return 'None';
			case CrmType.Customer: return 'Customer';
			case CrmType.Supplier: return 'Supplier';
			case CrmType.Investor: return 'Investor';
			case CrmType.Partner: return 'Partner';
			case CrmType.Employee: return 'Employee';
			case CrmType.Media: return 'Media';
			case CrmType.Carrier: return 'Carrier';
			default: return null;
		}
	}
	transform(value: CrmType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(CrmTypePipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(CrmTypePipe.keys, CrmTypePipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		CrmTypePipe
	],
	exports: [
		CrmTypePipe
	],
	providers: [
		CrmTypePipe
	]
})
export class CrmTypeModule { }
