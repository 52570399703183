import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum ShipmentPartyRole {
	None = 0,
	Shipper = 1,
	Consignee = 2,
	Seller = 4,
	Buyer = 8,
	Sender = 16,
	Receiver = 32,
	Booker = 64,
	Supplier = 128,
	Forwarder = 256,
	Notify1 = 512,
	Notify2 = 1024,
}

@Pipe({
name: 'shipmentPartyRole'
})
export class ShipmentPartyRolePipe extends BasePipe<ShipmentPartyRole> implements PipeTransform {
	static prefix = 'Backend.Types.ShipmentPartyRole.';
	static keys = [
		ShipmentPartyRole.None,
		ShipmentPartyRole.Shipper,
		ShipmentPartyRole.Consignee,
		ShipmentPartyRole.Seller,
		ShipmentPartyRole.Buyer,
		ShipmentPartyRole.Sender,
		ShipmentPartyRole.Receiver,
		ShipmentPartyRole.Booker,
		ShipmentPartyRole.Supplier,
		ShipmentPartyRole.Forwarder,
		ShipmentPartyRole.Notify1,
		ShipmentPartyRole.Notify2];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ShipmentPartyRole): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ShipmentPartyRole.None: return 'None';
			case ShipmentPartyRole.Shipper: return 'Shipper';
			case ShipmentPartyRole.Consignee: return 'Consignee';
			case ShipmentPartyRole.Seller: return 'Seller';
			case ShipmentPartyRole.Buyer: return 'Buyer';
			case ShipmentPartyRole.Sender: return 'Sender';
			case ShipmentPartyRole.Receiver: return 'Receiver';
			case ShipmentPartyRole.Booker: return 'Booker';
			case ShipmentPartyRole.Supplier: return 'Supplier';
			case ShipmentPartyRole.Forwarder: return 'Forwarder';
			case ShipmentPartyRole.Notify1: return 'Notify1';
			case ShipmentPartyRole.Notify2: return 'Notify2';
			default: return null;
		}
	}
	transform(value: ShipmentPartyRole, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ShipmentPartyRolePipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(ShipmentPartyRolePipe.keys, ShipmentPartyRolePipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ShipmentPartyRolePipe
	],
	exports: [
		ShipmentPartyRolePipe
	],
	providers: [
		ShipmentPartyRolePipe
	]
})
export class ShipmentPartyRoleModule { }
