import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreHarmonizedCommodityLookupComponent } from './CoreHarmonizedCommodityLookupComponent';
import { CoreHarmonizedCommodityNamePipe } from './CoreHarmonizedCommodityNamePipe';
import { CoreHarmonizedCommodityLookupService } from './CoreHarmonizedCommodityLookupService';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { HarmonizedCommodityLookupHandlerModule } from 'app/generated/backend/trade/service/harmonized-commodity-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';


@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		FormsModule,
		CommonMaterialModule,
		HelpPopupModule,
		TranslateModule,
		HarmonizedCommodityLookupHandlerModule
	],
	exports: [CoreHarmonizedCommodityLookupComponent, CoreHarmonizedCommodityNamePipe],
	declarations: [CoreHarmonizedCommodityLookupComponent, CoreHarmonizedCommodityNamePipe],
	providers: [CoreHarmonizedCommodityLookupService]
})
export class CoreHarmonizedCommodityModule { }
