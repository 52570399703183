import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';
import { ShipmentTaskResolveRequest } from 'app/generated/backend/shipment/api/shipment-task-resolve-request';

@Injectable()
export class ShipmentTaskResolveHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	put(
		number: string,
		taskId: number,
		request: ShipmentTaskResolveRequest
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		return this.http.put<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/tasks/${taskId}/resolve`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		ShipmentTaskResolveHandlerService
	]
})
export class ShipmentTaskResolveHandlerModule { }
