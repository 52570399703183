import { Injectable } from '@angular/core';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { Menu } from "app/core/menu/Menu";
import { MenuItem } from 'app/core/menu/MenuItem';
import { MenuItemGroup } from 'app/core/menu/MenuItemGroup';
import { AuthResponse } from 'app/generated/backend/auth/api/auth-response';
import { ObjectClass } from 'app/generated/backend/types/object-class';
import { PartyAccessControl } from 'app/generated/backend/types/party-access-control';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class PartyMenuService {
	public menuUpdated: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(null);
	public showMenu: boolean[];
	constructor(
		private authenticationService: CoreAuthenticationService) {
		authenticationService.authenticationChanged.subscribe(auth => {
			this.showMenu = authenticationService.getPartyReadRights();
			const menu = new Menu();
			menu.items = new Array<MenuItem>();
			menu.groups = new Array<MenuItemGroup>();
			menu.items.push({ iconClass: 'fa-tachometer-alt', routerLink: ['/party', 'dashboard'], queryParams: null, titleTranslateKey: 'nav.dashboard', taskClass: null });
			if (this.showMenu[PartyAccessControl.Analytics]) {
				menu.items.push({ iconClass: 'fa-analytics', routerLink: ['/party', 'analytics'], queryParams: null, titleTranslateKey: 'nav.analytics', taskClass: null });
			}
			if (this.showMenu[PartyAccessControl.PartyTasks]) {
				menu.items.push({ iconClass: 'fa-tasks', routerLink: ['/party', 'tasks'], queryParams: null, titleTranslateKey: 'nav.tasks', taskClass: ObjectClass.None });
			}
			this.addShipment(menu, auth);
			this.addPurchasing(menu);
			this.addSales(menu);
			this.addReceiving(menu);
			this.addLand(menu);
			this.addSeaFcl(menu);
			this.addSeaLcl(menu);
			this.addRailFcl(menu);
			this.addRailLcl(menu);
			this.addCustoms(menu);
			this.addSetup(menu);
			if (this.showMenu[PartyAccessControl.Messages]) {
				menu.items.push({ iconClass: 'fa-envelope', routerLink: ['/party', 'messages'], queryParams: null, titleTranslateKey: 'nav.messages', taskClass: null });
			}
			if (authenticationService.isCarrier()) {
				menu.items.push({ iconClass: 'fa-satellite-dish', routerLink: ['/party', 'carrierTracking'], queryParams: null, titleTranslateKey: 'nav.tracking', taskClass: null });
			}
			this.menuUpdated.next(menu);
		});
	}
	private addShipment(menu: Menu, auth: AuthResponse) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.Shipments]) {
			subMenuItems.push({ iconClass: 'fa-boxes', routerLink: ['/party', 'shipments'], queryParams: null, titleTranslateKey: 'nav.shipments', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.ShipmentServices]) {
			subMenuItems.push({ iconClass: 'fa-cube', routerLink: ['/party', 'shipmentServices'], queryParams: null, titleTranslateKey: 'nav.shipmentServices', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Shipments]) {
			subMenuItems.push({ iconClass: 'fa-user', routerLink: ['/party', 'shipmentParties'], queryParams: null, titleTranslateKey: 'nav.shipmentParties', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.ShipmentPartyContainers]) {
			subMenuItems.push({ iconClass: 'fa-container-storage', routerLink: ['/party', 'shipmentPartyContainers'], queryParams: null, titleTranslateKey: 'nav.containers', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.ShipmentServiceContainers]) {
			subMenuItems.push({ iconClass: 'fa-container-storage', routerLink: ['/party', 'shipmentServiceContainers'], queryParams: null, titleTranslateKey: 'nav.containers', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.PartyShipmentCargo]) {
			subMenuItems.push({ iconClass: 'fa-cubes', routerLink: ['/party', 'shipmentCargo'], queryParams: null, titleTranslateKey: 'nav.shipmentCargo', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.PartyShipmentDocuments]) {
			subMenuItems.push({ iconClass: 'fa-book', routerLink: ['/party', 'shipmentDocuments'], queryParams: null, titleTranslateKey: 'nav.shipmentDocuments', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.PartyShipmentVoyages]) {
			subMenuItems.push({ iconClass: 'fa-route', routerLink: ['/party', 'shipmentVoyages'], queryParams: null, titleTranslateKey: 'nav.shipmentScheduleVoyages', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.PartyShipmentReleases]) {
			subMenuItems.push({ iconClass: 'fa-sign-out-alt', routerLink: ['/party', 'shipmentReleases'], queryParams: null, titleTranslateKey: 'nav.shipmentReleases', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.PartyShipmentBookings]) {
			subMenuItems.push({ iconClass: 'fa-book', routerLink: ['/party', 'shipmentBookings'], queryParams: null, titleTranslateKey: 'nav.shipmentBookings', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-ship', routerLink: null, titleTranslateKey: 'nav.shipping', items: subMenuItems, taskClass: null });
		}
	}
	private addPurchasing(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.PurchaseOrder]) {
			subMenuItems.push({ iconClass: 'fa-inbox-in', routerLink: ['/party', 'purchaseOrders', 'master'], queryParams: null, titleTranslateKey: 'nav.purchaseOrders', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.PurchaseOrder]) {
			subMenuItems.push({ iconClass: 'fa-inbox-in', routerLink: ['/party', 'purchaseOrderItems', 'master'], queryParams: null, titleTranslateKey: 'nav.purchaseOrderItems', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-inbox-in', routerLink: null, titleTranslateKey: 'nav.purchasing', items: subMenuItems, taskClass: null });
		}
	}
	private addSales(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.SalesOrder]) {
			subMenuItems.push({ iconClass: 'fa-inbox-out', routerLink: ['/party', 'salesOrders', 'master'], queryParams: null, titleTranslateKey: 'nav.salesOrders', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.SalesOrder]) {
			subMenuItems.push({ iconClass: 'fa-inbox-out', routerLink: ['/party', 'salesOrderItems', 'master'], queryParams: null, titleTranslateKey: 'nav.salesOrderItems', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-inbox-out', routerLink: null, titleTranslateKey: 'nav.sales', items: subMenuItems, taskClass: null });
		}
	}
	private addReceiving(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.GoodsReceipt]) {
			subMenuItems.push({ iconClass: 'fa-hand-receiving', routerLink: ['/party', 'goodsReceipts', 'master'], queryParams: null, titleTranslateKey: 'nav.goodsReceipts', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.GoodsReceipt]) {
			subMenuItems.push({ iconClass: 'fa-hand-receiving', routerLink: ['/party', 'goodsReceipts', 'items', 'master'], queryParams: null, titleTranslateKey: 'nav.goodsReceiptItems', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Shipments]) {
			subMenuItems.push({ iconClass: 'fa-container-storage', routerLink: ['/party', 'inboundContainers', 'master'], queryParams: null, titleTranslateKey: 'nav.inboundContainers', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Shipments]) {
			subMenuItems.push({ iconClass: 'fa-light fa-calendar', routerLink: ['/party', 'deliveryPlanning'], queryParams: null, titleTranslateKey: 'nav.deliveryPlanning', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-hand-receiving', routerLink: null, titleTranslateKey: 'nav.receiving', items: subMenuItems, taskClass: null });
		}
	}
	private addLand(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.Charges] && (this.showMenu[PartyAccessControl.FclLandRates])) {
			subMenuItems.push({ iconClass: 'fa-globe', routerLink: ['/party', 'landCoverage'], queryParams: null, titleTranslateKey: 'nav.roadCoverage', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.FclLandRates]) {
			subMenuItems.push({ iconClass: 'fa-arrow-alt-square-right', routerLink: ['/party', 'rates', 'fcl-land'], queryParams: null, titleTranslateKey: 'nav.fclLandRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.LtlLandRates]) {
			subMenuItems.push({ iconClass: 'fa-arrow-alt-square-right', routerLink: ['/party', 'rates', 'ltl-land'], queryParams: null, titleTranslateKey: 'nav.ltlLandRates', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-road', routerLink: null, titleTranslateKey: 'nav.land', items: subMenuItems, taskClass: null });
		}
	}
	private addCustoms(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.ExportRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-out-alt', routerLink: ['/party', 'rates', 'export-clearance'], queryParams: null, titleTranslateKey: 'nav.exportRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.ImportRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-in-alt', routerLink: ['/party', 'rates', 'import-clearance'], queryParams: null, titleTranslateKey: 'nav.importRates', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-passport', routerLink: null, titleTranslateKey: 'nav.customs', items: subMenuItems, taskClass: null });
		}
	}
	private addSeaFcl(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.FclSeaRates]) {
			subMenuItems.push({ iconClass: 'fa-globe', routerLink: ['/party', 'seaCoverage'], queryParams: null, titleTranslateKey: 'nav.seaCoverage', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-hands-helping', routerLink: ['/party', 'fclTradeLanes'], queryParams: null, titleTranslateKey: 'nav.tradeLanesFcl', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-file-invoice-dollar', routerLink: ['/party', 'fclSeaRateCurrents'], queryParams: null, titleTranslateKey: 'nav.fclSeaRateCurrents', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.FclSeaOriginRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-out-alt', routerLink: ['/party', 'rates', 'fcl-sea-origin'], queryParams: null, titleTranslateKey: 'nav.fclSeaOriginRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.FclSeaRates]) {
			subMenuItems.push({ iconClass: 'fa-arrow-alt-square-right', routerLink: ['/party', 'rates', 'fcl-sea'], queryParams: null, titleTranslateKey: 'nav.fclSeaRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.FclSeaDestinationRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-in-alt', routerLink: ['/party', 'rates', 'fcl-sea-destination'], queryParams: null, titleTranslateKey: 'nav.fclSeaDestinationRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.SeaServices]) {
			subMenuItems.push({ iconClass: 'fa-cogs', routerLink: ['/party', 'seaServices'], queryParams: null, titleTranslateKey: 'nav.seaServices', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.FclScheduledRoutes]) {
			subMenuItems.push({ iconClass: 'fa-calendar', routerLink: ['/party', 'seaSchedules', 'fcl'], queryParams: null, titleTranslateKey: 'nav.fclSeaSchedules', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-water', routerLink: null, titleTranslateKey: 'nav.seaFcl', items: subMenuItems, taskClass: null });
		}
	}
	private addSeaLcl(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.LclSeaRates]) {
			subMenuItems.push({ iconClass: 'fa-hands-helping', routerLink: ['/party', 'lclTradeLanes'], queryParams: null, titleTranslateKey: 'nav.tradeLanesLcl', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-file-invoice-dollar', routerLink: ['/party', 'lclSeaRateCurrents'], queryParams: null, titleTranslateKey: 'nav.lclSeaRateCurrents', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.LclSeaOriginRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-out-alt', routerLink: ['/party', 'rates', 'lcl-sea-origin'], queryParams: null, titleTranslateKey: 'nav.lclSeaOriginRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.LclSeaRates]) {
			subMenuItems.push({ iconClass: 'fa-arrow-alt-square-right', routerLink: ['/party', 'rates', 'lcl-sea'], queryParams: null, titleTranslateKey: 'nav.lclSeaRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.LclSeaDestinationRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-in-alt', routerLink: ['/party', 'rates', 'lcl-sea-destination'], queryParams: null, titleTranslateKey: 'nav.lclSeaDestinationRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.LclScheduledRoutes]) {
			subMenuItems.push({ iconClass: 'fa-calendar', routerLink: ['/party', 'seaSchedules', 'lcl'], queryParams: null, titleTranslateKey: 'nav.lclSeaSchedules', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-water', routerLink: null, titleTranslateKey: 'nav.seaLcl', items: subMenuItems, taskClass: null });
		}
	}
	private addRailFcl(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.FclRailRates]) {
			subMenuItems.push({ iconClass: 'fa-globe', routerLink: ['/party', 'railCoverage'], queryParams: null, titleTranslateKey: 'nav.railCoverage', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.FclRailOriginRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-out-alt', routerLink: ['/party', 'rates', 'fcl-rail-origin'], queryParams: null, titleTranslateKey: 'nav.fclRailOriginRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.FclRailRates]) {

			subMenuItems.push({ iconClass: 'fa-ticket', routerLink: ['/party', 'rates', 'fcl-rail'], queryParams: null, titleTranslateKey: 'nav.fclRailRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.FclRailDestinationRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-in-alt', routerLink: ['/party', 'rates', 'fcl-rail-destination'], queryParams: null, titleTranslateKey: 'nav.fclRailDestinationRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.RailSchedules]) {
			subMenuItems.push({ iconClass: 'fa-calendar', routerLink: ['/party', 'railSchedules'], queryParams: null, titleTranslateKey: 'nav.railSchedules', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-train', routerLink: null, titleTranslateKey: 'nav.railFcl', items: subMenuItems, taskClass: null });
		}
	}
	private addRailLcl(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.LclRailOriginRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-out-alt', routerLink: ['/party', 'rates', 'lcl-rail-origin'], queryParams: null, titleTranslateKey: 'nav.lclRailOriginRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.LclRailRates]) {
			subMenuItems.push({ iconClass: 'fa-ticket', routerLink: ['/party', 'rates', 'lcl-rail'], queryParams: null, titleTranslateKey: 'nav.lclRailRates', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.LclRailDestinationRates]) {
			subMenuItems.push({ iconClass: 'fa-sign-in-alt', routerLink: ['/party', 'rates', 'lcl-rail-destination'], queryParams: null, titleTranslateKey: 'nav.lclRailDestinationRates', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-train', routerLink: null, titleTranslateKey: 'nav.railLcl', items: subMenuItems, taskClass: null });
		}
	}
	private addSetup(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[PartyAccessControl.Profile]) {
			subMenuItems.push({ iconClass: 'fa-user-circle', routerLink: ['/party', 'profile'], queryParams: null, titleTranslateKey: 'nav.profile', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.AccountingSetup]) {
			subMenuItems.push({ iconClass: 'fa-cash-register', routerLink: ['/party', 'setup', 'accounting'], queryParams: null, titleTranslateKey: 'nav.accountingSetup', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Currencies]) {
			subMenuItems.push({ iconClass: 'fa-dollar-sign', routerLink: ['/party', 'setup', 'currencies'], queryParams: null, titleTranslateKey: 'nav.currencies', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Charges]) {
			subMenuItems.push({ iconClass: 'fa-tags', routerLink: ['/party', 'charges'], queryParams: null, titleTranslateKey: 'nav.charges', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.RateContracts]) {
			subMenuItems.push({ iconClass: 'fa-money-check-edit-alt', routerLink: ['/party', 'rateContracts'], queryParams: null, titleTranslateKey: 'nav.rateContracts', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Locations]) {
			subMenuItems.push({ iconClass: 'fa-map-marker-alt', routerLink: ['/party', 'locations'], queryParams: null, titleTranslateKey: 'nav.locations', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Places]) {
			subMenuItems.push({ iconClass: 'fa-warehouse', routerLink: ['/party', 'places'], queryParams: null, titleTranslateKey: 'nav.places', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Parties]) {
			subMenuItems.push({ iconClass: 'fa-building', routerLink: ['/party', 'parties'], queryParams: null, titleTranslateKey: 'nav.parties', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Users]) {
			subMenuItems.push({ iconClass: 'fa-users', routerLink: ['/party', 'users'], queryParams: null, titleTranslateKey: 'nav.users', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Team]) {
			subMenuItems.push({ iconClass: 'fa-people-group', routerLink: ['/party', 'setup', 'teams'], queryParams: null, titleTranslateKey: 'nav.teams', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Transforms]) {
			subMenuItems.push({ iconClass: 'fa-map-signs', routerLink: ['/party', 'transforms'], queryParams: null, titleTranslateKey: 'nav.transforms', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.ShipmentTrackingStatus]) {
			subMenuItems.push({ iconClass: 'fa-compass', routerLink: ['/party', 'setup', 'shipmentTrackingStatus'], queryParams: null, titleTranslateKey: 'nav.shipmentTrackingStatuses', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.ContainerCodes]) {
			subMenuItems.push({ iconClass: 'fa-container-storage', routerLink: ['/party', 'setup', 'containerCodes'], queryParams: null, titleTranslateKey: 'nav.containerCodes', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.TariffDayCharge]) {
			subMenuItems.push({ iconClass: 'fa-container-storage', routerLink: ['/party', 'setup', 'tariffDayCharge'], queryParams: null, titleTranslateKey: 'nav.tariffDayCharge', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.Vessels]) {
			subMenuItems.push({ iconClass: 'fa-ship', routerLink: ['/party', 'vessels'], queryParams: null, titleTranslateKey: 'nav.vessels', taskClass: null });
		}
		if (this.showMenu[PartyAccessControl.PartyPackageTypes]) {
			subMenuItems.push({ iconClass: 'fa-archive', routerLink: ['/party', 'packageTypes'], queryParams: null, titleTranslateKey: 'nav.packageTypes', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-toolbox', routerLink: null, titleTranslateKey: 'nav.setup', items: subMenuItems, taskClass: null });
		}
	}
}
