import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DateOrderStyle } from 'app/generated/system/date-time/date-order-style';
import { LineBreak } from 'app/generated/system/io/line-break';
import { ShipmentContainerOperationMasterDownloadRequest } from 'app/generated/backend/shipment/api/shipment-container-operation-master-download-request';
import { ShipmentContainerOperationMasterModel } from 'app/generated/backend/shipment/api/shipment-container-operation-master-model';
import { ShipmentContainerOperationMasterRequest } from 'app/generated/backend/shipment/api/shipment-container-operation-master-request';

@Injectable()
export class ShipmentContainerOperationMasterHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	post(
		partyPlaceId: number,
		request: ShipmentContainerOperationMasterRequest
	): Observable<JsonResourceResponse<ShipmentContainerOperationMasterModel[]>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (partyPlaceId!==null && partyPlaceId!==undefined) {
			params = params.set('partyPlaceId', partyPlaceId.toString());
		}
		return this.http.post<JsonResourceResponse<ShipmentContainerOperationMasterModel[]>>('api/v1/shipment/containerOperation/master', request, { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	download(
		request: ShipmentContainerOperationMasterDownloadRequest
	): Observable<Blob> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (request.offset!==null && request.offset!==undefined) {
			params = params.set('offset', request.offset.toString());
		}
		if (request.limit!==null && request.limit!==undefined) {
			params = params.set('limit', request.limit.toString());
		}
		if (request.partyId!==null && request.partyId!==undefined) {
			params = params.set('partyId', request.partyId.toString());
		}
		if (request.partyPlaceId!==null && request.partyPlaceId!==undefined) {
			params = params.set('partyPlaceId', request.partyPlaceId.toString());
		}
		if (request.shipmentNumber!==null && request.shipmentNumber!==undefined) {
			params = params.set('shipmentNumber', request.shipmentNumber);
		}
		if (request.shipmentLocationType!==null && request.shipmentLocationType!==undefined) {
			params = params.set('shipmentLocationType', request.shipmentLocationType.toString());
		}
		if (request.partyCategory!==null && request.partyCategory!==undefined) {
			params = params.set('partyCategory', request.partyCategory.toString());
		}
		if (request.shipmentContainerOperationState!==null && request.shipmentContainerOperationState!==undefined) {
			params = params.set('shipmentContainerOperationState', request.shipmentContainerOperationState.toString());
		}
		if (request.receiverPartyId!==null && request.receiverPartyId!==undefined) {
			params = params.set('receiverPartyId', request.receiverPartyId.toString());
		}
		if (request.downloadSettings!==null && request.downloadSettings!==undefined) {
			if (request.downloadSettings.columnSeparator!==null && request.downloadSettings.columnSeparator!==undefined) {
				params = params.set('downloadSettings.columnSeparator', request.downloadSettings.columnSeparator);
			}
			if (request.downloadSettings.decimalSeparator!==null && request.downloadSettings.decimalSeparator!==undefined) {
				params = params.set('downloadSettings.decimalSeparator', request.downloadSettings.decimalSeparator);
			}
			if (request.downloadSettings.dateOrderStyle!==null && request.downloadSettings.dateOrderStyle!==undefined) {
				params = params.set('downloadSettings.dateOrderStyle', request.downloadSettings.dateOrderStyle.toString());
			}
			if (request.downloadSettings.lineBreak!==null && request.downloadSettings.lineBreak!==undefined) {
				params = params.set('downloadSettings.lineBreak', request.downloadSettings.lineBreak.toString());
			}
			if (request.downloadSettings.fileName!==null && request.downloadSettings.fileName!==undefined) {
				params = params.set('downloadSettings.fileName', request.downloadSettings.fileName);
			}
		}
		if (request.match!==null && request.match!==undefined) {
			params = params.set('match', request.match);
		}
		return this.http.get<Blob>('api/v1/shipment/containerOperation/master', { params, responseType: 'blob' as 'json', headers: {'Accept': 'application/vnd.ms-excel'} });
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		ShipmentContainerOperationMasterHandlerService
	]
})
export class ShipmentContainerOperationMasterHandlerModule { }
