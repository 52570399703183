import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from 'app/core/common-material/core-common-material.module';
import { CoreFacilityLookupComponent } from './CoreFacilityLookupComponent';
import { CoreFacilityLookupService } from './CoreFacilitylLookupService';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';


@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		CommonMaterialModule,
		FormsModule,
		TranslateModule,
		HelpPopupModule
	],
	exports: [CoreFacilityLookupComponent],
	declarations: [CoreFacilityLookupComponent],
	providers: [CoreFacilityLookupService]
})
export class CoreFacilityModule { }
