import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { Constants } from 'app/core/Constants';
import { CoreCustomizationService } from 'app/core/customization/CoreCustomizationService';
import { CoreIncotermsLookupService } from 'app/core/incoterms/CoreIncotermsLookupService';
import { PlacesDetailModel } from 'app/generated/backend/locality/api/places-detail-model';
import { PlacesPlaceModel } from 'app/generated/backend/locality/api/places-place-model';
import { PlacesDetailHandlerService } from 'app/generated/backend/locality/service/places-detail-handler';
import { Shipment } from 'app/generated/backend/shipment/api/shipment';
import { ShipmentCargoGroup } from 'app/generated/backend/shipment/api/shipment-cargo-group';
import { ShipmentLocation } from 'app/generated/backend/shipment/api/shipment-location';
import { ShipmentPackage } from 'app/generated/backend/shipment/api/shipment-package';
import { ContainerLookupHandlerService } from 'app/generated/backend/shipment/service/container-lookup-handler';
import { IncotermsLookupModel } from 'app/generated/backend/trade/api/incoterms-lookup-model';
import { CargoType } from 'app/generated/backend/types/cargo-type';
import { ContainerEquipment } from 'app/generated/backend/types/container-equipment';
import { ContainerSize } from 'app/generated/backend/types/container-size';
import { ContainerType } from 'app/generated/backend/types/container-type';
import { LocalityLookupModel } from 'app/generated/backend/types/locality-lookup-model';
import { ProcessVariant } from 'app/generated/backend/types/process-variant';
import { ShipmentLocationType } from 'app/generated/backend/types/shipment-location-type';
import { ShipmentPackageProperties } from 'app/generated/backend/types/shipment-package-properties';
import { ShipmentType } from 'app/generated/backend/types/shipment-type';
import { PartyPlaceLookupModel } from 'app/generated/backend/yard/api/party-place-lookup-model';
import { ShipmentDetailService } from 'app/shipment/detail/ShipmentDetailService';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BookingService } from '../BookingService';
import { BookingState } from '../BookingState';

@Component({
	selector: 'app-booking-cargo-groups',
	templateUrl: './BookingCargoGroupsComponent.html'
})
export class BookingCargoGroupsComponent implements OnInit, OnDestroy {
	public loading = true;
	public valid: boolean;
	public minPickupFrom: string;
	public hasPlaces = false;
	public pickupPartyPlaceId: number;
	public deliveryPartyPlaceId: number;
	protected subscriptions = new Array<Subscription>();
	private _pickupAddress: PlacesPlaceModel;
	public namedDestinationLocality: LocalityLookupModel;
	public namedOriginLocality: LocalityLookupModel;
	public originUseAddress = false;
	public destinationUseAddress = false;
	private _deliveryAddress: PlacesPlaceModel;
	private inputIncoterms: string;
	public incoterms: IncotermsLookupModel;
	public state: BookingState;
	public ShipmentType = ShipmentType;
	public originPlace: PlacesDetailModel;
	public destinationPlace: PlacesDetailModel;
	private _exceededMaxWeight: boolean = false;
	private containerMaxWeights = new Map<ContainerSize, number>();
	private _widthGreaterThanLength: boolean = false;
	public get widthGreaterThanLength(): boolean {
		return this._widthGreaterThanLength;
	}
	public set widthGreaterThanLength(value: boolean) {
		this._widthGreaterThanLength = value;
	}
	private containerMaxTemperature = new Map<ContainerSize, number>();;
	private containerMinTemperature = new Map<ContainerSize, number>();;
	public get exceededMaxWeight(): boolean {
		return this._exceededMaxWeight;
	}
	public set exceededMaxWeight(value: boolean) {
		this._exceededMaxWeight = value;
	}
	constructor(
		private router: Router,
		private authenticationService: CoreAuthenticationService,
		private bookingService: BookingService,
		protected placesDetailService: PlacesDetailHandlerService,
		private incotermsService: CoreIncotermsLookupService,
		private customizationService: CoreCustomizationService,
		private shipmentDetailService: ShipmentDetailService,
		private containerLookupService: ContainerLookupHandlerService,
		elementRef: ElementRef
	) {
		this.inputIncoterms = elementRef.nativeElement.getAttribute('incotermsId');
		this.shipmentDetailService.setCache(null);
	}


	ngOnInit() {
		this.setContainerMaxes(ContainerSize.Iso22);
		this.setContainerMaxes(ContainerSize.Iso42);
		this.setContainerMaxes(ContainerSize.Iso45);
		this.prepareModel();
		this.subscriptions.push(this.authenticationService.authenticationChanged.subscribe(() => {
			this.prepareModel();
		}));
	}
	prepareModel() {
		this.minPickupFrom = new Date().toISOString().substr(0, 10);
		this.originUseAddress = !this.hasPlaces;
		this.destinationUseAddress = !this.hasPlaces;
		this.loading = true;
		this.bookingService.partyId = this.authenticationService.getPartyId();
		this.state = this.bookingService.cargoGroupsState;
		if (!this.state) {
			this.state = new BookingState();
			this.state.shipment = new Shipment();
			this.state.shipment.type = ShipmentType.Fcl;
			this.state.shipment.process = ProcessVariant.Collaboration;
			this.state.shipment.cargoGroups = new Array<ShipmentCargoGroup>();
			this.state.shipment.packages = new Array<ShipmentPackage>();
			const pickupFrom = new Date();
			pickupFrom.setTime(pickupFrom.getTime() + Constants.WeekMilliseconds);
			this.state.shipment.pickupFrom = pickupFrom.toISOString().substr(0, 10);
			this.addDefaultCargoItem();
			this.bookingService.cargoGroupsState = this.state;
		} else {
			this.pickupPartyPlaceId = this.state.pickupPartyPlaceId;
			this.deliveryPartyPlaceId = this.state.deliveryPartyPlaceId;
			this.namedOriginLocality = this.state.namedOriginLocality;
			this.namedDestinationLocality = this.state.namedDestinationLocality;
		}
		this.state.shipment.charges = null;
		this.state.shipment.parties = null;
		this.state.shipment.services = null;
		if (!this.hasPlaces) {
			this.pickupAddress = this.customizationService.get('pickupAddress');
			this.deliveryAddress = this.customizationService.get('deliveryAddress');
		}
		this.getIncoterms();
		this.loading = false;
	}

	private getIncoterms() {
		this.subscriptions.push(this.incotermsService.getItems().pipe(delay(100)).subscribe(items => {
			this.setInitialIncoterms(items);
		}));
	}

	setInitialIncoterms(items: IncotermsLookupModel[]) {
		if (this.inputIncoterms) {
			items.forEach(item => {
				if (item.code === this.inputIncoterms) {
					this.state.shipment.incotermsId = item.id;
					this.incoterms = item;
				}
			});
		}
		if (!this.incoterms && this.state.shipment.incotermsId != null) {
			items.forEach(item => {
				if (item.id === this.state.shipment.incotermsId) {
					this.state.shipment.incotermsId = item.id;
					this.incoterms = item;
				}
			});
		}
		if (!this.incoterms) {
			items.forEach(item => {
				if (item.code === "FOB") {
					this.state.shipment.incotermsId = item.id;
					this.incoterms = item;
				}
			});
		}
		this.bookingService.incoterms = this.incoterms;
	}

	addDefaultCargoItem() {
		let minId = 0;
		this.state.shipment.cargoGroups.forEach(cargoGroup => {
			if (cargoGroup.id < minId) {
				minId = cargoGroup.id;
			}
		});
		const cargoGroup = new ShipmentCargoGroup();
		cargoGroup.id = minId - 1;
		cargoGroup.quantity = 1;
		cargoGroup.cargoType = CargoType.General;
		cargoGroup.cargoGroupProperties = ShipmentPackageProperties.NonStackable;
		if (this.state.shipment.type === ShipmentType.Lcl) {
			cargoGroup.weight = Constants.DefaultNetWeightPerPackage;
			cargoGroup.containerSize = null;
			cargoGroup.containerType = null;
		}
		else {
			cargoGroup.containerSize = ContainerSize.Iso22;
			cargoGroup.weight = Constants.DefaultNetWeightPerContainer;
			cargoGroup.containerType = ContainerType.GeneralPurpose;
		}
		cargoGroup.exceededMaxWeight = false;
		this.state.shipment.cargoGroups.push(cargoGroup);
	}

	public setContainerMaxes(containerSize: ContainerSize) {
		this.subscriptions.push(this.containerLookupService.get(containerSize).subscribe(
			response => {
				if (response) {
					this.containerMaxWeights.set(containerSize, response.data.maxWeight);
					this.containerMaxTemperature.set(containerSize, response.data.maxTemperature);
					this.containerMinTemperature.set(containerSize, response.data.minTemperature);
				}
			}
		));
	}

	cargoGroupsChange(cargoGroups: ShipmentCargoGroup[]) {
		if (!cargoGroups) {
			return;
		}
		if (!this.bookingService || !this.bookingService.cargoGroupsState) {
			this.state = new BookingState();
			this.prepareModel();
		}
		this.checkContainerMaxWeight(cargoGroups);
		this.checkDimension(cargoGroups);
	}

	public checkContainerMaxWeight(cargoGroups: ShipmentCargoGroup[]) {
		if (!this.containerMaxWeights) {
			return;
		}
		this.exceededMaxWeight = false;
		cargoGroups.forEach(cargoGroup => {
			if (cargoGroup.weight > this.containerMaxWeights.get(cargoGroup.containerSize)) {
				this.exceededMaxWeight = true;
			}
		})
	}

	checkDimension(cargoGroups: ShipmentCargoGroup[]) {
		this.widthGreaterThanLength = false;
		if (cargoGroups && cargoGroups.length) {
			cargoGroups.forEach(cargoGroup => {
				if (cargoGroup.width && cargoGroup.length && cargoGroup.width > cargoGroup.length) {
					this.widthGreaterThanLength = true;
				}
			});
		}
	}

	shipmentTypeChange(type: ShipmentType) {
		if (!type) {
			return;
		}
		this.state.shipment.cargoGroups = new Array<ShipmentCargoGroup>();
		this.addDefaultCargoItem();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public switchDestinationUseAddress() {
		this.destinationUseAddress = !this.destinationUseAddress;
		if (!this.destinationUseAddress) {
			this.deliveryPartyPlaceId = this.bookingService.cargoGroupsState.deliveryPartyPlaceId;
		} else {
			this.deliveryAddress = this.customizationService.get('deliveryAddress');
		}
	}

	public switchOriginUseAddress() {
		this.originUseAddress = !this.originUseAddress;
		if (!this.originUseAddress) {
			this.pickupPartyPlaceId = this.bookingService.cargoGroupsState.pickupPartyPlaceId;
		} else {
			this.pickupAddress = this.customizationService.get('pickupAddress');
		}
	}

	public setIncoterms(incoterms: IncotermsLookupModel) {
		this.incoterms = incoterms;
		this.bookingService.incoterms = this.incoterms;
	}
	getMask(value: number, mask: number): boolean {
		return (value & mask) !== 0;
	}

	get pickupAddress(): PlacesPlaceModel {
		return this._pickupAddress;
	};

	set pickupAddress(pickupAddress: PlacesPlaceModel) {
		if (pickupAddress !== this._pickupAddress) {
			this._pickupAddress = pickupAddress;
			this.customizationService.set('pickupAddress', pickupAddress);
			this.lookupOriginLocation(pickupAddress);
		}
	}

	get deliveryAddress(): PlacesPlaceModel {
		return this._deliveryAddress;
	};

	set deliveryAddress(deliveryAddress: PlacesPlaceModel) {
		if (deliveryAddress !== this._deliveryAddress) {
			this._deliveryAddress = deliveryAddress;
			this.customizationService.set('deliveryAddress', deliveryAddress);
			this.lookupDestinationLocation(deliveryAddress);
		}
	}
	copyPlaceToLocation(location: ShipmentLocation, partyPlace: PartyPlaceLookupModel) {
		location.partyPlaceId = partyPlace.id;
		location.instructions = partyPlace.instructions;
		location.latitude = partyPlace.latitude;
		location.longitude = partyPlace.longitude;
		location.address = partyPlace.intlAddress;
		location.containerEquipment = partyPlace.equipment;
		location.nearestLocality = partyPlace.nearestLocality;
	}
	setDeliveryPlace(partyPlace: PartyPlaceLookupModel) {
		if (partyPlace) {
			this.bookingService.cargoGroupsState.deliveryPartyPlaceId = partyPlace.id;
			const deliveryLocation = new ShipmentLocation();
			deliveryLocation.type = ShipmentLocationType.Delivery;
			this.copyPlaceToLocation(deliveryLocation, partyPlace);
			this.state.shipment.deliveryLocation = deliveryLocation;
		}
	}
	setPickupPlace(partyPlace: PartyPlaceLookupModel) {
		if (partyPlace) {
			this.bookingService.cargoGroupsState.pickupPartyPlaceId = partyPlace.id;
			const pickupLocation = new ShipmentLocation();
			pickupLocation.type = ShipmentLocationType.Pickup;
			this.copyPlaceToLocation(pickupLocation, partyPlace);
			this.state.shipment.pickupLocation = pickupLocation;
		}
	}
	copyPlaceDetailToLocation(place: PlacesDetailModel, location: ShipmentLocation) {
		location.address = place.address;
		location.latitude = place.latitude;
		location.longitude = place.longitude;
		location.countryId = place.countryId;
		location.postalCode = place.postalCode;
		location.nearestLocality = place.nearestLocality;
	}
	lookupOriginLocation(address: PlacesPlaceModel) {
		if (address) {

			this.subscriptions.push(this.placesDetailService.get(address.placeId).subscribe(
				response => {
					this.originPlace = response.data;
					const pickupLocation = new ShipmentLocation();
					this.copyPlaceDetailToLocation(response.data, pickupLocation);
					pickupLocation.type = ShipmentLocationType.Pickup;
					pickupLocation.containerEquipment = ContainerEquipment.Chassis;
					this.state.shipment.pickupLocation = pickupLocation;
				},
				error => {
					console.error(error);
				}
			));
		}
	}
	lookupDestinationLocation(address: PlacesPlaceModel) {
		if (address) {

			this.subscriptions.push(this.placesDetailService.get(address.placeId).subscribe(
				response => {
					this.destinationPlace = response.data;
					const deliveryLocation = new ShipmentLocation();
					this.copyPlaceDetailToLocation(response.data, deliveryLocation);
					deliveryLocation.type = ShipmentLocationType.Delivery;
					deliveryLocation.containerEquipment = ContainerEquipment.Chassis;
					this.state.shipment.deliveryLocation = deliveryLocation;
				},
				error => {
					console.error(error);
				}
			));
		}
	}
	itemIdentity(index) {
		return index;
	}

	onNext() {
		if (this.incoterms && this.incoterms.hasNamedOrigin && this.namedOriginLocality) {
			this.state.shipment.namedOriginLocality = this.namedOriginLocality;
		} else {
			this.state.shipment.namedOriginLocality = null;
		}
		if (this.incoterms && this.incoterms.hasNamedDestination && this.namedDestinationLocality) {
			this.state.shipment.namedDestinationLocality = this.namedDestinationLocality;
		} else {
			this.state.shipment.namedDestinationLocality = null;
		}
		this.router.navigate(['/booking/schedules']);

	}
}
