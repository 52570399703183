<div class="form-group row" *ngIf="item">
  <b class="col-sm-12">{{item.title}}&nbsp;</b><br />
  <p class="col-sm-12">
    {{item.body}}<br /><br />
    <a (click)="onGoToContent()" *ngIf="item.contentUri">{{'button.readMore' | translate}}</a>
  </p>
</div>

<div class="form-group row">
  <div class="col-sm-12 adn-buttons-horizontal text-right">
    <app-button-close (action)="onCancel()"></app-button-close>
  </div>
</div>