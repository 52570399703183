import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonBaseComponent } from './button-base.component';


@Component({
	selector: 'app-button-search',
	templateUrl: './CoreButtonRaised.html'
})
export class CoreButtonSearchComponent extends ButtonBaseComponent {
	@HostBinding('class') class = 'adn-material-button';

	@Input()
	public iconClass = 'fa-search';
	@Input()
	public titleTranslateKey = 'button.search';
	@Input()
	public disabled: boolean = false;

	@Input()
	public type = 'button';

	@Input()
	public hintTranslateKey: string;

	@Input()
	public color: string;

	@Output()
	action: EventEmitter<ButtonBaseComponent> = new EventEmitter<ButtonBaseComponent>();
}