import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum EventType {
	None = 0,
	Create = 1,
	Read = 2,
	Update = 3,
	Delete = 4,
	Cancel = 5,
	Recall = 6,
	Dispatch = 7,
	List = 9,
	Post = 10,
	Approve = 11,
	Confirm = 12,
	Submit = 13,
	Process = 14,
	Scan = 15,
	Fail = 16,
}

@Pipe({
name: 'eventType'
})
export class EventTypePipe extends BasePipe<EventType> implements PipeTransform {
	static prefix = 'Backend.Types.EventType.';
	static keys = [
		EventType.None,
		EventType.Create,
		EventType.Read,
		EventType.Update,
		EventType.Delete,
		EventType.Cancel,
		EventType.Recall,
		EventType.Dispatch,
		EventType.List,
		EventType.Post,
		EventType.Approve,
		EventType.Confirm,
		EventType.Submit,
		EventType.Process,
		EventType.Scan,
		EventType.Fail];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: EventType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case EventType.None: return 'None';
			case EventType.Create: return 'Create';
			case EventType.Read: return 'Read';
			case EventType.Update: return 'Update';
			case EventType.Delete: return 'Delete';
			case EventType.Cancel: return 'Cancel';
			case EventType.Recall: return 'Recall';
			case EventType.Dispatch: return 'Dispatch';
			case EventType.List: return 'List';
			case EventType.Post: return 'Post';
			case EventType.Approve: return 'Approve';
			case EventType.Confirm: return 'Confirm';
			case EventType.Submit: return 'Submit';
			case EventType.Process: return 'Process';
			case EventType.Scan: return 'Scan';
			case EventType.Fail: return 'Fail';
			default: return null;
		}
	}
	transform(value: EventType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(EventTypePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		EventTypePipe
	],
	exports: [
		EventTypePipe
	],
	providers: [
		EventTypePipe
	]
})
export class EventTypeModule { }
