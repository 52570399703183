import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CountryTradeHandlerModule } from 'app/generated/backend/world/service/country-trade-handler';
import { AdnavemLogoModule } from '../adnavem-logo/AdnavemLogoModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreSiteFooterComponent } from './CoreSiteFooterComponent';

@NgModule({
	imports: [
		AdnavemLogoModule,
		CommonModule,
		CommonMaterialModule,
		RouterModule,
		CountryTradeHandlerModule,
		TranslateModule],
	exports: [CoreSiteFooterComponent],
	declarations: [CoreSiteFooterComponent]
})
export class CoreSiteFooterModule { }
