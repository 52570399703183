import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CoreContentModule } from 'app/core/content/CoreContentModule';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { ContentTranslationHandlerModule } from 'app/generated/backend/content/service/content-translation-handler';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { LoadingModule } from '../loading/LoadingModule';
import { PanelComponent } from './PanelComponent';


@NgModule({
	imports: [
		CommonModule,
		CommonMaterialModule,
		TranslateModule,
		CoreContentModule,
		HelpPopupModule,
		ContentTranslationHandlerModule,
		LoadingModule
	],
	exports: [PanelComponent],
	declarations: [PanelComponent]
})
export class PanelModule { }
