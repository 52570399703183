import { ContainerEquipment } from 'app/generated/backend/types/container-equipment';
export class PartyPlacePointDetailModel {
	id: number;
	partyPlaceId: number;
	updatedUserId: number;
	updatedAt: string;
	code: string;
	name: string;
	equipment: ContainerEquipment;
	latitude: number;
	longitude: number;
	sortOrder: number;
}
