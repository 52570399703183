import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CoreAuthenticationService } from '../../core/authentication/CoreAuthenticationService';
import { AuthResponse } from '../../generated/backend/auth/api/auth-response';


@Component({
	selector: 'app-user-menu',
	templateUrl: './UserMenuComponent.html',
	styleUrls: ['UserMenuComponent.scss']
})
export class UserMenuComponent {
	@Input()
	public menuClass: string;
	public userName: string;
	public userInitials: string;
	public logoSha256: string;

	public authentication: AuthResponse;

	constructor(
		protected authenticationService: CoreAuthenticationService,
		private router: Router) {
		this.authentication = this.authenticationService.getAuthentication();
		this.authenticationService.authenticationChanged.subscribe(user => this.onAuthenticationChanged(user));
	}

	private onAuthenticationChanged(authentication: AuthResponse): void {
		this.authentication = this.authenticationService.getAuthentication();
		if (this.authentication && this.authentication.user) {
			if (this.authentication.user.name) {
				this.userName = this.authentication.user.name;
				//Takes the initials from full name
				var names = this.userName.split(' '),
					initials = names[0].substring(0, 1).toUpperCase();

				if (names.length > 1) {
					initials += names[names.length - 1].substring(0, 1).toUpperCase();
				}
				this.userInitials = initials;
			}
			if (this.authentication.user.settings) {
				this.logoSha256 = this.authentication.user.settings.logoSha256;
			}
		}
	}
	goTo(navigationRoutes: string[]) {
		this.router.navigate(navigationRoutes);
	}
}
