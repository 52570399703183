import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreButtonModule } from 'app/core/button/CoreButtonModule';
import { CommonMaterialModule } from 'app/core/common-material/core-common-material.module';
import { CoreContentModule } from 'app/core/content/CoreContentModule';
import { CoreCountryModule } from 'app/core/country/CoreCountryModule';
import { PanelModule } from 'app/core/panel/PanelModule';
import { LocaleLookupHandlerModule } from 'app/generated/backend/localization/service/locale-lookup-handler';
import { LocaleSelectComponent } from './LocaleSelectComponent';

const routes: Routes = [
	{
		path: '',
		component: LocaleSelectComponent
	},
];

@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		CoreButtonModule,
		CoreContentModule,
		CoreCountryModule,
		LocaleLookupHandlerModule,
		PanelModule,
		TranslateModule,
		RouterModule.forChild(routes),

	],
	declarations: [LocaleSelectComponent],
	providers: [LocaleSelectComponent],
	exports: [LocaleSelectComponent]
})
export class LocaleSelectModule { }
