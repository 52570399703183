import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouteLocationsModel } from 'app/generated/backend/routing/api/route-locations-model';
import { RouteMapHandlerService } from 'app/generated/backend/routing/service/route-map-handler';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-rail-map',
	templateUrl: './RailMapComponent.html'
})
export class RailMapComponent implements OnInit, OnDestroy {

	private _subscriptions = new Array<Subscription>();

	public model: RouteLocationsModel;

	constructor(
		private routeMapHandlerService: RouteMapHandlerService
	) {
	}
	ngOnInit(): void {
		this._subscriptions.push(this.routeMapHandlerService.get(-90, -180, 90, 180).subscribe(
			response => {
				this.model = response.data;
			}
		));
	}

	ngOnDestroy() {
		this._subscriptions.forEach(subscription => subscription.unsubscribe());
	}

}
