
import { Component, Input, OnInit } from '@angular/core';
import { CoreMapService } from './CoreMapService';

@Component({
    selector: 'app-core-map-polyline-point',
    template: ``
})
export class CoreMapPolylinePointComponent implements OnInit {

    private _latitude: number;
    private _longitude: number;
    private pointReady: boolean = false;

    @Input()
    set latitude(latitude: number) {
        this._latitude = latitude;
        if (this.pointReady) {
            this.coreMapService.sendToParent('polylines');
        }
    }

    get latitude(): number {
        return this._latitude;
    }

    @Input()
    set longitude(longitude: number) {
        this._longitude = longitude;
        if (this.pointReady) {
            this.coreMapService.sendToParent('polylines');
        }
    }

    get longitude(): number {
        return this._longitude;
    }

    constructor(
        private coreMapService: CoreMapService
    ) { }

    ngOnInit(): void {
        this.pointReady = true;
    }

}  