import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HarmonizedCommodityLookupModel } from 'app/generated/backend/trade/api/harmonized-commodity-lookup-model';

@Injectable()
export class HarmonizedCommodityLookupHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
	): Observable<JsonResourceResponse<HarmonizedCommodityLookupModel[]>> {
		return this.http.get<JsonResourceResponse<HarmonizedCommodityLookupModel[]>>('api/v1/trade/harmonizedCommodities/lookup').pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		HarmonizedCommodityLookupHandlerService
	]
})
export class HarmonizedCommodityLookupHandlerModule { }
