import { ApplicationRef, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { CoreLocaleLookupService } from 'app/core/locale/CoreLocaleLookupService';
import { CoreTaskSummaryService } from 'app/core/task-summary/CoreTaskSummaryService';
import { VersionCheckService } from 'app/core/version-check/VersionCheckService';
import { LocaleLookupModel } from 'app/generated/backend/localization/api/locale-lookup-model';
import { environment } from 'environments/environment';
import { AlertService } from './core/alert/alert.service';
import { CoreAuthenticationService } from './core/authentication/CoreAuthenticationService';
import { CoreChatterService } from './core/chatter/CoreChatterService';
import { CoreCustomizationService } from './core/customization/CoreCustomizationService';
import { CustomizationSettings } from './core/customization/CustomizationSettings';
import { GoToService } from './core/goto/goto-service';
import { LinkService } from './core/link.service';
import { TimerService } from './core/timer/timer.service';
import { AuthResponse } from './generated/backend/auth/api/auth-response';
import { PushSubscriptionRequest } from './generated/backend/comm/api/push-subscription-request';
import { PushSubscriptionHandlerService } from './generated/backend/comm/service/push-subscription-handler';
import { PartyCategory } from './generated/backend/types/party-category';

@Component({
	selector: 'app-root',
	templateUrl: './AppComponent.html'
})
export class AppComponent implements OnInit {
	public isLoggedIn = false;
	public isAdmin = false;
	public authentication: AuthResponse = null;
	public isCustomer = false;
	public flag: string;
	public connected = false;
	public locales: LocaleLookupModel[];
	public showLocale: boolean = false;

	updateSettings(settings: CustomizationSettings) {
		this.flag = settings.locale.flag;
	}

	constructor(
		private router: Router,
		private alertService: AlertService,
		private swPush: SwPush,
		private authenticationService: CoreAuthenticationService,
		private customizationService: CoreCustomizationService,
		translate: TranslateService,
		private goToService: GoToService,
		private linkService: LinkService,
		private versionCheckService: VersionCheckService,
		private taskSummaryService: CoreTaskSummaryService,
		private applicationRef: ApplicationRef,
		private timerService: TimerService,
		private pushNotificationHandlerService: PushSubscriptionHandlerService,
		private localeService: CoreLocaleLookupService,
		private chatterService: CoreChatterService
	) {
		translate.setDefaultLang('en-gb');
		translate.addLangs(['keys']);
	}

	goTo(navigationRoutes: string[]) {
		this.goToService.goTo(navigationRoutes);
	}

	goHome() {
		if (this.isLoggedIn) {
			this.goToService.goTo(['party', 'dashboard']);
			return;
		}
		this.goToService.goTo(['/']);
	}

	goToExternalUrl(url: string) {
		this.goToService.goToExternalUrl(url);
	}

	goToPipedExernalUrl(keyToTranslationUrl: string) {
		this.goToService.goToPipedExernalUrl(keyToTranslationUrl);
	}

	private onAuthenticationChanged(authentication: AuthResponse): void {
		this.isLoggedIn = authentication != null && authentication.user != null;
		this.isAdmin = this.authenticationService.isAdmin();
		this.authentication = authentication;
		this.isCustomer = false;
		//if (this.authenticationService.isValidated() && this.authenticationService.isAdmin) {
		//this.chatterService.connect();
		//if (authentication.chatterToken) {
		//	this.chatterService.sendMessage({ "authenticate": authentication.chatterToken });
		//}
		//}
		if (authentication != null && authentication.party != null && authentication.party.permissions != null) {
			// tslint:disable-next-line:no-bitwise
			this.isCustomer = (authentication.party.category & PartyCategory.Customer) === PartyCategory.Customer;
			if (this.swPush.isEnabled) {
				this.swPush.requestSubscription({
					serverPublicKey: environment.vapidPublicKey
				})
					.then(sub => {
						const request = new PushSubscriptionRequest();
						request.endpoint = sub.endpoint;
						request.auth = this.toBase64(sub.getKey("auth"));
						request.p256dh = this.toBase64(sub.getKey("p256dh"));
						this.pushNotificationHandlerService.post(request).subscribe();
					})
					.catch(err => console.error("Could not subscribe to notifications", err));
			}
		}
		if (authentication?.user) {
			this.customizationService.enableLocalStorage();
		} else {
			this.customizationService.disableLocalStorage();
		}
	}
	toBase64(buffer: ArrayBuffer): string {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return btoa(binary);
	}

	findUtmCampaignTag(url: string) {
		const key = url.substring(url.indexOf('?') + 1, url.indexOf('='));
		let params = '';
		if (url.indexOf('&') < 0) {
			params = url.substring(url.indexOf('=') + 1);
		} else {
			params = url.substring(url.indexOf('=') + 1, url.indexOf('&'));
		}
		if (key && key === "utm_campaign") {
			this.customizationService.addCampaign(params);
		}
	}

	ngOnInit() {
		this.chatterService.messageReceived.subscribe(message => {
			if (message) {
				const str = "Shipment " + message.shipment + " task " + message.shipmentTaskType + " has been resolved";
				this.alertService.info(str);
			}
		});
		this.authenticationService.authenticationChanged.subscribe(authentication => this.onAuthenticationChanged(authentication));
		this.router.events.subscribe(ev => {
			if (ev instanceof NavigationStart) {
				this.findUtmCampaignTag(ev.url);
				this.linkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://adnavem.com' + ev.url });
				this.localeService.getItems().subscribe(locales => {
					if (locales) {
						locales.forEach(locale => {
							if (locale.topLevelDomain && locale.topLevelDomain !== 'cn') {
								this.linkService.addTag({ rel: 'alternate', hreflang: locale.identifier, href: 'https://adnavem.' + locale.topLevelDomain + ev.url });
							}
						});
					}
					this.linkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'http://adnavem.cn' + ev.url });
				}, error => { console.error(error); });
			}
		});
		this.authenticationService.validate()
			.subscribe(
				data => {
					this.connected = true;
				},
				error => {
					this.connected = false;
					this.alertService.error('We are experiencing technical difficulties at the moment.')
				});
		this.customizationService.changed.subscribe(settings => this.updateSettings(settings));
		this.alertService.connectionStatusUpdated.subscribe(connected => {
			this.connected = connected;
		});
		this.updateSettings(this.customizationService.getSettings());
		this.versionCheckService.initVersionCheck('assets/version.json');

		const stableSubscription = this.applicationRef.isStable.subscribe(t => {
			if (t) {
				stableSubscription.unsubscribe();
				this.timerService.start();
			}
		});
		this.taskSummaryService.start();
		this.localeService.getItems().subscribe(response => {
			this.locales = response;
		}, error => { console.error(error); });
	}
}
