import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'binaryContentUrl'
})
export class CoreBinaryContentUrlPipe implements PipeTransform {

	transform(value: string, ...args: any[]) {
		if (!value) {
			return null;
		}
		return '/bin/' + value;
	}
}