<ng-container *ngIf="!model.children">
    <button mat-menu-item [ngClass]="link.isActive ? activeItemClass : itemClass" routerLinkActive="{{activeItemClass}}"
        #link="routerLinkActive" [routerLinkActiveOptions]="{exact: false}" [routerLink]="model.uri">
        {{model.title}}
    </button>
</ng-container>
<ng-container *ngIf="model.children">
    <mat-menu #m="matMenu">
        <public-menu *ngFor="let child of model.children" [model]="child" itemClass="adn-party-menu-item"
            activeItemClass="adn-party-menu-item-active">
        </public-menu>
    </mat-menu>
    <button mat-menu-item [matMenuTriggerFor]="m" mat-menu-item [ngClass]="link.isActive ? activeItemClass : itemClass"
        routerLinkActive="{{activeItemClass}}" #link="routerLinkActive" [routerLinkActiveOptions]="{exact: false}"
        [routerLink]="model.uri">
        {{model.title}}
    </button>
</ng-container>