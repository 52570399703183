import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonBaseComponent } from './button-base.component';


@Component({
	selector: 'app-button-save',
	templateUrl: './CoreButtonRaised.html'
})
export class ButtonSaveComponent extends ButtonBaseComponent {
	@HostBinding('class') class = 'adn-material-button';

	@Input()
	public iconClass = 'fa-save';
	@Input()
	public titleTranslateKey = 'button.save';
	@Input()
	public color = 'accent';
	@Input()
	public disabled: boolean = false;

	@Input()
	public type = 'button';

	@Input()
	public hintTranslateKey: string;


	@Output()
	action: EventEmitter<ButtonBaseComponent> = new EventEmitter<ButtonBaseComponent>();
}