import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum MeasurementUnit {
	None = 0,
	Kilogram = 1,
	Tonne = 2,
	Pound = 3,
	Kilometre = 4,
	Mile = 5,
	NauticalMile = 6,
	TEU = 7,
	FEU = 8,
}

@Pipe({
name: 'measurementUnit'
})
export class MeasurementUnitPipe extends BasePipe<MeasurementUnit> implements PipeTransform {
	static prefix = 'Backend.Types.MeasurementUnit.';
	static keys = [
		MeasurementUnit.None,
		MeasurementUnit.Kilogram,
		MeasurementUnit.Tonne,
		MeasurementUnit.Pound,
		MeasurementUnit.Kilometre,
		MeasurementUnit.Mile,
		MeasurementUnit.NauticalMile,
		MeasurementUnit.TEU,
		MeasurementUnit.FEU];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: MeasurementUnit): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case MeasurementUnit.None: return 'None';
			case MeasurementUnit.Kilogram: return 'Kilogram';
			case MeasurementUnit.Tonne: return 'Tonne';
			case MeasurementUnit.Pound: return 'Pound';
			case MeasurementUnit.Kilometre: return 'Kilometre';
			case MeasurementUnit.Mile: return 'Mile';
			case MeasurementUnit.NauticalMile: return 'NauticalMile';
			case MeasurementUnit.TEU: return 'TEU';
			case MeasurementUnit.FEU: return 'FEU';
			default: return null;
		}
	}
	transform(value: MeasurementUnit, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(MeasurementUnitPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		MeasurementUnitPipe
	],
	exports: [
		MeasurementUnitPipe
	],
	providers: [
		MeasurementUnitPipe
	]
})
export class MeasurementUnitModule { }
