import { Component, Input, OnInit } from '@angular/core';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';
import { ShipmentDetailService } from 'app/shipment/detail/ShipmentDetailService';
import { Subscription } from 'rxjs';
@Component({
	selector: 'app-shipment-sub-menu',
	templateUrl: './ShipmentSubMenuComponent.html'
})
export class ShipmentSubMenuComponent implements OnInit {
	@Input()
	public menuClass: string;
	public model: ShipmentDetailModel;
	private subscriptions = new Array<Subscription>();

	constructor(
		protected shipmentDetailService: ShipmentDetailService,
		protected authenticationService: CoreAuthenticationService
	) {
	}



	ngOnInit() {
		this.subscriptions.push(this.shipmentDetailService.detailChanged.subscribe(detail => {
			this.model = detail;
		}));
		this.subscriptions.push(this.authenticationService.authenticationChanged.subscribe(change => {
			this.model = null;
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
	}
}
