import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreSelectInputComponent } from './CoreSelectInputComponent';
import { CoreSelectOptionComponent } from './CoreSelectOptionComponent';

@NgModule({
    imports: [
        AutocompleteModule,
        CommonMaterialModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        HelpPopupModule
    ],
    exports: [CoreSelectInputComponent, CoreSelectOptionComponent],
    declarations: [CoreSelectInputComponent, CoreSelectOptionComponent]
})
export class CoreSelectInputModule { }
