import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { PartyUserLookupService } from './PartyUserLookupService';

@Pipe({
	name: 'partyUserEmail'
})
export class PartyUserEmailPipe implements PipeTransform {
	constructor(
		protected service: PartyUserLookupService
	) {

	}
	transform(value: number, ...args: any[]) {
		if (!value) {
			return null;
		}
		return this.service.getItem(value).pipe(map(data => { return data ? data.email : value }));
	}
}
