import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { ButtonAnalyticsBaseComponent } from 'app/core/button/button-analytics-base.component';
import { ButtonBaseComponent } from './button-base.component';


@Component({
	selector: 'app-core-button-login-register',
	templateUrl: './CoreButtonFlat.html'
})
export class CoreButtonLoginRegisterComponent extends ButtonAnalyticsBaseComponent {
	@HostBinding('class') class = 'adn-material-button';

	constructor(
		protected angulartics2: Angulartics2,
		protected router: Router
	) {
		super(angulartics2, router);
	}

	@Input()
	public category = 'login';
	@Input()
	public href = '/user/login'
	@Input()
	public iconClass: string;
	@Input()
	public titleTranslateKey = 'button.loginRegister';
	@Input()
	public color = 'basic';

	@Input()
	public disabled: boolean = false;

	@Input()
	public type = 'button';


	@Input()
	public hintTranslateKey: string;


	@Output()
	action: EventEmitter<ButtonBaseComponent>;
}