<div [ngClass]="{'adn-public-header': !isLoggedIn}" class="adn-header sticky-header">
  <div class="adn-header-row">
    <a (click)="goHome()" class="clickable">
      <img *ngIf="isLoggedIn" src="/assets/adnavem_logo_white.svg" alt="Adnavem" width="111" height="24" />
      <img *ngIf="!isLoggedIn" src="/assets/adnavem_logo_red.svg" alt="Adnavem" width="111" height="24" />
    </a>
    <app-public-menu *ngIf="!isLoggedIn" uri="/" class="adn-public-menu d-none d-xl-block"
      menuClass="adn-public-menu-dropdown">
    </app-public-menu>
    <div *ngIf="isLoggedIn && isCustomer" class="adn-public-menu d-none d-xl-block"></div>
    <div *ngIf="isLoggedIn && isCustomer" class="d-none d-xl-block">
      <button mat-button (click)="goTo(['/booking'])" class="adnavem-dark-text-with-light-background">
        <span class="fal fa-material fa-money-check-alt fa-fw adn-menu-booking-btn"
          aria-hidden="true"></span>&nbsp;{{'nav.booking'
        |
        translate}}
      </button>
      <button mat-button style="margin-left: 8px;" (click)="goTo(['/shipment/new'])"
        class="adnavem-dark-text-with-light-background">
        <span class="fal fa-material fa-plus fa-fw adn-menu-booking-btn"
          aria-hidden="true"></span>&nbsp;{{'nav.newShipment'
        |
        translate}}
      </button>
    </div>
    <div fxFlex></div>

    <div fxFlex="none" fxLayout="row" fxLayoutAlign="end center">
      <app-core-mega-search *ngIf="isLoggedIn"></app-core-mega-search>
      <app-core-button-talk-to-expert *ngIf="!isLoggedIn" class="d-none d-sm-block"></app-core-button-talk-to-expert>
      <app-core-button-login-register *ngIf="!isLoggedIn" class="d-none d-sm-block"></app-core-button-login-register>
      <app-shipment-sub-menu *ngIf="isLoggedIn && authentication?.party" menuClass="adn-color-white">
      </app-shipment-sub-menu>
      <app-party-menu *ngIf="isLoggedIn && authentication?.party" class="d-none d-xl-block" menuClass="adn-color-white">
        {{authentication.party.name}}<app-task-summary></app-task-summary>&nbsp;<span
          class="fal fa-fw fa-sort-down fa-1x adn-menu-user-arrow"></span>
      </app-party-menu>
      <app-user-menu *ngIf="isLoggedIn && authentication?.user" class="d-none d-xl-block"></app-user-menu>
      <mat-menu #adminMenu="matMenu" class="d-block d-md-none">
        <app-admin-submenu></app-admin-submenu>
      </mat-menu>
      <div *ngIf="isLoggedIn && isAdmin" class="d-none d-xl-block">
        <button name='adminSubMenuButton' mat-button [matMenuTriggerFor]="adminMenu"
          [ngClass]="'adnavem-text-color-with-primary-background adn-admin-button-margin'">
          <span class="fal fa-fw fa-material fa-user-secret adn-menu-icons"
            aria-hidden="true"></span>&nbsp;Admin&nbsp;<i class="fal fa-fw fa-sort-down fa-1x adn-menu-user-arrow"></i>
        </button>
        <mat-menu #adminMenu="matMenu" class="adnavem-primary-background-color">
          <app-admin-submenu></app-admin-submenu>
        </mat-menu>
        &nbsp;
      </div>
      <div *ngIf="!isLoggedIn">
        <button mat-menu-item (click)="showLocale?showLocale=false: showLocale=true"
          class="adnavem-public-menuitem-color d-none d-xl-block">
          <img *ngIf="country" alt="" src="/assets/flags/4x3/{{country}}.svg" width="20" height="15"
            class="adn-menu-flags" /> <img alt="" src="/assets/flags/4x3/{{flag}}.svg" width="20" height="15"
            class="adn-menu-flags" />
        </button>
      </div>


      <!-- Hamburger menu -->
      <div class="d-block">
        <button mat-icon-button [matMenuTriggerFor]="mainMenu"
          [ngClass]="{'adn-color-white': isLoggedIn, 'adnavem-public-menuitem-color': !isLoggedIn}">
          <span class="fal fa-fw fa-material fa-bars"></span>
        </button>
        <mat-menu #mainMenu="matMenu" class="adn-color-primary">
          <div *ngIf="isLoggedIn" class="adn-color-primary">
            <button mat-menu-item (click)="goTo(['/'])" class="adn-color-primary">
              <span class="fal fa-material fa-fw fa-home adn-menu-icons" aria-hidden="true"></span>&nbsp;{{'nav.home'
              | translate}}</button>
          </div>
          <div *ngIf="isLoggedIn && isCustomer" class="adn-color-primary d-xl-none">
            <button mat-menu-item (click)="goTo(['/booking'])" class="adn-color-primary">
              <span class="fal fa-material fa-fw fa-money-check-alt adn-menu-icons"
                aria-hidden="true"></span>&nbsp;{{'nav.booking'
              | translate}}</button>
            <button mat-menu-item (click)="goTo(['/shipment/new'])" class="adn-color-primary">
              <span class="fal fa-material fa-fw fa-plus adn-menu-icons"
                aria-hidden="true"></span>&nbsp;{{'nav.newShipment'
              | translate}}</button>
          </div>
          <app-party-menu *ngIf="isLoggedIn && authentication?.party"
            menuClass="adn-color-primary adnavem-white-background-color d-xl-none">{{authentication.party.name}}
            <app-task-summary></app-task-summary>
          </app-party-menu>
          <app-user-menu class="d-xl-none" *ngIf="isLoggedIn && authentication?.user"></app-user-menu>
          <app-admin-menu class="d-xl-none" *ngIf="isAdmin"></app-admin-menu>
          <app-public-menu uri="/" class="adn-public-menu" [isDropdown]="true" [isLoggedIn]="isLoggedIn">
          </app-public-menu>
          <button *ngIf="!isLoggedIn" mat-menu-item (click)="goTo(['/company/contact'])"
            class="adnavem-text-color-with-primary-background">{{'button.talkToAnExpert' |
            translate}}</button>
          <button *ngIf="!isLoggedIn" mat-menu-item (click)="goTo(['/user/login'])"
            class="adn-color-primary">{{'button.loginRegister' |
            translate}}</button>
          <button mat-menu-item class="adn-color-primary" (click)="goTo(['/glossary'])">
            {{'nav.glossary' | translate}}</button>
          <button mat-menu-item (click)="showLocale?showLocale=false: showLocale=true" class="adn-color-primary">
            <img *ngIf="flag" alt="" src="/assets/flags/4x3/{{flag}}.svg" width="20" height="15" class="adn-menu-flags"
              alt="" />&nbsp;Language
          </button>
        </mat-menu>
      </div>

      <div *ngIf="!connected" class="adn-color-white">
        &nbsp;<span class="fal fa-fw fa-sm fa-signal-slash adn-menu-icons network-icon-blink" aria-hidden="true"
          title="{{'error.disconnected' | translate}}"></span>&nbsp;
      </div>
    </div>
  </div>
</div>
<div class="adn-main-content">
  <app-alert></app-alert>
  <app-locale-select *ngIf="showLocale" (selected)="showLocale=false" [isAdmin]="isAdmin" [isVisible]="showLocale">
  </app-locale-select>
  <div [ngClass]="{'adn-main-content-outlet-hidden': showLocale}">
    <router-outlet></router-outlet>
  </div>
</div>
<app-site-footer *ngIf="!isLoggedIn"></app-site-footer>