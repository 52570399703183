
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { CoreMapCoordinates } from './CoreMapCoordinates';
import { CoreMapService } from './CoreMapService';

@Component({
    selector: 'app-core-map-marker',
    template: ``
})
export class CoreMapMarkerComponent implements OnInit {

    private _latitude: number;
    private _longitude: number;
    private _title: string;
    private _iconUrl: string;
    private markerReady: boolean = false;

    @Output()
    dragEnd: EventEmitter<CoreMapCoordinates> = new EventEmitter<CoreMapCoordinates>();

    @Output()
    markerClick: EventEmitter<CoreMapCoordinates> = new EventEmitter<CoreMapCoordinates>();

    @Input()
    set latitude(latitude: number) {
        this._latitude = latitude;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
    }

    get latitude(): number {
        return this._latitude;
    }

    @Input()
    set longitude(longitude: number) {
        this._longitude = longitude;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
    }

    get longitude(): number {
        return this._longitude;
    }

    @Input()
    set title(title: string) {
        this._title = title;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
    }

    get title(): string {
        return this._title;
    }

    @Input()
    set iconUrl(iconUrl: string) {
        this._iconUrl = iconUrl;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
    }

    get iconUrl(): string {
        return this._iconUrl;
    }

    @Input() markerDraggable: boolean = false;

    constructor(
        private coreMapService: CoreMapService
    ) { }

    ngOnInit(): void {
        this.markerReady = true;
    }

    onDragEnd(event: MouseEvent) {
        let coordinates = new CoreMapCoordinates();
        coordinates.latitude = event.coords.lat;
        coordinates.longitude = event.coords.lng;
        this.dragEnd.emit(coordinates);
    }

    onMarkerClick() {
        let coordinates = new CoreMapCoordinates();
        coordinates.latitude = this.latitude;
        coordinates.longitude = this.longitude;
        this.markerClick.emit(coordinates);
    }

} 