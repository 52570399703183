import { HttpClient } from '@angular/common/http';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { CoreEntityCache } from 'app/core/cache/CoreEntityCache';
import { MatchableEntity } from 'app/_models/matchable-entity';
import { SimpleLookupService } from 'app/_templates/simple-lookup.service';


export abstract class PartyBaseIdLookupService<T extends MatchableEntity> extends SimpleLookupService<T> {

	constructor(
		protected http: HttpClient,
		private authenticationService: CoreAuthenticationService,
		uriPrefix: string,
		uriSuffix: string,
		protected cache: CoreEntityCache<T> = null
	) {
		super(http, null, cache);
		this.authenticationService.authenticationChanged.subscribe(auth => {
			super.setPath(uriPrefix + '/' + auth?.party?.id + '/' + uriSuffix)
		});
	}
}
