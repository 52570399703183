import { Component, Input } from '@angular/core';
import { Locality } from '../../../generated/backend/locality/api/locality';
import { LocationFunction } from '../../../generated/backend/types/location-function';
import { LocationSize } from '../../../generated/backend/types/location-size';

@Component({
	selector: 'app-locality-icon',
	templateUrl: './LocalityIconComponent.html',
	styleUrls: ['LocalityIconComponent.css']
})
export class LocalityIconComponent {

	_locationFunction: LocationFunction;
	_size: LocationSize;
	_class: string;
	_style: string;

	setClass() {
		if (this._locationFunction & LocationFunction.Sea) {
			this._class = 'fa-anchor';
		} else if (this._locationFunction & LocationFunction.Air) {
			this._class = 'fa-plane';
		} else if (this._locationFunction & LocationFunction.Rail) {
			this._class = 'fa-train';
		} else if (this._locationFunction & LocationFunction.Road) {
			this._class = 'fa-truck';
		} else {
			this._class = 'fa-building';
		}
		if (this._size == LocationSize.None) {
			this._class = 'fa-dot-circle';
		} else if (this._size == LocationSize.Small) {
			this._class += ' adn-location-size-small';
		} else if (this._size == LocationSize.Medium) {
			this._class += ' adn-location-size-medium';
		} else if (this._size == LocationSize.Large) {
			this._class += ' adn-location-size-large';
		}
	}

	@Input()
	set locality(locality: Locality) {
		this._locationFunction = locality.function;
		this._size = locality.size;
		this.setClass();
	}

	@Input()
	set locationFunction(locationFunction: LocationFunction) {
		this._locationFunction = locationFunction;
		this.setClass();
	}

	@Input()
	set size(size: LocationSize) {
		this._size = size;
		this.setClass();
	}
}
