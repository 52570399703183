import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RateIndexLookupComponent } from "./RateIndexLookupComponent";
import { RateIndexLookupService } from "./RateIndexLookupService";
import { TranslateModule } from '@ngx-translate/core';
import { MeasurementUnitModule } from '../../generated/backend/types/measurement-unit';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreCurrencyModule } from "../currency/CoreCurrencyModule";
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { RateIndexLookupHandlerModule } from 'app/generated/backend/trade/service/rate-index-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		FormsModule,
		MeasurementUnitModule,
		CoreCurrencyModule,
		TranslateModule,
		CommonMaterialModule,
		HelpPopupModule,
		RateIndexLookupHandlerModule
	],
	exports: [RateIndexLookupComponent],
	declarations: [RateIndexLookupComponent],
	providers: [RateIndexLookupService]
})
export class RateIndexModule {
}
