import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { GoToService } from 'app/core/goto/goto-service';
import { CoreUtilUrl } from '../util/Url';

@Directive({
    selector: '[routeTransformer]'
})
export class RouteTransformerDirective {

    constructor(
        private router: Router,
        private angulartics2: Angulartics2,
        private goToService: GoToService
    ) { }

    public navigate(href: string) {
        if (href.startsWith && (href.startsWith('//') || href.startsWith('https://') || href.startsWith('http://') || href.startsWith("mailto"))) {
            this.goToService.goToExternalUrl(href);
        } else {
            CoreUtilUrl.navigate(this.router, href);
        }
    }

    @HostListener('click', ['$event'])
    public onClick(event) {
        if (event.target.tagName === 'A') {
            this.navigate(event.target.getAttribute('href'));
            event.preventDefault();
        } else if (event.target.tagName === 'BUTTON') {
            const href = event.target.getAttribute('href');
            const category = event.target.getAttribute('category');
            if (href && category) {
                this.angulartics2.eventTrack.next({ action: 'click', properties: { category: category, label: window.location.href } });
                this.navigate(href);
                event.preventDefault();
            }
        } else {
            return;
        }
    }

};