import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';

const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => EmailInputComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => EmailInputComponent),
	multi: true
};


@Component({
	selector: 'app-email-input',
	templateUrl: './EmailInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class EmailInputComponent implements ControlValueAccessor, Validator {
	@Input()
	required = false;

	@Input()
	rows: number;

	@Input()
	disabled: boolean;

	@Input()
	helpUri: string;

	@Input()
	label: string;

	@Input()
	placeholder: string = '';

	@Input()
	hint: string;

	@Input()
	maxlength: number;

	@Input()
	minlength: number;

	@Input()
	iconClass: string;

	@Output()
	blur: EventEmitter<any> = new EventEmitter<any>();

	@Input()
	set pattern(pattern: string) {
		if (pattern !== this._pattern) {
			this._pattern = pattern;
			this._regExp = new RegExp(pattern);
		}
	}

	get pattern(): string {
		return this._pattern;
	}

	public errorStateMatcher = new SimpleErrorStateMatcher();

	// The internal data model
	private _pattern: string;
	private _regExp: RegExp;
	private innerValue: string;

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): any {
		return this.innerValue;
	};

	// returns null when valid else the validation object
	// in this case we're checking if the json parsing has
	// passed or failed from the onChange method
	public validate(c: UntypedFormControl): ValidationErrors | null {
		if (this.value == null || this.value.length < 1) {
			if (!this.required) {
				this.errorStateMatcher.valid = true;
				return null;
			}
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				required: true
			};
		}
		if (!this.isEmailValid()) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.invalidEmail';
			return {
				required: true
			};
		}
		this.errorStateMatcher.valid = true;
		return null;
	}

	isEmailValid(): boolean {
		let invalidFormat1 = '@.';
		let invalidFormat2 = '..';
		let invalidFormat3 = '.';
		let invalidFormat4 = '.@';
		if (!this.value.includes('@')) {
			return false;
		} else if (this.value.includes(invalidFormat1)) {
			return false;
		} else if (this.value.indexOf(invalidFormat3) === 0) {
			return false;
		} else if (this.value.includes(invalidFormat2)) {
			return false;
		} else if (this.value.includes(invalidFormat4)) {
			return false;
		} else if (!this.value.includes(invalidFormat3)) {
			return false;
		} else if (this.value.substring(this.value.lastIndexOf('.') + 1, this.value.length).length < 2) {
			return false;
		} else if (this.value.substring(this.value.lastIndexOf('.') + 1, this.value.length).length > 4) {
			return false;
		} else if (this.value.substring(0, this.value.lastIndexOf('@')).includes('@')) {
			return false;
		} else {
			return true;
		}
	}

	// set accessor including call the onchange callback
	set value(v: any) {
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.validate(null);
			this.onChangeCallback(v);
		}
	}

	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.validate(null);
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	constructor(
		public translateService: TranslateService
	) {
	}

	onBlur() {
		if (this.value) {
			(this.value.trim() === '') ? this.value = null : this.value = this.value.trim();
		}
		this.blur.emit(this.value);
	}
}
