import { Injectable } from '@angular/core';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { Menu } from "app/core/menu/Menu";
import { MenuItem } from 'app/core/menu/MenuItem';
import { MenuItemGroup } from 'app/core/menu/MenuItemGroup';
import { AdminAccessControl } from 'app/generated/backend/types/admin-access-control';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class AdminMenuService {
	public menuUpdated: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(null);
	public showMenu: boolean[];
	constructor(authenticationService: CoreAuthenticationService) {
		authenticationService.authenticationChanged.subscribe(auth => {
			this.showMenu = authenticationService.getAdminListRights();
			const menu = new Menu();
			menu.items = new Array<MenuItem>();
			menu.groups = new Array<MenuItemGroup>();
			menu.items.push({ iconClass: 'fa-tachometer-alt', routerLink: ['/admin', 'dashboard'], queryParams: null, titleTranslateKey: 'nav.dashboard', taskClass: null });

			this.addCrm(menu);
			this.addAutomation(menu);
			this.addShipment(menu);
			if (this.showMenu[AdminAccessControl.Parties]) {
				menu.items.push({ iconClass: 'fa-building', routerLink: ['/admin', 'parties'], queryParams: null, titleTranslateKey: 'nav.parties', taskClass: null });
			}
			if (this.showMenu[AdminAccessControl.Users]) {
				menu.items.push({ iconClass: 'fa-users', routerLink: ['/admin', 'users'], queryParams: null, titleTranslateKey: 'nav.users', taskClass: null });
			}
			if (this.showMenu[AdminAccessControl.PartyUserActivity]) {
				menu.items.push({ iconClass: 'fa-list', routerLink: ['/admin', 'partyUserActivityLog'], queryParams: null, titleTranslateKey: 'nav.partyUserActivityLog', taskClass: null });
			}
			this.addPricing(menu);
			this.addFinance(menu);
			if (this.showMenu[AdminAccessControl.Analytics]) {
				menu.items.push({ iconClass: 'fa-analytics', routerLink: ['/admin', 'analytics'], queryParams: null, titleTranslateKey: 'nav.analytics', taskClass: null });
			}

			this.addCms(menu);
			this.addSupport(menu);
			this.addSetup(menu);
			this.addSystem(menu);

			this.menuUpdated.next(menu);
		});
	}

	private addShipment(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.ShipmentBookings]) {
			subMenuItems.push({ iconClass: 'fa-book', routerLink: ['/admin', 'shipment', 'bookings'], queryParams: null, titleTranslateKey: 'nav.bookings', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Containers]) {
			subMenuItems.push({ iconClass: 'fa-container-storage', routerLink: ['/admin', 'shipment', 'containers'], queryParams: null, titleTranslateKey: 'nav.containers', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Shipments]) {
			subMenuItems.push({ iconClass: 'fa-boxes', routerLink: ['/admin', 'shipment', 'shipments'], queryParams: null, titleTranslateKey: 'nav.shipments', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.ShipmentServices]) {
			subMenuItems.push({ iconClass: 'fa-cube', routerLink: ['/admin', 'shipment', 'services'], queryParams: null, titleTranslateKey: 'nav.shipmentServices', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.ShipmentParties]) {
			subMenuItems.push({ iconClass: 'fa-user', routerLink: ['/admin', 'shipment', 'parties'], queryParams: null, titleTranslateKey: 'nav.shipmentParties', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.ShipmentVoyages]) {
			subMenuItems.push({ iconClass: 'fa-route', routerLink: ['/admin', 'shipment', 'scheduleVoyages'], queryParams: null, titleTranslateKey: 'nav.shipmentScheduleVoyages', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.ShipmentVoyages]) {
			subMenuItems.push({ iconClass: 'fa-route', routerLink: ['/admin', 'shipment', 'trackingVoyages'], queryParams: null, titleTranslateKey: 'nav.shipmentTrackingVoyages', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.PartyTasks]) {
			subMenuItems.push({ iconClass: 'fa-tasks', routerLink: ['/admin', 'shipment', 'tasks'], queryParams: null, titleTranslateKey: 'nav.shipmentTasks', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.ShipmentServiceRejections]) {
			subMenuItems.push({ iconClass: 'fa-ban', routerLink: ['/admin', 'shipment', 'serviceRejections'], queryParams: null, titleTranslateKey: 'nav.shipmentServiceRejections', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.ShipmentReleases]) {
			subMenuItems.push({ iconClass: 'fa-sign-out-alt', routerLink: ['/admin', 'shipment', 'releases'], queryParams: null, titleTranslateKey: 'nav.releases', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-boxes', routerLink: ['/admin', 'shipment'], titleTranslateKey: 'nav.shipping', items: subMenuItems, taskClass: null });
		}
	}
	private addPricing(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.TradeLanes]) {
			subMenuItems.push({ iconClass: 'fa-hands-helping', routerLink: ['/admin', 'locationTradeLanes'], queryParams: null, titleTranslateKey: 'nav.locationTradeLanes', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.TradeLanes]) {
			subMenuItems.push({ iconClass: 'fa-hands-helping', routerLink: ['/admin', 'countryTradeLanes'], queryParams: null, titleTranslateKey: 'nav.countryTradeLanes', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.RailSchedules] || this.showMenu[AdminAccessControl.SeaSchedules]) {
			subMenuItems.push({ iconClass: 'fa-hands-helping', routerLink: ['/admin', 'pricing', 'customsClearanceCoverage'], queryParams: null, titleTranslateKey: 'nav.customsClearanceCoverage', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.RateChart]) {
			subMenuItems.push({ iconClass: 'fa-box-usd', routerLink: ['/admin', 'roadCoverage'], queryParams: null, titleTranslateKey: 'nav.roadCoverage', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.RateChart]) {
			subMenuItems.push({ iconClass: 'fa-box-usd', routerLink: ['/admin', 'pricing', 'carriageCoverage'], queryParams: null, titleTranslateKey: 'nav.carriageCoverage', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.PartySeaRateCurrent]) {
			subMenuItems.push({ iconClass: 'fa-file-invoice-dollar', routerLink: ['/admin', 'partyFclSeaRateCurrents'], queryParams: null, titleTranslateKey: 'nav.fclPartySeaRates', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.PartySeaRateCurrent]) {
			subMenuItems.push({ iconClass: 'fa-file-invoice-dollar', routerLink: ['/admin', 'partyLclSeaRateCurrents'], queryParams: null, titleTranslateKey: 'nav.lclPartySeaRates', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.SeaSchedules]) {
			subMenuItems.push({ iconClass: 'fa-calendar', routerLink: ['/admin', 'pricing', 'seaSchedules', 'fcl'], queryParams: null, titleTranslateKey: 'nav.fclSeaSchedules', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.SeaSchedules]) {
			subMenuItems.push({ iconClass: 'fa-calendar', routerLink: ['/admin', 'pricing', 'seaSchedules', 'lcl'], queryParams: null, titleTranslateKey: 'nav.lclSeaSchedules', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.RailSchedules]) {
			subMenuItems.push({ iconClass: 'fa-calendar', routerLink: ['/admin', 'pricing', 'railSchedules'], queryParams: null, titleTranslateKey: 'nav.railSchedules', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-box-usd', routerLink: ['/admin', 'pricing'], titleTranslateKey: 'nav.pricing', items: subMenuItems, taskClass: null });
		}
	}
	private addFinance(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.Agreements]) {
			subMenuItems.push({ iconClass: 'fa-handshake', routerLink: ['/admin', 'finance', 'agreements'], queryParams: null, titleTranslateKey: 'nav.agreements', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.ShipmentCharges]) {
			subMenuItems.push({ iconClass: 'fa-box-usd', routerLink: ['/admin', 'finance', 'shipmentCharges'], queryParams: null, titleTranslateKey: 'nav.shipmentCharges', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.ShipmentCharges]) {
			subMenuItems.push({ iconClass: 'fa-box-usd', routerLink: ['/admin', 'finance', 'shipmentChargeEvents'], queryParams: null, titleTranslateKey: 'nav.shipmentChargeEvents', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.PartyAccountTransactions]) {
			subMenuItems.push({ iconClass: 'fa-file-invoice-dollar', routerLink: ['/admin', 'finance', 'partyAccountTransactions'], queryParams: null, titleTranslateKey: 'nav.partyAccountTransactions', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Invoices]) {
			subMenuItems.push({ iconClass: 'fa-sack-dollar', routerLink: ['/admin', 'finance', 'invoices'], queryParams: null, titleTranslateKey: 'nav.invoices', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Invoices]) {
			subMenuItems.push({ iconClass: 'fa-sack-dollar', routerLink: ['/admin', 'finance', 'invoiceRows'], queryParams: null, titleTranslateKey: 'nav.invoiceRows', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.PartyRateMargin]) {
			subMenuItems.push({ iconClass: 'fa-wave-pulse', routerLink: ['/admin', 'finance', 'partyRateMargin'], queryParams: null, titleTranslateKey: 'nav.rateMargin', taskClass: null });
		}
		if (subMenuItems.length) {
			subMenuItems.push({ iconClass: 'fa-book', routerLink: ['/admin', 'finance', 'dac7'], queryParams: null, titleTranslateKey: 'nav.dac7', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-landmark', routerLink: ['/admin', 'finance'], titleTranslateKey: 'nav.finance', items: subMenuItems, taskClass: null });
		}
	}
	private addCms(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.Media]) {
			subMenuItems.push({ iconClass: 'fa-image', routerLink: ['/admin', 'cms', 'media'], queryParams: null, titleTranslateKey: 'nav.media', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Content]) {
			subMenuItems.push({ iconClass: 'fa-newspaper', routerLink: ['/admin', 'cms', 'content', 'tree'], queryParams: null, titleTranslateKey: 'nav.contentTree', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Content]) {
			subMenuItems.push({ iconClass: 'fa-newspaper', routerLink: ['/admin', 'cms', 'content', 'translations'], queryParams: null, titleTranslateKey: 'nav.contentTranslations', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Templates]) {
			subMenuItems.push({ iconClass: 'fa-table', routerLink: ['/admin', 'cms', 'templates'], queryParams: null, titleTranslateKey: 'nav.templates', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Topic]) {
			subMenuItems.push({ iconClass: 'fa-language', routerLink: ['/admin', 'cms', 'topic'], queryParams: null, titleTranslateKey: 'nav.topics', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Translations]) {
			subMenuItems.push({ iconClass: 'fa-language', routerLink: ['/admin', 'cms', 'translations'], queryParams: null, titleTranslateKey: 'nav.translations', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-image', routerLink: ['/admin', 'cms'], titleTranslateKey: 'nav.cms', items: subMenuItems, taskClass: null });
		}
	}
	private addSupport(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.SupportIssue]) {
			subMenuItems.push({ iconClass: 'fa-book-sparkles', routerLink: ['/admin', 'support', 'issues'], queryParams: null, titleTranslateKey: 'nav.issues', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.TimeEntry]) {
			subMenuItems.push({ iconClass: 'fa-stopwatch', routerLink: ['/admin', 'support', 'timeEntries'], queryParams: null, titleTranslateKey: 'nav.timeEntries', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-sparkles', routerLink: ['/admin', 'support'], titleTranslateKey: 'nav.support', items: subMenuItems, taskClass: null });
		}
	}
	private addSystem(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.Daemons]) {
			subMenuItems.push({ iconClass: 'fa-microchip', routerLink: ['/admin', 'system', 'daemons'], queryParams: null, titleTranslateKey: 'nav.daemons', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-thermometer-three-quarters', routerLink: ['/admin', 'system', 'status'], queryParams: null, titleTranslateKey: 'nav.status', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Jobs]) {
			subMenuItems.push({ iconClass: 'fa-tasks', routerLink: ['/admin', 'system', 'jobs'], queryParams: null, titleTranslateKey: 'nav.jobs', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Message]) {
			subMenuItems.push({ iconClass: 'fa-messages', routerLink: ['/admin', 'system', 'messages'], queryParams: null, titleTranslateKey: 'nav.messages', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Parameters]) {
			subMenuItems.push({ iconClass: 'fa-tasks', routerLink: ['/admin', 'system', 'parameters'], queryParams: null, titleTranslateKey: 'nav.parameters', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.UserInfoProcessing]) {
			subMenuItems.push({ iconClass: 'fa-list', routerLink: ['/admin', 'system', 'user-info-processing'], queryParams: null, titleTranslateKey: 'nav.userInfoProcessing', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-users-cog', routerLink: ['/admin', 'system'], titleTranslateKey: 'nav.system', items: subMenuItems, taskClass: null });
		}
	}
	private addSetup(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.Countries]) {
			subMenuItems.push({ iconClass: 'fa-globe', routerLink: ['/admin', 'setup', 'countries'], queryParams: null, titleTranslateKey: 'nav.countries', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Currencies]) {
			subMenuItems.push({ iconClass: 'fa-dollar-sign', routerLink: ['/admin', 'setup', 'currencies'], queryParams: null, titleTranslateKey: 'nav.currencies', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.DocumentTypes]) {
			subMenuItems.push({ iconClass: 'fa-book', routerLink: ['/admin', 'setup', 'documentTypes'], queryParams: null, titleTranslateKey: 'nav.documentTypes', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.HarmonizedCommodities]) {
			subMenuItems.push({ iconClass: 'fa-shopping-bag', routerLink: ['/admin', 'setup', 'harmonizedCommodities'], queryParams: null, titleTranslateKey: 'nav.harmonizedCommodities', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.HazardousSubstances]) {
			subMenuItems.push({ iconClass: 'fa-exclamation-triangle', routerLink: ['/admin', 'setup', 'hazardousSubstances'], queryParams: null, titleTranslateKey: 'nav.hazardousSubstances', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Incoterms]) {
			subMenuItems.push({ iconClass: 'fa-table', routerLink: ['/admin', 'setup', 'incoterms'], queryParams: null, titleTranslateKey: 'nav.incoterms', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Languages]) {
			subMenuItems.push({ iconClass: 'fa-language', routerLink: ['/admin', 'setup', 'languages'], queryParams: null, titleTranslateKey: 'nav.languages', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Locale]) {
			subMenuItems.push({ iconClass: 'fa-globe', routerLink: ['/admin', 'setup', 'locale'], queryParams: null, titleTranslateKey: 'nav.locales', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Locations]) {
			subMenuItems.push({ iconClass: 'fa-map-marker-alt', routerLink: ['/admin', 'setup', 'locations'], queryParams: null, titleTranslateKey: 'nav.locations', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Facilities]) {
			subMenuItems.push({ iconClass: 'fa-truck-ramp-box', routerLink: ['/admin', 'setup', 'facilities'], queryParams: null, titleTranslateKey: 'nav.facilities', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.PackageTypes]) {
			subMenuItems.push({ iconClass: 'fa-archive', routerLink: ['/admin', 'setup', 'packageTypes'], queryParams: null, titleTranslateKey: 'nav.packageTypes', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.RateIndexes]) {
			subMenuItems.push({ iconClass: 'fa-university', routerLink: ['/admin', 'setup', 'rateIndexes'], queryParams: null, titleTranslateKey: 'nav.rateIndexes', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Routes]) {
			subMenuItems.push({ iconClass: 'fa-route', routerLink: ['/admin', 'setup', 'routes'], queryParams: null, titleTranslateKey: 'nav.routes', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.SeasonalityProfiles]) {
			subMenuItems.push({ iconClass: 'fa-chart-waterfall', routerLink: ['/admin', 'setup', 'seasonalityProfiles'], queryParams: null, titleTranslateKey: 'nav.seasonalityProfiles', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.UserGroup]) {
			subMenuItems.push({ iconClass: 'fa-users', routerLink: ['/admin', 'setup', 'userGroup'], queryParams: null, titleTranslateKey: 'nav.userGroup', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Vessels]) {
			subMenuItems.push({ iconClass: 'fa-ship', routerLink: ['/admin', 'setup', 'vessels'], queryParams: null, titleTranslateKey: 'nav.vessels', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-toolbox', routerLink: ['/admin', 'setup'], titleTranslateKey: 'nav.setup', items: subMenuItems, taskClass: null });
		}

	}

	private addAutomation(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.OcrJob]) {
			subMenuItems.push({ iconClass: 'fa-filter', routerLink: ['/admin', 'automation', 'ocrJobs'], queryParams: null, titleTranslateKey: 'nav.ocrJobs', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Edi]) {
			subMenuItems.push({ iconClass: 'fa-filter', routerLink: ['/admin', 'automation', 'ediMessages'], queryParams: null, titleTranslateKey: 'nav.ediMessages', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.Edi]) {
			subMenuItems.push({ iconClass: 'fa-filter', routerLink: ['/admin', 'automation', 'ediFiles'], queryParams: null, titleTranslateKey: 'nav.ediFiles', taskClass: null });
		}
		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-users-cog', routerLink: ['/admin', 'automation'], titleTranslateKey: 'nav.automation', items: subMenuItems, taskClass: null });
		}
	}

	private addCrm(menu: Menu) {
		const subMenuItems = new Array<MenuItem>();
		if (this.showMenu[AdminAccessControl.Companies] || this.showMenu[AdminAccessControl.Deal]) {
			subMenuItems.push({ iconClass: 'fa-filter', routerLink: ['/admin', 'crm', 'funnel'], queryParams: null, titleTranslateKey: 'nav.funnel', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-clock', routerLink: ['/admin', 'crm', 'campaign'], queryParams: null, titleTranslateKey: 'nav.campaigns', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-building', routerLink: ['/admin', 'crm', 'companies'], queryParams: null, titleTranslateKey: 'nav.companies', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-handshake', routerLink: ['/admin', 'crm', 'deal'], queryParams: null, titleTranslateKey: 'nav.deals', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-building', routerLink: ['/admin', 'crm', 'companyStageEvents'], queryParams: null, titleTranslateKey: 'nav.companyStageEvents', taskClass: null });
			subMenuItems.push({ iconClass: 'fa-chart-bar', routerLink: ['/admin', 'crm', 'pipeline'], queryParams: null, titleTranslateKey: 'nav.pipeline', taskClass: null });
		}
		if (this.showMenu[AdminAccessControl.TradeLanes]) {
			subMenuItems.push({ iconClass: 'fa-hands-helping', routerLink: ['/admin', 'customerTradeLanes'], queryParams: null, titleTranslateKey: 'nav.partyCustomerTradeLanes', taskClass: null });
		}

		if (subMenuItems.length) {
			menu.groups.push({ iconClass: 'fa-handshake', routerLink: ['/admin', 'crm'], titleTranslateKey: 'nav.crm', items: subMenuItems, taskClass: null });
		}
	}
}
