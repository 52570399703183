import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShipmentCargoDetail } from 'app/generated/backend/shipment/api/shipment-cargo-detail';
import { ShipmentCargoModel } from 'app/generated/backend/shipment/api/shipment-cargo-model';
import { ShipmentCargoModifyRequest } from 'app/generated/backend/shipment/api/shipment-cargo-modify-request';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';

@Injectable()
export class ShipmentCargoHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		partyId: number,
		shipmentCargoDetailId: number,
		number: string
	): Observable<JsonResourceResponse<ShipmentCargoModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (partyId!==null && partyId!==undefined) {
			params = params.set('partyId', partyId.toString());
		}
		if (shipmentCargoDetailId!==null && shipmentCargoDetailId!==undefined) {
			params = params.set('shipmentCargoDetailId', shipmentCargoDetailId.toString());
		}
		return this.http.get<JsonResourceResponse<ShipmentCargoModel>>(`api/v1/shipment/shipments/${number}/cargo`, { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	post(
		number: string,
		request: ShipmentCargoDetail
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		return this.http.post<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/cargo`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	patch(
		number: string,
		request: ShipmentCargoModifyRequest
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		return this.http.patch<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/cargo`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	delete(
		number: string,
		id: number
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (id!==null && id!==undefined) {
			params = params.set('id', id.toString());
		}
		return this.http.delete<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/cargo`, { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		ShipmentCargoHandlerService
	]
})
export class ShipmentCargoHandlerModule { }
