
export class IsoDuration {


    hours: number = 0;
    minutes: number = 0;

    constructor(
        str: string
    ) {
        if (str) {
            let number = 0;
            for (var i = 0; i < str.length; i++) {
                const c = str.charCodeAt(i);
                if (c >= 48 && c <= 57) {
                    number = number * 10 + (c - 48);
                    continue;
                }
                if (c == 72) {
                    this.hours = number;
                }
                else if (c == 77) {
                    this.minutes = number;
                }
                number = 0;
            }
        }
    }


}
