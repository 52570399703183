import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';
import { ShipmentRelease } from 'app/generated/backend/shipment/api/shipment-release';

@Injectable()
export class ShipmentReleaseHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	put(
		number: string,
		serviceId: number,
		request: ShipmentRelease
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		return this.http.put<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/services/${serviceId}/releases`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	delete(
		number: string,
		serviceId: number,
		releaseId: number
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (releaseId!==null && releaseId!==undefined) {
			params = params.set('releaseId', releaseId.toString());
		}
		return this.http.delete<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/services/${serviceId}/releases`, { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		ShipmentReleaseHandlerService
	]
})
export class ShipmentReleaseHandlerModule { }
