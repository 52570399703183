import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreCountryIso2NamePipe } from './CoreCountryIso2NamePipe';
import { CoreCountryIso2Pipe } from './CoreCountryIso2Pipe';
import { CoreCountryLookupComponent } from './CoreCountryLookupComponent';
import { CoreCountryLookupService } from './CoreCountryLookupService';
import { CoreCountryNamePipe } from './CoreCountryNamePipe';
import { CountryLookupHandlerModule } from 'app/generated/backend/locality/service/country-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';

@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		FormsModule,
		CommonMaterialModule,
		CountryLookupHandlerModule,
		TranslateModule,
		HelpPopupModule
	],
	exports: [CoreCountryNamePipe, CoreCountryIso2Pipe, CoreCountryIso2NamePipe, CoreCountryLookupComponent],
	declarations: [CoreCountryNamePipe, CoreCountryIso2Pipe, CoreCountryIso2NamePipe, CoreCountryLookupComponent],
	providers: [CoreCountryNamePipe, CoreCountryIso2Pipe, CoreCountryIso2NamePipe, CoreCountryLookupService]
})
export class CoreCountryModule { }
