import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ContainerSizeModule } from 'app/generated/backend/types/container-size';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { ContainerSizeInputComponent } from './ContainerSizeInputComponent';
import { ContainerSizeRadioGroupComponent } from './ContainerSizeRadioGroupComponent';
import { ContainerSizeFlagCheckBoxComponent } from 'app/core/container-size-input/ContainerSizeFlagCheckBoxComponent';

@NgModule({
    imports: [
        AutocompleteModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        CommonMaterialModule,
        ContainerSizeModule,
        HelpPopupModule
    ],
    exports: [ContainerSizeInputComponent, ContainerSizeRadioGroupComponent, ContainerSizeFlagCheckBoxComponent],
    declarations: [ContainerSizeInputComponent, ContainerSizeRadioGroupComponent, ContainerSizeFlagCheckBoxComponent]
})

export class ContainerSizeInputModule {
}
