import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum ShipmentLocationType {
	None = 0,
	Pickup = 1,
	Delivery = 2,
}

@Pipe({
name: 'shipmentLocationType'
})
export class ShipmentLocationTypePipe extends BasePipe<ShipmentLocationType> implements PipeTransform {
	static prefix = 'Backend.Types.ShipmentLocationType.';
	static keys = [
		ShipmentLocationType.None,
		ShipmentLocationType.Pickup,
		ShipmentLocationType.Delivery];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ShipmentLocationType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ShipmentLocationType.None: return 'None';
			case ShipmentLocationType.Pickup: return 'Pickup';
			case ShipmentLocationType.Delivery: return 'Delivery';
			default: return null;
		}
	}
	transform(value: ShipmentLocationType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ShipmentLocationTypePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ShipmentLocationTypePipe
	],
	exports: [
		ShipmentLocationTypePipe
	],
	providers: [
		ShipmentLocationTypePipe
	]
})
export class ShipmentLocationTypeModule { }
