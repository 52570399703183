import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { ButtonBaseComponent } from 'app/core/button/button-base.component';
import { ButtonAnalyticsBaseComponent } from './button-analytics-base.component';

@Component({
	selector: 'app-button-contact-us',
	templateUrl: './button-analytics-base.component.html'
})
export class ButtonContactUsComponent extends ButtonAnalyticsBaseComponent {
	@HostBinding('class') class = 'adn-material-button';

	constructor(
		protected angulartics2: Angulartics2,
		protected router: Router
	) {
		super(angulartics2, router);
		this.href = '/contact-us';
		this.category = 'contactUs';
		this.iconClass = 'fa-envelope';
		this.color = 'accent';
		this.titleTranslateKey = 'button.contactUs';
	}

	@Input()
	public href: string;

	@Input()
	public category: string;


	@Input()
	public disabled: boolean = false;

	@Input()
	public type = 'button';

	@Input()
	public iconClass: string;

	@Input()
	public titleTranslateKey: string;

	@Input()
	public hintTranslateKey: string;

	@Input()
	public color: string;

	@Output()
	action: EventEmitter<ButtonBaseComponent>;
}
