import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CoreTaskSummaryModule } from 'app/core/task-summary/CoreTaskSummaryModule';
import { CoreTaskSummaryService } from 'app/core/task-summary/CoreTaskSummaryService';
import { VersionCheckService } from 'app/core/version-check/VersionCheckService';
import { CancelShipmentHandlerModule } from 'app/generated/backend/shipment/service/cancel-shipment-handler';
import { ChangeShipmentTypeHandlerModule } from 'app/generated/backend/shipment/service/change-shipment-type-handler';
import { CreateShipmentHandlerModule } from 'app/generated/backend/shipment/service/create-shipment-handler';
import { ExcludeShipmentServiceHandlerModule } from 'app/generated/backend/shipment/service/exclude-shipment-service-handler';
import { GetSchedulesHandlerModule } from 'app/generated/backend/shipment/service/get-schedules-handler';
import { GetSuppliersHandlerModule } from 'app/generated/backend/shipment/service/get-suppliers-handler';
import { MoveCargoHandlerModule } from 'app/generated/backend/shipment/service/move-cargo-handler';
import { MoveContainerHandlerModule } from 'app/generated/backend/shipment/service/move-container-handler';
import { RejectShipmentServiceHandlerModule } from 'app/generated/backend/shipment/service/reject-shipment-service-handler';
import { ResetShipmentServiceHandlerModule } from 'app/generated/backend/shipment/service/reset-shipment-service-handler';
import { SetLocationHandlerModule } from 'app/generated/backend/shipment/service/set-location-handler';
import { SetPartiesHandlerModule } from 'app/generated/backend/shipment/service/set-parties-handler';
import { SetScheduleHandlerModule } from 'app/generated/backend/shipment/service/set-schedule-handler';
import { SetSuppliersHandlerModule } from 'app/generated/backend/shipment/service/set-suppliers-handler';
import { ShipmentBookingHandlerModule } from 'app/generated/backend/shipment/service/shipment-booking-handler';
import { ShipmentCargoHandlerModule } from 'app/generated/backend/shipment/service/shipment-cargo-handler';
import { ShipmentContainerHandlerModule } from 'app/generated/backend/shipment/service/shipment-container-handler';
import { ShipmentContainerOperationMasterHandlerModule } from 'app/generated/backend/shipment/service/shipment-container-operation-master-handler';
import { ShipmentDetailHandlerModule } from 'app/generated/backend/shipment/service/shipment-detail-handler';
import { ShipmentDocumentHandlerModule } from 'app/generated/backend/shipment/service/shipment-document-handler';
import { ShipmentDocumentsHandlerModule } from 'app/generated/backend/shipment/service/shipment-documents-handler';
import { ShipmentContainerOperationTimeSlotHandlerModule } from 'app/generated/backend/shipment/service/shipment-container-operation-time-slot-handler';
import { ShipmentPackageHandlerModule } from 'app/generated/backend/shipment/service/shipment-package-handler';
import { ShipmentRecalculateHandlerModule } from 'app/generated/backend/shipment/service/shipment-recalculate-handler';
import { ShipmentReleaseHandlerModule } from 'app/generated/backend/shipment/service/shipment-release-handler';
import { ShipmentTaskResolveHandlerModule } from 'app/generated/backend/shipment/service/shipment-task-resolve-handler';
import { TaskSummaryHandlerModule } from 'app/generated/backend/task/service/task-summary-handler';
import { SettingsHandlerModule } from 'app/generated/backend/user/service/settings-handler';
import { WidgetDataHandlerModule } from 'app/generated/backend/widget/service/widget-data-handler';
import { ShipmentDetailService } from 'app/shipment/detail/ShipmentDetailService';
import { BookingService } from '../booking/BookingService';
import { AlertModule } from './alert/alert.module';
import { CoreAuthenticationService } from './authentication/CoreAuthenticationService';
import { CoreAuthGuard } from './authentication/CoreAuthGuard';
import { CoreCacheService } from './cache/CoreCacheService';
import { CoreChatterService } from './chatter/CoreChatterService';
import { CoreCustomizationService } from './customization/CoreCustomizationService';
import { CoreLanguageModule } from './language/CoreLanguageModule';
import { LinkService } from './link.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { CoreNavigationService } from './navigation/CoreNavigationService';
import { RateIndexModule } from "./rate-index/RateIndexModule";
import { TimerService } from './timer/timer.service';
import { ShippingInstructionHandlerModule } from 'app/generated/backend/shipment/service/shipping-instruction-handler';
import { VerifiedGrossMassHandlerModule } from 'app/generated/backend/shipment/service/verified-gross-mass-handler';
import { ShipmentPackingListHandlerModule } from 'app/generated/backend/shipment/service/shipment-packing-list-handler';

@NgModule({
	imports: [
		AlertModule,
		CancelShipmentHandlerModule,
		ChangeShipmentTypeHandlerModule,
		CommonModule,
		CoreLanguageModule,
		CreateShipmentHandlerModule,
		ExcludeShipmentServiceHandlerModule,
		GetSchedulesHandlerModule,
		GetSuppliersHandlerModule,
		MoveCargoHandlerModule,
		MoveContainerHandlerModule,
		RateIndexModule,
		RejectShipmentServiceHandlerModule,
		ResetShipmentServiceHandlerModule,
		SetLocationHandlerModule,
		SetPartiesHandlerModule,
		SetScheduleHandlerModule,
		SetSuppliersHandlerModule,
		SettingsHandlerModule,
		WidgetDataHandlerModule,
		ShipmentBookingHandlerModule,
		ShipmentCargoHandlerModule,
		ShipmentContainerHandlerModule,
		ShipmentPackageHandlerModule,
		ShipmentDetailHandlerModule,
		ShipmentDocumentHandlerModule,
		ShipmentDocumentsHandlerModule,
		ShipmentRecalculateHandlerModule,
		ShipmentReleaseHandlerModule,
		ShipmentTaskResolveHandlerModule,
		ShipmentContainerOperationMasterHandlerModule,
		ShipmentContainerOperationTimeSlotHandlerModule,
		ShippingInstructionHandlerModule,
		ShipmentPackingListHandlerModule,
		VerifiedGrossMassHandlerModule,
		TaskSummaryHandlerModule,
		CoreTaskSummaryModule
	],
	providers: [
		CoreAuthGuard,
		CoreAuthenticationService,
		BookingService,
		CoreCacheService,
		CoreNavigationService,
		CoreCustomizationService,
		LinkService,
		CoreChatterService,
		ShipmentDetailService,
		TimerService,
		VersionCheckService,
		CoreTaskSummaryService
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}
