<div>
  <button mat-menu-item [matMenuTriggerFor]="userMenu"
    class="adn-color-primary adnavem-white-background-color d-block d-xl-none">
    <!-- Initials mobile -->
    <span class="fa-stack fa-sm adn-menu-initials-block-mobile" aria-hidden="true">
      <span class="fal fa-circle fa-stack-2x adn-menu-initials-circle"></span>
      <span class="fa-stack-1x fa-inverse adn-menu-initials-text">{{userInitials}}</span>
    </span>{{userName}}
  </button>

  <button mat-button [matMenuTriggerFor]="userMenu" class="adn-color-white d-none d-xl-block">
    <!-- Initials desktop -->
    <span *ngIf="!logoSha256" class="fa-stack fa-lg adn-menu-initials-block">
      <span class="fal fa-circle fa-stack-2x adn-menu-initials-circle"></span>
      <span class="fa-stack-1x fa-inverse adn-menu-initials-text">{{userInitials}}</span>
    </span>
    <div *ngIf="logoSha256" class="adn-user-logo"><img src="{{logoSha256 | binaryContentUrl}}" alt="{{userName}}" />
    </div><span class="adn-color-white">{{userName}}&nbsp;<span
        class="fal fa-sort-down fa-1x fa-fw adn-menu-user-arrow"></span></span>
  </button>

  <mat-menu #userMenu="matMenu" class="adnavem-dark-text-with-light-background">
    <app-user-menu-alternatives [isSideMenu]="false"></app-user-menu-alternatives>
  </mat-menu>
</div>