import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PackageTypeLookupModel } from 'app/generated/backend/trade/api/package-type-lookup-model';
import { BaseIdInputTypeaheadComponent } from 'app/_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent';
import { CorePackageTypeLookupService } from './CorePackageTypeLookupService';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CorePackageTypeLookupComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CorePackageTypeLookupComponent),
	multi: true
};

@Component({
	selector: 'app-core-package-type-lookup',
	templateUrl: '../../_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CorePackageTypeLookupComponent extends BaseIdInputTypeaheadComponent<PackageTypeLookupModel>  {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false; 

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string;

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Output()
	itemSelected: EventEmitter<PackageTypeLookupModel> = new EventEmitter<PackageTypeLookupModel>();

	constructor(
		protected service: CorePackageTypeLookupService
	) {
		super(service);
	}

	toDisplayText(item: PackageTypeLookupModel): string {
		if (!item) {
			return null;
		}
		return item.code;
	}

	toOptionText(item: PackageTypeLookupModel): string {
		if (!item) {
			return null;
		}
		return item.match;
	}
}
