import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from 'app/core/common-material/core-common-material.module';
import { EntityClassModule } from 'app/generated/backend/types/entity-class';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { SearchHandlerModule } from '../../generated/backend/search/service/search-handler';
import { CoreMegaSearchComponent } from './CoreMegaSearchComponent';

@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		CommonMaterialModule,
		EntityClassModule,
		FormsModule,
		SearchHandlerModule,
		TranslateModule
	],
	declarations: [CoreMegaSearchComponent],
	exports: [CoreMegaSearchComponent]
})
export class CoreMegaSearchModule { };
