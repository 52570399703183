import { HttpClient, HttpParams } from '@angular/common/http';
import { MatchableEntity } from 'app/_models/matchable-entity';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { CoreEntityCache } from '../core/cache/CoreEntityCache';
import { JsonResourceResponse } from '../core/json/json-resource-response';

export class SimpleLookupService<T extends MatchableEntity> {
	private getItemsObservable: Observable<JsonResourceResponse<T[]>>;


	constructor(
		protected http: HttpClient,
		protected path: string,
		protected cache: CoreEntityCache<T> = null
	) {
		if (path) {
			this.setPath(path);
		}
	}

	setPath(path: string) {
		this.path = path;
		this.getItemsObservable = this.http.get<JsonResourceResponse<T[]>>(`api/v1/${path}`).pipe(share());
	}

	handleError(error: Response | any) {
		return throwError(error);
	}

	updateItem(item: T): T {
		return item;
	}

	updateItems(items: T[]): T[] {
		items.forEach(item => this.updateItem(item));
		return items;
	}

	getItem(id: number): Observable<T> {
		if (this.cache) {
			const item = this.cache.getItem(id);
			if (item) {
				return of(item);
			}
			if (!this.cache.getItems()) {
				return this.getItems().pipe(
					map(response => {
						return this.cache.getItem(id);
					}));
			}
			return of(null);
		}
		const path = this.path;
		const url = `api/v1/${path}/${id}`;
		return this.http.get<JsonResourceResponse<T>>(url).pipe(
			map(response => {
				const item = this.updateItem(response.data);
				if (this.cache) {
					this.cache.setItem(item);
				}
				return item;
			}),
			catchError(this.handleError));
	}
	getDetail(id: number): Observable<T> {
		const path = this.path;
		const url = `api/v1/${path}/${id}?level=1`;
		return this.http.get<JsonResourceResponse<T>>(url).pipe(
			map(response => {
				const item = this.updateItem(response.data);
				return item;
			}),
			catchError(this.handleError));
	}
	getItems(): Observable<T[]> {
		return this.get();
	}

	get(): Observable<T[]> {
		if (this.cache) {
			const items = this.cache.getItems();
			if (items) {
				return of(items);
			}
		}
		return this.getItemsObservable.pipe(
			map(response => {
				const items = this.updateItems(response.data);
				if (this.cache) {
					this.cache.setItems(items);
				}
				return items;
			}
			),
			catchError(this.handleError));
	}

	getFiltered(filter: string): Observable<T[]> {
		const path = this.path;
		const params = new HttpParams()
			.set('filter', filter);
		const url = `api/v1/${path}`;
		return this.http.get<JsonResourceResponse<T[]>>(url, { params }).pipe(
			map(response => this.updateItems(response.data)),
			catchError(this.handleError));
	}

}
