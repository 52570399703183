import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { HelpPopupDialogComponent } from './help-popup-dialog.component';

@Component({
	selector: 'app-help-popup-button',
	templateUrl: './help-popup-button.component.html'
})

export class HelpPopupButtonComponent implements OnInit, OnDestroy {

	private subscriptions: Subscription[] = new Array<Subscription>();

	@Input()
	public uri: string;

	@Input()
	public class = 'help-popup-button';

	public constructor(
		private dialog: MatDialog
	) {
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
	}
	openDialog(event: Event) {
		event.stopPropagation();
		const dialogConfig = new MatDialogConfig();

		dialogConfig.hasBackdrop = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			uri: this.uri
		};

		this.dialog.open(HelpPopupDialogComponent, dialogConfig);
	}
}
