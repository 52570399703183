import { Injectable } from '@angular/core';
import { RateIndexLookupModel } from 'app/generated/backend/trade/api/rate-index-lookup-model';
import { RateIndexLookupHandlerService } from 'app/generated/backend/trade/service/rate-index-lookup-handler';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { CoreCacheService } from '../cache/CoreCacheService';
@Injectable()
export class RateIndexLookupService extends CachedEntityService<RateIndexLookupModel> {
	constructor(
		rateIndexLookupHandlerService: RateIndexLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(rateIndexLookupHandlerService, cacheService.getRateIndexes());
	}
	updateItem(item: RateIndexLookupModel): RateIndexLookupModel {
		if (item) {
			item.match = '';
			if (item.code) {
				item.match += item.code.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}

	transformItem(item: RateIndexLookupModel): RateIndexLookupModel {
		if (item) {
			item.match = '';
			if (item.name) {
				item.match += item.name.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
			if (item.code) {
				item.match += ' ' + item.code.toLowerCase();
			}
		}
		return item;
	}
}
