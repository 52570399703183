import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum AuthRequestCode {
	None = 0,
	Validate = 1,
	Login = 2,
	SwitchParty = 3,
}

@Pipe({
name: 'authRequestCode'
})
export class AuthRequestCodePipe extends BasePipe<AuthRequestCode> implements PipeTransform {
	static prefix = 'Backend.Auth.Api.AuthRequestCode.';
	static keys = [
		AuthRequestCode.None,
		AuthRequestCode.Validate,
		AuthRequestCode.Login,
		AuthRequestCode.SwitchParty];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: AuthRequestCode): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case AuthRequestCode.None: return 'None';
			case AuthRequestCode.Validate: return 'Validate';
			case AuthRequestCode.Login: return 'Login';
			case AuthRequestCode.SwitchParty: return 'SwitchParty';
			default: return null;
		}
	}
	transform(value: AuthRequestCode, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(AuthRequestCodePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		AuthRequestCodePipe
	],
	exports: [
		AuthRequestCodePipe
	],
	providers: [
		AuthRequestCodePipe
	]
})
export class AuthRequestCodeModule { }
