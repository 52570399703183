<app-core-map *ngIf="model" class="adn-map-fixed-ratio-60" [latitude]="1" [longitude]="1" [zoom]="2" [fitBounds]="true">
    <app-core-map-marker *ngFor="let location of model.locations" [latitude]="location.latitude"
        [longitude]="location.longitude" [title]="location.name"
        [iconUrl]="'api/v1/content/label?t='+location.code+'&fg=F80&bg=FFF'">
    </app-core-map-marker>
    <app-core-map-polyline *ngFor="let route of model.routes">
        <app-core-map-polyline-point *ngFor="let point of route.points" [latitude]="point.latitude"
            [longitude]="point.longitude">
        </app-core-map-polyline-point>
    </app-core-map-polyline>
</app-core-map>