import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreCountryLookupService } from './CoreCountryLookupService';

@Pipe({
	name: 'countryName'
})
export class CoreCountryNamePipe implements PipeTransform {
	constructor(
		protected service: CoreCountryLookupService
	) {

	}
	transform(value: number, ...args: any[]) : Observable<string> {
		if (!value) {
			return of(null);
		}
		return this.service.getItem(value).pipe(map(data => { return data ? data.name : null }));
	}
}
