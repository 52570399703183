import { ShipmentContainer } from "app/generated/backend/shipment/api/shipment-container";
import { ShipmentDetailModel } from "app/generated/backend/shipment/api/shipment-detail-model";
import { ShipmentPartyModel } from "app/generated/backend/shipment/api/shipment-party-model";
import { ShipmentPartyRateContractModel } from "app/generated/backend/shipment/api/shipment-party-rate-contract-model";
import { ShipmentService } from "app/generated/backend/shipment/api/shipment-service";
import { ShipmentUserModel } from "app/generated/backend/shipment/api/shipment-user-model";
import { ShipmentVesselModel } from "app/generated/backend/shipment/api/shipment-vessel-model";
import { LocalityCategory } from "app/generated/backend/types/locality-category";
import { LocalityLookupModel } from "app/generated/backend/types/locality-lookup-model";

export class CoreCacheShipment {
    getLocality(id: number, category: LocalityCategory): LocalityLookupModel {
        return this.localityByKey.get(id + ';' + category);
    }
    shipment: ShipmentDetailModel;
    userById: Map<number, ShipmentUserModel>;
    partyById: Map<number, ShipmentPartyModel>;
    serviceById: Map<number, ShipmentService>;
    containerById: Map<number, ShipmentContainer>;
    vesselById: Map<number, ShipmentVesselModel>;
    partyRateContractById: Map<number, ShipmentPartyRateContractModel>;
    localityByKey: Map<string, LocalityLookupModel>;
}