import { Component, Input, OnInit } from '@angular/core';
import { Menu } from 'app/core/menu/Menu';
import { Subscription } from 'rxjs';
import { PartyMenuService } from "./PartyMenuService";
@Component({
	selector: 'app-party-menu',
	templateUrl: './PartyMenuComponent.html'
})
export class PartyMenuComponent implements OnInit {
	@Input()
	public menuClass: string;
	public menu: Menu;
	private subscriptions = new Array<Subscription>();

	constructor(private service: PartyMenuService) {
	}
	ngOnInit() {
		this.subscriptions.push(this.service.menuUpdated.subscribe(menu => this.menu = menu));
	}
	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
