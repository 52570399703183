import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum ShipmentPackageProperties {
	None = 0,
	NonStackable = 1,
	Stackable = 2,
}

@Pipe({
name: 'shipmentPackageProperties'
})
export class ShipmentPackagePropertiesPipe extends BasePipe<ShipmentPackageProperties> implements PipeTransform {
	static prefix = 'Backend.Types.ShipmentPackageProperties.';
	static keys = [
		ShipmentPackageProperties.None,
		ShipmentPackageProperties.NonStackable,
		ShipmentPackageProperties.Stackable];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ShipmentPackageProperties): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ShipmentPackageProperties.None: return 'None';
			case ShipmentPackageProperties.NonStackable: return 'NonStackable';
			case ShipmentPackageProperties.Stackable: return 'Stackable';
			default: return null;
		}
	}
	transform(value: ShipmentPackageProperties, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ShipmentPackagePropertiesPipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(ShipmentPackagePropertiesPipe.keys, ShipmentPackagePropertiesPipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ShipmentPackagePropertiesPipe
	],
	exports: [
		ShipmentPackagePropertiesPipe
	],
	providers: [
		ShipmentPackagePropertiesPipe
	]
})
export class ShipmentPackagePropertiesModule { }
