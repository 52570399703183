import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorCode, ErrorCodePipe } from '../../generated/backend/service/error-code';
import { AlertBanner } from './alert-banner';
import { AlertMessage } from './alert.message';


@Injectable()
export class AlertService {
	public subject = new BehaviorSubject<AlertMessage>({ type: null, text: null, loading: false });
	public bannerAdded = new BehaviorSubject<AlertBanner>(null);
	public connectionStatusUpdated = new BehaviorSubject<boolean>(null);
	private keepAfterNavigationChange = false;
	private connected: boolean;

	constructor(
		router: Router,
		private errorCodePipe: ErrorCodePipe,
		private translateService: TranslateService
	) {
		// clear alert message on route change
		router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterNavigationChange) {
					// only keep for a single location change
					this.keepAfterNavigationChange = false;
				} else {
					// clear alert
					this.subject.next({ type: null, text: null, loading: false });
				}
			}
		});
	}

	start(): boolean {
		this.subject.next({ type: null, text: null, loading: true });
		return true;
	}

	success(message: any = null, keepAfterNavigationChange = false): boolean {
		this.keepAfterNavigationChange = keepAfterNavigationChange;
		this.subject.next({ type: 'success', text: message, loading: false });
		return false;
	}

	banner(banner: AlertBanner) {
		this.bannerAdded.next(banner);
	}

	connectionStatus(connected: boolean) {
		if (this.connected !== connected) {
			this.connected = connected;
			this.connectionStatusUpdated.next(connected);
		}
	}

	info(message: string = null): boolean {
		this.subject.next({ type: 'info', text: message, loading: false });
		return false;
	}

	errorCode(errorCode: ErrorCode) {
		this.errorCodePipe.transform(errorCode, null).subscribe(
			value => {
				this.subject.next({ type: 'error', text: value, loading: false });
			}
		)
	}

	error(message: any, keepAfterNavigationChange = false): boolean {
		console.error(message);
		this.keepAfterNavigationChange = keepAfterNavigationChange;
		const httpErrorResponse = message as HttpErrorResponse;
		// Json API error
		if (httpErrorResponse) {
			if (httpErrorResponse.error && httpErrorResponse.error.errors && httpErrorResponse.error.errors.length) {
				const error = httpErrorResponse.error.errors[0];
				if (error.detail) {
					this.subject.next({ type: 'error', text: error.detail, loading: false });
					return false;
				}
				if (error.code) {
					this.errorCode(Number(error.code));
					return false;
				}
			}
			if (httpErrorResponse.status) {
				const statusText = this.translateService.instant("Http.Status." + httpErrorResponse.status.toString() + ".label");
				if (statusText) {
					this.subject.next({ type: 'error', text: statusText, loading: false });
					return false;
				}
				this.subject.next({ type: 'error', text: 'Server returned an error (' + httpErrorResponse.status + ')', loading: false });
				return false;
			}
		}
		this.subject.next({ type: 'error', text: message, loading: false });

		return false;
	}

	showLoadingSpinner() {
		this.subject.next({ type: null, text: null, loading: true });
		return false;
	}

	hideLoadingSpinner() {
		this.subject.next({ type: null, text: null, loading: false });
		return false;
	}

	getMessage(): Observable<AlertMessage> {
		return this.subject.asObservable();
	}
}
