import { ContainerEquipment } from 'app/generated/backend/types/container-equipment';
import { LocalityLookupModel } from 'app/generated/backend/types/locality-lookup-model';
import { ShipmentLocationType } from 'app/generated/backend/types/shipment-location-type';
export class ShipmentLocation {
	id: number;
	updatedUserId: number;
	createdAt: string;
	updatedAt: string;
	submittedAt: string;
	shipmentId: number;
	partyPlaceId: number;
	type: ShipmentLocationType;
	latitude: number;
	longitude: number;
	containerEquipment: ContainerEquipment;
	address: string;
	instructions: string;
	countryId: number;
	postalCode: string;
	selectLocationUserId: number;
	selectLocationAt: string;
	nearestLocality: LocalityLookupModel;
	canSave: boolean;
	canModify: boolean;
}
