import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IncotermsDetailModel } from 'app/generated/backend/trade/api/incoterms-detail-model';

@Injectable()
export class IncotermsDetailHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		id: number
	): Observable<JsonResourceResponse<IncotermsDetailModel>> {
		return this.http.get<JsonResourceResponse<IncotermsDetailModel>>(`api/v1/trade/incoterms/detail/${id}`).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	put(
		id: number,
		request: IncotermsDetailModel
	): Observable<JsonResourceResponse<IncotermsDetailModel>> {
		return this.http.put<JsonResourceResponse<IncotermsDetailModel>>(`api/v1/trade/incoterms/detail/${id}`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	delete(
		id: number
	): Observable<void> {
		return this.http.delete<void>(`api/v1/trade/incoterms/detail/${id}`).pipe(
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		IncotermsDetailHandlerService
	]
})
export class IncotermsDetailHandlerModule { }
