export class Constants {
    public static HourMilliseconds = 3600000;
    public static DayMilliseconds = 86400000;
    public static WeekMilliseconds = 604800000;
    public static DefaultNetWeightPerContainer = 8000; //in kg
    public static DefaultNetWeightPerPackage = 1000; //in kg
    public static MinWeightValue = 0.001;
    public static MinVolumeValue = 0.000001;
    public static MinLengthValue = 0.1;
    public static MinWidthValue = 0.1;
    public static MinHeightValue = 0.1;
}