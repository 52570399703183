import { Injectable } from '@angular/core';
import { LocaleLookupModel } from 'app/generated/backend/localization/api/locale-lookup-model';
import { DateOrderStyle } from 'app/generated/system/date-time/date-order-style';

@Injectable()
export class CoreLocaleLocalizationService {

	public getDate(offsetDay: number): string {
		const date = new Date();
		date.setDate(date.getDate() + offsetDay);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();
		let str = '';
		str += year.toString();
		str += '-';
		if (month < 10) {
			str += '0';
		}
		str += month.toString();
		str += '-';
		if (day < 10) {
			str += '0';
		}
		str += day.toString();
		return str;
	}

	public localizedToIsoDateString(input: string, hasTime: boolean, locale: LocaleLookupModel): string {
		let intArray: any[] = [];
		let part: number = 0;
		if (!locale) {
			throw 'error.invalidDate';
		}
		for (let i = 0; i < input.length; i++) {
			const c = input[i];
			if (c >= '0' && c <= '9') {
				part = part * 10 + parseInt(c, 10);
			} else {
				if (part >= 0) {
					if (intArray.length < 5) {
						intArray.push(part);
						part = 0;
					} else {
						intArray.push(input.substring(i));
						break;
					}
				}
			}
		}
		if ((!hasTime || !locale.twelveHourSymbolAm) && part >= 0) {
			intArray.push(part);
		}
		if (!hasTime && intArray.length !== 3) {
			throw 'error.invalidDate';
		}
		if (hasTime && !locale.twelveHourSymbolAm && !locale.twelveHourSymbolPm && intArray.length !== 5) {
			throw 'error.invalidTime';
		}
		if (hasTime && locale.twelveHourSymbolAm && !locale.twelveHourSymbolPm && intArray.length !== 6) {
			throw 'error.invalidTime';
		}
		let year: number;
		let month: number;
		let day: number;
		if (locale.dateOrderStyle === DateOrderStyle.DMY) {
			year = intArray[2];
			month = intArray[1];
			day = intArray[0]
		} else if (locale.dateOrderStyle === DateOrderStyle.MDY) {
			year = intArray[2];
			month = intArray[0];
			day = intArray[1]
		} else {
			year = intArray[0];
			month = intArray[1];
			day = intArray[2]
		}
		if (year < 1000 || year > 2999) {
			throw 'error.invalidDate';
		}
		if (month < 1 || month > 12) {
			throw 'error.invalidDate';
		}
		if (day < 1 || day > 31) {
			throw 'error.invalidDate';
		}

		let str = '';
		str += year.toString();
		str += '-';
		if (month < 10) {
			str += '0';
		}
		str += month.toString();
		str += '-';
		if (day < 10) {
			str += '0';
		}
		str += day.toString();
		let hours: number;
		let minutes: number;
		if (hasTime) {
			hours = intArray[3];
			minutes = intArray[4];
			if (hours > 23) {
				throw 'error.invalidTime';
			}
			if (minutes > 59) {
				throw 'error.invalidTime';
			}
			str += 'T';
			if (locale.twelveHourSymbolAm && locale.twelveHourSymbolPm) {
				if (hours < 1 || hours > 12) {
					throw 'error.invalidTime';
				}
				const inputMeridiem = intArray[5].toString().trim().replace(String.fromCharCode(160), " ");
				if (inputMeridiem === locale.twelveHourSymbolAm.replace(String.fromCharCode(160), " ")) {
					if (hours === 12) {
						str += '00'
					} else {
						if (hours < 10) {
							str += '0';
						}
						str += hours.toString();
					}
				} else if (inputMeridiem === locale.twelveHourSymbolPm.replace(String.fromCharCode(160), " ")) {
					if (hours === 12) {
						str += '12'
					} else {
						str += (hours + 12).toString();
					}
				} else {
					throw 'error.invalidTime';
				}
			} else {
				if (hours < 10) {
					str += '0';
				}
				str += hours.toString();
			}
			str += ':';
			if (minutes < 10) {
				str += '0';
			}
			str += minutes.toString();
			str += ':00';
		}
		return str;
	}
}