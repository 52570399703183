import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum LocationSize {
	None = 0,
	Small = 1,
	Medium = 2,
	Large = 3,
	Cluster = 4,
}

@Pipe({
name: 'locationSize'
})
export class LocationSizePipe extends BasePipe<LocationSize> implements PipeTransform {
	static prefix = 'Backend.Types.LocationSize.';
	static keys = [
		LocationSize.None,
		LocationSize.Small,
		LocationSize.Medium,
		LocationSize.Large,
		LocationSize.Cluster];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: LocationSize): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case LocationSize.None: return 'None';
			case LocationSize.Small: return 'Small';
			case LocationSize.Medium: return 'Medium';
			case LocationSize.Large: return 'Large';
			case LocationSize.Cluster: return 'Cluster';
			default: return null;
		}
	}
	transform(value: LocationSize, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(LocationSizePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		LocationSizePipe
	],
	exports: [
		LocationSizePipe
	],
	providers: [
		LocationSizePipe
	]
})
export class LocationSizeModule { }
