import { Injectable } from '@angular/core';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { CountryLookupModel } from 'app/generated/backend/locality/api/country-lookup-model';
import { CountryLookupHandlerService } from 'app/generated/backend/locality/service/country-lookup-handler';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
@Injectable()
export class CoreCountryLookupService extends CachedEntityService<CountryLookupModel> {
	constructor(
		service: CountryLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(service, cacheService.getCountries());
	}
	transformItem(item: CountryLookupModel): CountryLookupModel {
		if (item) {
			item.match = '';
			if (item.name) {
				item.match += item.name.toLowerCase();
			}
		}
		return item;
	}
}
