import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingComponent } from './LoadingComponent';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [LoadingComponent],
	exports: [LoadingComponent]
})
export class LoadingModule {

}