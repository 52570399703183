import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { PartyUserLookupModel } from 'app/generated/backend/party/api/party-user-lookup-model';
import { PartyBaseIdLookupService } from 'app/party/_templates/party-base-id-lookup-service';

@Injectable()
export class PartyUserLookupService extends PartyBaseIdLookupService<PartyUserLookupModel> {
	constructor(
		http: HttpClient,
		authenticationService: CoreAuthenticationService,
		cacheService: CoreCacheService
	) {
		super(http, authenticationService, 'party/parties', 'users/lookup', cacheService.getPartyUsers());
	}

	updateItem(item: PartyUserLookupModel): PartyUserLookupModel {
		if (item) {
			if (item.email) {
				item.match = item.name + ' (' + item.email + ')';
			} else {
				item.match = item.name;
			}
		}
		return item;
	}
}
