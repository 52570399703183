import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PlacesMasterHandlerModule } from 'app/generated/backend/locality/service/places-master-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { AddressLookupComponent } from './AddressLookupComponent';

@NgModule({
	imports: [
		AutocompleteModule,
		CommonMaterialModule,
		CommonModule,
		FormsModule,
		HelpPopupModule,
		PlacesMasterHandlerModule,
		ReactiveFormsModule,
		TranslateModule,
	],
	declarations: [AddressLookupComponent],
	exports: [AddressLookupComponent]
})
export class AddressLookupModule { };
