import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { CoreCustomizationService } from 'app/core/customization/CoreCustomizationService';
import { VersionCheckService } from 'app/core/version-check/VersionCheckService';
import { ContentAccess } from 'app/generated/backend/content/api/content-access';
import { ContentTreeModel } from 'app/generated/backend/content/api/content-tree-model';
import { ContentTreeHandlerService } from 'app/generated/backend/content/service/content-tree-handler';
import { AdminAccessControl } from 'app/generated/backend/types/admin-access-control';
import { PermissionFlags } from 'app/generated/backend/types/permission-flags';
import { CountryTradeModel } from 'app/generated/backend/world/api/country-trade-model';
import { CountryTradeHandlerService } from 'app/generated/backend/world/service/country-trade-handler';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-site-footer',
	templateUrl: 'CoreSiteFooterComponent.html',
	styleUrls: ['CoreSiteFooterComponent.scss']
})
export class CoreSiteFooterComponent implements OnInit, OnDestroy {

	private subscriptions = new Array<Subscription>();
	private languageIso2: string;
	private _contentAccess: ContentAccess;
	private _loadedLanguageIso2: string;
	private _loadedContentAccess: ContentAccess;
	public version: string = null;
	public models: ContentTreeModel[] = [];
	constructor(
		private customizationService: CoreCustomizationService,
		private contentTreeService: ContentTreeHandlerService,
		private authenticationService: CoreAuthenticationService,
		private countryTradeHandlerService: CountryTradeHandlerService,
		private versionCheckService: VersionCheckService
	) {
	}

	public importCountries: Array<CountryTradeModel>;
	public exportCountries: Array<CountryTradeModel>;
	public now = new Date();

	sortChildren(model: ContentTreeModel) {
		if (model.children) {
			const locale = this.customizationService.getLocaleIdentifier();
			model.children.sort((a, b) => {
				return a.title.localeCompare(b.title, locale, { sensitivity: 'base' });
			});
			model.children.forEach(child => {
				this.sortChildren(child);
			});
		}
	}
	loadMenu() {
		if (this.languageIso2 && (this.languageIso2 !== this._loadedLanguageIso2 || this._contentAccess !== this._loadedContentAccess)) {
			this._loadedLanguageIso2 = this.languageIso2;
			this._loadedContentAccess = this._contentAccess;
			this._contentAccess = this.getContentAccess();
			this.subscriptions.push(this.contentTreeService.get(this.languageIso2, '/', this._contentAccess, 1)
				.subscribe(response => {
					if (response.data && response.data.children) {
						this.models = response.data.children;
						this.models.forEach(model => {
							this.sortChildren(model);
						});
					}
				},
					error => {
						console.error(error);
					}));
		}
	}
	getContentAccess(): ContentAccess {
		if (this.authenticationService.hasAdminPermission(AdminAccessControl.Content, PermissionFlags.Read)) {
			return ContentAccess.Confidential;
		}
		if (this.authenticationService.getUserId()) {
			return ContentAccess.Restricted;
		}
		return ContentAccess.Public;
	}

	updateContentAccess() {
		const contentAccess = this.getContentAccess();
		if (contentAccess !== this._contentAccess) {
			this._contentAccess = contentAccess;
			this.loadMenu();
		}
	}
	loadCountries() {
		this.countryTradeHandlerService.get().subscribe(response => {
			if (response) {
				this.onCountriesLoaded(response.data);
			}
		}, error => {
			console.error(error);
		});
	}


	private onCountriesLoaded(countries: CountryTradeModel[]) {
		if (countries) {
			this.importCountries = new Array<CountryTradeModel>();
			this.exportCountries = new Array<CountryTradeModel>();
			countries.forEach(country => {
				if (country.isExport) {
					this.exportCountries.push(country);
				}

				if (country.isImport) {
					this.importCountries.push(country);
				}

			});
			const locale = this.customizationService.getLocaleIdentifier();
			this.importCountries.sort((a, b) => {
				return a.name.localeCompare(b.name, locale, { sensitivity: 'base' });
			});
			this.exportCountries.sort((a, b) => {
				return a.name.localeCompare(b.name, locale, { sensitivity: 'base' });
			});
		}
	}

	ngOnInit() {
		this._contentAccess = this.getContentAccess();
		this.subscriptions.push(this.customizationService.changed.subscribe(settings => {
			if (settings.locale.languageIso2 !== this.languageIso2) {
				this.languageIso2 = settings.locale.languageIso2;
				this.loadMenu();
				this.loadCountries();
			}
		}));
		this.subscriptions.push(this.authenticationService.authenticationChanged.subscribe(authentication => {
			if (authentication) {
				this.updateContentAccess();
			}
		}));
		this.subscriptions.push(this.versionCheckService.versionChanged.subscribe(version => {
			if (version) {
				this.version = version.version;
			}
		}));
	}
	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
