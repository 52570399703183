import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreButtonModule } from 'app/core/button/CoreButtonModule';
import { MasterFilterComponent } from 'app/core/master/filter/CoreMasterFilterComponent';
import { MasterFilterFormComponent } from 'app/core/master/filter/CoreMasterFilterFormComponent';
import { MasterFilterControlsComponent } from 'app/core/master/filter/CoreMasterFilterControlsComponent';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { MasterPaginationComponent } from "./MasterPaginationComponent";
import { MasterTableHeaderComponent } from './MasterTableHeaderComponent';
import { MasterServerSidePaginationComponent } from "./MasterServerSidePaginationComponent";
import { MasterServerSideTableHeaderComponent } from './MasterServerSideTableHeaderComponent';
import { MasterTableBodyComponent } from './MasterTableBodyComponent';
import { MasterTableRowDirective } from './MasterTableRowDirective';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';

@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		CoreButtonModule,
		DragDropModule,
		FormsModule,
		NgxPaginationModule,
		TranslateModule,
		CdkTableModule
	],
	exports: [
		MasterPaginationComponent,
		MasterServerSidePaginationComponent,
		MasterTableHeaderComponent,
		MasterServerSideTableHeaderComponent,
		MasterFilterComponent,
		MasterFilterFormComponent,
		MasterFilterControlsComponent,
		MasterTableBodyComponent,
		MasterTableRowDirective
	],
	declarations: [
		MasterPaginationComponent,
		MasterServerSidePaginationComponent,
		MasterTableHeaderComponent,
		MasterServerSideTableHeaderComponent,
		MasterFilterComponent,
		MasterFilterFormComponent,
		MasterFilterControlsComponent,
		MasterTableBodyComponent,
		MasterTableRowDirective

	]
})
export class MasterModule { }
