import { Directive, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartyAccessControl } from 'app/generated/backend/types/party-access-control';
import { PermissionFlags } from 'app/generated/backend/types/permission-flags';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, Subscription } from 'rxjs';
import { AlertService } from '../../core/alert/alert.service';
import { CoreAuthenticationService } from '../../core/authentication/CoreAuthenticationService';


export interface PartyDetailServiceInterface<T> {
	get?(partyId: number, id: number): Observable<JsonResourceResponse<T>>;
	put?(partyId: number, id: number, request: T): Observable<JsonResourceResponse<T>>;
	delete?(partyId: number, id: number): Observable<void>;
	create?(partyId: number, request: T): Observable<JsonResourceResponse<T>>;
	read?(partyId: number, id: number): Observable<JsonResourceResponse<T>>;
	update?(partyId: number, id: number, request: T): Observable<JsonResourceResponse<T>>;
}

export interface PartyDetailModelInterface {
	id: number;
}
type ParameterlessConstructor<T> = new () => T;

@Directive()
export abstract class PartyDetailBase<T extends PartyDetailModelInterface> implements OnInit, OnDestroy {
	protected route: ActivatedRoute;
	protected router: Router;
	public itemId: number;
	protected alertService: AlertService;
	protected authenticationService: CoreAuthenticationService;
	public editItem: T;
	public loading = false;
	public canRead = false;
	public canCreate = false;
	public canUpdate = false;
	public canDelete = false;
	protected subscriptions = new Array<Subscription>();

	constructor(
		protected service: PartyDetailServiceInterface<T>,
		private partyAccessControl: PartyAccessControl,
		private uri: string,
		private ctor: ParameterlessConstructor<T>,
		private injector: Injector
	) {
		this.alertService = this.injector.get(AlertService);
		this.authenticationService = this.injector.get(CoreAuthenticationService);
		this.router = this.injector.get(Router);
		this.route = this.injector.get(ActivatedRoute);
	}

	onCreated() {
	}

	onLoaded() {

	}

	onSavingError(_: any) {
		return;
	}

	onSaved() {
		this.alertService.success('Saved');
		this.router.navigate([this.uri]);
	}

	onDeleted() {
		this.alertService.success('Deleted');
		this.router.navigate([this.uri]);
	}

	ngOnInit() {
		this.canRead = this.authenticationService.hasPartyPermission(this.partyAccessControl, PermissionFlags.Read);
		this.canCreate = this.authenticationService.hasPartyPermission(this.partyAccessControl, PermissionFlags.Create);
		this.canUpdate = this.authenticationService.hasPartyPermission(this.partyAccessControl, PermissionFlags.Update);
		this.canDelete = this.authenticationService.hasPartyPermission(this.partyAccessControl, PermissionFlags.Delete);
		let activatedRoute = this.route;
		let id: any;
		while (activatedRoute) {
			id = activatedRoute.snapshot.params['id'];
			if (id) {
				break;
			}
			activatedRoute = activatedRoute.parent;
		}
		if (!id) {
			id = this.route.snapshot.queryParams['id'];
		}
		if (!id || id === 'new') {
			this.editItem = new this.ctor();
			this.onCreated();
		} else {
			this.itemId = id;
			this.loadData();
		}
	}
	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
	read() {
		this.loading = this.alertService.start();
		this.subscriptions.push(this.service.read(this.authenticationService.getPartyId(), this.itemId)
			.subscribe(
				{
					next:
						response => {
							this.editItem = response.data;
							this.onLoaded();
							this.loading = this.alertService.success();
						},
					error:
						error => {
							this.loading = this.alertService.error(error);
						}
				}));
	}
	get() {
		this.loading = this.alertService.start();
		this.subscriptions.push(this.service.get(this.authenticationService.getPartyId(), this.itemId)
			.subscribe(
				{
					next:
						response => {
							this.editItem = response.data;
							this.onLoaded();
							this.loading = this.alertService.success();
						},
					error:
						error => {
							this.loading = this.alertService.error(error);
						}
				}));
	}

	loadData() {
		const authentication = this.authenticationService.getAuthentication();
		if (authentication != null && this.itemId) {
			if (this.service.read) {
				this.read();
				return;
			}
			if (this.service.get) {
				this.get();
				return;
			}
		}
	}
	onDelete() {
		this.loading = this.alertService.start();
		const item = this.editItem;
		this.subscriptions.push(this.service.delete(this.authenticationService.getAuthentication().party.id, item.id)
			.subscribe(
				response => {
					this.loading = this.alertService.success();
					this.onDeleted();
					this.editItem = null;
				},
				error => {
					this.loading = this.alertService.error(error);
				}));
	}
	onCancel() {
		this.router.navigate([this.uri]);
	}

	onCreate() {
		this.loading = this.alertService.start();
		this.subscriptions.push(this.service.create(this.authenticationService.getPartyId(), this.editItem)
			.subscribe(
				{
					next:
						response => {
							this.editItem = response.data;
							this.loading = this.alertService.success();
							this.onSaved();
						},
					error:
						error => {
							this.loading = this.alertService.error(error);
						}
				}));
	}

	onUpdate() {
		this.loading = this.alertService.start();
		this.subscriptions.push(this.service.update(this.authenticationService.getPartyId(), this.editItem.id, this.editItem)
			.subscribe(
				{
					next:
						response => {
							this.editItem = response.data;
							this.loading = this.alertService.success();
							this.onSaved();
						},
					error:
						error => {
							this.loading = this.alertService.error(error);
						}
				}));
	}

	onSave() {
		if (!this.editItem.id && this.service.create) {
			this.onCreate();
			return;
		}
		if (this.service.update) {
			this.onUpdate();
			return;
		}
		this.loading = this.alertService.start();
		this.subscriptions.push(this.service.put(this.authenticationService.getAuthentication().party.id, this.editItem.id, this.editItem)
			.subscribe(
				response => {
					this.editItem = response.data;
					this.loading = this.alertService.success();
					this.onSaved();
				},
				error => {
					this.loading = this.alertService.error(error);
					this.onSavingError(error);
				}));
	}

	toggleMask(value: number, mask: number): number {
		if (value & mask) {
			value &= ~mask;
		} else {
			value |= mask;
		}
		return value;
	}

	getMask(value: number, mask: number): boolean {
		return (value & mask) !== 0;
	}

	getBit(value: number, bit: number): boolean {
		const mask = 1 << bit;
		return (value & mask) !== 0;
	}
	toggleBit(value: number, bit: number): number {
		const mask = 1 << bit;
		if (value & mask) {
			value &= ~mask;
		} else {
			value |= mask;
		}
		if (value === 0) {
			value = null;
		}
		return value;
	}
}
