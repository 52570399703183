import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TimerService } from '../timer/timer.service';

import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { TaskSummaryModel } from 'app/generated/backend/task/api/task-summary-model';
import { TaskSummaryHandlerService } from 'app/generated/backend/task/service/task-summary-handler';
@Injectable()
export class CoreTaskSummaryService {
	private _taskSummaries: TaskSummaryModel[];
	public updated = new BehaviorSubject<TaskSummaryModel[]>(null);

	constructor(
		private timerService: TimerService,
		private taskSummaryHandlerService: TaskSummaryHandlerService,
		private authenticationService: CoreAuthenticationService
	) {
	}

	public get(): TaskSummaryModel[] {
		return this._taskSummaries;
	}
	public updateSummaries(taskSummaries: TaskSummaryModel[]) {
		this._taskSummaries = taskSummaries;
		this.updated.next(this._taskSummaries);
	}

	public refresh() {
		const partyId = this.authenticationService.getPartyId();
		if (!partyId) {
			this.updateSummaries(null);
			return;
		}
		this.taskSummaryHandlerService.get(this.authenticationService.getPartyId()).subscribe({
			next: response => {
				this.updateSummaries(response.data);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	public start() {
		this.timerService.everyMinute.subscribe(() => this.refresh());
		this.authenticationService.authenticationChanged.subscribe(authentication => {
			this.refresh();
		});
		this.refresh();
	}

}