import { Injectable } from '@angular/core';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { CurrencyLookupHandlerService } from 'app/generated/backend/currency/service/currency-lookup-handler';
import { CurrencyLookupModel } from 'app/generated/backend/currency/api/currency-lookup-model';
@Injectable()
export class CoreCurrencyLookupService extends CachedEntityService<CurrencyLookupModel> {
	constructor(
		currenciesHandlerService: CurrencyLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(currenciesHandlerService, cacheService.getCurrencies());
	}
	transformItem(item: CurrencyLookupModel): CurrencyLookupModel {
		if (item) {
			item.match = '';
			if (item.iso3) {
				item.match += item.iso3.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}
}
