import { CargoType } from 'app/generated/backend/types/cargo-type';
import { ContainerSize } from 'app/generated/backend/types/container-size';
import { ContainerType } from 'app/generated/backend/types/container-type';
import { ShipmentContainer } from 'app/generated/backend/shipment/api/shipment-container';
import { ShipmentPackage } from 'app/generated/backend/shipment/api/shipment-package';
import { ShipmentPackageProperties } from 'app/generated/backend/types/shipment-package-properties';
export class ShipmentCargoGroup {
	id: number;
	createdAt: string;
	updatedUserId: number;
	updatedAt: string;
	shipmentId: number;
	weight: number;
	length: number;
	width: number;
	height: number;
	cargoGroupProperties: ShipmentPackageProperties;
	containerSize: ContainerSize;
	containerType: ContainerType;
	cargoType: CargoType;
	packageTypeId: number;
	quantity: number;
	temperature: number;
	bookingHarmonizedCommodity: string;
	cargoHarmonizedCommodity: string;
	chargesAt: string;
	shipmentPackages: ShipmentPackage[];
	containers: ShipmentContainer[];
	exceededMaxWeight: boolean;
	greaterThanMaxTemperature: boolean;
	lessThanMinTemperature: boolean;
}
