import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CoreBytesMeasurePipe } from 'app/core/measure/CoreBytesMeasurePipe';
import { CoreTransportDistancePipe } from 'app/core/measure/CoreTransportDistanceMeasurePipe';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { CoreLengthInputComponent } from './CoreLengthInputComponent';
import { CoreLengthMeasurePipe } from './CoreLengthMeasurePipe';
import { CoreMeasureInputComponent } from './CoreMeasureInputComponent';
import { CoreTemperatureInputComponent } from './CoreTemperatureInputComponent';
import { CoreTemperatureMeasurePipe } from './CoreTemperatureMeasurePipe';
import { CoreVolumeInputComponent } from './CoreVolumeInputComponent';
import { CoreVolumeMeasurePipe } from './CoreVolumeMeasurePipe';
import { CoreWeightInputComponent } from './CoreWeightInputComponent';
import { CoreWeightMeasurePipe } from './CoreWeightMeasurePipe';


@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		FormsModule,
		NgbModule,
		TranslateModule,
		HelpPopupModule
	],
	exports: [
		CoreMeasureInputComponent,
		CoreWeightInputComponent,
		CoreVolumeInputComponent,
		CoreTemperatureInputComponent,
		CoreLengthInputComponent,
		CoreLengthMeasurePipe,
		CoreVolumeMeasurePipe,
		CoreWeightMeasurePipe,
		CoreBytesMeasurePipe,
		CoreTransportDistancePipe,
		CoreTemperatureMeasurePipe
	],
	declarations: [
		CoreMeasureInputComponent,
		CoreWeightInputComponent,
		CoreVolumeInputComponent,
		CoreTemperatureInputComponent,
		CoreLengthInputComponent,
		CoreLengthMeasurePipe,
		CoreVolumeMeasurePipe,
		CoreWeightMeasurePipe,
		CoreBytesMeasurePipe,
		CoreTransportDistancePipe,
		CoreTemperatureMeasurePipe
	],
})
export class CoreMeasureModule { }
