import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum LocalityCategory {
	Locality = 1,
	None = 0,
	Country = -1,
	Region = -2,
	Location = -3,
	Facility = -4,
	PostalCodeRange = -6,
	Reserved7 = -7,
	Depot = -8,
	LocationGroup = -9,
}

@Pipe({
name: 'localityCategory'
})
export class LocalityCategoryPipe extends BasePipe<LocalityCategory> implements PipeTransform {
	static prefix = 'Backend.Types.LocalityCategory.';
	static keys = [
		LocalityCategory.Locality,
		LocalityCategory.None,
		LocalityCategory.Country,
		LocalityCategory.Region,
		LocalityCategory.Location,
		LocalityCategory.Facility,
		LocalityCategory.PostalCodeRange,
		LocalityCategory.Reserved7,
		LocalityCategory.Depot,
		LocalityCategory.LocationGroup];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: LocalityCategory): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case LocalityCategory.Locality: return 'Locality';
			case LocalityCategory.None: return 'None';
			case LocalityCategory.Country: return 'Country';
			case LocalityCategory.Region: return 'Region';
			case LocalityCategory.Location: return 'Location';
			case LocalityCategory.Facility: return 'Facility';
			case LocalityCategory.PostalCodeRange: return 'PostalCodeRange';
			case LocalityCategory.Reserved7: return 'Reserved7';
			case LocalityCategory.Depot: return 'Depot';
			case LocalityCategory.LocationGroup: return 'LocationGroup';
			default: return null;
		}
	}
	transform(value: LocalityCategory, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(LocalityCategoryPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		LocalityCategoryPipe
	],
	exports: [
		LocalityCategoryPipe
	],
	providers: [
		LocalityCategoryPipe
	]
})
export class LocalityCategoryModule { }
