import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { ContactUsRequestModel } from 'app/generated/backend/crm/api/contact-us-request-model';
import { ContactUsHandlerService } from 'app/generated/backend/crm/service/contact-us-handler';
import { CrmType } from 'app/generated/backend/types/crm-type';
import { PartyCategory } from 'app/generated/backend/types/party-category';
import { Subscription } from 'rxjs';
import { AlertService } from '../alert/alert.service';


@Component({
	selector: 'app-contact-us',
	templateUrl: './ContactUsComponent.html'
})
export class ContactUsComponent implements OnInit, OnDestroy {

	private _subscriptions = new Array<Subscription>();

	public model = new ContactUsRequestModel();
	public success = false;
	public disableUser = false;
	public disableParty = false;
	public disableVolumeType = false;

	public crmTypes = [
		CrmType.Customer,
		CrmType.Supplier,
		CrmType.None
	];

	constructor(
		private contactUsHandlerService: ContactUsHandlerService,
		private authenticationService: CoreAuthenticationService,
		private alertService: AlertService,
		private translateService: TranslateService
	) {
	}
	ngOnInit(): void {
		this._subscriptions.push(this.authenticationService.authenticationChanged.subscribe(auth => {
			this.model.type = CrmType.Customer;
			if (auth && auth.user) {
				this.model.name = auth.user.name;
				this.model.phone = auth.user.mobile;
				this.model.email = auth.user.email;
				this.disableUser = true;
				this.disableVolumeType = true;
			}
			if (auth && auth.party) {
				this.model.company = auth.party.name;
				if (auth.party.category & PartyCategory.Customer) {
					this.model.type = CrmType.Customer;
				} else if (auth.party.category & PartyCategory.Supplier) {
					this.model.type = CrmType.Supplier;
				} else {
					this.model.type = CrmType.None;
				}
				this.model.countryId = auth.party.countryId;
				this.disableParty = true;
			}
		}));
	}

	ngOnDestroy() {
		this._subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	onSubmit() {
		if (this.model && this.model.phone) {
			this.model.phone = this.model.phone.toString();
		}
		this.alertService.start();
		this._subscriptions.push(this.contactUsHandlerService.post(this.model)
			.subscribe(
				response => {
					this.success = true;
					this.alertService.success(this.translateService.instant('field.submitted.label'));
				},
				error => {
					this.alertService.error(error);
				}));
	}

}
