import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PartyPlaceLookupModel } from 'app/generated/backend/yard/api/party-place-lookup-model';
import { BaseIdInputTypeaheadComponent } from 'app/_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent';
import { PartyPlaceAddDialog } from './detail/PartyPlaceAddDialog';
import { PartyPlaceLookupService } from './PartyPlaceLookupService';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => PartyPlaceLookupComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => PartyPlaceLookupComponent),
	multi: true
};

@Component({
	selector: 'app-party-place-lookup',
	templateUrl: '../../_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class PartyPlaceLookupComponent extends BaseIdInputTypeaheadComponent<PartyPlaceLookupModel> {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string = 'fa-building';

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Output()
	itemSelected: EventEmitter<PartyPlaceLookupModel> = new EventEmitter<PartyPlaceLookupModel>();

	constructor(
		protected service: PartyPlaceLookupService,
		protected router: Router,
		public dialog: MatDialog
	) {
		super(service);
	}

	onAdd() {
		const dialogRef = this.dialog.open(PartyPlaceAddDialog, {
			width: '60%',
			height: '80%'
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.allItems.push(this.service.updateItem(result));
				this.value = result.id;
				this.itemSelected.emit(result);
			}
		});
	}

	toDisplayText(item: PartyPlaceLookupModel): string {
		if (!item) {
			return null;
		}
		if (item.code) {
			return item.code + ' ' + item.name;
		} else {
			return item.name;
		}
	}

}