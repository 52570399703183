<div class="adn-form-field"
        [ngClass]="{'adn-form-field-invalid': !errorStateMatcher.valid, 'adn-form-field-disabled': disabled}">
        <label>
                <span *ngIf="iconClass" class="fal fa-fw" [ngClass]="iconClass"
                        aria-hidden="true">&nbsp;</span>{{label}}
                <span *ngIf="required">&nbsp;*</span>
        </label>
        <div class="adn-form-field-content">
                <input class="number-input-style" type="number" [ngModel]="scaledValue" [placeholder]="placeholder"
                        (blur)="onBlur()" [required]="required" [disabled]="disabled" (focus)="onFocus()"
                        (ngModelChange)="onChange($event)">
                <span class="input-suffix" onMouseOver="this.style.cursor='pointer'"
                        title="{{ 'field.unitTooltip.label' | translate}}">
                        <div ngbDropdown>
                                <span name ='dropDownToggle' ngbDropdownToggle class="ngbDropdown-toggle">{{unit.name}}</span>
                                <div ngbDropdownMenu>
                                        <button type="button" ngbDropdownItem *ngFor="let unit of units"
                                                (click)="selectUnit(unit)">{{unit.name}}</button>
                                </div>
                        </div>
                </span>

                <div class="input-icon" *ngIf="value">
                        <button mat-button mat-icon-button matSuffix *ngIf="value && !required && !disabled"
                                type="button" (click)="value=null;onBlur()" title="{{ 'field.clear.label' | translate}}"
                                tabindex="-1">
                                <span class="fal fa-fw fa-times-circle"></span>
                        </button>
                </div>
        </div>
</div>
<div class="adn-form-field-error" *ngIf="!errorStateMatcher.valid">
        <span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;<span
                class="error-text">{{errorStateMatcher.errorKey | translate}}</span>
</div>