import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreContentModule } from 'app/core/content/CoreContentModule';
import { CommonMaterialModule } from '../core/common-material/core-common-material.module';
import { HomeComponent } from './HomeComponent';


const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	}
];

@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		CoreContentModule,
		FormsModule,
		TranslateModule,
		RouterModule.forChild(routes)
	],
	declarations: [HomeComponent]
})
export class HomeModule { }
