import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { ButtonBaseComponent } from './button-base.component';

export abstract class ButtonAnalyticsBaseComponent extends ButtonBaseComponent {

	public abstract href: string;

	public abstract category: string;


	constructor(
		protected angulartics2: Angulartics2,
		protected router: Router
	) {
		super();
	}

	onClick() {
		if (this.href) {
			this.angulartics2.eventTrack.next({ action: 'click', properties: { category: this.category, label: window.location.href } });
			if (!this.action) {
				this.router.navigate([this.href]);
			}
		}
		super.onClick();
	}

}