import { Shipment } from "app/generated/backend/shipment/api/shipment";
import { ShipmentDetailModel } from "app/generated/backend/shipment/api/shipment-detail-model";
import { ShipmentLocation } from "app/generated/backend/shipment/api/shipment-location";
import { ShipmentParty } from "app/generated/backend/shipment/api/shipment-party";
import { LocalityLookupModel } from "app/generated/backend/types/locality-lookup-model";

export class BookingState {
	shipment: Shipment;
	detail: ShipmentDetailModel;
	signedAt: string;
	signature: string;
	parties: ShipmentParty[];
	pickupLocation: ShipmentLocation;
	deliveryLocation: ShipmentLocation;
	pickupPartyPlaceId: number;
	deliveryPartyPlaceId: number;
	namedDestinationLocality: LocalityLookupModel;
	namedOriginLocality: LocalityLookupModel;
}