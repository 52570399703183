import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable()
export class CoreMissingTranslationHandler implements MissingTranslationHandler {
    private _missingKeys = new Set<string>();
    constructor(private httpClient: HttpClient) { }
    handle(params: MissingTranslationHandlerParams) {
        if (!this._missingKeys.has(params.key)) {
            console.error('Missing translation for ' + params.key);
            this._missingKeys.add(params.key);
            this.httpClient.get('/api/v1/content/reportMissingTranslation?key=' + params.key).subscribe(
                (res: Response) => {
                },
                error => {
                    console.error(error);
                }
            );
        }
        return params.key;
    }
}