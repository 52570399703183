import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContainerType, ContainerTypePipe } from 'app/generated/backend/types/container-type';
import { BaseEnumRadioGroupComponent } from 'app/_templates/BaseEnumRadioGroupComponent';
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ContainerTypeInputComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => ContainerTypeInputComponent),
	multi: true
};
@Component({
	selector: 'app-container-type-input',
	templateUrl: '../../_templates/BaseEnumRadioGroupComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class ContainerTypeInputComponent extends BaseEnumRadioGroupComponent<ContainerType> {

	@Input()
	labelKey: string;

	@Input()
	helpUri: string;

	constructor(
		containerTypePipe: ContainerTypePipe) {
		super([ContainerType.GeneralPurpose, ContainerType.Reefer], containerTypePipe);
	}

}
