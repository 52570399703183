import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum LocationFunction {
	None = 0,
	Sea = 1,
	Air = 2,
	Rail = 4,
	Road = 8,
	Depot = 16,
}

@Pipe({
name: 'locationFunction'
})
export class LocationFunctionPipe extends BasePipe<LocationFunction> implements PipeTransform {
	static prefix = 'Backend.Types.LocationFunction.';
	static keys = [
		LocationFunction.None,
		LocationFunction.Sea,
		LocationFunction.Air,
		LocationFunction.Rail,
		LocationFunction.Road,
		LocationFunction.Depot];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: LocationFunction): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case LocationFunction.None: return 'None';
			case LocationFunction.Sea: return 'Sea';
			case LocationFunction.Air: return 'Air';
			case LocationFunction.Rail: return 'Rail';
			case LocationFunction.Road: return 'Road';
			case LocationFunction.Depot: return 'Depot';
			default: return null;
		}
	}
	transform(value: LocationFunction, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(LocationFunctionPipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(LocationFunctionPipe.keys, LocationFunctionPipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		LocationFunctionPipe
	],
	exports: [
		LocationFunctionPipe
	],
	providers: [
		LocationFunctionPipe
	]
})
export class LocationFunctionModule { }
