import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { ContentAccess } from 'app/generated/backend/content/api/content-access';
import { ContentBodyFormat } from 'app/generated/backend/content/api/content-body-format';
import { ContentTranslationModel } from 'app/generated/backend/content/api/content-translation-model';
import { ContentTranslationHandlerService } from 'app/generated/backend/content/service/content-translation-handler';
import { AdminAccessControl } from 'app/generated/backend/types/admin-access-control';
import { Error } from 'app/generated/backend/handler/error';
import { PermissionFlags } from 'app/generated/backend/types/permission-flags';
import { Subscription } from 'rxjs';
import { CoreCustomizationService } from '../customization/CoreCustomizationService';

export enum PanelWidth {
	None = 0,
	Narrow = 1,
	Balance = 2,
	Medium = 3,
	Stretch = 4,
	Wide = 5
}

@Component({
	selector: 'app-panel',
	templateUrl: './PanelComponent.html',
	styleUrls: ['./PanelComponent.scss']
})

export class PanelComponent implements OnInit, OnDestroy {
	@Input()
	middle: boolean;
	@Input()
	dialog = false;

	private _titleTranslateKey: string;
	private _contentAccess: ContentAccess;
	public get titleTranslateKey(): string {
		return this._titleTranslateKey;
	}
	@Input()
	public set titleTranslateKey(value: string) {
		if (value !== this._titleTranslateKey) {
			this._titleTranslateKey = value;
			if (value) {
				this.translateTitle();
			}
		}
	}

	private _parentTitleTranslateKey: string;
	public get parentTitleTranslateKey(): string {
		return this._parentTitleTranslateKey;
	}
	@Input()
	public set parentTitleTranslateKey(value: string) {
		if (value !== this._parentTitleTranslateKey) {
			this._parentTitleTranslateKey = value;
			if (value) {
				this.translateParentTitle();
			}
		}
	}

	private _contentUri: string;
	public content: ContentTranslationModel;
	public contentBodySafeHtml: SafeHtml;
	languageIso2: string;
	@Input()
	public set contentUri(contentUri: string) {
		if (contentUri !== this._contentUri) {
			this._contentUri = contentUri;
			this.updateContent();
		}
	}

	@Input()
	helpUri: string;

	@Input()
	set title(title: string) {
		if (this._title !== title) {
			this._title = title;
			this.updateTitle();
		}
	}

	get title(): string {
		return this._title;
	}

	@Input()
	set parentTitle(parentTitle: string) {
		if (this._parentTitle !== parentTitle) {
			this._parentTitle = parentTitle;
			this.updateTitle();
		}
	}

	get parentTitle(): string {
		return this._parentTitle;
	}

	@Input()
	set titleId(titleId: string) {
		if (this._titleId !== titleId) {
			this._titleId = titleId;
			this.updateTitle();
		}
	}

	get titleId(): string {
		return this._titleId;
	}

	@Input()
	set state(state: string) {
		if (this._state !== state) {
			this._state = state;
			this.updateTitle();
		}
	}

	get state(): string {
		return this._state;
	}

	@Input()
	loading: boolean;

	@Input()
	errors: Error[];

	@Input()
	panelWidth: PanelWidth = 0;

	private _parentTitle: string;
	private _title: string;
	private _titleId: string;
	private _state: string;
	public hideInfo: boolean = false;

	private subscriptions: Subscription[] = new Array<Subscription>();

	public constructor(
		private authenticationService: CoreAuthenticationService,
		protected customizationService: CoreCustomizationService,
		private titleService: Title,
		private translateService: TranslateService,
		private contentTranslateHandlerService: ContentTranslationHandlerService,
		private domSanitizer: DomSanitizer
	) {
		this.languageIso2 = this.customizationService.getLanguageIso2();
		this.subscriptions.push(this.authenticationService.authenticationChanged.subscribe(authentication => {
			this.updateContentAccess();
		}));
	}


	getContentAccess(): ContentAccess {
		if (this.authenticationService.hasAdminPermission(AdminAccessControl.Content, PermissionFlags.Read)) {
			return ContentAccess.Confidential;
		}
		if (this.authenticationService.getUserId()) {
			return ContentAccess.Restricted;
		}
		return ContentAccess.Public;
	}

	updateContentAccess() {
		const contentAccess = this.getContentAccess();
		if (contentAccess !== this._contentAccess) {
			this._contentAccess = contentAccess;
			this.updateContent();
		}
	}
	updateTitle() {
		let title = this._title;
		if (title) {
			if (this._titleId) {
				title += ' ';
				title += this._titleId;
			}
			if (!this.dialog) {
				this.titleService.setTitle(title);
			}
		}
	}

	updateContent() {
		this.content = null;
		this.contentBodySafeHtml = null;
		if (this._contentUri) {
			this.subscriptions.push(this.contentTranslateHandlerService.get(this.languageIso2, this._contentUri, this._contentAccess, '').subscribe(
				response => {
					this.content = response.data;
					if (this.content.bodyFormat == ContentBodyFormat.Html) {
						this.contentBodySafeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.content.body);
					}
					if (this.content.title && !this.dialog) {
						this.titleService.setTitle(this.content.title);
					}
				},
				error => {
					console.error(error);
				}));
		}
	}

	translateTitle() {
		this.subscriptions.push(this.translateService.stream(this.titleTranslateKey).subscribe(
			translation => {
				this.title = translation;
				this.updateTitle();
			}
		));
	}

	translateParentTitle() {
		this.subscriptions.push(this.translateService.stream(this.parentTitleTranslateKey).subscribe(
			translation => {
				this.parentTitle = translation;
			}
		));
	}

	toggleHideInfo(toggle: boolean) {
		this.hideInfo = toggle;
	}

	ngOnInit() {
		this.subscriptions.push(this.customizationService.changed.subscribe(settings => {
			const languageIso2 = this.customizationService.getLanguageIso2();
			if (languageIso2 !== this.languageIso2) {
				this.languageIso2 = languageIso2;
				this.updateContent();
				if (this.titleTranslateKey) {
					this.translateTitle();
				}
			}
		}));
		this.hideInfo = !this.customizationService.getSettings().showInfo;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
	}
}
