import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RejectShipmentServiceRequest } from 'app/generated/backend/shipment/api/reject-shipment-service-request';
import { RejectShipmentServiceResponse } from 'app/generated/backend/shipment/api/reject-shipment-service-response';

@Injectable()
export class RejectShipmentServiceHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	post(
		request: RejectShipmentServiceRequest
	): Observable<JsonResourceResponse<RejectShipmentServiceResponse>> {
		return this.http.post<JsonResourceResponse<RejectShipmentServiceResponse>>('api/v1/shipment/rejectShipmentService', request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		RejectShipmentServiceHandlerService
	]
})
export class RejectShipmentServiceHandlerModule { }
