<div *ngFor="let model of models; let i = index" [ngClass]="menuClass">
    <button mat-menu-item [matMenuTriggerFor]="publicMenu" [ngClass]="link.isActive ? activeItemClass : itemClass"
        routerLinkActive="{{activeItemClass}}" #link="routerLinkActive" class="adn-color-white"
        (click)="$event.stopPropagation()">
        <span class="adnavem-public-menuitem-color">
            {{model.title}}&nbsp;
            <em *ngIf="model.children && !isDropdown" class="fal fa-fw fa-sort-down fa-1x adn-menu-user-arrow"></em>
        </span>
    </button>
    <mat-menu #publicMenu="matMenu">
        <public-menu *ngFor="let child of model.children" [model]="child"
            itemClass="adn-party-menu-item adnavem-public-menuitem-color" activeItemClass="adn-party-menu-item-active">
        </public-menu>
    </mat-menu>
</div>


<button *ngIf="!isLoggedIn && isDropdown" mat-menu-item [matMenuTriggerFor]="importMenu" class="adn-color-primary">
    {{'footer.import.title' | translate}}
</button>
<mat-menu #importMenu="matMenu" class="adnavem-dark-text-with-light-background">
    <button *ngFor="let country of importCountries" mat-menu-item (click)="goToWorld([country.uri])">
        &nbsp;{{'footer.import.title' | translate}} {{'country.' + country.iso2 | translate}}
    </button>
</mat-menu>

<button *ngIf="!isLoggedIn && isDropdown" mat-menu-item [matMenuTriggerFor]="exportMenu" class="adn-color-primary">
    {{'footer.export.title' | translate}}
</button>
<mat-menu #exportMenu="matMenu" class="adnavem-dark-text-with-light-background">
    <button *ngFor="let country of exportCountries" mat-menu-item (click)="goToWorld([country.uri])">
        &nbsp;{{'footer.export.title' | translate}} {{'country.' + country.iso2 | translate}}
    </button>
</mat-menu>