import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CancelShipmentRequest } from 'app/generated/backend/shipment/api/cancel-shipment-request';
import { CancelShipmentResponse } from 'app/generated/backend/shipment/api/cancel-shipment-response';

@Injectable()
export class CancelShipmentHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	post(
		request: CancelShipmentRequest
	): Observable<JsonResourceResponse<CancelShipmentResponse>> {
		return this.http.post<JsonResourceResponse<CancelShipmentResponse>>('api/v1/shipment/cancelShipment', request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		CancelShipmentHandlerService
	]
})
export class CancelShipmentHandlerModule { }
