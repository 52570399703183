import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ContactUsHandlerModule } from 'app/generated/backend/crm/service/contact-us-handler';
import { CrmTypeModule } from 'app/generated/backend/types/crm-type';
import { AlertModule } from '../alert/alert.module';
import { CoreButtonModule } from '../button/CoreButtonModule';
import { CoreCaptchaModule } from '../captcha/CoreCaptchaModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreCountryModule } from '../country/CoreCountryModule';
import { EmailModule } from '../email/EmailModule';
import { CoreNumberInputModule } from '../number/CoreNumberInputModule';
import { CorePhoneNumberModule } from '../phone-number/CorePhoneNumberModule';
import { CoreTextInputModule } from '../text-input/CoreTextInputModule';
import { ContactUsComponent } from './ContactUsComponent';

@NgModule({
	imports: [
		AlertModule,
		CoreCaptchaModule,
		CommonMaterialModule,
		CommonModule,
		ContactUsHandlerModule,
		CoreButtonModule,
		CoreCountryModule,
		CoreTextInputModule,
		CrmTypeModule,
		EmailModule,
		FormsModule,
		CorePhoneNumberModule,
		CoreNumberInputModule,
		TranslateModule

	],
	declarations: [
		ContactUsComponent
	],
	exports: [
		ContactUsComponent
	]
})
export class ContactUsModule { }
