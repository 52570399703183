import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { HarmonizedCommodityLookupModel } from 'app/generated/backend/trade/api/harmonized-commodity-lookup-model';
import { AutocompleteFilterPipe } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteFilterPipe';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Subscription } from 'rxjs';
import { CoreHarmonizedCommodityLookupService } from './CoreHarmonizedCommodityLookupService';

const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreHarmonizedCommodityLookupComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreHarmonizedCommodityLookupComponent),
	multi: true
};

@Component({
	selector: 'app-core-harmonized-commodity-lookup',
	templateUrl: '../../_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreHarmonizedCommodityLookupComponent implements ControlValueAccessor, Validator {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number = 20;

	@Input()
	public label: string;

	@Input()
	public iconClass: string = 'fa-shopping-bag';

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Input()
	private onlySixDigitCode: boolean = false;

	@Output()
	itemSelected: EventEmitter<HarmonizedCommodityLookupModel> = new EventEmitter<HarmonizedCommodityLookupModel>();

	public loading: boolean = true;
	private _innerValue: string;
	public model: string = '';
	public allItems: HarmonizedCommodityLookupModel[];
	protected subscriptions = new Array<Subscription>();
	public errorStateMatcher = new SimpleErrorStateMatcher();
	private error: string;
	public closeDropdown = false;
	public currentIndex = null;
	public currentSelectedId = null;

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): string {
		return this._innerValue;
	};

	// set accessor including call the onchange callback
	set value(id: string) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
			this.onChangeCallback(id);
		}
	}

	// From ControlValueAccessor interface
	writeValue(id: string) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: UntypedFormControl): ValidationErrors | null {
		if (this.error) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = this.error;
			return {
				jsonParseError: {
					valid: false,
				}
			};
		}
		if (!this.value && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				jsonParseError: {
					valid: false,
				}
			};
		}
		this.errorStateMatcher.valid = true;
		return null;
	}

	constructor(
		protected service: CoreHarmonizedCommodityLookupService
	) { }

	load() {
		this.loading = true;
		this.error = null;
		this.errorStateMatcher.valid = true;
		this.currentIndex = null;
		this.currentSelectedId = null;
		this.subscriptions.push(this.service.getItems().subscribe(items => {
			this.allItems = this.sortItems(this.filterItems(items));
			this.selectItem(this._innerValue);
			this.loading = false;
		},
			error => {
				const httpErrorResponse = error as HttpErrorResponse;
				if (httpErrorResponse && httpErrorResponse.status) {
					this.error = "Http.Status." + httpErrorResponse.status.toString() + ".label";
				} else {
					this.error = "Http.Status.500.label";
				}
				this.validate(null);
				this.loading = false;
			}));
	}

	ngOnInit() {
		this.load();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	toDisplayText(item: HarmonizedCommodityLookupModel): string {
		if (!item) {
			return null;
		}
		return item.code;
	}

	toTitleText(item: HarmonizedCommodityLookupModel): string {
		return this.toOptionText(item);
	}

	toOptionText(item: HarmonizedCommodityLookupModel): string {
		if (!item) {
			return null;
		}
		return item.match;
	}

	sortItems(items: HarmonizedCommodityLookupModel[]): HarmonizedCommodityLookupModel[] {
		return items;
	}

	filterItems(items: HarmonizedCommodityLookupModel[]): HarmonizedCommodityLookupModel[] {
		if (this.onlySixDigitCode) {
			let filteredItems = [];
			items.forEach(eachItem => {
				if (eachItem.code && eachItem.code.length === 6) {
					filteredItems.push(eachItem);
				}
			});
			return filteredItems;
		}
		return items;
	}

	onItemSelected(code: any) {
		this.selectItem(code);
		this.allItems.forEach(eachItem => {
			if (eachItem.code === code) {
				this.itemSelected.emit(eachItem);
			}
		});

	}

	selectItem(code: string) {
		if (code === null) {
			this.value = null;
			this.model = null;
		}
		if (code && this.allItems) {
			this.value = code;
			this.allItems.forEach(eachItem => {
				if (eachItem.code === this._innerValue) {
					this.model = this.toDisplayText(eachItem);
				}
			});
			this.error = null;
		}
		this.validate(null);
	}

	modelChange() {
		if (this.model.trim() === '') {
			this.value = null;
		}
		if (this.value && this.model) {
			this.allItems.forEach(eachItem => {
				if (eachItem.code === this.value) {
					if (this.toDisplayText(eachItem) === this.model) {
						this.error = null;
					} else {
						this.error = 'error.notAValidOption';
					}
				}
			});
		}
		this.validate(null);
		this.currentIndex = null;
		this.currentSelectedId = null;
	}

	getOptionValue(option: HarmonizedCommodityLookupModel): string {
		return option.code;
	}

	closeOption(isSelectionNeeded: boolean) {
		this.error = null;
		if (!isSelectionNeeded) {
			this.currentSelectedId = null;
			this.currentIndex = null;
		}
		if (this.model && this.model.trim() !== '') {
			const autocompleteFilterPipe = new AutocompleteFilterPipe();
			let filteredList = autocompleteFilterPipe.transform(this.allItems, this.model, this.maxOptions);
			if (filteredList && ((filteredList.length === 1 && isSelectionNeeded) || (this.currentSelectedId))) {
				if (filteredList.length === 1 && isSelectionNeeded) {
					this.selectItem(filteredList[0].code);
					this.itemSelected.emit(filteredList[0]);
				} else if (this.currentSelectedId) {
					const item = filteredList.filter((filteredItem: { id: any; }) => filteredItem.id === this.currentSelectedId);
					if (item && item.length) {
						this.selectItem(item[0].code);
						this.itemSelected.emit(item[0]);
					}
				}
			} else {
				this.error = 'error.notAValidOption';
			}
		}
		this.validate(null);
	}

	onClear() {
		this.value = null;
		this.model = '';
		this.currentIndex = null;
		this.currentSelectedId = null;
	}

	onAdd() {

	}
	onKeyDownEvent(event: any) {
		let items: HarmonizedCommodityLookupModel[];
		const autocompleteFilterPipe = new AutocompleteFilterPipe();
		let filteredList = autocompleteFilterPipe.transform(this.allItems, this.model, this.maxOptions)
		if (filteredList && filteredList.length) {
			items = filteredList;
		} else {
			items = this.allItems;
		}
		if (event.code === 'ArrowDown') {
			if (this.currentIndex === null) {
				if (this.addNew && this.currentSelectedId !== 'addNew') {
					this.currentSelectedId = 'addNew';
				} else {
					this.currentIndex = 0;
					this.currentSelectedId = null;
				}
			} else if (items.length && items.length - 1 > this.currentIndex) {
				this.currentIndex = this.currentIndex + 1;
			}
		} else if (event.code === 'ArrowUp') {
			if (this.currentIndex > 0) {
				this.currentIndex = this.currentIndex - 1;
				this.currentSelectedId = null;
			} else if (this.currentIndex === 0 && this.addNew) {
				this.currentSelectedId = 'addNew';
				this.currentIndex = null;
			}
		}
		this.HandleItemSelection(event, items);
	}

	private HandleItemSelection(event: any, items: HarmonizedCommodityLookupModel[]) {
		if (this.currentSelectedId === 'addNew') {
			if (event.code === 'Enter') {
				this.onAdd();
			}
		} else if (this.currentIndex !== null) {
			const currenctObject = items[this.currentIndex];
			if (!currenctObject) {
				return;
			}
			if ((event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Tab')) {
				this.selectItem(items[this.currentIndex].code);
				this.itemSelected.emit(items[this.currentIndex]);
			}
			if (this.currentSelectedId === 'addNew') {
				return;
			}
			if (currenctObject) {
				this.currentSelectedId = currenctObject.id;
				const element = document.getElementById(this.currentSelectedId);
				if (element) {
					element.scrollIntoView({ block: 'nearest' });
				}
			}
		}
	}
}