import { Component, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';



@Component({
    selector: 'alert-popup-component',
    templateUrl: 'alert-popup-component.html',
    styleUrls: ['./alert.component.scss'],
    template: '{{ data }}',
})
export class AlertPopupComponent {

    public message: string;

    public type: string  ;

    constructor(
        private snackBarRef: MatSnackBarRef<AlertPopupComponent>,

        @Inject(MAT_SNACK_BAR_DATA)

        public data: any) {

        if (data && data.message && data.message.text) {
            this.message = data.message.text;
            this.type = data.message.type;
        }

    }

     close() {
        this.snackBarRef.dismiss();
    }


}