import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ContainerTypeModule } from 'app/generated/backend/types/container-type';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { ContainerTypeInputComponent } from './ContainerTypeInputComponent';
import { ContainerTypeFlagCheckBoxComponent } from 'app/core/container-type-input/ContainerTypeFlagCheckBoxComponent';

@NgModule({
    imports: [
        AutocompleteModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        CommonMaterialModule,
        ContainerTypeModule,
        HelpPopupModule
    ],
    exports: [ContainerTypeInputComponent, ContainerTypeFlagCheckBoxComponent],
    declarations: [ContainerTypeInputComponent, ContainerTypeFlagCheckBoxComponent]
})
export class ContainerTypeInputModule {
}
