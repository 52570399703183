import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PartyPlaceMasterModel } from 'app/generated/backend/yard/api/party-place-master-model';

@Injectable()
export class PartyPlaceMasterHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		offset: number,
		limit: number,
		partyId: number
	): Observable<JsonResourceResponse<PartyPlaceMasterModel[]>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (offset!==null && offset!==undefined) {
			params = params.set('offset', offset.toString());
		}
		if (limit!==null && limit!==undefined) {
			params = params.set('limit', limit.toString());
		}
		return this.http.get<JsonResourceResponse<PartyPlaceMasterModel[]>>(`api/v1/yard/parties/${partyId}/places/master`, { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		PartyPlaceMasterHandlerService
	]
})
export class PartyPlaceMasterHandlerModule { }
