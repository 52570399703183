<div *ngIf="content?.body && card" class="centered-maxwidth-container">
    <mat-card>
        <div [innerHTML]="contentBodySafeHtml || content.body"></div>
        <div *ngIf="cta" class="row">
            <div class="col-sm-12 adn-buttons-horizontal text-right">
                <app-button-contact-us></app-button-contact-us>
            </div>
        </div>
    </mat-card>
</div>
<div *ngIf="content?.body && !card" class="adn-content" style="position: relative;">
    <dynamic-html [content]="content.body" routeTransformer></dynamic-html>
    <button type="button" *ngIf="contentId" (click)="navigateToContent()" style="opacity: .5;
        position: absolute;
        top: 0px;
        right: 0px;">Edit</button>
</div>