import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-loading',
	templateUrl: './LoadingComponent.html',
	styleUrls: ['LoadingComponent.scss']
})
export class LoadingComponent {
	@Input()
	set loading(loading: boolean) {
		if (this._loading != loading) {
			this._loading = loading;
		}
	}
	get loading(): boolean {
		return this._loading;
	}
	private _loading: boolean;
	constructor() {
		// This is intentional
	}
}