import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, Input, OnInit, ViewContainerRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AutocompleteComponent } from './AutocompleteComponent';

@Directive({
  selector: '[appAutocomplete]'
})
export class AutocompleteDirective implements OnInit {
  @Input() appAutocomplete: AutocompleteComponent;

  private overlayRef: OverlayRef;
  protected subscriptions = new Array<Subscription>();

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private vcr: ViewContainerRef,
    private overlay: Overlay
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(fromEvent(this.origin, 'click').pipe().subscribe(() => {
      if (!this.overlayRef) {
        this.openDropdown();
      }
    }));
    this.subscriptions.push(fromEvent(this.origin, 'focus').pipe().subscribe(() => {
      if (!this.overlayRef) {
        this.openDropdown();
      }
    }));
    this.subscriptions.push(fromEvent(this.origin, 'keydown').pipe().subscribe(() => {
      if (!this.overlayRef) {
        this.openDropdown();
      }
    }));
  }

  openDropdown() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
    this.overlayRef = this.overlay.create({
      width: this.origin.offsetWidth,
      maxHeight: 40 * 5,
      backdropClass: '',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.getOverlayPosition()
    });
    const template = new TemplatePortal(this.appAutocomplete.rootTemplate, this.vcr);
    this.overlayRef.attach(template);
    overlayTabOutside(this.overlayRef).subscribe(() => this.close());
    overlayEscape(this.overlayRef).subscribe(() => {
      this.close(false);
    });
    overlayClickOutside(this.overlayRef, this.origin).subscribe(() => this.close());
    this.appAutocomplete.optionsClick().pipe(takeUntil(this.overlayRef.detachments())).subscribe((value: number) => {
      this.appAutocomplete.selectOption(value);
      this.close();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private close(isSelectionNeeded: boolean = true) {
    this.overlayRef.detach();
    this.overlayRef = null;
    this.appAutocomplete.closeDropdown(isSelectionNeeded);
  }

  private getOverlayPosition() {
    const positions = [
      new ConnectionPositionPair(
        { originX: 'start', originY: 'bottom' },
        { overlayX: 'start', overlayY: 'top' }
      ),
      new ConnectionPositionPair(
        { originX: 'start', originY: 'top' },
        { overlayX: 'start', overlayY: 'bottom' }
      )
    ];

    return this.overlay
      .position()
      .flexibleConnectedTo(this.origin)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withPush(false);
  }

  get origin() {
    return this.host.nativeElement;
  }
}

export function overlayTabOutside(overlayRef: OverlayRef) {
  return fromEvent<KeyboardEvent>(document, 'keydown').pipe(filter(event => {
    return event.code === 'Tab' || event.code === 'Enter' || event.code === 'NumpadEnter';
  }),
    takeUntil(overlayRef.detachments())
  )
}

export function overlayEscape(overlayRef: OverlayRef) {
  return fromEvent<KeyboardEvent>(document, 'keydown').pipe(filter(event => {
    return event.code === 'Escape';
  }),
    takeUntil(overlayRef.detachments())
  )
}

export function overlayClickOutside(overlayRef: OverlayRef, origin: HTMLElement) {
  return fromEvent<MouseEvent>(document, 'mousedown').pipe(filter(event => {
    const clickTarget = event.target as HTMLElement;
    const notOrigin = clickTarget !== origin; // the input
    const notOverlay = !!overlayRef && (overlayRef.overlayElement.contains(clickTarget) === false); // the autocomplete
    return notOrigin && notOverlay;
  }),
    takeUntil(overlayRef.detachments())
  )
}
