<div class="adn-form-field"
  [ngClass]="{'adn-form-field-invalid': !errorStateMatcher.valid, 'adn-form-field-disabled': disabled}">
  <label>
    <span *ngIf="iconClass" class="fal fa-fw" [ngClass]="iconClass" aria-hidden="true">&nbsp;</span>{{label}}
    <span *ngIf="required">&nbsp;*</span>
  </label>
  <div class="adn-form-field-content">
    <input class="input-style" type="text" [(ngModel)]="value" [placeholder]="placeholder" (blur)="onBlur()"
      [maxlength]="maxlength" [minlength]="minlength" [required]="required" [disabled]="disabled" autocomplete="none" />
    <div class="input-icon" *ngIf="value">
      <button mat-button mat-icon-button matSuffix *ngIf="value && !required && !disabled" type="button"
        (click)="value=null" title="{{ 'field.clear.label' | translate}}" tabindex="-1">
        <span class="fal fa-fw fa-times-circle"></span>
      </button>
    </div>
    <div class="input-icon">
      <a href="mailto:{{value}}" *ngIf="value && errorStateMatcher.valid" mat-button matSuffix mat-icon-button
        title="{{'button.sendMail' | translate}}" target="_blank" rel="noopener">
        <span class="fal fa-fw fa-envelope adn-color-primary" aria-hidden="true"></span></a>
    </div>
    <app-help-popup-button class="input-icon" *ngIf="helpUri" [uri]="helpUri"></app-help-popup-button>
  </div>
</div>
<div class="adn-form-field-hint" *ngIf="errorStateMatcher.valid && hint">{{hint}}</div>
<div class="adn-form-field-error" *ngIf="!errorStateMatcher.valid">
  <span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;<span
    class="error-text">{{errorStateMatcher.errorKey | translate}}</span>
</div>