import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum PhoneNumberType {
	Invalid = 0,
	Mobile = 1,
	Landline = 2,
	Special = 3,
	TollFree = 4,
	PremiumRate = 5,
	Satellite = 6,
	Paging = 7,
/** Public Pay Telephone */
	Public = 8,
	SharedCost = 9,
	VoiceMail = 10,
/** Any purpose above */
	GeneralPurpose = 255,
}

@Pipe({
name: 'phoneNumberType'
})
export class PhoneNumberTypePipe extends BasePipe<PhoneNumberType> implements PipeTransform {
	static prefix = 'Backend.Types.PhoneNumberType.';
	static keys = [
		PhoneNumberType.Invalid,
		PhoneNumberType.Mobile,
		PhoneNumberType.Landline,
		PhoneNumberType.Special,
		PhoneNumberType.TollFree,
		PhoneNumberType.PremiumRate,
		PhoneNumberType.Satellite,
		PhoneNumberType.Paging,
		PhoneNumberType.Public,
		PhoneNumberType.SharedCost,
		PhoneNumberType.VoiceMail,
		PhoneNumberType.GeneralPurpose];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: PhoneNumberType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case PhoneNumberType.Invalid: return 'Invalid';
			case PhoneNumberType.Mobile: return 'Mobile';
			case PhoneNumberType.Landline: return 'Landline';
			case PhoneNumberType.Special: return 'Special';
			case PhoneNumberType.TollFree: return 'TollFree';
			case PhoneNumberType.PremiumRate: return 'PremiumRate';
			case PhoneNumberType.Satellite: return 'Satellite';
			case PhoneNumberType.Paging: return 'Paging';
			case PhoneNumberType.Public: return 'Public';
			case PhoneNumberType.SharedCost: return 'SharedCost';
			case PhoneNumberType.VoiceMail: return 'VoiceMail';
			case PhoneNumberType.GeneralPurpose: return 'GeneralPurpose';
			default: return null;
		}
	}
	transform(value: PhoneNumberType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(PhoneNumberTypePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		PhoneNumberTypePipe
	],
	exports: [
		PhoneNumberTypePipe
	],
	providers: [
		PhoneNumberTypePipe
	]
})
export class PhoneNumberTypeModule { }
