import { Pipe, PipeTransform } from '@angular/core';
import { TransportMode } from 'app/generated/backend/types/transport-mode';
import { ServiceCode } from '../../generated/backend/types/service-code';

@Pipe({
	name: 'serviceCodeClass'
})
// The work of the pipe is handled in the tranform method with our pipe's class
export class ServiceCodeClassPipe implements PipeTransform {
	static transformServiceToClass(value: ServiceCode, mode: TransportMode): string {
		switch (value) {
			case ServiceCode.Delivery: return 'fa-truck';
			case ServiceCode.ExportClearance: return 'fa-file-signature';
			case ServiceCode.ImportClearance: return 'fa-file-signature';
			case ServiceCode.Pickup: return 'fa-truck';
			case ServiceCode.Carriage: {
				if (mode === TransportMode.Rail) {
					return "fa-train";
				} else {
					return "fa-ship";
				}
			}
			case ServiceCode.OriginHandling: return 'fa-forklift';
			case ServiceCode.DestinationHandling: return 'fa-forklift';
			default: return 'fa-truck';
		}
	}

	transform(value: ServiceCode, args: number) {
		return ServiceCodeClassPipe.transformServiceToClass(value, args);
	}
}
