import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TimerService {
	private ticks = 0;
	public everySecond = new BehaviorSubject<number>(0);
	public everyMinute = new BehaviorSubject<number>(0);
	private timerStarted = false;

	constructor(
		private readonly zone: NgZone
	) {
	}

	public start() {
		if (!this.timerStarted) {
			this.timerStarted = true;
			this.zone.runOutsideAngular(() => setInterval(() => {
				this.ticks++;
				if (this.ticks > 10) {
					this.zone.run(() => {
						if (this.ticks % 60 === 0) {
							this.everyMinute.next(this.ticks);
						}
						this.everySecond.next(this.ticks);
					});
				}
			}, 1000));
		}
	}
}