import { Entity } from '../../_models/entity';

export class CoreEntityCache<T extends Entity> {

	private itemById: Map<number, T> = new Map<number, T>();
	private items: T[];
	public getItem(id: number): T {
		return this.itemById.get(id);
	}
	public setItem(item: T) {
		this.itemById.set(item.id, item);
	}
	public getItems(): T[] {
		return this.items;
	}
	public setItems(items: T[]) {
		items.forEach(item => { this.setItem(item); });
		this.items = items;
	}
	public clear() {
		this.itemById = new Map<number, T>();
		this.items = null;
	}
}
