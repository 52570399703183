import { MapsAPILoader } from '@agm/core';
import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { AbmComponent } from 'angular-baidu-maps';
import { Subscription } from 'rxjs';
import { CoreCustomizationService } from '../customization/CoreCustomizationService';
import { MapVendor } from '../customization/MapVendor';
import { CoreMapCoordinates } from './CoreMapCoordinates';
import { CoreMapMarkerComponent } from './CoreMapMarkerComponent';
import { CoreMapPolylineComponent } from './CoreMapPolylineComponent';
import { CoreMapService } from './CoreMapService';

declare const BMap: any;

@Component({
	templateUrl: './CoreMapComponent.html',
	selector: 'app-core-map'
})
export class CoreMapComponent implements OnInit {
	@ViewChild('map', { read: addEventListener, static: true }) mapComp: AbmComponent;

	public mapIsLoaded: boolean = false;
	public mapVendor: MapVendor = MapVendor.Google;
	private _latitude: number = 0;
	private _longitude: number = 0;
	private _subscriptions = new Array<Subscription>();

	// baidu map properties
	private _baiduMap: any;

	@Input()
	public zoom: number = 2;

	@Input()
	public fitBounds: boolean = false;

	@Input()
	set latitude(latitude: number) {
		this._latitude = latitude;
		this.baiduMapCenterPanTo();
	}

	get latitude(): number {
		return this._latitude;
	}

	@Input()
	set longitude(longitude: number) {
		this._longitude = longitude;
		this.baiduMapCenterPanTo();
	}

	get longitude(): number {
		return this._longitude;
	}

	@Output()
	onMapLoadedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ContentChildren(CoreMapMarkerComponent) markers: QueryList<CoreMapMarkerComponent>;

	@ContentChildren(CoreMapPolylineComponent) polylines: QueryList<CoreMapPolylineComponent>;

	constructor(
		private mapsAPILoader: MapsAPILoader,
		public customizationService: CoreCustomizationService,
		private coreMapService: CoreMapService,
	) {
		this.mapVendor = customizationService.getSettings().mapVendor;
	}

	ngOnInit() {
		if (this.mapVendor === MapVendor.Google) {
			this.mapsAPILoader.load().then(() => {
				this.mapIsLoaded = true;
				this.onMapLoadedEvent.emit(true);
			}).catch(() => {
				this.mapIsLoaded = false;
			});
		}
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
	}

	ngAfterViewInit() {
		this._subscriptions.push(this.markers.changes.subscribe(markers => {
			this.baiduMapSetMarkers(markers);
		}));
		this._subscriptions.push(this.polylines.changes.subscribe(polylines => {
			this.baiduMapSetPolyLines(polylines);
		}));
		this.coreMapService.getParentMessages().subscribe((data) => {
			if (this._baiduMap) {
				if (data === 'markers') {
					this.markers.notifyOnChanges();
				}
				if (data === 'polylines') {
					this.polylines.notifyOnChanges();
				}
			}
		});
	}

	// baidu maps - settings

	onBaiduMapReady(map: any) {
		this._baiduMap = map;
		this.mapIsLoaded = true;
		map.addControl(new BMap.MapTypeControl());
		map.enableScrollWheelZoom(true);
		this.onBaiduMapLoaded();
	}

	onBaiduMapLoaded() {
		this.baiduMapCenterPanTo();
		this.markers.notifyOnChanges();
		this.polylines.notifyOnChanges();
		this.onMapLoadedEvent.emit(true);
	}

	baiduMapSetViewport() {
		if (this.fitBounds) {
			let points = [];
			this.markers.forEach(marker => {
				points.push(new BMap.Point(marker.longitude, marker.latitude));
			});
			this.polylines.forEach(polyline => {
				let pointArray = polyline.getPointsArray();
				pointArray.forEach(point => {
					points.push(point);
				});

			});
			this._baiduMap.setViewport(points);

		}
	}

	baiduMapCenterPanTo() {
		if (this._baiduMap) {
			if (this.fitBounds) {
				this.baiduMapSetViewport();
			} else {
				if (this.latitude && this.longitude && this.zoom) {
					this._baiduMap.centerAndZoom(new BMap.Point(this.longitude, this.latitude), this.zoom);
				} else {
					this._baiduMap.centerAndZoom(new BMap.Point(70, 30), 2);
				}
			}
		}
	}

	baiduMapSetPolyLines(polylines: QueryList<CoreMapPolylineComponent>) {
		if (this._baiduMap) {
			polylines.forEach(polyline => {
				let baiduPolylineObject = new BMap.Polyline(polyline.getPointsArray(),
					{ strokeColor: "black", strokeWeight: 3, strokeOpacity: 1 }
				);
				this._baiduMap.addOverlay(baiduPolylineObject);
			});
		}

		// NOT YET IMPLEMENTED -> editable polyline
	}

	baiduMapSetMarkers(markers: QueryList<CoreMapMarkerComponent>) {
		if (this._baiduMap) {
			this._baiduMap.clearOverlays();
			markers.forEach(marker => {
				this.onBaiduSetMarker(marker);
			});
		}
	}

	onBaiduSetMarker(marker: CoreMapMarkerComponent) {
		if (this._baiduMap) {
			let icon = new BMap.Icon(marker.iconUrl,);
			if (marker.markerDraggable) {
				icon = new BMap.Icon(marker.iconUrl, new BMap.Size(32, 32), {});
			} else {
				icon = new BMap.Icon(marker.iconUrl, {});
			}
			let markerOptions = {
				title: marker.title ? marker.title : null,
				icon: marker.iconUrl ? icon : null
			};
			let baiduMarker = new BMap.Marker(new BMap.Point(marker.longitude, marker.latitude), markerOptions);
			if (marker.markerDraggable) {
				baiduMarker.enableDragging()
				baiduMarker.addEventListener("dragend", function (e: any) {
					let coordinates = new CoreMapCoordinates();
					coordinates.latitude = e.point.lat;
					coordinates.longitude = e.point.lng;
					marker.dragEnd.emit(coordinates);
				})
			}
			this._baiduMap.addOverlay(baiduMarker);
		}
	}

	styles: any[] =   [
		{
			"featureType": "administrative",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#476276"
				},
				{
					"lightness": "53"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "simplified"
				},
				{
					"saturation": "-100"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text",
			"stylers": [
				{
					"gamma": "0.75"
				}
			]
		},
		{
			"featureType": "administrative.province",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#787878"
				},
				{
					"lightness": "30"
				}
			]
		},
		{
			"featureType": "administrative.province",
			"elementType": "geometry",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#B9C2C8"
				},
				{
					"saturation": "-40"
				}
			]
		},
		{
			"featureType": "administrative.province",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "administrative.locality",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "administrative.locality",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "administrative.neighborhood",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "simplified"
				},
				{
					"lightness": "17"
				}
			]
		},
		{
			"featureType": "administrative.land_parcel",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"color": "#f8f8f8"
				}
			]
		},
		{
			"featureType": "landscape.man_made",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "landscape.natural",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"saturation": "-100"
				},
				{
					"lightness": "-37"
				}
			]
		},
		{
			"featureType": "landscape.natural",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"saturation": "-100"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				},
				{
					"saturation": "-100"
				},
				{
					"lightness": "80"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "off"
				},
				{
					"saturation": "-100"
				},
				{
					"lightness": "0"
				}
			]
		},
		{
			"featureType": "poi.attraction",
			"elementType": "geometry",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#93C9C3"
				},
				{
					"visibility": "on"
				},
				{
					"saturation": "-15"
				},
				{
					"lightness": "62"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "off"
				},
				{
					"lightness": "20"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"lightness": "20"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"saturation": "-100"
				},
				{
					"gamma": "1.00"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				},
				{
					"saturation": "-100"
				},
				{
					"gamma": "0.50"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#E0E8EF"
				},
				{
					"saturation": "-100"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#476276"
				},
				{
					"lightness": "35"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#476276"
				},
				{
					"lightness": "40"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#ffffff"
				},
				{
					"lightness": "25"
				}
			]
		},
		{
			"featureType": "transit.line",
			"elementType": "geometry",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#8DD3FB"
				},
				{
					"lightness": "42"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#8DD3FB"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{
					"saturation": "-53"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#476276"
				},
				{
					"lightness": "42"
				},
				{
					"gamma": "0.55"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"lightness": "81"
				}
			]
		}
	]  
}
