import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IncotermsLookupModel } from 'app/generated/backend/trade/api/incoterms-lookup-model';
import { BaseIdInputTypeaheadComponent } from 'app/_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent';
import { CoreIncotermsLookupService } from './CoreIncotermsLookupService';


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreIncotermsLookupComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreIncotermsLookupComponent),
	multi: true
};

@Component({
	selector: 'app-incoterms-lookup',
    templateUrl: '../../_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreIncotermsLookupComponent extends BaseIdInputTypeaheadComponent<IncotermsLookupModel> {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false; 

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string;

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Output()
	itemSelected: EventEmitter<IncotermsLookupModel> = new EventEmitter<IncotermsLookupModel>();

	constructor(
		protected service: CoreIncotermsLookupService
	) {
		super(service);
	}

	toDisplayText(item: IncotermsLookupModel): string {
		if (!item) {
			return null;
		}
		if (item.code) {
			return item.code + ' ' + item.name;
		}
		return item.name;
	}

	toTitleText(item: IncotermsLookupModel): string {
		if (!item) {
			return null;
		}
		return item.name;
	}
}
