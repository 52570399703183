import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { CoreCustomizationService } from 'app/core/customization/CoreCustomizationService';
import { ContentAccess } from 'app/generated/backend/content/api/content-access';
import { ContentBodyFormat } from 'app/generated/backend/content/api/content-body-format';
import { ContentTranslationModel } from 'app/generated/backend/content/api/content-translation-model';
import { ContentTranslationHandlerService } from 'app/generated/backend/content/service/content-translation-handler';
import { AdminAccessControl } from 'app/generated/backend/types/admin-access-control';
import { PermissionFlags } from 'app/generated/backend/types/permission-flags';
import { Observable, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';



@Component({
	selector: 'app-content',
	templateUrl: './CoreContentComponent.html'
})
export class CoreContentComponent implements OnInit, OnDestroy {

	private _contentAccess: ContentAccess;
	private _loadedContentAccess: ContentAccess;
	private _uri: string;
	private languageIso2: string;

	public contentId: number;

	get uri(): string {
		return this._uri;
	}

	@Input()
	set uri(uri: string) {
		if (uri !== this._uri) {
			this._uri = uri;
			this.updateContent();
		}
	}
	private _replacements: Map<string, string> = new Map<string, string>();

	@Input()
	set replacements(replacements: Map<string, string>) {
		if (replacements !== this._replacements) {
			this._replacements = replacements;
			this.setReplacements();
		}
	}

	@Input()
	public cta = false;

	@Input()
	public page = false;

	@Input()
	public card = false;

	@Input()
	public templateName: string = '';

	@Output('error')
	public errorEmitter: EventEmitter<string> = new EventEmitter<string>();
	@Output('loaded')
	public loadedEmitter: EventEmitter<ContentTranslationModel> = new EventEmitter<ContentTranslationModel>();
	@Output('redirect')
	public redirectEmitter: EventEmitter<string> = new EventEmitter<string>();

	public content: ContentTranslationModel;
	public contentBodySafeHtml: SafeHtml;
	protected subscriptions = new Array<Subscription>();
	translateOutput: Observable<any>;
	translateWorker = new Subject<void>();
	translateWorker$ = this.translateWorker.asObservable();
	constructor(
		private contentTranslateHandlerService: ContentTranslationHandlerService,
		private customizationService: CoreCustomizationService,
		private titleService: Title,
		private metaService: Meta,
		private authenticationService: CoreAuthenticationService,
		private domSanitizer: DomSanitizer,
		private router: Router
	) {
		this.translateOutput = this.translateWorker$.pipe(
			switchMap(() => {
				return this.contentTranslateHandlerService.get(this.languageIso2, this.uri, this._contentAccess, this.templateName)
			}),
		);
		this.translateOutput.subscribe(
			response => {
				this.loadData(response.data);
			},
			error => {
				console.error(error);
				this.errorEmitter.emit(error);
			});
	}

	getContentAccess(): ContentAccess {
		if (this.authenticationService.hasAdminPermission(AdminAccessControl.Content, PermissionFlags.Read)) {
			return ContentAccess.Confidential;
		}
		if (this.authenticationService.getUserId() > 0) {
			return ContentAccess.Restricted;
		}
		return ContentAccess.Public;
	}

	ngOnInit() {
		this._contentAccess = this.getContentAccess();
		this.subscriptions.push(this.customizationService.changed.subscribe(settings => {
			if (settings.locale.languageIso2 !== this.languageIso2) {
				this.languageIso2 = settings.locale.languageIso2;
				this.updateContent();
			}
		}));
		this.subscriptions.push(this.authenticationService.authenticationChanged.subscribe(authentication => {
			if (authentication) {
				this.updateContentAccess();
			}
		}));
	}
	ngOnDestroy() {
		if (this.page) {
			this.titleService.setTitle(null);
			this.metaService.updateTag({ name: 'description', content: null });
			this.metaService.updateTag({ name: 'keywords', content: null });
		}
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
	updateContentAccess() {
		const contentAccess = this.getContentAccess();
		if (contentAccess !== this._contentAccess) {
			this._contentAccess = contentAccess;
		}
		this.updateContent();
	}
	private _loadedUri: string;
	private _loadedLanguageIso2: string;
	setReplacements(): void {
		this.contentBodySafeHtml = null;
		if (this.content) {
			if (this.content.body && this._replacements) {
				let output = "";
				const input = this.content.body;

				let p = input.indexOf("{#");
				if (p < 0) {
					output = input;
				} else {
					output += input.substr(0, p);
					while (true) {
						let e = this.content.body.indexOf("#}", p);
						if (e < p) {
							output += input.substr(p);
							break;
						}
						let key = this.content.body.substr(p + 2, e - p - 2);
						const encode = key.startsWith('uri;');
						if (encode) {
							key = key.substr(4);
						}
						let val = this._replacements.get(key);
						if (val) {
							if (encode) {
								val = encodeURIComponent(val);
							}
							output += val.toString();
						}
						e += 2;
						p = input.indexOf("{#", e);
						if (p < e) {
							output += input.substr(e);
							break;
						}
						output += input.substr(e, p - e);
					}
				}
				this.content.body = output;
			}
			if (this.content.bodyFormat === ContentBodyFormat.Html) {
				this.contentBodySafeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.content.body);
			}
		}
	}
	updateContent(): void {
		if (this.customizationService.showKeys) {
			this.content = new ContentTranslationModel();
			this.content.body = this.uri;
			this.contentBodySafeHtml = null;
		} else if (
			this.authenticationService.isValidated() &&
			this.languageIso2 &&
			this.uri &&
			(this.uri !== this._loadedUri || this.languageIso2 !== this._loadedLanguageIso2 || this._contentAccess !== this._loadedContentAccess)) {
			this._loadedLanguageIso2 = this.languageIso2;
			this._loadedUri = this.uri;
			this._loadedContentAccess = this._contentAccess;
			this.translateWorker.next();
		} else {
			this.setReplacements();
		}
	}

	private loadData(content: ContentTranslationModel) {
		if (content.redirectUri && this.page) {
			this.redirectEmitter.emit(content.redirectUri);
		} else if (content.languageIso2 && content.languageIso2.length && this._loadedLanguageIso2 !== content.languageIso2) {
			console.error('Out-of-band content', this._loadedLanguageIso2, content.languageIso2);
		}
		else if (content.uri && content.uri.length && this._loadedUri !== content.uri) {
			console.error('Out-of-band content', this._loadedUri, content.uri);
		}
		else if (this._loadedContentAccess !== content.access) {
			console.error('Out-of-band content', this._loadedContentAccess, content.access);
		}
		else {
			this.content = content;
			if (this.authenticationService.hasAdminPermission(AdminAccessControl.Content, PermissionFlags.Update)) {
				this.contentId = this.content.id;
			} else {
				this.contentId = null;
			}
			this.setReplacements();
			if (this.page) {
				if (this.content.title) {
					this.titleService.setTitle(this.content.title);
				}
				if (this.content.description) {
					this.metaService.updateTag({ name: 'description', content: this.content.description });
				}
				if (this.content.keywords) {
					this.metaService.updateTag({ name: 'keywords', content: this.content.keywords });
				}
			}
			this.loadedEmitter.emit(this.content);
		}
	}

	navigateToContent() {
		window.open(this.router.serializeUrl(this.router.createUrlTree(['admin', 'cms', 'content', this.contentId])), '_blank');
	}
}
