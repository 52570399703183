import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CoreCustomizationService } from '../customization/CoreCustomizationService';


@Pipe({
	name: 'localizedDateTime',
})
export class CoreLocalizedDateTimePipe implements PipeTransform {
	constructor(private customizationService: CoreCustomizationService) {

	}

	transform(value: any, ...args: any[]) {

		if (!value) {
			return null;
		}

		const locale = this.customizationService.getLocale();
		const datePipe = new DatePipe(locale.identifier);
		return datePipe.transform(value, locale.dateTimeFormat);
	}
}