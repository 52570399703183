import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';
import { VerifiedGrossMassRequestModel } from 'app/generated/backend/shipment/api/verified-gross-mass-request-model';

@Injectable()
export class VerifiedGrossMassHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	put(
		number: string,
		id: number,
		request: VerifiedGrossMassRequestModel
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		return this.http.put<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/shippingInstruction/VerifiedGrossMass/${id}`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		VerifiedGrossMassHandlerService
	]
})
export class VerifiedGrossMassHandlerModule { }
