
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../../../core/common-material/core-common-material.module';
import { UserMenuAlternativesComponent } from './user-menu-alternatives.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		CommonMaterialModule
	],
	declarations: [UserMenuAlternativesComponent],
	exports: [UserMenuAlternativesComponent]
})
export class UserMenuAlternativesModule { }
