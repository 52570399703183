import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum PartyAccessControl {
	Profile = 0,
	Locations = 1,
	Charges = 2,
	ImportRates = 3,
	ExportRates = 4,
	FclLandRates = 5,
	FclSeaRates = 6,
	Vessels = 7,
	Shipments = 8,
	ShipmentServices = 9,
	Users = 10,
	Parties = 11,
	FclSeaOriginRates = 12,
	FclSeaDestinationRates = 13,
	RailSchedules = 14,
	Transforms = 16,
	NotificationRules = 17,
	ShipmentPartyContainers = 18,
	Places = 19,
	SeaServices = 20,
	FclScheduledRoutes = 21,
	Messages = 22,
	LclScheduledRoutes = 23,
	ShipmentTrackingStatus = 24,
	PartyTasks = 25,
	RateContracts = 26,
	Currencies = 27,
	ShipmentServiceContainers = 28,
	AccountingSetup = 29,
	Analytics = 30,
	FclRailRates = 32,
	FclRailOriginRates = 33,
	FclRailDestinationRates = 34,
	PartyShipmentVoyages = 35,
	PartyShipmentDocuments = 36,
	PartyShipmentCargo = 37,
	PurchaseOrder = 38,
	SalesOrder = 39,
	GoodsReceipt = 40,
	PartyShipmentReleases = 41,
	PartyShipmentBookings = 42,
	LtlLandRates = 43,
	LclSeaRates = 44,
	LclSeaOriginRates = 45,
	LclSeaDestinationRates = 46,
	LclRailRates = 47,
	LclRailOriginRates = 48,
	LclRailDestinationRates = 49,
	Reserved50 = 50,
	PartyPackage = 51,
	TradeLane = 52,
	PartyContact = 53,
	IntegrationEvents = 54,
	Reserved55 = 55,
	Reserved56 = 56,
	Reserved57 = 57,
	ContainerCodes = 58,
	Team = 59,
	PartyPackageTypes = 60,
	ShipmentBookings = 61,
	TariffDayCharge = 62,
	MaxAccessControl = 63,
}

@Pipe({
name: 'partyAccessControl'
})
export class PartyAccessControlPipe extends BasePipe<PartyAccessControl> implements PipeTransform {
	static prefix = 'Backend.Types.PartyAccessControl.';
	static keys = [
		PartyAccessControl.Profile,
		PartyAccessControl.Locations,
		PartyAccessControl.Charges,
		PartyAccessControl.ImportRates,
		PartyAccessControl.ExportRates,
		PartyAccessControl.FclLandRates,
		PartyAccessControl.FclSeaRates,
		PartyAccessControl.Vessels,
		PartyAccessControl.Shipments,
		PartyAccessControl.ShipmentServices,
		PartyAccessControl.Users,
		PartyAccessControl.Parties,
		PartyAccessControl.FclSeaOriginRates,
		PartyAccessControl.FclSeaDestinationRates,
		PartyAccessControl.RailSchedules,
		PartyAccessControl.Transforms,
		PartyAccessControl.NotificationRules,
		PartyAccessControl.ShipmentPartyContainers,
		PartyAccessControl.Places,
		PartyAccessControl.SeaServices,
		PartyAccessControl.FclScheduledRoutes,
		PartyAccessControl.Messages,
		PartyAccessControl.LclScheduledRoutes,
		PartyAccessControl.ShipmentTrackingStatus,
		PartyAccessControl.PartyTasks,
		PartyAccessControl.RateContracts,
		PartyAccessControl.Currencies,
		PartyAccessControl.ShipmentServiceContainers,
		PartyAccessControl.AccountingSetup,
		PartyAccessControl.Analytics,
		PartyAccessControl.FclRailRates,
		PartyAccessControl.FclRailOriginRates,
		PartyAccessControl.FclRailDestinationRates,
		PartyAccessControl.PartyShipmentVoyages,
		PartyAccessControl.PartyShipmentDocuments,
		PartyAccessControl.PartyShipmentCargo,
		PartyAccessControl.PurchaseOrder,
		PartyAccessControl.SalesOrder,
		PartyAccessControl.GoodsReceipt,
		PartyAccessControl.PartyShipmentReleases,
		PartyAccessControl.PartyShipmentBookings,
		PartyAccessControl.LtlLandRates,
		PartyAccessControl.LclSeaRates,
		PartyAccessControl.LclSeaOriginRates,
		PartyAccessControl.LclSeaDestinationRates,
		PartyAccessControl.LclRailRates,
		PartyAccessControl.LclRailOriginRates,
		PartyAccessControl.LclRailDestinationRates,
		PartyAccessControl.Reserved50,
		PartyAccessControl.PartyPackage,
		PartyAccessControl.TradeLane,
		PartyAccessControl.PartyContact,
		PartyAccessControl.IntegrationEvents,
		PartyAccessControl.Reserved55,
		PartyAccessControl.Reserved56,
		PartyAccessControl.Reserved57,
		PartyAccessControl.ContainerCodes,
		PartyAccessControl.Team,
		PartyAccessControl.PartyPackageTypes,
		PartyAccessControl.ShipmentBookings,
		PartyAccessControl.TariffDayCharge,
		PartyAccessControl.MaxAccessControl];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: PartyAccessControl): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case PartyAccessControl.Profile: return 'Profile';
			case PartyAccessControl.Locations: return 'Locations';
			case PartyAccessControl.Charges: return 'Charges';
			case PartyAccessControl.ImportRates: return 'ImportRates';
			case PartyAccessControl.ExportRates: return 'ExportRates';
			case PartyAccessControl.FclLandRates: return 'FclLandRates';
			case PartyAccessControl.FclSeaRates: return 'FclSeaRates';
			case PartyAccessControl.Vessels: return 'Vessels';
			case PartyAccessControl.Shipments: return 'Shipments';
			case PartyAccessControl.ShipmentServices: return 'ShipmentServices';
			case PartyAccessControl.Users: return 'Users';
			case PartyAccessControl.Parties: return 'Parties';
			case PartyAccessControl.FclSeaOriginRates: return 'FclSeaOriginRates';
			case PartyAccessControl.FclSeaDestinationRates: return 'FclSeaDestinationRates';
			case PartyAccessControl.RailSchedules: return 'RailSchedules';
			case PartyAccessControl.Transforms: return 'Transforms';
			case PartyAccessControl.NotificationRules: return 'NotificationRules';
			case PartyAccessControl.ShipmentPartyContainers: return 'ShipmentPartyContainers';
			case PartyAccessControl.Places: return 'Places';
			case PartyAccessControl.SeaServices: return 'SeaServices';
			case PartyAccessControl.FclScheduledRoutes: return 'FclScheduledRoutes';
			case PartyAccessControl.Messages: return 'Messages';
			case PartyAccessControl.LclScheduledRoutes: return 'LclScheduledRoutes';
			case PartyAccessControl.ShipmentTrackingStatus: return 'ShipmentTrackingStatus';
			case PartyAccessControl.PartyTasks: return 'PartyTasks';
			case PartyAccessControl.RateContracts: return 'RateContracts';
			case PartyAccessControl.Currencies: return 'Currencies';
			case PartyAccessControl.ShipmentServiceContainers: return 'ShipmentServiceContainers';
			case PartyAccessControl.AccountingSetup: return 'AccountingSetup';
			case PartyAccessControl.Analytics: return 'Analytics';
			case PartyAccessControl.FclRailRates: return 'FclRailRates';
			case PartyAccessControl.FclRailOriginRates: return 'FclRailOriginRates';
			case PartyAccessControl.FclRailDestinationRates: return 'FclRailDestinationRates';
			case PartyAccessControl.PartyShipmentVoyages: return 'PartyShipmentVoyages';
			case PartyAccessControl.PartyShipmentDocuments: return 'PartyShipmentDocuments';
			case PartyAccessControl.PartyShipmentCargo: return 'PartyShipmentCargo';
			case PartyAccessControl.PurchaseOrder: return 'PurchaseOrder';
			case PartyAccessControl.SalesOrder: return 'SalesOrder';
			case PartyAccessControl.GoodsReceipt: return 'GoodsReceipt';
			case PartyAccessControl.PartyShipmentReleases: return 'PartyShipmentReleases';
			case PartyAccessControl.PartyShipmentBookings: return 'PartyShipmentBookings';
			case PartyAccessControl.LtlLandRates: return 'LtlLandRates';
			case PartyAccessControl.LclSeaRates: return 'LclSeaRates';
			case PartyAccessControl.LclSeaOriginRates: return 'LclSeaOriginRates';
			case PartyAccessControl.LclSeaDestinationRates: return 'LclSeaDestinationRates';
			case PartyAccessControl.LclRailRates: return 'LclRailRates';
			case PartyAccessControl.LclRailOriginRates: return 'LclRailOriginRates';
			case PartyAccessControl.LclRailDestinationRates: return 'LclRailDestinationRates';
			case PartyAccessControl.Reserved50: return 'Reserved50';
			case PartyAccessControl.PartyPackage: return 'PartyPackage';
			case PartyAccessControl.TradeLane: return 'TradeLane';
			case PartyAccessControl.PartyContact: return 'PartyContact';
			case PartyAccessControl.IntegrationEvents: return 'IntegrationEvents';
			case PartyAccessControl.Reserved55: return 'Reserved55';
			case PartyAccessControl.Reserved56: return 'Reserved56';
			case PartyAccessControl.Reserved57: return 'Reserved57';
			case PartyAccessControl.ContainerCodes: return 'ContainerCodes';
			case PartyAccessControl.Team: return 'Team';
			case PartyAccessControl.PartyPackageTypes: return 'PartyPackageTypes';
			case PartyAccessControl.ShipmentBookings: return 'ShipmentBookings';
			case PartyAccessControl.TariffDayCharge: return 'TariffDayCharge';
			case PartyAccessControl.MaxAccessControl: return 'MaxAccessControl';
			default: return null;
		}
	}
	transform(value: PartyAccessControl, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(PartyAccessControlPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		PartyAccessControlPipe
	],
	exports: [
		PartyAccessControlPipe
	],
	providers: [
		PartyAccessControlPipe
	]
})
export class PartyAccessControlModule { }
