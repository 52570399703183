import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ContainerSizeInputModule } from 'app/core/container-size-input/ContainerSizeInputModule';
import { ContainerTypeInputModule } from 'app/core/container-type-input/ContainerTypeInputModule';
import { CoreDateTimeModule } from 'app/core/date-time/CoreDateTimeModule';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { CoreNumberInputModule } from 'app/core/number/CoreNumberInputModule';
import { CorePackageTypeModule } from 'app/core/package-type/CorePackageTypeModule';
import { PackageTypeDetailHandlerModule } from 'app/generated/backend/trade/service/package-type-detail-handler';
import { ContainerTypeModule } from 'app/generated/backend/types/container-type';
import { ShipmentPackagePropertiesModule } from 'app/generated/backend/types/shipment-package-properties';
import { ShipmentTypeModule } from 'app/generated/backend/types/shipment-type';
import { ShipmentCargoGroupsComponent } from 'app/shipment/cargo-groups/ShipmentCargoGroupsComponent';
import { CoreButtonModule } from '../../core/button/CoreButtonModule';
import { CommonMaterialModule } from '../../core/common-material/core-common-material.module';
import { CoreHarmonizedCommodityModule } from '../../core/harmonized-commodity/CoreHarmonizedCommodityModule';
import { CoreMeasureModule } from '../../core/measure/CoreMeasureModule';
import { PanelModule } from '../../core/panel/PanelModule';
import { ContainerLookupHandlerModule } from '../../generated/backend/shipment/service/container-lookup-handler';
import { ContainerSizeModule } from '../../generated/backend/types/container-size';

@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		ContainerSizeInputModule,
		ContainerSizeModule,
		ContainerTypeInputModule,
		ContainerTypeModule,
		ContainerLookupHandlerModule,
		CoreButtonModule,
		CoreDateTimeModule,
		CoreHarmonizedCommodityModule,
		CoreMeasureModule,
		CorePackageTypeModule,
		FormsModule,
		HelpPopupModule,
		CoreNumberInputModule,
		PackageTypeDetailHandlerModule,
		PanelModule,
		ShipmentPackagePropertiesModule,
		ShipmentTypeModule,
		TranslateModule,
	],
	declarations: [ShipmentCargoGroupsComponent],
	exports: [
		ShipmentCargoGroupsComponent
	]
})
export class ShipmentCargoGroupsModule { }
