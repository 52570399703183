import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class CoreTranslateLoader implements TranslateLoader {

	constructor(private httpClient: HttpClient) { }
	getTranslation(lang: string): Observable<any> {
		if (lang === 'keys') {
			return new Observable(
				(observer: any) => {
					observer.next({});
				});
		}
		const p = lang.indexOf('-');
		if (p !== -1) {
			lang = lang.substr(0, p);
		}
		const apiAddress = '/api/v1/content/translations/' + lang;
		return new Observable(observer => {
			this.httpClient.get(apiAddress).subscribe(
				(res: Response) => {
					observer.next(res);
					observer.complete();
				},
				error => {
					console.error(error);
					//  failed to retrieve from api, switch to local
					this.httpClient.get('/assets/i18n/en.json').subscribe((res: Response) => {
						observer.next(res);
						observer.complete();
					},
						error => {
							observer.error(error);
						});
				}
			);
		});
	}
}