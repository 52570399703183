import { HttpErrorResponse } from '@angular/common/http';
import { Directive, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { MatchableEntity } from 'app/_models/matchable-entity';
import { Subscription } from 'rxjs';
import { BaseIdInputService } from '../BaseIdInputService';
import { SimpleErrorStateMatcher } from '../simple-error-state-matcher';
import { AutocompleteFilterPipe } from './autocomplete/AutocompleteFilterPipe';

const noop = () => {
	// This is intentional
};

@Directive()
export abstract class BaseIdInputTypeaheadComponent<T extends MatchableEntity> implements ControlValueAccessor, Validator, OnInit, OnDestroy {

	public abstract required: boolean;
	public abstract disabled: boolean;
	public abstract label: string;
	public abstract iconClass: string;
	public abstract placeholder: string;
	public abstract hint: string;
	public abstract helpUri: string;
	public abstract maxOptions: number;
	public abstract addNew: boolean;
	public itemSelected: EventEmitter<T> = new EventEmitter<T>();

	public loading: boolean = true;
	private _innerValue: number;
	public model: string = '';
	public allItems: T[];
	protected subscriptions = new Array<Subscription>();
	public errorStateMatcher = new SimpleErrorStateMatcher();
	private error: string;
	public currentIndex = null;
	public currentSelectedId = null;

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): number {
		return this._innerValue;
	};

	// set accessor including call the onchange callback
	set value(id: number) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
		}
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	// From ControlValueAccessor interface
	writeValue(id: number) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: UntypedFormControl): ValidationErrors | null {
		if (this.error) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = this.error;
			return {
				jsonParseError: {
					valid: false,
				}
			};
		}
		if (!this.value && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				jsonParseError: {
					valid: false,
				}
			};
		}
		if (!this.disabled && this.value && this.allItems && (!this.model || !this.model.length)) {
			let found = false;
			this.allItems.forEach(eachItem => {
				if (this.value === eachItem.id) {
					found = true;
				}
			});
			if (!found) {
				this.errorStateMatcher.valid = false;
				this.errorStateMatcher.errorKey = 'error.valueNotFound';
				return {
					jsonParseError: {
						valid: false,
					}
				};
			}
		}
		this.errorStateMatcher.valid = true;
		return null;
	}

	constructor(
		protected service: BaseIdInputService<T>
	) { }

	load() {
		this.loading = true;
		this.error = null;
		this.errorStateMatcher.valid = true;
		this.subscriptions.push(this.service.getItems().subscribe(items => {
			this.allItems = this.sortItems(this.filterItems(items));
			this.selectItem(this._innerValue);
			this.loading = false;
			this.onLoaded();
		},
			error => {
				const httpErrorResponse = error as HttpErrorResponse;
				if (httpErrorResponse && httpErrorResponse.status) {
					this.error = "Http.Status." + httpErrorResponse.status.toString() + ".label";
				} else {
					this.error = "Http.Status.500.label";
				}
				this.validate(null);
				this.loading = false;
			}));
	}

	onLoaded() {

	}

	ngOnInit() {
		this.load();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	toDisplayText(item: T): string {
		if (!item) {
			return null;
		}
		return item.match;
	}

	toTitleText(item: T): string {
		return this.toDisplayText(item);
	}

	toOptionText(item: T): string {
		return this.toDisplayText(item);
	}

	sortItems(items: T[]): T[] {
		return items;
	}

	filterItems(items: T[]): T[] {
		return items;
	}

	onItemSelected(option: any) {
		if (option === 'addNew') {
			this.onAdd()
		} else {
			this.selectItem(option);
			this.allItems.forEach(eachItem => {
				if (eachItem.id === option) {
					this.itemSelected.emit(eachItem);
				}
			});
		}
	}

	selectItem(id: number) {
		if (this.allItems) {
			if (id) {
				this.value = id;
				this.allItems.forEach(eachItem => {
					if (eachItem.id === this.value) {
						this.model = this.toDisplayText(eachItem);
					}
				});
				this.error = null;
			} else {
				this.value = null;
				this.model = '';
			}
		}
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	modelChange() {
		if (this.model.trim() === '') {
			this.value = null;
			this.itemSelected.emit(null);
		}
		if (this.value && this.model) {
			this.allItems.forEach(eachItem => {
				if (eachItem.id === this.value) {
					if (this.toDisplayText(eachItem) === this.model) {
						this.error = null;
					} else {
						this.error = 'error.notAValidOption';
					}
				}
			});
		}
		this.validate(null);
		this.currentIndex = null;
		this.currentSelectedId = null;
		this.onChangeCallback(this._innerValue);
	}

	getOptionValue(option: T): number {
		return option.id;
	}

	closeOption(isSelectionNeeded: boolean) {
		this.error = null;
		if (!isSelectionNeeded) {
			this.currentSelectedId = null;
			this.currentIndex = null;
		}
		if (this.model && this.model.trim() !== '') {
			const autocompleteFilterPipe = new AutocompleteFilterPipe();
			let filteredList = autocompleteFilterPipe.transform(this.allItems, this.model, this.maxOptions)
			if (filteredList && ((filteredList.length === 1 && isSelectionNeeded) || (this.currentSelectedId))) {
				if (filteredList.length === 1 && isSelectionNeeded) {
					this.selectItem(filteredList[0].id);
					this.itemSelected.emit(filteredList[0]);
				} else if (this.currentSelectedId) {
					const item = filteredList.filter((filteredItem: { id: any; }) => filteredItem.id === this.currentSelectedId);
					if (item && item.length) {
						this.selectItem(item[0].id);
						this.itemSelected.emit(item[0]);
					}
				}
			} else {
				this.error = 'error.notAValidOption';
			}
		}
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	onClear() {
		this.value = null;
		this.validate(null);
		this.onChangeCallback(this._innerValue);
		this.itemSelected.emit(null);
		this.model = '';
		this.currentIndex = null;
		this.currentSelectedId = null;
	}

	onAdd() {

	}

	onKeyDownEvent(event: any) {
		let items: T[];
		const autocompleteFilterPipe = new AutocompleteFilterPipe();
		let filteredList = autocompleteFilterPipe.transform(this.allItems, this.model, this.maxOptions)
		if (filteredList && filteredList.length) {
			items = filteredList;
		} else {
			items = this.allItems;
		}
		if (!items || !items.length) {
			return;
		}
		if (event.code === 'ArrowDown') {
			if (this.currentIndex === null) {
				if (this.addNew && this.currentSelectedId !== 'addNew') {
					this.currentSelectedId = 'addNew';
				} else {
					this.currentIndex = 0;
					this.currentSelectedId = null;
				}
			} else if (items.length && items.length - 1 > this.currentIndex) {
				this.currentIndex = this.currentIndex + 1;
			}
		} else if (event.code === 'ArrowUp') {
			if (this.currentIndex > 0) {
				this.currentIndex = this.currentIndex - 1;
				this.currentSelectedId = null;
			} else if (this.currentIndex === 0 && this.addNew) {
				this.currentSelectedId = 'addNew';
				this.currentIndex = null;
				const element = document.getElementById(this.currentSelectedId);
				if (element) {
					element.scrollIntoView({ block: 'nearest' });
				}
			}
		}
		this.HandleItemSelection(event, items);
	}

	private HandleItemSelection(event: any, items: T[]) {
		if (this.currentSelectedId === 'addNew') {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				this.onAdd();
			}
		} else if (this.currentIndex !== null) {
			const currenctObject = items[this.currentIndex];
			if (!currenctObject) {
				return;
			}
			if ((event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Tab')) {
				this.selectItem(items[this.currentIndex].id);
				this.itemSelected.emit(items[this.currentIndex]);
			}
			if (this.currentSelectedId === 'addNew') {
				return;
			}
			if (currenctObject) {
				this.currentSelectedId = currenctObject.id;
				const element = document.getElementById(this.currentSelectedId);
				if (element) {
					element.scrollIntoView({ block: 'nearest' });
				}
			}
		}
	}
}

