import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WidgetDataRequest } from 'app/generated/backend/widget/api/widget-data-request';
import { WidgetDataResponse } from 'app/generated/backend/widget/api/widget-data-response';

@Injectable()
export class WidgetDataHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	post(
		request: WidgetDataRequest
	): Observable<JsonResourceResponse<WidgetDataResponse>> {
		return this.http.post<JsonResourceResponse<WidgetDataResponse>>('api/v1/widget/data', request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		WidgetDataHandlerService
	]
})
export class WidgetDataHandlerModule { }
