<div class="popup-notification">
        <span class="alert-icon">
            <i *ngIf="type === 'error'" class="fal fa-fw fa-ban adn-color-alert"></i>
            <i *ngIf="type === 'info'" class="fal fa-fw fa-info-circle adn-color-accent-darker"></i>
            <i *ngIf="type === 'success'" class="fal fa-fw fa-check-circle adn-color-green"></i>
        </span>
        <span class="pop-up-message">
            {{message | translate}}
        </span>
        <span>
            <span class="close-icon">
                <i class="fal fa-times" (click)="close()"></i>
            </span>
        </span>
</div>