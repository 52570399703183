import { Router } from "@angular/router";

export class CoreUtilUrl {
    static navigateWithReturnUrl(router: Router, url: string, returnUrl: string) {
        router.navigate([url], { queryParams: { returnUrl: returnUrl } });
    }
    static navigate(router: Router, url: string) {
        const urlParts = url.split('?');
        if (urlParts.length === 1) {
            router.navigate([urlParts[0]]);
        } else if (urlParts.length === 2) {
            const params = urlParts[1].split('&');
            let pair = null;
            const queryParams = {};
            params.forEach((d) => {
                pair = d.split('=');
                queryParams[`${pair[0]}`] = pair[1];
            });
            router.navigate([urlParts[0]], { queryParams: queryParams });
        }
    };
}