import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreBinaryContentModule } from 'app/core/binary-content/CoreBinaryContentModule';
import { CommonMaterialModule } from '../../core/common-material/core-common-material.module';
import { UserMenuAlternativesModule } from './alternatives/user-menu-alternatives.module';
import { UserMenuComponent } from './UserMenuComponent';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		CommonMaterialModule,
		CoreBinaryContentModule,
		UserMenuAlternativesModule
	],
	declarations: [UserMenuComponent],
	exports: [UserMenuComponent]
})
export class UserMenuModule { }
