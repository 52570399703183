import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum ObjectClass {
	None = 0,
	Shipment = 1,
	ShipmentService = 2,
	ShipmentLocation = 3,
	ShipmentParty = 4,
	ShipmentBooking = 5,
	ShipmentRelease = 6,
	ShipmentContainer = 7,
	ShipmentDocument = 8,
	PartyTask = 9,
	ShipmentCargo = 10,
	PurchaseOrder = 11,
	PurchaseOrderItem = 12,
	SalesOrder = 13,
	SalesOrderItem = 14,
	GoodsReceipt = 15,
	GoodsReceiptItem = 16,
	ShipmentPackage = 17,
	ScheduledRoute = 18,
	ScheduledLeg = 19,
	Company = 20,
	ShipmentPackingList = 21,
	ShipmentInstruction = 22,
}

@Pipe({
name: 'objectClass'
})
export class ObjectClassPipe extends BasePipe<ObjectClass> implements PipeTransform {
	static prefix = 'Backend.Types.ObjectClass.';
	static keys = [
		ObjectClass.None,
		ObjectClass.Shipment,
		ObjectClass.ShipmentService,
		ObjectClass.ShipmentLocation,
		ObjectClass.ShipmentParty,
		ObjectClass.ShipmentBooking,
		ObjectClass.ShipmentRelease,
		ObjectClass.ShipmentContainer,
		ObjectClass.ShipmentDocument,
		ObjectClass.PartyTask,
		ObjectClass.ShipmentCargo,
		ObjectClass.PurchaseOrder,
		ObjectClass.PurchaseOrderItem,
		ObjectClass.SalesOrder,
		ObjectClass.SalesOrderItem,
		ObjectClass.GoodsReceipt,
		ObjectClass.GoodsReceiptItem,
		ObjectClass.ShipmentPackage,
		ObjectClass.ScheduledRoute,
		ObjectClass.ScheduledLeg,
		ObjectClass.Company,
		ObjectClass.ShipmentPackingList,
		ObjectClass.ShipmentInstruction];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ObjectClass): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ObjectClass.None: return 'None';
			case ObjectClass.Shipment: return 'Shipment';
			case ObjectClass.ShipmentService: return 'ShipmentService';
			case ObjectClass.ShipmentLocation: return 'ShipmentLocation';
			case ObjectClass.ShipmentParty: return 'ShipmentParty';
			case ObjectClass.ShipmentBooking: return 'ShipmentBooking';
			case ObjectClass.ShipmentRelease: return 'ShipmentRelease';
			case ObjectClass.ShipmentContainer: return 'ShipmentContainer';
			case ObjectClass.ShipmentDocument: return 'ShipmentDocument';
			case ObjectClass.PartyTask: return 'PartyTask';
			case ObjectClass.ShipmentCargo: return 'ShipmentCargo';
			case ObjectClass.PurchaseOrder: return 'PurchaseOrder';
			case ObjectClass.PurchaseOrderItem: return 'PurchaseOrderItem';
			case ObjectClass.SalesOrder: return 'SalesOrder';
			case ObjectClass.SalesOrderItem: return 'SalesOrderItem';
			case ObjectClass.GoodsReceipt: return 'GoodsReceipt';
			case ObjectClass.GoodsReceiptItem: return 'GoodsReceiptItem';
			case ObjectClass.ShipmentPackage: return 'ShipmentPackage';
			case ObjectClass.ScheduledRoute: return 'ScheduledRoute';
			case ObjectClass.ScheduledLeg: return 'ScheduledLeg';
			case ObjectClass.Company: return 'Company';
			case ObjectClass.ShipmentPackingList: return 'ShipmentPackingList';
			case ObjectClass.ShipmentInstruction: return 'ShipmentInstruction';
			default: return null;
		}
	}
	transform(value: ObjectClass, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ObjectClassPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ObjectClassPipe
	],
	exports: [
		ObjectClassPipe
	],
	providers: [
		ObjectClassPipe
	]
})
export class ObjectClassModule { }
