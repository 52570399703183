<div class="adn-form-field-no-label">
    <div class="adn-form-field-content">
        <input class="input-style" [(ngModel)]="model" [appAutocomplete]="autocomplete" autocomplete="none"
        (keydown)="onKeyDownEvent($event)" placeholder="{{'field.search.placeholder' | translate}}" (ngModelChange)="modelChange()" (focus)="onFocus()">
        <div class="input-icon"><span class="fal fa-fw"
                [ngClass]="{'fa-search': state===0, 'fa-spinner fa-spin': state===1, 'fa-exclamation-circle': state===2}"></span>
        </div>
    </div>
</div>

<app-autocomplete #autocomplete="appAutocomplete" (selectItem)="onItemSelected($event)" (closeOption)="closeOption()">
    <ng-template appAutocompleteContent>
        <ng-container *ngIf="allItems">
            <app-option *ngFor="let option of allItems" [value]="option" id={{option.path}}
                title="{{option.class | entityClass : 'label' | async}}"
                [ngClass]="{'adn-dropdwon-active': option.path === currentSelectedId, 'adn-dropdwon-inactive': option.path !== currentSelectedId}">
                {{option.label}}
            </app-option>
            <app-option class="no-result" *ngIf="noResults">{{'field.noResults.label' | translate}}</app-option>
        </ng-container>
    </ng-template>
</app-autocomplete>