import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryLookupModel } from 'app/generated/backend/locality/api/country-lookup-model';
import { BaseIdInputTypeaheadComponent } from 'app/_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent';
import { CoreCountryLookupService } from './CoreCountryLookupService';


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreCountryLookupComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreCountryLookupComponent),
	multi: true
};

@Component({
	selector: 'app-core-country-lookup',
	templateUrl: './CoreCountryLookupComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreCountryLookupComponent extends BaseIdInputTypeaheadComponent<CountryLookupModel> {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string = 'fa-flag-alt';

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Output()
	itemSelected: EventEmitter<CountryLookupModel> = new EventEmitter<CountryLookupModel>();

	constructor(
		protected service: CoreCountryLookupService
	) {
		super(service);
	}

	toDisplayText(item: CountryLookupModel): string {
		if (!item) {
			return null;
		}
		return item.name;
	}

	getIso2(value: number) {
		let iso2 = '';
		if (this.allItems) {
			this.allItems.forEach(eachItem => {
				if (eachItem.id === value) {
					iso2 = eachItem.iso2;
				}
			})
		}
		return iso2;
	}
}
