import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContainerSize, ContainerSizePipe } from 'app/generated/backend/types/container-size';
import { BaseEnumRadioGroupComponent } from 'app/_templates/BaseEnumRadioGroupComponent';
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ContainerSizeRadioGroupComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => ContainerSizeRadioGroupComponent),
	multi: true
};
@Component({
	selector: 'app-container-size-radio-group',
	templateUrl: '../../_templates/BaseEnumRadioGroupComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class ContainerSizeRadioGroupComponent extends BaseEnumRadioGroupComponent<ContainerSize> {

	@Input()
	labelKey: string;

	@Input()
	helpUri: string;

	constructor(
		containerSizePipe: ContainerSizePipe) {
		super([ContainerSize.Iso22, ContainerSize.Iso42, ContainerSize.Iso45], containerSizePipe);
	}

}
