import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdnavemLogoComponent } from './AdnavemLogoComponent';


@NgModule({
	imports: [
		CommonModule,
		RouterModule
	],
	exports: [AdnavemLogoComponent],
	declarations: [AdnavemLogoComponent]
})
export class AdnavemLogoModule { }
