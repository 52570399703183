<div *ngIf="label" class="adn-control-label">
  <span *ngIf="required" [ngClass]="{'text-danger': required && (!value || value === defaultValue)}">{{label}}&nbsp;*</span>
</div>
<div class="row-without-margin">
  <button *ngFor="let day of days; let i = index" mat-mini-fab [color]="getWeekday(i)=='-'?null:'accent'" type="button"
    (click)="toggleWeekday(i)" [disabled]="disabled" class="button-row-margin">
    {{day}}
  </button>
  <button mat-button *ngIf="value && !required && !disabled" mat-icon-button
    title="{{ 'field.clear.label' | translate}}" (click)="value=null" tabindex="-1">
    <span class="fal fa-fw fa-times-circle adn-color-primary"></span>
  </button>
  <app-help-popup-button *ngIf="helpUri" [uri]="helpUri"></app-help-popup-button>
</div>