import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorCodeModule } from '../../generated/backend/service/error-code';
import { CoreButtonModule } from '../button/CoreButtonModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { AlertPopupComponent } from './alert-popup-component';
import { AlertComponent } from './alert.component';


@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		CommonMaterialModule,
		ErrorCodeModule,
		CoreButtonModule
	],
	exports: [AlertComponent, AlertPopupComponent],
	declarations: [AlertComponent, AlertPopupComponent]
})
export class AlertModule { }
