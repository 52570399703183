import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from 'app/core/common-material/core-common-material.module';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { EmailInputComponent } from './EmailInputComponent';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		CommonMaterialModule,
		TranslateModule,
		HelpPopupModule
	],
	exports: [EmailInputComponent],
	declarations: [EmailInputComponent]
})
export class EmailModule { }
