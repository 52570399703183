import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceCodeClassPipe } from './service-code-class.pipe';


@NgModule({
	imports: [
		CommonModule
	],
	declarations: [ServiceCodeClassPipe],
	exports: [ServiceCodeClassPipe],
	providers: [ServiceCodeClassPipe]
})
export class ServiceCodeClassModule { }
