import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CoreContentModule } from 'app/core/content/CoreContentModule';
import { CoreButtonModule } from '../button/CoreButtonModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { LoadingModule } from '../loading/LoadingModule';
import { HelpPopupButtonComponent } from './help-popup-button.component';
import { HelpPopupDialogComponent } from './help-popup-dialog.component';


@NgModule({
    imports: [
        CommonMaterialModule,
        CommonModule,
        CoreButtonModule,
        CoreContentModule,
        LoadingModule,
        MatDialogModule,
        TranslateModule,
    ],
    exports: [HelpPopupButtonComponent, HelpPopupDialogComponent],
    declarations: [HelpPopupButtonComponent, HelpPopupDialogComponent]
})
export class HelpPopupModule { }
