import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CoreMeasureModule } from 'app/core/measure/CoreMeasureModule';
import { MediaTypeModule } from '../../generated/backend/types/media-type';
import { CoreButtonModule } from '../button/CoreButtonModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreBinaryButtonComponent } from './CoreBinaryButtonComponent';
import { CoreBinaryContentComponent } from './CoreBinaryContentComponent';
import { CoreBinaryContentUrlPipe } from './CoreBinaryContentUrlPipe';
import { FileDropDirective } from './FileDropListener';
@NgModule({
	imports: [
		CommonModule,
		CommonMaterialModule,
		TranslateModule,
		MediaTypeModule,
		CoreMeasureModule,
		CoreButtonModule
	],
	declarations: [CoreBinaryContentUrlPipe, FileDropDirective, CoreBinaryContentComponent, CoreBinaryButtonComponent],
	exports: [CoreBinaryContentUrlPipe, CoreBinaryContentComponent, CoreBinaryButtonComponent],
	providers: [CoreBinaryContentUrlPipe]
})
export class CoreBinaryContentModule { }
