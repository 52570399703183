<agm-map *ngIf="mapVendor===1 && mapIsLoaded && latitude && longitude" [latitude]="latitude" [longitude]="longitude"
    [zoom]="zoom" mapTypeControl="true" [fitBounds]="fitBounds" [styles]="styles">
    <agm-marker *ngFor="let marker of markers" [latitude]="marker.latitude" [longitude]="marker.longitude"
        [markerDraggable]="marker.markerDraggable" (dragEnd)="marker.onDragEnd($event)"
        (markerClick)="marker.onMarkerClick()" [title]="marker.title" [iconUrl]="marker.iconUrl ? marker.iconUrl : null"
        [agmFitBounds]="fitBounds"></agm-marker>
    <agm-polyline *ngFor="let polyline of polylines" [strokeColor]="polyline.strokeColor"
        [geodesic]="polyline.geodesic">
        <agm-polyline-point *ngFor="let point of polyline.points" [latitude]="point.latitude"
            [longitude]="point.longitude" [agmFitBounds]="fitBounds">
        </agm-polyline-point>
    </agm-polyline>
</agm-map>
<abm-map #map *ngIf="mapVendor===2" (ready)="onBaiduMapReady($event)">
</abm-map>