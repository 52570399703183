import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum MediaType {
	ApplicationOctetStream = 0,
	ApplicationPdf = 1,
	ApplicationMsWord = 2,
	ApplicationVndMsExcel = 3,
	ApplicationX509CaCert = 4,
	ApplicationEdifact = 5,
	ApplicationXml = 6,
	TextCsv = 1000,
	VideoMp4 = 2000,
	ImagePng = 4000,
	ImageJpeg = 4001,
	ImageTiff = 4002,
	ImageSvg = 4003,
	MultiPartReport = 5000,
}

@Pipe({
name: 'mediaType'
})
export class MediaTypePipe extends BasePipe<MediaType> implements PipeTransform {
	static prefix = 'Backend.Types.MediaType.';
	static keys = [
		MediaType.ApplicationOctetStream,
		MediaType.ApplicationPdf,
		MediaType.ApplicationMsWord,
		MediaType.ApplicationVndMsExcel,
		MediaType.ApplicationX509CaCert,
		MediaType.ApplicationEdifact,
		MediaType.ApplicationXml,
		MediaType.TextCsv,
		MediaType.VideoMp4,
		MediaType.ImagePng,
		MediaType.ImageJpeg,
		MediaType.ImageTiff,
		MediaType.ImageSvg,
		MediaType.MultiPartReport];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: MediaType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case MediaType.ApplicationOctetStream: return 'ApplicationOctetStream';
			case MediaType.ApplicationPdf: return 'ApplicationPdf';
			case MediaType.ApplicationMsWord: return 'ApplicationMsWord';
			case MediaType.ApplicationVndMsExcel: return 'ApplicationVndMsExcel';
			case MediaType.ApplicationX509CaCert: return 'ApplicationX509CaCert';
			case MediaType.ApplicationEdifact: return 'ApplicationEdifact';
			case MediaType.ApplicationXml: return 'ApplicationXml';
			case MediaType.TextCsv: return 'TextCsv';
			case MediaType.VideoMp4: return 'VideoMp4';
			case MediaType.ImagePng: return 'ImagePng';
			case MediaType.ImageJpeg: return 'ImageJpeg';
			case MediaType.ImageTiff: return 'ImageTiff';
			case MediaType.ImageSvg: return 'ImageSvg';
			case MediaType.MultiPartReport: return 'MultiPartReport';
			default: return null;
		}
	}
	transform(value: MediaType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(MediaTypePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		MediaTypePipe
	],
	exports: [
		MediaTypePipe
	],
	providers: [
		MediaTypePipe
	]
})
export class MediaTypeModule { }
