import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'app/core/alert/alert.service';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { GoToService } from 'app/core/goto/goto-service';
import { ContentTranslationModel } from 'app/generated/backend/content/api/content-translation-model';
import { StatusCode } from 'app/generated/system/http/status-code';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './DefaultComponent.html'
})
export class DefaultComponent implements OnInit, OnDestroy {
  public notFound = false;
  public uri: string = null;
  public loading = false;
  private contentLoaded = false;
  private _subscriptions: Subscription[] = new Array<Subscription>();
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authenticationService: CoreAuthenticationService,
    private goToService: GoToService
  ) {

  }

  public onRedirect(uri: string) {

    this.goToService.goTo([uri]);
  }

  public onError(error: any) {
    console.error(error);
    if (!this.contentLoaded) {

      if (error.status === StatusCode.Unauthorized) {
        if (this.authenticationService.getUserId() > 0) {
          this.alertService.error(error, false);
        } else {
          this.router.navigate(['/user/login'], { queryParams: { returnUrl: this.uri } });
        }
      }

      this.notFound = true;
      this.loading = false;
    }
  }
  public onLoaded(evt: ContentTranslationModel) {
    this.notFound = false;
    this.loading = false;
    this.contentLoaded = true;
  }

  parseRoute() {
    let uri = '';
    if (this.activatedRoute.snapshot.url) {
      this.activatedRoute.snapshot.url.forEach(segment => {
        uri += '/';
        uri += segment.path;
      });
    }
    this.uri = uri;
    this.loading = true;
  }
  ngOnInit() {
    this._subscriptions.push(this.activatedRoute.url
      .subscribe(() => {
        this.notFound = false;
        this.parseRoute();
      }));

  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
