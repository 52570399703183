import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminMenuComponent } from "./AdminMenuComponent";
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../../core/common-material/core-common-material.module';
import { AdminMenuService } from "./AdminMenuService";
import { MenuModule } from 'app/core/menu/MenuModule';
import { AdminSubmenuComponent } from "./AdminSubmenuComponent";
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		CommonMaterialModule,
		MenuModule
	],
	declarations: [AdminMenuComponent, AdminSubmenuComponent],
	exports: [AdminMenuComponent, AdminSubmenuComponent],
	providers: [AdminMenuService]
})
export class AdminMenuModule {
}
