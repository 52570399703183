import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { PlacesPlaceModel } from 'app/generated/backend/locality/api/places-place-model';
import { PlacesMasterHandlerService } from 'app/generated/backend/locality/service/places-master-handler';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Subscription } from 'rxjs';

const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => AddressLookupComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => AddressLookupComponent),
	multi: true
};
@Component({
	selector: 'app-address-lookup',
	templateUrl: './AddressLookupComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class AddressLookupComponent implements ControlValueAccessor, Validator, OnInit {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string = 'fa-map-marker-alt';

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Output()
	itemSelected: EventEmitter<PlacesPlaceModel> = new EventEmitter<PlacesPlaceModel>();

	public loading = false;
	private _innerValue: PlacesPlaceModel;
	public errorStateMatcher = new SimpleErrorStateMatcher();
	protected subscriptions = new Array<Subscription>();
	public allItems: PlacesPlaceModel[] = [];
	private error: string;
	public model: string = '';
	public currentIndex = null;
	public currentSelectedId = null;

	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): PlacesPlaceModel {
		return this._innerValue;
	};

	// set accessor including call the onchange callback
	set value(v: PlacesPlaceModel) {
		if (v && v !== this._innerValue) {
			this._innerValue = v;
			this.model = this._innerValue.address;
		}
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	// From ControlValueAccessor interface
	writeValue(value: PlacesPlaceModel) {
		if (value && value !== this._innerValue) {
			this._innerValue = value;
			this.model = this._innerValue.address;
		}
		this.validate(null);
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: UntypedFormControl): ValidationErrors | null {
		if (this.error) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = this.error;
			return {
				jsonParseError: {
					valid: false,
				}
			};
		}
		if (!this.value && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				jsonParseError: {
					valid: false,
				}
			};
		}
		this.errorStateMatcher.valid = true;
		return null;
	}

	toDisplayText(item: PlacesPlaceModel): string {
		if (!item) {
			return null;
		}
		return item.address;
	}

	toTitleText(item: PlacesPlaceModel): string {
		return this.toDisplayText(item);
	}

	toOptionText(item: PlacesPlaceModel): string {
		if (!item) {
			return null;
		}
		return item.address;
	}

	onItemSelected(option: PlacesPlaceModel) {
		if (option) {
			this.value = option;
			this.error = null;
			this.validate(null);
			this.onChangeCallback(this._innerValue);
			this.itemSelected.emit(this._innerValue);
		}
	}

	modelChange() {
		this.error = null;
		if (this.model.trim() === '') {
			this.value = null;
		}
		this.verifyValidOption();
		this.subscriptions.push(this.service.get(this.model, null).subscribe(response => {
			if (response.data.places) {
				this.allItems = response.data.places;
			}
			this.validate(null);
			this.onChangeCallback(this._innerValue);
			this.loading = false;
			this.currentIndex = null;
			this.currentSelectedId = null;
		},
			error => {
				const httpErrorResponse = error as HttpErrorResponse;
				if (httpErrorResponse && httpErrorResponse.status) {
					this.error = "Http.Status." + httpErrorResponse.status.toString() + ".label";
				} else {
					this.error = "Http.Status.500.label";
				}
				this.allItems = [];
				this.validate(null);
				this.onChangeCallback(this._innerValue);
				this.loading = false;
				this.currentIndex = null;
				this.currentSelectedId = null;
			}));
	}

	constructor(
		private service: PlacesMasterHandlerService
	) { }

	load() {
		this.loading = true;
		this.error = null;
		this.currentIndex = null;
		this.currentSelectedId = null;
		this.errorStateMatcher.valid = true;
		this.subscriptions.push(this.service.get('', null).subscribe(response => {
			if (response.data.places) {
				this.allItems = response.data.places;
			}
			this.validate(null);
			this.loading = false;
		},
			error => {
				const httpErrorResponse = error as HttpErrorResponse;
				if (httpErrorResponse && httpErrorResponse.status) {
					this.error = "Http.Status." + httpErrorResponse.status.toString() + ".label";
				} else {
					this.error = "Http.Status.500.label";
				}
				this.allItems = [];
				this.validate(null);
				this.loading = false;
			}));
	}

	closeOption(isSelectionNeeded: boolean) {
		if (!isSelectionNeeded) {
			this.currentSelectedId = null;
			this.currentIndex = null;
			this.value = null;
		}
		if (this.model && this.model.trim() !== '' && this.allItems && this.allItems.length && isSelectionNeeded) {
			if (this.value) {
				for (let i = 0; i < this.allItems.length; i++) {
					if (this.allItems[i].placeId === this.value.placeId) {
						this.value = this.allItems[i];
						this.verifyValidOption();
						return;
					}
				}
			}
			this.value = this.allItems[0];
			this.verifyValidOption();
		}
	}

	verifyValidOption() {
		this.error = null;
		if (this.model) {
			if (this.toDisplayText(this.value) !== this.model) {
				this.error = 'error.notAValidOption';
			}
		}
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	ngOnInit() {
		this.load();
	}

	onClear() {
		this.value = null;
		this.model = '';
		this.currentIndex = null;
		this.currentSelectedId = null;
	}

	onKeyDownEvent(event: any) {
		let items: PlacesPlaceModel[];
		items = this.allItems;
		if (!items || !items.length) {
			return;
		}
		if (event.code === 'ArrowDown') {
			if (this.currentIndex === null) {
				this.currentIndex = 0;
				this.currentSelectedId = null;
			} else if (items.length && items.length - 1 > this.currentIndex) {
				this.currentIndex = this.currentIndex + 1;
			}
		} else if (event.code === 'ArrowUp') {
			if (this.currentIndex > 0) {
				this.currentIndex = this.currentIndex - 1;
				this.currentSelectedId = null;
			}
		}
		this.HandleItemSelection(event, items);
	}

	private HandleItemSelection(event: any, items: PlacesPlaceModel[]) {
		if (this.currentIndex !== null) {
			const currenctObject = items[this.currentIndex];
			if (!currenctObject) {
				return;
			}
			if ((event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Tab')) {
				this.onItemSelected(items[this.currentIndex]);
			}
			if (currenctObject) {
				this.currentSelectedId = currenctObject.placeId;
				const element = document.getElementById(this.currentSelectedId);
				if (element) {
					element.scrollIntoView({ block: 'nearest' });
				}
			}
		}
	}
}
