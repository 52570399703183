import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PlacesMasterModel } from 'app/generated/backend/locality/api/places-master-model';

@Injectable()
export class PlacesMasterHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		filter: string,
		language: string
	): Observable<JsonResourceResponse<PlacesMasterModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (filter!==null && filter!==undefined) {
			params = params.set('filter', filter);
		}
		if (language!==null && language!==undefined) {
			params = params.set('language', language);
		}
		return this.http.get<JsonResourceResponse<PlacesMasterModel>>('api/v1/locality/places/master', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		PlacesMasterHandlerService
	]
})
export class PlacesMasterHandlerModule { }
