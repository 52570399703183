export class CoreMapCoordinates {
    latitude: number;
    longitude: number;

    constructor(
        latitude: number = 0,
        longitude: number = 30,
    ) {
        this.latitude = latitude;
        this.longitude = longitude
    }
}
