import { UserAuthMethod } from 'app/generated/backend/types/user-auth-method';
export class SettingsModel {
	name: string;
	localeId: number;
	currencyId: number;
	countryTimeZoneId: number;
	password: string;
	passwordRepeat: string;
	authMethod: UserAuthMethod;
	email: string;
	mobile: string;
	logoSha256: string;
	showInfo: boolean;
}
