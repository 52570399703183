import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum ContainerEquipment {
	None = 0,
	Chassis = 1,
	SideLoader = 2,
	Crane = 4,
	All = 7,
}

@Pipe({
name: 'containerEquipment'
})
export class ContainerEquipmentPipe extends BasePipe<ContainerEquipment> implements PipeTransform {
	static prefix = 'Backend.Types.ContainerEquipment.';
	static keys = [
		ContainerEquipment.None,
		ContainerEquipment.Chassis,
		ContainerEquipment.SideLoader,
		ContainerEquipment.Crane,
		ContainerEquipment.All];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ContainerEquipment): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ContainerEquipment.None: return 'None';
			case ContainerEquipment.Chassis: return 'Chassis';
			case ContainerEquipment.SideLoader: return 'SideLoader';
			case ContainerEquipment.Crane: return 'Crane';
			case ContainerEquipment.All: return 'All';
			default: return null;
		}
	}
	transform(value: ContainerEquipment, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ContainerEquipmentPipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(ContainerEquipmentPipe.keys, ContainerEquipmentPipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ContainerEquipmentPipe
	],
	exports: [
		ContainerEquipmentPipe
	],
	providers: [
		ContainerEquipmentPipe
	]
})
export class ContainerEquipmentModule { }
