import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PlacesDetailModel } from 'app/generated/backend/locality/api/places-detail-model';

@Injectable()
export class PlacesDetailHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		placeId: string
	): Observable<JsonResourceResponse<PlacesDetailModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (placeId!==null && placeId!==undefined) {
			params = params.set('placeId', placeId);
		}
		return this.http.get<JsonResourceResponse<PlacesDetailModel>>('api/v1/locality/places/detail', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		PlacesDetailHandlerService
	]
})
export class PlacesDetailHandlerModule { }
