import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum StatusCode {
	None = 0,
	Continue = 100,
	SwitchingProtocols = 101,
	Processing = 102,
	EarlyHints = 103,
	Ok = 200,
	Created = 201,
	Accepted = 202,
	NonAuthoritativeInformation = 203,
	NoContent = 204,
	ResetContent = 205,
	PartialContent = 206,
	MultiStatus = 207,
	AlreadyReported = 208,
	MultipleChoices = 300,
	MovedPermanently = 301,
	Found = 302,
	SeeOther = 303,
	NotModified = 304,
	UseProxy = 305,
	Reserved306 = 306,
	TemporaryRedirect = 307,
	PermanentRedirect = 308,
	BadRequest = 400,
	Unauthorized = 401,
	PaymentRequired = 402,
	Forbidden = 403,
	NotFound = 404,
	MethodNotAllowed = 405,
	NotAcceptable = 406,
	ProxyAuthenticationRequired = 407,
	RequestTimeout = 408,
	Conflict = 409,
	Gone = 410,
	LengthRequired = 411,
	PreconditionFailed = 412,
	RequestEntityTooLarge = 413,
	RequestUriTooLong = 414,
	UnsupportedMediaType = 415,
	RequestedRangeNotSatisfiable = 416,
	ExpectationFailed = 417,
	MisdirectedRequest = 421,
	UnprocessableEntity = 422,
	Locked = 423,
	FailedDependency = 424,
	TooEarly = 425,
	UpgradeRequired = 426,
	PreconditionRequired = 428,
	TooManyRequests = 429,
	RequestHeaderFieldsTooLarge = 431,
	UnavailableForLegalReasons = 451,
	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
	HttpVersionNotSupported = 505,
	VariantAlsoNegotiates = 506,
	InsufficientStorage = 507,
	LoopDetected = 508,
	NotExtended = 510,
	NetworkAuthenticationRequired = 511,
}

@Pipe({
name: 'statusCode'
})
export class StatusCodePipe extends BasePipe<StatusCode> implements PipeTransform {
	static prefix = 'System.Http.StatusCode.';
	static keys = [
		StatusCode.None,
		StatusCode.Continue,
		StatusCode.SwitchingProtocols,
		StatusCode.Processing,
		StatusCode.EarlyHints,
		StatusCode.Ok,
		StatusCode.Created,
		StatusCode.Accepted,
		StatusCode.NonAuthoritativeInformation,
		StatusCode.NoContent,
		StatusCode.ResetContent,
		StatusCode.PartialContent,
		StatusCode.MultiStatus,
		StatusCode.AlreadyReported,
		StatusCode.MultipleChoices,
		StatusCode.MovedPermanently,
		StatusCode.Found,
		StatusCode.SeeOther,
		StatusCode.NotModified,
		StatusCode.UseProxy,
		StatusCode.Reserved306,
		StatusCode.TemporaryRedirect,
		StatusCode.PermanentRedirect,
		StatusCode.BadRequest,
		StatusCode.Unauthorized,
		StatusCode.PaymentRequired,
		StatusCode.Forbidden,
		StatusCode.NotFound,
		StatusCode.MethodNotAllowed,
		StatusCode.NotAcceptable,
		StatusCode.ProxyAuthenticationRequired,
		StatusCode.RequestTimeout,
		StatusCode.Conflict,
		StatusCode.Gone,
		StatusCode.LengthRequired,
		StatusCode.PreconditionFailed,
		StatusCode.RequestEntityTooLarge,
		StatusCode.RequestUriTooLong,
		StatusCode.UnsupportedMediaType,
		StatusCode.RequestedRangeNotSatisfiable,
		StatusCode.ExpectationFailed,
		StatusCode.MisdirectedRequest,
		StatusCode.UnprocessableEntity,
		StatusCode.Locked,
		StatusCode.FailedDependency,
		StatusCode.TooEarly,
		StatusCode.UpgradeRequired,
		StatusCode.PreconditionRequired,
		StatusCode.TooManyRequests,
		StatusCode.RequestHeaderFieldsTooLarge,
		StatusCode.UnavailableForLegalReasons,
		StatusCode.InternalServerError,
		StatusCode.NotImplemented,
		StatusCode.BadGateway,
		StatusCode.ServiceUnavailable,
		StatusCode.GatewayTimeout,
		StatusCode.HttpVersionNotSupported,
		StatusCode.VariantAlsoNegotiates,
		StatusCode.InsufficientStorage,
		StatusCode.LoopDetected,
		StatusCode.NotExtended,
		StatusCode.NetworkAuthenticationRequired];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: StatusCode): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case StatusCode.None: return 'None';
			case StatusCode.Continue: return 'Continue';
			case StatusCode.SwitchingProtocols: return 'SwitchingProtocols';
			case StatusCode.Processing: return 'Processing';
			case StatusCode.EarlyHints: return 'EarlyHints';
			case StatusCode.Ok: return 'Ok';
			case StatusCode.Created: return 'Created';
			case StatusCode.Accepted: return 'Accepted';
			case StatusCode.NonAuthoritativeInformation: return 'NonAuthoritativeInformation';
			case StatusCode.NoContent: return 'NoContent';
			case StatusCode.ResetContent: return 'ResetContent';
			case StatusCode.PartialContent: return 'PartialContent';
			case StatusCode.MultiStatus: return 'MultiStatus';
			case StatusCode.AlreadyReported: return 'AlreadyReported';
			case StatusCode.MultipleChoices: return 'MultipleChoices';
			case StatusCode.MovedPermanently: return 'MovedPermanently';
			case StatusCode.Found: return 'Found';
			case StatusCode.SeeOther: return 'SeeOther';
			case StatusCode.NotModified: return 'NotModified';
			case StatusCode.UseProxy: return 'UseProxy';
			case StatusCode.Reserved306: return 'Reserved306';
			case StatusCode.TemporaryRedirect: return 'TemporaryRedirect';
			case StatusCode.PermanentRedirect: return 'PermanentRedirect';
			case StatusCode.BadRequest: return 'BadRequest';
			case StatusCode.Unauthorized: return 'Unauthorized';
			case StatusCode.PaymentRequired: return 'PaymentRequired';
			case StatusCode.Forbidden: return 'Forbidden';
			case StatusCode.NotFound: return 'NotFound';
			case StatusCode.MethodNotAllowed: return 'MethodNotAllowed';
			case StatusCode.NotAcceptable: return 'NotAcceptable';
			case StatusCode.ProxyAuthenticationRequired: return 'ProxyAuthenticationRequired';
			case StatusCode.RequestTimeout: return 'RequestTimeout';
			case StatusCode.Conflict: return 'Conflict';
			case StatusCode.Gone: return 'Gone';
			case StatusCode.LengthRequired: return 'LengthRequired';
			case StatusCode.PreconditionFailed: return 'PreconditionFailed';
			case StatusCode.RequestEntityTooLarge: return 'RequestEntityTooLarge';
			case StatusCode.RequestUriTooLong: return 'RequestUriTooLong';
			case StatusCode.UnsupportedMediaType: return 'UnsupportedMediaType';
			case StatusCode.RequestedRangeNotSatisfiable: return 'RequestedRangeNotSatisfiable';
			case StatusCode.ExpectationFailed: return 'ExpectationFailed';
			case StatusCode.MisdirectedRequest: return 'MisdirectedRequest';
			case StatusCode.UnprocessableEntity: return 'UnprocessableEntity';
			case StatusCode.Locked: return 'Locked';
			case StatusCode.FailedDependency: return 'FailedDependency';
			case StatusCode.TooEarly: return 'TooEarly';
			case StatusCode.UpgradeRequired: return 'UpgradeRequired';
			case StatusCode.PreconditionRequired: return 'PreconditionRequired';
			case StatusCode.TooManyRequests: return 'TooManyRequests';
			case StatusCode.RequestHeaderFieldsTooLarge: return 'RequestHeaderFieldsTooLarge';
			case StatusCode.UnavailableForLegalReasons: return 'UnavailableForLegalReasons';
			case StatusCode.InternalServerError: return 'InternalServerError';
			case StatusCode.NotImplemented: return 'NotImplemented';
			case StatusCode.BadGateway: return 'BadGateway';
			case StatusCode.ServiceUnavailable: return 'ServiceUnavailable';
			case StatusCode.GatewayTimeout: return 'GatewayTimeout';
			case StatusCode.HttpVersionNotSupported: return 'HttpVersionNotSupported';
			case StatusCode.VariantAlsoNegotiates: return 'VariantAlsoNegotiates';
			case StatusCode.InsufficientStorage: return 'InsufficientStorage';
			case StatusCode.LoopDetected: return 'LoopDetected';
			case StatusCode.NotExtended: return 'NotExtended';
			case StatusCode.NetworkAuthenticationRequired: return 'NetworkAuthenticationRequired';
			default: return null;
		}
	}
	transform(value: StatusCode, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(StatusCodePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		StatusCodePipe
	],
	exports: [
		StatusCodePipe
	],
	providers: [
		StatusCodePipe
	]
})
export class StatusCodeModule { }
