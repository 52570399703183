import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WeekdaysInputComponent } from './WeekdaysInputComponent';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { HelpPopupModule } from '../help-popup/help-popup.module';
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		CommonMaterialModule,
		TranslateModule,
		HelpPopupModule
	],
	exports: [WeekdaysInputComponent],
	declarations: [WeekdaysInputComponent]
})
export class WeekdaysModule {
}
