<button *ngFor="let menuItem of menu.items" mat-menu-item [ngClass]="link.isActive ? activeItemClass : itemClass"
    routerLinkActive="{{activeItemClass}}" #link="routerLinkActive" [routerLinkActiveOptions]="{exact: false}"
    [routerLink]="menuItem.routerLink" [queryParams]="menuItem.queryParams">
    <span class="fal fa-fw fa-material adn-menu-icons" [ngClass]="menuItem.iconClass"
        aria-hidden="true"></span>&nbsp;{{menuItem.titleTranslateKey | translate}}<app-task-summary
        *ngIf="menuItem.taskClass !== null" [class]="menuItem.taskClass"></app-task-summary>
</button>
<ng-template ngFor let-group [ngForOf]="menu.groups">
    <mat-menu #analyticsMenu="matMenu">
        <button *ngFor="let menuItem of group.items" mat-menu-item
            [ngClass]="link.isActive ? activeItemClass : itemClass" routerLinkActive="{{activeItemClass}}"
            #link="routerLinkActive" [routerLinkActiveOptions]="{exact: false}" [routerLink]="menuItem.routerLink"
            [queryParams]="menuItem.queryParams">
            <span class="fal fa-fw fa-material adn-menu-icons" [ngClass]="menuItem.iconClass"
                aria-hidden="true"></span>&nbsp;{{menuItem.titleTranslateKey | translate}}<app-task-summary
                *ngIf="menuItem.taskClass !== null" [class]="menuItem.taskClass">
            </app-task-summary>
        </button>
    </mat-menu>
    <button mat-menu-item [matMenuTriggerFor]="analyticsMenu" [ngClass]="itemClass">
        <span class="fal fa-fw fa-material adn-menu-icons" [ngClass]="group.iconClass"
            aria-hidden="true"></span>&nbsp;{{group.titleTranslateKey | translate}}<app-task-summary
            *ngIf="group.taskClass !== null" [class]="group.taskClass"></app-task-summary>
        &nbsp;<span *ngIf="caret" class="fal fa-fw fa-material fa-caret-down adn-menu-icons" aria-hidden="true"></span>
    </button>
</ng-template>