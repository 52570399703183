import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreAuthenticationService } from 'app/core/authentication/CoreAuthenticationService';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { PartyPlaceLookupModel } from 'app/generated/backend/yard/api/party-place-lookup-model';
import { PartyBaseIdLookupService } from 'app/party/_templates/party-base-id-lookup-service';

@Injectable()
export class PartyPlaceLookupService extends PartyBaseIdLookupService<PartyPlaceLookupModel> {
	constructor(
		http: HttpClient,
		authenticationService: CoreAuthenticationService,
		cacheService: CoreCacheService
	) {
		super(http, authenticationService, 'yard/parties', 'places/lookup', cacheService.getPartyPlaces());
	}

	updateItem(item: PartyPlaceLookupModel): PartyPlaceLookupModel {
		if (item) {
			item.match = '';
			if (item.code) {
				item.match += item.code.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}
}
