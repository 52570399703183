<div ngModelGroup="cargoGroups">

    <div class="row form-group" *ngIf="showLcl">
        <div class="col-sm-12">
            <mat-button-toggle-group name="shipmentType" [(ngModel)]="shipmentType" [required]="true"
                [disabled]="disabled">
                <mat-button-toggle [value]="ShipmentType.Fcl">
                    {{ShipmentType.Fcl | shipmentType : 'label' | async}}</mat-button-toggle>
                <mat-button-toggle [value]="ShipmentType.Lcl">
                    {{ShipmentType.Lcl | shipmentType : 'label' | async}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div *ngIf="shipmentType===ShipmentType.Lcl && cargoGroups">
        <div *ngFor="let item of cargoGroups; let i = index; trackBy:itemIdentity" id="cargoRow{{i}}"
            class="row form-group">
            <app-number-input class="col-sm-2" name="quantity{{i}}" label="{{'field.quantity.label' | translate}}"
                [min]="1" [(ngModel)]="item.quantity" [required]="true" [disabled]="disabled">
            </app-number-input>
            <app-core-package-type-lookup class="col-sm-4" name="packageTypeId{{i}}" [(ngModel)]="item.packageTypeId"
                label="{{'field.packageType.label' | translate}}" (itemSelected)="setDims($event, item)"
                (ngModelChange)="onModelChange(item, i)" [disabled]="disabled" helpUri="help/booking/packageType">
            </app-core-package-type-lookup>
            <app-core-harmonized-commodity-lookup *ngIf="!isPublic" class="col-sm-4" name="harmonizedCommodity{{i}}"
                hint="{{'field.harmonizedCommodity.hint' | translate}}" [disabled]="disabled"
                label="{{'field.harmonizedCommodity.label' | translate}}" (ngModelChange)="onModelChange(item, i)"
                helpUri="help/booking/cargoGroup/harmonizedCommodity" [(ngModel)]="item.harmonizedCommodity">
            </app-core-harmonized-commodity-lookup>
            <div class="col-sm-2">
                <div class="adn-control-label">{{'field.shipmentPackageProperties.label' | translate}}</div>
                <ul>
                    <li class="list-inline-item" *ngFor="let packageProperty of packageProperties">
                        <mat-checkbox class="col-md-2" name="property{{i}}" [disabled]="disabled"
                            [checked]="getMask(item.cargoGroupProperties, packageProperty)"
                            (ngModelChange)="onModelChange(item, i)"
                            (change)="item.cargoGroupProperties===packagePropertyStackable ? item.cargoGroupProperties=packagePropertyNonStackable : item.cargoGroupProperties=packagePropertyStackable"
                            title="{{packageProperty | shipmentPackageProperties : 'description' | async}}">
                            {{packageProperty | shipmentPackageProperties : 'label' | async}}
                        </mat-checkbox>
                    </li>
                </ul>
            </div>
            <div class="col-sm-3">
                <app-length-input id="lengthInput" name="lengthInput{{i}}" [(ngModel)]="item.length"
                    [disabled]="disabled" label="{{'field.length' | translate}}" [min]="minLengthValue"
                    (ngModelChange)="onModelChange(item, i)" [required]="true" helpUri="help/booking/length"
                    [disabled]="isLengthReadonly" (blur)="processDimension()">
                </app-length-input>
            </div>
            <div class="col-sm-3">
                <app-length-input id="widthInput" name="widthInput{{i}}" [(ngModel)]="item.width" [disabled]="disabled"
                    label="{{'field.width.label' | translate}}" [min]="minWidthValue" [required]="true"
                    (ngModelChange)="onModelChange(item, i)" helpUri="help/booking/width" [disabled]="isWidthReadonly"
                    (blur)="processDimension()">
                </app-length-input>
            </div>
            <div class="col-sm-3">
                <app-length-input id="heightInput" name="heightInput{{i}}" [(ngModel)]="item.height"
                    (ngModelChange)="onModelChange(item, i)" [disabled]="disabled"
                    label="{{'field.height.label' | translate}}" [min]="minHeightValue" [required]="true"
                    helpUri="help/booking/height" [disabled]="isHeightReadonly">
                </app-length-input>
            </div>
            <div class="col-sm-2">
                <app-weight-input name="weight{{i}}" label="{{'field.weightWithoutUnit.label' | translate}}" unit="kg"
                    [(ngModel)]="item.weight" [required]="true" [disabled]="disabled"
                    (ngModelChange)="onModelChange(item, i)" helpUri="help/booking/weight">
                </app-weight-input>
            </div>
            <div *ngIf="!isPublic" class="col-sm-1 adn-buttons-horizontal text-right">
                <app-button-delete-icon (action)="onDelete(i)" [disabled]="cargoGroups.length === 1 || disabled">
                </app-button-delete-icon>
            </div>
            <hr />
        </div>
        <div *ngIf="widthGreaterThanLength" class="row alert alert-danger">
            <div class="col-sm-12">
                {{'field.widthGreaterThanLength.label' | translate}}
            </div>
        </div>
        <div *ngIf="!isPublic" class="row form-group">
            <div class="col-sm-11">
                <p>{{'information.addPackage' | translate}}
                </p>
            </div>
            <div class="col-sm-1 adn-buttons-horizontal text-right">
                <app-button-add-icon (action)="onAdd()" [disabled]="cargoGroups.length > 2 || disabled">
                </app-button-add-icon>
            </div>
        </div>

    </div>
    <div *ngIf="shipmentType===ShipmentType.Fcl && cargoGroups">
        <div *ngFor="let item of cargoGroups; let i = index; trackBy:itemIdentity" id="cargoRow{{i}}"
            class="form-group">
            <div class="row row-margin">
                <app-container-type-input class="col-sm-12 col-md-5 col-lg-4" name="containerType{{i}}"
                    label="{{'field.containerType.label' | translate}}" [(ngModel)]="item.containerType"
                    [disabled]="disabled" [required]="true" (itemSelected)="onContainerTypeChange(i)"
                    (ngModelChange)="onModelChange(item, i)" helpUri="help/field/containerType">
                </app-container-type-input>
                <app-container-size-radio-group class="col-sm-12 col-md-6 col-lg-7" name="containerSize{{i}}"
                    (blur)="getContainerMaxWeight(item.containerSize, item.weight, i)" [disabled]="disabled"
                    label="{{'field.containerSize.label' | translate}}" [(ngModel)]="item.containerSize"
                    (ngModelChange)="onModelChange(item, i)" [required]="true" helpUri="help/field/containerSize">
                </app-container-size-radio-group>
                <app-number-input class="col-sm-6 col-md-5 col-lg-2" name="quantity{{i}}" [disabled]="disabled"
                    label="{{'field.quantity.label' | translate}}" [min]="1" [(ngModel)]="item.quantity"
                    (ngModelChange)="onModelChange(item, i)" [required]="true"
                    helpUri="help/booking/cargoGroup/maxNetWeightPerContainer">
                </app-number-input>
                <app-weight-input *ngIf="!isPublic" class="col-sm-6 col-md-6 col-lg-2" name="weight{{i}}"
                    label="{{'field.maxNetWeight.label' | translate}}" [(ngModel)]="item.weight" [required]="true"
                    helpUri="help/booking/cargoGroup/maxNetWeightPerContainer" [disabled]="disabled"
                    (ngModelChange)="onModelChange(item, i)"
                    (blur)="getContainerMaxWeight(item.containerSize, item.weight, i)">
                </app-weight-input>
                <app-temperature-input *ngIf="item.containerType===ContainerType.Reefer"
                    class="col-sm-12 col-md-11 col-lg-2" name="temperature{{i}}"
                    (ngModelChange)="onModelChange(item, i)" label="{{'field.temperature.label' | translate}}"
                    [(ngModel)]="item.temperature" [required]="true" helpUri="help/field/temperature"
                    [disabled]="disabled"
                    (blur)="getContainerMinMaxTemperature(item.containerSize, item.temperature, i)">
                </app-temperature-input>
                <app-core-harmonized-commodity-lookup *ngIf="!isPublic"
                    [ngClass]="item.containerType===ContainerType.Reefer ? 'col-sm-11 col-md-11 col-lg-5' : 'col-sm-11 col-md-11 col-lg-7'"
                    name="harmonizedCommodity{{i}}" hint="{{'field.harmonizedCommodity.hint' | translate}}"
                    label="{{'field.harmonizedCommodity.label' | translate}}" [disabled]="disabled"
                    (ngModelChange)="onModelChange(item, i)" helpUri="help/booking/cargoGroup/harmonizedCommodity"
                    [(ngModel)]="item.harmonizedCommodity">
                </app-core-harmonized-commodity-lookup>
                <div *ngIf="!isPublic" class="col-sm-1 adn-buttons-horizontal text-right">
                    <app-button-delete-icon (action)="onDelete(i)" [disabled]="cargoGroups.length === 1 || disabled">
                    </app-button-delete-icon>
                </div>
            </div>
            <div class="col-sm-12">
                <div *ngIf="item.exceededMaxWeight" class="row alert alert-danger">
                    {{'field.exceededMaxWeight.label' | translate}}
                </div>
            </div>
            <div class="col-sm-12">
                <div *ngIf="item.containerType===ContainerType.Reefer && item.greaterThanMaxTemperature"
                    class="row alert alert-danger">
                    {{'field.greaterThanMaxTemperature.label' | translate}}
                </div>
            </div>
            <div class="col-sm-12">
                <div *ngIf="item.containerType===ContainerType.Reefer && item.lessThanMinTemperature"
                    class="row alert alert-danger">
                    {{'field.lessThanMinTemperature.label' | translate}}
                </div>
            </div>
            <hr />
        </div>
        <div *ngIf="!isPublic" class="row form-group">
            <div class="col-sm-11">
                <p>{{'information.addContainer' | translate}}</p>
            </div>
            <div class="col-sm-1 adn-buttons-horizontal text-right">
                <app-button-add-icon (action)="onAdd()" [disabled]="cargoGroups.length > 2 || disabled">
                </app-button-add-icon>
            </div>
        </div>
    </div>


</div>