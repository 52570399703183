import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../../../core/common-material/core-common-material.module';
import { PartyUserLookupComponent } from './PartyUserLookupComponent';
import { PartyUserLookupService } from './PartyUserLookupService';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { PartyUserEmailPipe } from './PartyUserEmailPipe';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';

@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		FormsModule,
		TranslateModule,
		CommonMaterialModule,
		HelpPopupModule
	],
	exports: [PartyUserLookupComponent, PartyUserEmailPipe],
	declarations: [PartyUserLookupComponent, PartyUserEmailPipe],
	providers: [PartyUserLookupService, PartyUserEmailPipe]
})
export class PartyUserLookupModule { }
