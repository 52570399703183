import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonBaseComponent } from './button-base.component';


@Component({
	selector: 'app-button-add-icon',
	templateUrl: './button-icon-base.component.html'
})
export class ButtonAddIconComponent extends ButtonBaseComponent {
	@Input()
	public iconClass = 'fa-plus';
	@Input()
	public titleTranslateKey = 'button.add';
	@Input()
	public color = 'accent';
	@Input()
	public disabled: boolean = false;
	@Input()
	public type = 'button';
	@Input()
	public hintTranslateKey: string;
	@Output()
	public action: EventEmitter<ButtonAddIconComponent> = new EventEmitter<ButtonAddIconComponent>();
}