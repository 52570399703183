import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'countryIso2Name'
})
export class CoreCountryIso2NamePipe implements PipeTransform {
	constructor(
		protected translateService: TranslateService
	) {

	}
	instant(value: string): string {
		if (!value) {
			return null;
		}
		return this.translateService.instant('country.' + value);
	}
	transform(value: string, args: any[]) {
		if (!value) {
			return null;
		}
		return this.translateService.stream('country.' + value);
	}
}
