import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum ContainerType {
	None = 0,
	GeneralPurpose = 1,
	Reefer = 2,
	All = 3,
}

@Pipe({
name: 'containerType'
})
export class ContainerTypePipe extends BasePipe<ContainerType> implements PipeTransform {
	static prefix = 'Backend.Types.ContainerType.';
	static keys = [
		ContainerType.None,
		ContainerType.GeneralPurpose,
		ContainerType.Reefer,
		ContainerType.All];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ContainerType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ContainerType.None: return 'None';
			case ContainerType.GeneralPurpose: return 'GeneralPurpose';
			case ContainerType.Reefer: return 'Reefer';
			case ContainerType.All: return 'All';
			default: return null;
		}
	}
	transform(value: ContainerType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ContainerTypePipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(ContainerTypePipe.keys, ContainerTypePipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ContainerTypePipe
	],
	exports: [
		ContainerTypePipe
	],
	providers: [
		ContainerTypePipe
	]
})
export class ContainerTypeModule { }
