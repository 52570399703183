import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoToService } from './goto-service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule
	],
	providers: [GoToService]
})
export class GoToModule { }
