<div class="adn-form-field"
	[ngClass]="{'adn-form-field-invalid': !errorStateMatcher.valid, 'adn-form-field-disabled': disabled}">
	<label>
		<span *ngIf="iconClass" class="fal fa-fw" [ngClass]="iconClass" aria-hidden="true">&nbsp;</span>{{label}}
		<ng-content select="app-text-label"></ng-content><span *ngIf="required">&nbsp;*</span>
	</label>
	<div class="adn-form-field-content">
		<mat-radio-group aria-label="Select an option" [(ngModel)]="value"  (change)="onItemSelected($event)">
			<mat-radio-button *ngFor="let option of allItems" [value]="getOptionValue(option)" [disabled]="disabled">{{toOptionText(option)}}
			</mat-radio-button>
		</mat-radio-group>
	</div>
</div>
<div class="adn-form-field-hint" *ngIf="errorStateMatcher.valid && hint">{{hint}}</div>
<div class="adn-form-field-error" *ngIf="!errorStateMatcher.valid">
	<span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;<span
		class="error-text">{{errorStateMatcher.errorKey | translate}}</span>
</div>
