import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { FacilityLookupModel } from 'app/generated/backend/locality/api/facility-lookup-model';
import { SimpleLookupService } from '../../_templates/simple-lookup.service';

@Injectable()
export class CoreFacilityLookupService extends SimpleLookupService<FacilityLookupModel> {

	constructor(
		protected http: HttpClient,
		private cacheService: CoreCacheService
	) {
		super(http, 'locality/facilities/lookup', cacheService.getFacilities())

	}

	updateItem(item: FacilityLookupModel): FacilityLookupModel {
		if (item) {
			item.match = '';
			if (item.code) {
				item.match += item.code.toLowerCase();
			}
			if (item.name) {
				item.match += ' ';
				item.match += item.name.toLowerCase();
			}
			if (item.locationCode) {
				item.match += ' ';
				item.match += item.locationCode.toLowerCase();
			}
			if (item.locationName) {
				item.match += ' ';
				item.match += item.locationName.toLowerCase();
			}
		}
		return item;
	}
}
