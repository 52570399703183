import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ShipmentSubMenuComponent } from 'app/shipment/menu/ShipmentSubMenuComponent';
import { AlertModule } from '../../core/alert/alert.module';
import { CommonMaterialModule } from '../../core/common-material/core-common-material.module';
import { PanelModule } from '../../core/panel/PanelModule';
import { ServiceCodeClassModule } from '../../core/service-code-class/service-code-class.module';
import { ServiceClassModule } from '../../generated/backend/types/service-class';
import { ServiceCodeModule } from '../../generated/backend/types/service-code';
import { ShipmentMenuComponent } from './ShipmentMenuComponent';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AlertModule,
		PanelModule,
		ServiceCodeModule,
		ServiceClassModule,
		ServiceCodeClassModule,
		TranslateModule,
		CommonMaterialModule,
		RouterModule
	],
	exports: [ShipmentMenuComponent, ShipmentSubMenuComponent],
	declarations: [ShipmentMenuComponent, ShipmentSubMenuComponent]
})
export class ShipmentMenuModule { }
