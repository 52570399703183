import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum TransportMode {
	None = 0,
	Sea = 1,
	Truck = 2,
	Rail = 4,
	All = 7,
}

@Pipe({
name: 'transportMode'
})
export class TransportModePipe extends BasePipe<TransportMode> implements PipeTransform {
	static prefix = 'Backend.Types.TransportMode.';
	static keys = [
		TransportMode.None,
		TransportMode.Sea,
		TransportMode.Truck,
		TransportMode.Rail,
		TransportMode.All];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: TransportMode): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case TransportMode.None: return 'None';
			case TransportMode.Sea: return 'Sea';
			case TransportMode.Truck: return 'Truck';
			case TransportMode.Rail: return 'Rail';
			case TransportMode.All: return 'All';
			default: return null;
		}
	}
	transform(value: TransportMode, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(TransportModePipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(TransportModePipe.keys, TransportModePipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		TransportModePipe
	],
	exports: [
		TransportModePipe
	],
	providers: [
		TransportModePipe
	]
})
export class TransportModeModule { }
