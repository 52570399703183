import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ContentTreeHandlerModule } from 'app/generated/backend/content/service/content-tree-handler';
import { CommonMaterialModule } from '../../core/common-material/core-common-material.module';
import { MenuComponent } from './MenuComponent';
import { PublicMenuComponent } from "./PublicMenuComponent";
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		CommonMaterialModule,
		ContentTreeHandlerModule
	],
	declarations: [PublicMenuComponent, MenuComponent],
	exports: [PublicMenuComponent, MenuComponent]
})
export class PublicMenuModule {
}
