import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum PermissionFlags {
	None = 0,
	Create = 1,
	Read = 2,
	Update = 4,
	Delete = 8,
	List = 16,
	Download = 32,
	Approve = 64,
	All = 127,
}

@Pipe({
name: 'permissionFlags'
})
export class PermissionFlagsPipe extends BasePipe<PermissionFlags> implements PipeTransform {
	static prefix = 'Backend.Types.PermissionFlags.';
	static keys = [
		PermissionFlags.None,
		PermissionFlags.Create,
		PermissionFlags.Read,
		PermissionFlags.Update,
		PermissionFlags.Delete,
		PermissionFlags.List,
		PermissionFlags.Download,
		PermissionFlags.Approve,
		PermissionFlags.All];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: PermissionFlags): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case PermissionFlags.None: return 'None';
			case PermissionFlags.Create: return 'Create';
			case PermissionFlags.Read: return 'Read';
			case PermissionFlags.Update: return 'Update';
			case PermissionFlags.Delete: return 'Delete';
			case PermissionFlags.List: return 'List';
			case PermissionFlags.Download: return 'Download';
			case PermissionFlags.Approve: return 'Approve';
			case PermissionFlags.All: return 'All';
			default: return null;
		}
	}
	transform(value: PermissionFlags, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(PermissionFlagsPipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(PermissionFlagsPipe.keys, PermissionFlagsPipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		PermissionFlagsPipe
	],
	exports: [
		PermissionFlagsPipe
	],
	providers: [
		PermissionFlagsPipe
	]
})
export class PermissionFlagsModule { }
