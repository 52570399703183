import { Injectable } from '@angular/core';
import { PackageTypeLookupModel } from 'app/generated/backend/trade/api/package-type-lookup-model';
import { PackageTypeLookupHandlerService } from 'app/generated/backend/trade/service/package-type-lookup-handler';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreCacheService } from '../cache/CoreCacheService';

@Injectable()
export class CorePackageTypeLookupService extends CachedEntityService<PackageTypeLookupModel> {
	constructor(
		packageHandlerService: PackageTypeLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(packageHandlerService, cacheService.getPackageTypes());
	}

	nameByCode: Map<string, string>;
	getNameByCode(code: string): Observable<string> {

		if (this.nameByCode) {
			return of(this.nameByCode.get(code));
		}
		return this.service.get().pipe(map(response => {
			this.nameByCode = new Map<string, string>();
			response.data.forEach(item => {
				this.nameByCode.set(item.code, item.name);
			});
			return this.nameByCode.get(code);
		}));
	}
	nameById: Map<number, string>;
	getNameById(id: number): Observable<string> {

		if (this.getNameById) {
			return of(this.nameById.get(id));
		}
		return this.service.get().pipe(map(response => {
			this.nameById = new Map<number, string>();
			response.data.forEach(item => {
				this.nameById.set(item.id, item.name);
			});
			return this.nameById.get(id);
		}));
	}
	transformItem(item: PackageTypeLookupModel): PackageTypeLookupModel {
		if (item) {
			item.match = '';
			if (item.code) {
				item.match += item.code.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}

}



