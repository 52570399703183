import { ErrorStateMatcher } from "@angular/material/core";
import { FormGroupDirective, NgForm, UntypedFormControl } from "@angular/forms";

export class SimpleErrorStateMatcher implements ErrorStateMatcher {
	public valid = false;
	public errorKey: string;
	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return !this.valid;
	}
}
