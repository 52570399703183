import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { CoreMeasureBaseComponent, MeasureUnit } from './CoreMeasureBaseComponent';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreTemperatureInputComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreTemperatureInputComponent),
	multi: true
};

@Component({
	selector: 'app-temperature-input',
	templateUrl: './CoreMeasureBaseComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreTemperatureInputComponent extends CoreMeasureBaseComponent implements OnInit, Validator {

	units: MeasureUnit[] = [
		{ factor: (1 / 1.8), offset: 32, name: '°F', metric: false },
		{ factor: 1, offset: 0, name: '°C', metric: true },
	];
	unit: MeasureUnit = this.units[1];
}
