import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum ShipmentType {
	None = 0,
	Fcl = 1,
	Lcl = 2,
}

@Pipe({
name: 'shipmentType'
})
export class ShipmentTypePipe extends BasePipe<ShipmentType> implements PipeTransform {
	static prefix = 'Backend.Types.ShipmentType.';
	static keys = [
		ShipmentType.None,
		ShipmentType.Fcl,
		ShipmentType.Lcl];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ShipmentType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ShipmentType.None: return 'None';
			case ShipmentType.Fcl: return 'Fcl';
			case ShipmentType.Lcl: return 'Lcl';
			default: return null;
		}
	}
	transform(value: ShipmentType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ShipmentTypePipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(ShipmentTypePipe.keys, ShipmentTypePipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ShipmentTypePipe
	],
	exports: [
		ShipmentTypePipe
	],
	providers: [
		ShipmentTypePipe
	]
})
export class ShipmentTypeModule { }
