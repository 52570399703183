import { AuthRequestCode } from 'app/generated/backend/auth/api/auth-request-code';
export class AuthRequest {
	code: AuthRequestCode;
	referrer: string;
	username: string;
	password: string;
	captcha: string;
	partyId: number;
	secondFactorResponse: string;
	remember: boolean;
}
