import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { CoreNumberInputComponent } from './CoreNumberInputComponent';


@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		FormsModule,
		TranslateModule,
		HelpPopupModule
	],
	exports: [CoreNumberInputComponent],
	declarations: [CoreNumberInputComponent]
})
export class CoreNumberInputModule { }
