import { Injectable } from '@angular/core';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { LanguageLookupModel } from 'app/generated/backend/localization/api/language-lookup-model';
import { LanguageLookupHandlerService } from 'app/generated/backend/localization/service/language-lookup-handler';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
@Injectable()
export class CoreLanguageService extends CachedEntityService<LanguageLookupModel> {
	constructor(
		languagesHandlerService: LanguageLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(languagesHandlerService, cacheService.getLanguages());
	}
	transformItem(item: LanguageLookupModel): LanguageLookupModel {
		if (item) {
			item.match = '';
			if (item.iso2) {
				item.match += item.iso2.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}
}
