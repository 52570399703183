import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RouteLocationsModel } from 'app/generated/backend/routing/api/route-locations-model';

@Injectable()
export class RouteMapHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		fromLat: number,
		fromLong: number,
		toLat: number,
		toLong: number
	): Observable<JsonResourceResponse<RouteLocationsModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (fromLat!==null && fromLat!==undefined) {
			params = params.set('fromLat', fromLat.toString());
		}
		if (fromLong!==null && fromLong!==undefined) {
			params = params.set('fromLong', fromLong.toString());
		}
		if (toLat!==null && toLat!==undefined) {
			params = params.set('toLat', toLat.toString());
		}
		if (toLong!==null && toLong!==undefined) {
			params = params.set('toLong', toLong.toString());
		}
		return this.http.get<JsonResourceResponse<RouteLocationsModel>>('api/v1/routing/routes/map', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		RouteMapHandlerService
	]
})
export class RouteMapHandlerModule { }
