import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PushSubscriptionRequest } from 'app/generated/backend/comm/api/push-subscription-request';

@Injectable()
export class PushSubscriptionHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	post(
		request: PushSubscriptionRequest
	): Observable<void> {
		return this.http.post<void>('api/comm/pushSubscription', request).pipe(
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		PushSubscriptionHandlerService
	]
})
export class PushSubscriptionHandlerModule { }
