import { ContentAccess } from 'app/generated/backend/content/api/content-access';
import { ContentBodyFormat } from 'app/generated/backend/content/api/content-body-format';
export class ContentTranslationModel {
	id: number;
	languageIso2: string;
	redirectUri: string;
	title: string;
	subtitle: string;
	description: string;
	keywords: string;
	body: string;
	uri: string;
	bodyFormat: ContentBodyFormat;
	access: ContentAccess;
	includeInNavigation: boolean;
	modified: string;
	children: number[];
}
