import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './AutocompleteComponent';
import { AutocompleteDirective } from './AutocompleteDirective';
import { AutocompleteContentDirective } from './AutocompleteContentDirective';
import { OptionComponent } from './option/OptionComponent';
import { AutocompleteFilterPipe } from './AutocompleteFilterPipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AutocompleteComponent,
    AutocompleteContentDirective,
    AutocompleteDirective,
    AutocompleteFilterPipe,
    OptionComponent
  ],
  exports: [
    AutocompleteComponent,
    AutocompleteContentDirective,
    AutocompleteDirective,
    AutocompleteFilterPipe,
    OptionComponent
  ]
})
export class AutocompleteModule {
}
