import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { CoreMeasureBaseComponent, MeasureUnit } from './CoreMeasureBaseComponent';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreWeightInputComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreWeightInputComponent),
	multi: true
};

@Component({
	selector: 'app-weight-input',
	templateUrl: './CoreMeasureBaseComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreWeightInputComponent extends CoreMeasureBaseComponent implements OnInit, Validator {

	units: MeasureUnit[] = [
		{ factor: 0.000001, name: 'mg', offset: 0, metric: true },
		{ factor: 0.001, name: 'g', offset: 0, metric: true },
		{ factor: 1, name: 'kg', offset: 0, metric: true },
		{ factor: 1000, name: 't', offset: 0, metric: true },
		{ factor: 0.02835, name: 'oz', offset: 0, metric: false },
		{ factor: 0.454, name: 'lbs', offset: 0, metric: false },
		{ factor: 6.35, name: 'st', offset: 0, metric: false },
		{ factor: 907, name: 'ton', offset: 0, metric: false }
	];
	unit: MeasureUnit = this.units[2];
}
