import { Injectable } from '@angular/core';
import { CoreAuthenticationService } from '../core/authentication/CoreAuthenticationService';
import { BookingState } from './BookingState';
import { IncotermsLookupModel } from 'app/generated/backend/trade/api/incoterms-lookup-model';

@Injectable()
export class BookingService {
	private _testMode: boolean;
	public cargoGroupsState: BookingState;
	public schedulesState: BookingState;
	public suppliersState: BookingState;
	public partiesState: BookingState;
	public incoterms: IncotermsLookupModel;

	private _partyId: number;
	public set partyId(partyId: number) {
		if (partyId !== this._partyId) {
			this.reset();
			this._partyId = partyId;
		}
	}

	reset() {
		this.cargoGroupsState = null;
		this.schedulesState = null;
		this.suppliersState = null;
		this.partiesState = null;
		this._testMode = false;
		this.incoterms = null;
	}
	setTestMode() {
		this._testMode = true;
	}
	getTestMode(): boolean {
		return this._testMode;
	}
	constructor(
		authenticationService: CoreAuthenticationService
	) {
		authenticationService.authenticationChanged.subscribe(authResponse => {
			if (authResponse?.party?.id) {
				this.partyId = authResponse.party.id;
			}
		});
	}
}
