<div *ngIf="place && !place.postalCode">
    <span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;

    <span *ngIf="place.nearestLocality && place.nearestLocality.code"
        class="error-text">{{'error.notExactAddressPostalCode' | translate
        :
        {'postalCode':place.nearestLocality.code, 'path':place.nearestLocality.path} }}</span>
    <span *ngIf="place.nearestLocality && !place.nearestLocality.code"
        class="error-text">{{'error.notExactAddressNoPostalCode' | translate
        :
        {'path':place.nearestLocality.path} }}</span>
    <span *ngIf="!place.nearestLocality" class="error-text">{{'error.noPlaceFound' | translate }}</span>

</div>