import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';

const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreNumberInputComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreNumberInputComponent),
	multi: true
};

function isNumeric(value: string | number): boolean {
	return !isNaN(Number(value.toString()));
}

@Component({
	selector: 'app-number-input',
	templateUrl: './CoreNumberInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreNumberInputComponent implements OnInit, Validator {
	@Input()
	required = false;

	@Input()
	disabled: boolean;

	@Input()
	min: number;

	@Input()
	max: number;

	@Input()
	helpUri: string;

	@Input()
	label: string;

	@Input()
	placeholder: string = '';

	@Input()
	hint: string;

	@Input()
	iconClass: string;

	@Input()
	suffix: string;

	@Input()
	integer: boolean = false;

	@Input()
	positiveOnly: boolean = false;

	@Input()
	public color = 'accent';

	@Output() blur = new EventEmitter<number>();

	@Output() focus = new EventEmitter<number>();

	// The internal data model
	private innerValue: number;

	public errorStateMatcher = new SimpleErrorStateMatcher();

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): any {
		return this.innerValue;
	};

	// returns null when valid else the validation object
	// in this case we're checking if the json parsing has
	// passed or failed from the onChange method
	public validate(c: UntypedFormControl) {
		if ((this.value == null || this.value.length < 1) && !this.required) {
			this.errorStateMatcher.valid = true;
			return null;
		}
		if (this.value == null || !isNumeric(this.value)) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				jsonParseError: {
					valid: false,
				},
			};
		}
		if (this.min) {
			const numeric = parseFloat(this.value);
			if (numeric < this.min) {
				this.errorStateMatcher.valid = false;
				this.errorStateMatcher.errorKey = 'error.numberLessThanMinimum';
				return {
					jsonParseError: {
						valid: false,
					},
				};
			}
		}
		if (this.max) {
			const numeric = parseFloat(this.value);
			if (numeric > this.max) {
				this.errorStateMatcher.valid = false;
				this.errorStateMatcher.errorKey = 'error.numberGreaterThanMaximum';
				return {
					jsonParseError: {
						valid: false,
					},
				};
			}
		}
		this.errorStateMatcher.valid = true;
		return null;
	}

	// set accessor including call the onchange callback
	set value(v: any) {
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.validate(null);
			this.onChangeCallback(v);
		}
	}

	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.validate(null);
		}
	}

	onBlur() {
		if (this.integer && this.value && parseInt(this.value)) {
			this.value = Math.round(parseInt(this.value));
		}
		if (this.positiveOnly && this.value && parseInt(this.value)) {
			this.value = Math.abs(parseInt(this.value));
		}
		this.blur.emit(this.value);
	}

	onFocus() {
		this.focus.emit(this.value);
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}
	constructor(
	) {
	}

	ngOnInit() {
	}
}
