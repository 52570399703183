<form #form="ngForm" *ngIf="state">
    <div class="row form-group">
        <div class="col-sm-5" *ngIf="originUseAddress">
            <app-address-lookup name="pickupAddress" label="{{'field.pickup.label' | translate}}"
                [(ngModel)]="pickupAddress" [required]="true" helpUri="help/booking/pickupAddress">
            </app-address-lookup>
            <app-place-detail-hint [place]="originPlace"></app-place-detail-hint>
        </div>
        <app-party-place-lookup class="col-sm-5" *ngIf="!originUseAddress" id="pickupPlace"
            label="{{'field.pickupPlace.label' | translate}}" name="pickupPlace" [(ngModel)]="pickupPartyPlaceId"
            (itemSelected)="setPickupPlace($event)" [required]="true" helpUri="help/booking/pickupPlace"
            [addNew]="true">
        </app-party-place-lookup>
        <div class="col-sm-1">
            <app-core-button-generic-icon *ngIf="hasPlaces && !originUseAddress" iconClass="fa-warehouse"
                titleTranslateKey="button.switchToAddressInput.tooltip" (action)="switchOriginUseAddress();">
            </app-core-button-generic-icon>
            <app-core-button-generic-icon *ngIf="hasPlaces && originUseAddress" iconClass="fa-map"
                titleTranslateKey="button.switchToPlaceInput.tooltip" (action)="switchOriginUseAddress();">
            </app-core-button-generic-icon>
        </div>
        <div class="col-sm-5" *ngIf="destinationUseAddress">
            <app-address-lookup name="deliveryAddress" label="{{'field.delivery.label' | translate}}"
                [(ngModel)]="deliveryAddress" [required]="true" helpUri="help/booking/deliveryAddress">
            </app-address-lookup>
            <app-place-detail-hint [place]="destinationPlace"></app-place-detail-hint>
        </div>
        <app-party-place-lookup class="col-sm-5" *ngIf="!destinationUseAddress" id="deliveryPlace"
            label="{{'field.deliveryPlace.label' | translate}}" name="deliveryPlace" [(ngModel)]="deliveryPartyPlaceId"
            (itemSelected)="setDeliveryPlace($event)" [required]="true" helpUri="help/booking/deliveryPlace"
            [addNew]="true">
        </app-party-place-lookup>
        <div class="col-sm-1">
            <app-core-button-generic-icon *ngIf="hasPlaces && !destinationUseAddress" iconClass="fa-warehouse"
                titleTranslateKey="button.switchToAddressInput.tooltip" (action)="switchDestinationUseAddress();">
            </app-core-button-generic-icon>
            <app-core-button-generic-icon *ngIf="hasPlaces && destinationUseAddress" iconClass="fa-map"
                titleTranslateKey="button.switchToPlaceInput.tooltip" (action)="switchDestinationUseAddress();">
            </app-core-button-generic-icon>
        </div>
    </div>
    <app-shipment-cargo-groups *ngIf="state?.shipment?.type" [(shipmentType)]="state.shipment.type"
        [(cargoGroups)]="state.shipment.cargoGroups" (cargoGroupsChange)="cargoGroupsChange($event)"
        (shipmentTypeChange)="shipmentTypeChange($event)"></app-shipment-cargo-groups>

    <hr />

    <div class="row form-group">
        <div class="col-sm-3 has-feedback"
            [ngClass]="{'has-error':!form.controls['pickupFrom']?.valid, 'has-success':form.controls['pickupFrom']?.valid}">
            <app-date-input id="pickupFrom" name="pickupFrom" [(ngModel)]="state.shipment.pickupFrom"
                [min]="minPickupFrom" label="{{'field.cargoReadyDate.label' | translate}}" [required]="true"
                helpUri="help/booking/earliestPickup" hasTime="true"></app-date-input>
        </div>
        <div class="col-sm-3 has-feedback"
            [ngClass]="{'has-error':!form.controls['incotermsId']?.valid, 'has-success':form.controls['incotermsId']?.valid}">
            <app-incoterms-lookup id="incotermsId" name="incotermsId" [(ngModel)]="state.shipment.incotermsId"
                (itemSelected)="setIncoterms($event)" label="{{'field.incoterms.label' | translate}}" [required]="true"
                helpUri="help/booking/incoterms">
            </app-incoterms-lookup>
        </div>

        <app-core-locality-lookup class="col-sm-3" name="namedOriginLocality"
            label="{{'field.namedOrigin.label' | translate}}" [(ngModel)]="namedOriginLocality" [type]="-3"
            [required]="incoterms?.hasNamedOrigin">
        </app-core-locality-lookup>

        <app-core-locality-lookup class="col-sm-3" name="namedDestinationLocality"
            label="{{'field.namedDestination.label' | translate}}" [(ngModel)]="namedDestinationLocality" [type]="-3"
            [required]="incoterms?.hasNamedDestination">
        </app-core-locality-lookup>

    </div>
    <div class="row form-group">
        <div class="col-sm-12 adn-buttons-horizontal text-right">
            <app-button-search (action)="onNext()"
                [disabled]="loading || !form.valid || widthGreaterThanLength || exceededMaxWeight">
            </app-button-search>
        </div>
    </div>
</form>