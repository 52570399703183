import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CoreCustomizationService } from 'app/core/customization/CoreCustomizationService';
import { LocaleSource } from 'app/core/customization/LocaleSource';
import { CoreLocaleLookupService } from 'app/core/locale/CoreLocaleLookupService';
import { LocaleLookupModel } from 'app/generated/backend/localization/api/locale-lookup-model';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-locale-select',
	templateUrl: './LocaleSelectComponent.html',
	styleUrls: ['LocaleSelectComponent.scss']
})
export class LocaleSelectComponent implements OnInit {

	private subscriptions = new Array<Subscription>();
	public moderatedLocales: LocaleLookupModel[];
	public otherLocales: LocaleLookupModel[];

	@Input()
	public isAdmin: boolean = false;

	@Input()
	public isVisible: boolean = false;

	@Output()
	public selected: EventEmitter<any> = new EventEmitter<any>();

	@HostListener('document:click', ['$event'])
	click(event: any) {
		if (!this.eRef.nativeElement.contains(event.target)) {
			if (!this.isVisible) {
				this.selected.emit(null);
			}
			this.isVisible = false;
		}
	}

	constructor(
		private localeService: CoreLocaleLookupService,
		private customizationService: CoreCustomizationService,
		private eRef: ElementRef
	) { }

	getShowKeys(): boolean {
		return this.customizationService.showKeys;
	}

	setLocale(locale: LocaleLookupModel) {
		this.customizationService.setLocale(locale, LocaleSource.UserSettings);
		this.selected.emit(null);
		this.isVisible = false;
	}

	setLocaleKeys() {
		this.customizationService.showKeys = true;
		this.selected.emit(null);
		this.isVisible = false;
	}

	ngOnInit() {
		this.moderatedLocales = [];
		this.otherLocales = [];
		this.localeService.getItems().subscribe(
			response => {
				response.forEach(locale => {
					if (locale.moderatedLanguage) {
						this.moderatedLocales.push(locale);
					} else {
						this.otherLocales.push(locale);
					}
				});
			}, error => {
				console.error(error);
			}
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}


}
