import { Component, ElementRef, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CoreAuthenticationService } from "app/core/authentication/CoreAuthenticationService";
import { CoreCustomizationService } from "app/core/customization/CoreCustomizationService";
import { CustomizationSettings } from "app/core/customization/CustomizationSettings";
import { ContentAccess } from "app/generated/backend/content/api/content-access";
import { TopicInfoModel } from "app/generated/backend/content/api/topic-info-model";
import { TopicInfoHandlerService } from "app/generated/backend/content/service/topic-info-handler";
import { AdminAccessControl } from "app/generated/backend/types/admin-access-control";
import { PermissionFlags } from "app/generated/backend/types/permission-flags";
import { Subscription } from "rxjs";
import { GlossaryPopupComponent } from "./GlossaryPopupComponent";

@Component({
	selector: 'app-glossary-tag',
	templateUrl: './GlossaryTagComponent.html',
	styleUrls: ['GlossaryComponent.scss']
})
export class GlossaryTagComponent implements OnDestroy {

	public title: string;
	public uri: string;
	protected subscriptions = new Array<Subscription>();

	constructor(
		private dialog: MatDialog,
		private service: TopicInfoHandlerService,
		private authenticationService: CoreAuthenticationService,
		private customizationService: CoreCustomizationService,
		private elementRef: ElementRef,
	) {
		this.title = elementRef.nativeElement.getAttribute('title');
		this.uri = elementRef.nativeElement.getAttribute('uri');
	}

	getFirstSubscription(language: string) {
		return new Promise((resolve) => {
			this.subscriptions[1] = this.service.get(this.uri, language, this.getContentAccess()).subscribe({
				next: response => resolve(response.data),
				error: error => {
					console.error(error);
				}
			});
		})
	}

	firstSubscription(settings: CustomizationSettings) {
		return new Promise((resolve) => {
			const language = settings.locale.languageIso2;
			if (this.subscriptions.length >= 2) {
				this.getFirstSubscription(language).then(value => resolve(value));
			} else {
				this.subscriptions.push(this.service.get(this.uri, language, this.getContentAccess()).subscribe({
					next: response => resolve(response.data),
					error: error => {
						console.error(error);
					}
				}));
			}
		})
	}

	updateFirstSubscription() {
		return new Promise((resolve) => {
			this.subscriptions[0] = this.customizationService.changed.subscribe(settings => this.firstSubscription(settings).then((value: TopicInfoModel) => resolve(value)));
		})
	}

	handleSubscription() {
		return new Promise((resolve) => {
			if (this.subscriptions.length >= 2) {
				this.updateFirstSubscription().then(value => resolve(value))
			} else {
				this.subscriptions.push(this.customizationService.changed.subscribe(settings => this.firstSubscription(settings).then((value: TopicInfoModel) => resolve(value))));
			}
		})
	}

	openDialog(event: Event) {
		event.stopPropagation();
		this.handleSubscription().then((value: TopicInfoModel) => {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.hasBackdrop = true;
			dialogConfig.autoFocus = true;
			dialogConfig.data = {
				title: this.title,
				uri: this.uri,
				data: value
			};
			const dialogRef = this.dialog.open(GlossaryPopupComponent, dialogConfig);
			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					window.scrollTo(0, 0);
				}
			});
		})
	}

	getContentAccess(): ContentAccess {
		if (this.authenticationService.hasAdminPermission(AdminAccessControl.Content, PermissionFlags.Read)) {
			return ContentAccess.Confidential;
		}
		if (this.authenticationService.getUserId()) {
			return ContentAccess.Restricted;
		}
		return ContentAccess.Public;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

}
