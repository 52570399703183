import { Component, Input } from '@angular/core';
import { PlacesDetailModel } from 'app/generated/backend/locality/api/places-detail-model';

@Component({
	selector: 'app-place-detail-hint',
	templateUrl: './PlaceDetailHintComponent.html'
})
export class PlaceDetailHintComponent {
	@Input()
	public place: PlacesDetailModel;

	constructor(
	) {
	}
}
