import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum ErrorCode {
	Success = 0,
	InternalError = 1,
	EmailAlreadyExists = 2,
	InvalidCountry = 3,
	InvalidVatNumber = 4,
	VatAlreadyExists = 5,
	InvalidEmail = 6,
	UserLockout = 7,
	InvalidPassword = 8,
	InvalidSecondFactorResponse = 9,
	VerificationExpired = 10,
	UserDisabled = 11,
	InvalidCaptcha = 12,
	CompanyIsAlreadyRegistered = 13,
	InvalidRegistrationCountry = 14,
	AuthenticationChallenge = 15,
	InvalidContainerNumber = 16,
	OverlappingRate = 17,
	VirusDetected = 18,
	MissingValue = 19,
	OutOfRange = 20,
	MustBeGreaterThan = 21,
	OtherObjectsDependOnThisObject = 22,
	ObjectAlreadyExists = 23,
	MixOfRateBasisNotSupported = 24,
	ConflictValue = 25,
	ObjectNotFound = 26,
	InvalidDate = 27,
	InvalidNumber = 28,
	ValueNotSupported = 29,
	MoreThanOneObjectsFound = 30,
	ContainerClosed = 31,
	WrongUser = 32,
	WrongParty = 33,
	NoPermission = 34,
	NotLoggedIn = 35,
	PreConditionsNotMet = 36,
	LineTooLong = 37,
	MissingParty = 38,
	InvalidType = 39,
	NoLinkParty = 40,
	NotMatchLocation = 41,
	NotMatchFacility = 42,
	UnavailableSlot = 43,
	InvalidIsoTimestamp = 44,
	UnknownOlsonTimeZone = 45,
	InvalidHsCode = 46,
	InvalidEan = 47,
	InvalidUpc = 48,
	InvalidTimePriorToEtd = 49,
	NoFeature = 50,
	InvalidPackageType = 51,
	InvalidSeal = 52,
	InvalidCarrier = 53,
}

@Pipe({
name: 'errorCode'
})
export class ErrorCodePipe extends BasePipe<ErrorCode> implements PipeTransform {
	static prefix = 'Backend.Service.ErrorCode.';
	static keys = [
		ErrorCode.Success,
		ErrorCode.InternalError,
		ErrorCode.EmailAlreadyExists,
		ErrorCode.InvalidCountry,
		ErrorCode.InvalidVatNumber,
		ErrorCode.VatAlreadyExists,
		ErrorCode.InvalidEmail,
		ErrorCode.UserLockout,
		ErrorCode.InvalidPassword,
		ErrorCode.InvalidSecondFactorResponse,
		ErrorCode.VerificationExpired,
		ErrorCode.UserDisabled,
		ErrorCode.InvalidCaptcha,
		ErrorCode.CompanyIsAlreadyRegistered,
		ErrorCode.InvalidRegistrationCountry,
		ErrorCode.AuthenticationChallenge,
		ErrorCode.InvalidContainerNumber,
		ErrorCode.OverlappingRate,
		ErrorCode.VirusDetected,
		ErrorCode.MissingValue,
		ErrorCode.OutOfRange,
		ErrorCode.MustBeGreaterThan,
		ErrorCode.OtherObjectsDependOnThisObject,
		ErrorCode.ObjectAlreadyExists,
		ErrorCode.MixOfRateBasisNotSupported,
		ErrorCode.ConflictValue,
		ErrorCode.ObjectNotFound,
		ErrorCode.InvalidDate,
		ErrorCode.InvalidNumber,
		ErrorCode.ValueNotSupported,
		ErrorCode.MoreThanOneObjectsFound,
		ErrorCode.ContainerClosed,
		ErrorCode.WrongUser,
		ErrorCode.WrongParty,
		ErrorCode.NoPermission,
		ErrorCode.NotLoggedIn,
		ErrorCode.PreConditionsNotMet,
		ErrorCode.LineTooLong,
		ErrorCode.MissingParty,
		ErrorCode.InvalidType,
		ErrorCode.NoLinkParty,
		ErrorCode.NotMatchLocation,
		ErrorCode.NotMatchFacility,
		ErrorCode.UnavailableSlot,
		ErrorCode.InvalidIsoTimestamp,
		ErrorCode.UnknownOlsonTimeZone,
		ErrorCode.InvalidHsCode,
		ErrorCode.InvalidEan,
		ErrorCode.InvalidUpc,
		ErrorCode.InvalidTimePriorToEtd,
		ErrorCode.NoFeature,
		ErrorCode.InvalidPackageType,
		ErrorCode.InvalidSeal,
		ErrorCode.InvalidCarrier];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ErrorCode): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ErrorCode.Success: return 'Success';
			case ErrorCode.InternalError: return 'InternalError';
			case ErrorCode.EmailAlreadyExists: return 'EmailAlreadyExists';
			case ErrorCode.InvalidCountry: return 'InvalidCountry';
			case ErrorCode.InvalidVatNumber: return 'InvalidVatNumber';
			case ErrorCode.VatAlreadyExists: return 'VatAlreadyExists';
			case ErrorCode.InvalidEmail: return 'InvalidEmail';
			case ErrorCode.UserLockout: return 'UserLockout';
			case ErrorCode.InvalidPassword: return 'InvalidPassword';
			case ErrorCode.InvalidSecondFactorResponse: return 'InvalidSecondFactorResponse';
			case ErrorCode.VerificationExpired: return 'VerificationExpired';
			case ErrorCode.UserDisabled: return 'UserDisabled';
			case ErrorCode.InvalidCaptcha: return 'InvalidCaptcha';
			case ErrorCode.CompanyIsAlreadyRegistered: return 'CompanyIsAlreadyRegistered';
			case ErrorCode.InvalidRegistrationCountry: return 'InvalidRegistrationCountry';
			case ErrorCode.AuthenticationChallenge: return 'AuthenticationChallenge';
			case ErrorCode.InvalidContainerNumber: return 'InvalidContainerNumber';
			case ErrorCode.OverlappingRate: return 'OverlappingRate';
			case ErrorCode.VirusDetected: return 'VirusDetected';
			case ErrorCode.MissingValue: return 'MissingValue';
			case ErrorCode.OutOfRange: return 'OutOfRange';
			case ErrorCode.MustBeGreaterThan: return 'MustBeGreaterThan';
			case ErrorCode.OtherObjectsDependOnThisObject: return 'OtherObjectsDependOnThisObject';
			case ErrorCode.ObjectAlreadyExists: return 'ObjectAlreadyExists';
			case ErrorCode.MixOfRateBasisNotSupported: return 'MixOfRateBasisNotSupported';
			case ErrorCode.ConflictValue: return 'ConflictValue';
			case ErrorCode.ObjectNotFound: return 'ObjectNotFound';
			case ErrorCode.InvalidDate: return 'InvalidDate';
			case ErrorCode.InvalidNumber: return 'InvalidNumber';
			case ErrorCode.ValueNotSupported: return 'ValueNotSupported';
			case ErrorCode.MoreThanOneObjectsFound: return 'MoreThanOneObjectsFound';
			case ErrorCode.ContainerClosed: return 'ContainerClosed';
			case ErrorCode.WrongUser: return 'WrongUser';
			case ErrorCode.WrongParty: return 'WrongParty';
			case ErrorCode.NoPermission: return 'NoPermission';
			case ErrorCode.NotLoggedIn: return 'NotLoggedIn';
			case ErrorCode.PreConditionsNotMet: return 'PreConditionsNotMet';
			case ErrorCode.LineTooLong: return 'LineTooLong';
			case ErrorCode.MissingParty: return 'MissingParty';
			case ErrorCode.InvalidType: return 'InvalidType';
			case ErrorCode.NoLinkParty: return 'NoLinkParty';
			case ErrorCode.NotMatchLocation: return 'NotMatchLocation';
			case ErrorCode.NotMatchFacility: return 'NotMatchFacility';
			case ErrorCode.UnavailableSlot: return 'UnavailableSlot';
			case ErrorCode.InvalidIsoTimestamp: return 'InvalidIsoTimestamp';
			case ErrorCode.UnknownOlsonTimeZone: return 'UnknownOlsonTimeZone';
			case ErrorCode.InvalidHsCode: return 'InvalidHsCode';
			case ErrorCode.InvalidEan: return 'InvalidEan';
			case ErrorCode.InvalidUpc: return 'InvalidUpc';
			case ErrorCode.InvalidTimePriorToEtd: return 'InvalidTimePriorToEtd';
			case ErrorCode.NoFeature: return 'NoFeature';
			case ErrorCode.InvalidPackageType: return 'InvalidPackageType';
			case ErrorCode.InvalidSeal: return 'InvalidSeal';
			case ErrorCode.InvalidCarrier: return 'InvalidCarrier';
			default: return null;
		}
	}
	transform(value: ErrorCode, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ErrorCodePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ErrorCodePipe
	],
	exports: [
		ErrorCodePipe
	],
	providers: [
		ErrorCodePipe
	]
})
export class ErrorCodeModule { }
