import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum ContentAccess {
	Public = 0,
	Restricted = 1,
	Confidential = 2,
}

@Pipe({
name: 'contentAccess'
})
export class ContentAccessPipe extends BasePipe<ContentAccess> implements PipeTransform {
	static prefix = 'Backend.Content.Api.ContentAccess.';
	static keys = [
		ContentAccess.Public,
		ContentAccess.Restricted,
		ContentAccess.Confidential];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ContentAccess): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ContentAccess.Public: return 'Public';
			case ContentAccess.Restricted: return 'Restricted';
			case ContentAccess.Confidential: return 'Confidential';
			default: return null;
		}
	}
	transform(value: ContentAccess, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ContentAccessPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ContentAccessPipe
	],
	exports: [
		ContentAccessPipe
	],
	providers: [
		ContentAccessPipe
	]
})
export class ContentAccessModule { }
