import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreCaptchaImageComponent } from './CoreCaptchaImageComponent';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		CommonMaterialModule
	],
	exports: [CoreCaptchaImageComponent],
	declarations: [CoreCaptchaImageComponent]
})
export class CoreCaptchaModule { }
