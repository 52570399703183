import { MapsAPILoader } from "@agm/core";
import { Component, Inject, Injector } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CoreCustomizationService } from "app/core/customization/CoreCustomizationService";
import { GeocodingHandlerService } from "app/generated/backend/locality/service/geocoding-handler";
import { ReverseGeocodingHandlerService } from "app/generated/backend/locality/service/reverse-geocoding-handler";
import { PartyPlaceDetailHandlerService } from 'app/generated/backend/yard/service/party-place-detail-handler';
import { PartyPlaceDetailComponent } from "./PartyPlaceDetailComponent";

export interface PartyPlaceAddDialogData {
}

@Component({
  templateUrl: './PartyPlaceDetailComponent.html'
})
export class PartyPlaceAddDialog extends PartyPlaceDetailComponent {

  constructor(
    public dialogRef: MatDialogRef<PartyPlaceAddDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PartyPlaceAddDialogData,
    service: PartyPlaceDetailHandlerService,
    mapsAPILoader: MapsAPILoader,
    customizationService: CoreCustomizationService,
    protected reverseGeocodingService: ReverseGeocodingHandlerService,
    protected geoCodingService: GeocodingHandlerService,
    protected dialog: MatDialog,
    injector: Injector) {
    super(service, customizationService, mapsAPILoader, reverseGeocodingService, geoCodingService, dialog, injector);
  }
  onSaved() {
    this.dialogRef.close(this.editItem);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
