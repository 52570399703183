import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryTimeZoneLookupModel } from 'app/generated/backend/localization/api/country-time-zone-lookup-model';
import { BaseIdInputTypeaheadComponent } from 'app/_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent';
import { CoreCountryTimeZoneLookupService } from "./CoreCountryTimeZoneLookupService";


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreCountryTimeZoneLookupComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreCountryTimeZoneLookupComponent),
	multi: true
};

@Component({
	selector: 'app-core-country-time-zone-lookup',
	templateUrl: '../../_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreCountryTimeZoneLookupComponent extends BaseIdInputTypeaheadComponent<CountryTimeZoneLookupModel> {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string;

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Output()
	itemSelected: EventEmitter<CountryTimeZoneLookupModel> = new EventEmitter<CountryTimeZoneLookupModel>();

	constructor(protected service: CoreCountryTimeZoneLookupService) {
		super(service);
	}

	toDisplayText(item: CountryTimeZoneLookupModel): string {
		return this.toOptionText(item);
	}

	toOptionText(item: CountryTimeZoneLookupModel): string {
		if (!item) {
			return null;
		}
		return item.olson;
	}
}
