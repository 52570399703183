import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreTextInputComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreTextInputComponent),
	multi: true
};
export enum PatternType {
	None = 0,
	WebsiteUrl = 1,
	Ean = 2,
	Upc = 3
}
class Map {
	key: number;
	value: number;
}
@Component({
	selector: 'app-text-input',
	templateUrl: './CoreTextInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreTextInputComponent implements ControlValueAccessor, Validator {
	@Input()
	required = false;

	@Input()
	rows: number;

	@Input()
	disabled: boolean;

	@Input()
	helpUri: string;

	@Input()
	label: string;

	@Input()
	placeholder: string = '';

	@Input()
	hint: string;

	@Input()
	maxlength: number;

	@Input()
	maxLengthPerLine: number;

	@Input()
	minlength: number;

	@Input()
	iconClass: string;

	@Input()
	prefix: string;

	@Output()
	blur: EventEmitter<any> = new EventEmitter<any>();

	@Input()
	set pattern(pattern: string) {
		if (pattern !== this._pattern) {
			this._pattern = pattern;
			this._regExp = new RegExp(pattern);
		}
	}

	get pattern(): string {
		return this._pattern;
	}
	@Input()
	patternType: PatternType = 0;

	public errorStateMatcher = new SimpleErrorStateMatcher();
	private websiteUrlPattern = new RegExp('^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,3}(:[0-9]{1,5})?(\/.*)?$');
	// The internal data model
	private _pattern: string;
	private _regExp: RegExp;
	private innerValue: string;

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): string {
		return this.innerValue;
	};

	public constructor(
	) {
	}

	// returns null when valid else the validation object
	// in this case we're checking if the json parsing has
	// passed or failed from the onChange method
	public validate(c: UntypedFormControl): ValidationErrors | null {
		if ((this.value == null || this.value.length < 1) && this.required && !this.disabled) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				required: true
			};
		}
		if (this.value) {
			if (!this.value.toString().trim) {
				console.error(this.value.toString() + ' is not a string');
			}
			else if (this.value.toString().trim() === '') {
				if (this.required) {
					this.errorStateMatcher.valid = false;
					this.errorStateMatcher.errorKey = 'error.required';
					return {
						required: true
					};
				}
			}
		}
		if (this.value && this._regExp && !this._regExp.test(this.value)) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.invalidCharacters';
			return {
				invalidCharacters: true
			};
		}
		if (this.value && this.patternType === PatternType.WebsiteUrl && !this.websiteUrlPattern.test(this.value)) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.invalidCharacters';
			return {
				invalidCharacters: true
			};
		}
		if (this.value && this.patternType === PatternType.Ean && !this.validateEan(this.value)) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.invalidEan';
			return {
				invalidCharacters: true
			};
		}
		if (this.value && this.patternType === PatternType.Upc && !this.validateUpc(this.value)) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.invalidUpc';
			return {
				invalidCharacters: true
			};
		}
		if (this.value && this.maxlength && !this.maxLengthPerLine && this.value.length > 0 && this.value.length > this.maxlength) {
			return this.validateMaxLength();
		}
		if (this.value && this.maxLengthPerLine && this.value.length > 0) {
			let isValid = true;
			isValid = this.validateMaxLengthPerLine(isValid);
			if (isValid && this.maxlength && this.value.length > this.maxlength) {
				return this.validateMaxLength();
			}
			if (!isValid) {
				this.errorStateMatcher.valid = false;
				this.errorStateMatcher.errorKey = 'error.exceedsMaxLengthPerLine';
				return {
					exceedsMaxLength: true
				}
			}
		}
		if (this.value && this.minlength && this.value.length > 0 && this.value.length < this.minlength) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.belowMinLength';
			return {
				belowMinLength: true
			};
		}
		this.errorStateMatcher.valid = true;
		return null;
	}

	private validateMaxLengthPerLine(isValid: boolean) {
		let splittedLines = this.value.split('\n');
		if (splittedLines) {
			splittedLines.forEach(line => {
				if (line.length > this.maxLengthPerLine) {
					isValid = false;
				}
			});
		}
		return isValid;
	}

	private validateMaxLength() {
		this.errorStateMatcher.valid = false;
		this.errorStateMatcher.errorKey = 'error.exceedsMaxLength';
		return {
			exceedsMaxLength: true
		};
	}

	private validateEan(value: string): boolean {
		let eanMaps: Map[] = [];
		for (let i = 0; i < value.length - 1; i++) {
			if (isNaN(+value[i])) return false;
		}
		for (let i = 0; i < value.length - 1; i++) {
			let map = new Map();
			map.key = +value[i];
			map.value = 0;
			eanMaps.push(map);
		}
		let checkSum = +value[value.length - 1];

		let firstDigit = 0;
		if (eanMaps.length % 2 === 0) {
			firstDigit = 1;
		} else {
			firstDigit = 3;
		}
		let sum: number = 0;
		eanMaps.forEach(ean => {
			ean.value += ean.key * firstDigit;
			sum += ean.value;
			if (firstDigit === 1) {
				firstDigit = 3;
			} else {
				firstDigit = 1;
			}
		});
		let nearestValue = sum;
		while (nearestValue % 10 !== 0) {
			nearestValue += 1;
		}
		if (nearestValue - sum == checkSum) {
			return true;
		}
		return false;
	}

	private validateUpc(value: string): boolean {
		let evenSum: number = 0;
		let oddSum: number = 0;
		let checkSum: number = +value[value.length - 1];
		for (let i = 0; i < value.length - 1; i++) {
			if (i % 2 === 0) {
				oddSum += +value[i];
			} else {
				evenSum += +value[i];
			}
		}
		oddSum = oddSum * 3;
		let result = oddSum + evenSum;
		let m = result % 10;
		if (m && m > 0 && (10 - m === checkSum)) {
			return true;
		}
		return false;
	}

	// set accessor including call the onchange callback
	set value(v: string) {
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.validate(null);
			this.onChangeCallback(v);
		}
	}

	// From ControlValueAccessor interface
	writeValue(value: string) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.validate(null);
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	onBlur() {
		if (this.value) {
			const trimmed = this.value.trim();
			if (!trimmed.length) {
				this.value = null;
			} else {
				this.value = trimmed;
			}
		} else {
			this.value = null;
		}
		this.blur.emit(this.value);
	}

	onClear() {
		this.value = null;
		this.onBlur();
	}
}
