import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs';
import { AlertBanner } from '../alert/alert-banner';
import { AlertService } from '../alert/alert.service';
import { TimerService } from '../timer/timer.service';
import { Version } from './version';

@Injectable()
export class VersionCheckService {
	private currentVersion: string;
	public versionChanged = new BehaviorSubject<Version>(null);

	constructor(
		protected http: HttpClient,
		protected alertService: AlertService,
		private timerService: TimerService,
		@Optional() private swUpdate: SwUpdate
	) {
	}

	public initVersionCheck(url: string) {
		if (this.swUpdate && this.swUpdate.isEnabled) {
			this.swUpdate.available.subscribe(event => {
				this.alertNewVersion();
			});
			this.timerService.everyMinute.subscribe(() => this.swUpdate.checkForUpdate());
			this.checkVersion(url);
		} else {
			this.timerService.everyMinute.subscribe(() => this.checkVersion(url));

		}
	}

	launchNewVersion() {
		if (this.swUpdate && this.swUpdate.isEnabled) {
			this.swUpdate.activateUpdate().then(() => {
				location.reload();
			});
		} else {
			window.location.reload();
		}
	}

	private alertNewVersion() {
		const banner = new AlertBanner();
		banner.type = 'versionChanged';
		banner.autoAction = true;
		banner.action = this.launchNewVersion;
		this.alertService.banner(banner);
	}

	private checkVersion(url: string) {
		// timestamp these requests to invalidate caches
		this.http.get(url + '?t=' + new Date().getTime())
			.subscribe(
				{
					next: (response: Version) => {
						this.alertService.connectionStatus(true);
						if (this.currentVersion && this.currentVersion !== response.version) {
							this.alertNewVersion();
						}
						if (this.currentVersion !== response.version) {
							this.versionChanged.next(response);
						}
						this.currentVersion = response.version;
					},
					error:
						(err) => {
							this.alertService.connectionStatus(false);
						}
				}
			);
	}

}