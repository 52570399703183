import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum ContainerSize {
	None = 0,
	Iso22 = 1,
	Iso42 = 2,
	Iso45 = 4,
	All = 7,
}

@Pipe({
name: 'containerSize'
})
export class ContainerSizePipe extends BasePipe<ContainerSize> implements PipeTransform {
	static prefix = 'Backend.Types.ContainerSize.';
	static keys = [
		ContainerSize.None,
		ContainerSize.Iso22,
		ContainerSize.Iso42,
		ContainerSize.Iso45,
		ContainerSize.All];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ContainerSize): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ContainerSize.None: return 'None';
			case ContainerSize.Iso22: return 'Iso22';
			case ContainerSize.Iso42: return 'Iso42';
			case ContainerSize.Iso45: return 'Iso45';
			case ContainerSize.All: return 'All';
			default: return null;
		}
	}
	transform(value: ContainerSize, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ContainerSizePipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(ContainerSizePipe.keys, ContainerSizePipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ContainerSizePipe
	],
	exports: [
		ContainerSizePipe
	],
	providers: [
		ContainerSizePipe
	]
})
export class ContainerSizeModule { }
