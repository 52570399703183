import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LanguageLookupModel } from 'app/generated/backend/localization/api/language-lookup-model';

@Injectable()
export class LanguageLookupHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
	): Observable<JsonResourceResponse<LanguageLookupModel[]>> {
		return this.http.get<JsonResourceResponse<LanguageLookupModel[]>>('api/v1/Localization/languages/lookup').pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		LanguageLookupHandlerService
	]
})
export class LanguageLookupHandlerModule { }
