import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';


@Injectable({
    providedIn: 'root'
})
export class CoreChatterService {

    subject: WebSocketSubject<unknown>;
    public messageReceived: BehaviorSubject<any> = new BehaviorSubject(null);

    public connect(): void {
        if (this.subject) {
            this.subject.complete();
        }
        try {
            var loc = window.location, new_uri: string;
            if (loc.protocol === "https:") {
                new_uri = "wss:";
            } else {
                new_uri = "ws:";
            }
            new_uri += "//" + loc.host;
            new_uri += loc.pathname + "chatter/ws";
            console.log(new_uri);
            this.subject = webSocket(new_uri);

            this.subject.subscribe({
                next: msg => this.messageReceived.next(msg), // Called whenever there is a message from the server.
                error: err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
                complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
            });
        } catch {

        }
    }

    sendMessage(msg: any) {
        this.subject.next(msg);
    }
    close() {
        try {
            this.subject.complete(); // Closes the connection.
        } catch {

        }
    }

}