import { MatchableEntity } from 'app/_models/matchable-entity';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { CoreEntityCache } from '../core/cache/CoreEntityCache';
import { JsonResourceResponse } from '../core/json/json-resource-response';

export interface EntityService<T extends MatchableEntity> {
	get(): Observable<JsonResourceResponse<T[]>>;
}

export class CachedEntityService<T extends MatchableEntity> {
	private getItemsObservable: Observable<JsonResourceResponse<T[]>>;


	constructor(
		protected service: EntityService<T>,
		protected cache: CoreEntityCache<T>
	) {
		this.getItemsObservable = this.service.get().pipe(share());
	}

	handleError(error: Response | any) {
		return throwError(error);
	}

	transformItem(item: T): T {
		return item;
	}

	transformItems(items: T[]): T[] {
		items.forEach(item => this.transformItem(item));
		return items;
	}

	getItem(id: number): Observable<T> {

		const item = this.cache.getItem(id);
		if (item) {
			return of(item);
		}
		return this.getItems().pipe(
			map(response => {
				return this.cache.getItem(id);
			}));
	}
	getItems(): Observable<T[]> {

		const items = this.cache.getItems();
		if (items) {
			return of(items);
		}

		return this.getItemsObservable.pipe(
			map(response => {
				const items = this.transformItems(response.data);
				if (this.cache) {
					this.cache.setItems(items);
				}
				return items;
			}
			),
			catchError(this.handleError));
	}

}
