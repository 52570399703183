import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreTaskSummaryComponent } from 'app/core/task-summary/CoreTaskSummaryComponent';
import { CommonMaterialModule } from '../common-material/core-common-material.module';

@NgModule({
	imports: [
		CommonModule,
		CommonMaterialModule,
	],
	exports: [CoreTaskSummaryComponent],
	declarations: [CoreTaskSummaryComponent]
})
export class CoreTaskSummaryModule { }
