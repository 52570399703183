import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum ProcessVariant {
	None = 0,
	Collaboration = 1,
	Tracking = 2,
	Destination = 3,
}

@Pipe({
name: 'processVariant'
})
export class ProcessVariantPipe extends BasePipe<ProcessVariant> implements PipeTransform {
	static prefix = 'Backend.Types.ProcessVariant.';
	static keys = [
		ProcessVariant.None,
		ProcessVariant.Collaboration,
		ProcessVariant.Tracking,
		ProcessVariant.Destination];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ProcessVariant): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ProcessVariant.None: return 'None';
			case ProcessVariant.Collaboration: return 'Collaboration';
			case ProcessVariant.Tracking: return 'Tracking';
			case ProcessVariant.Destination: return 'Destination';
			default: return null;
		}
	}
	transform(value: ProcessVariant, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ProcessVariantPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ProcessVariantPipe
	],
	exports: [
		ProcessVariantPipe
	],
	providers: [
		ProcessVariantPipe
	]
})
export class ProcessVariantModule { }
