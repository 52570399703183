import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreFormFieldDisplayComponent } from 'app/core/form/CoreFormFieldDisplayComponent';
import { CoreButtonModule } from '../button/CoreButtonModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';

@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		CoreButtonModule,
		FormsModule,
		TranslateModule,
		HelpPopupModule
	],
	exports: [
		CoreFormFieldDisplayComponent
	],
	declarations: [
		CoreFormFieldDisplayComponent
	]
})
export class CoreFormModule { }
