import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreMapModule } from 'app/core/map/CoreMapModule';
import { RouteMapHandlerModule } from 'app/generated/backend/routing/service/route-map-handler';
import { AlertModule } from '../alert/alert.module';
import { CoreButtonModule } from '../button/CoreButtonModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { RailMapComponent } from './RailMapComponent';

@NgModule({
	imports: [
		AlertModule,
		CommonMaterialModule,
		CommonModule,
		RouteMapHandlerModule,
		CoreButtonModule,
		CoreMapModule,
		FormsModule,
		TranslateModule

	],
	declarations: [
		RailMapComponent
	],
	exports: [
		RailMapComponent
	]
})
export class RailMapModule { }
