import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleSource } from 'app/core/customization/LocaleSource';
import { CoreLocaleLookupService } from 'app/core/locale/CoreLocaleLookupService';
import { LocaleLookupModel } from 'app/generated/backend/localization/api/locale-lookup-model';
import { AlertService } from '../../../core/alert/alert.service';
import { CoreAuthenticationService } from '../../../core/authentication/CoreAuthenticationService';
import { CoreCustomizationService } from '../../../core/customization/CoreCustomizationService';
import { CustomizationSettings } from '../../../core/customization/CustomizationSettings';
import { AuthResponse } from '../../../generated/backend/auth/api/auth-response';
import { PartyCategory } from '../../../generated/backend/types/party-category';



@Component({
	selector: 'app-user-menu-alternatives',
	templateUrl: './user-menu-alternatives.component.html'
})
export class UserMenuAlternativesComponent implements OnInit {

	@Input()
	public isSideMenu: boolean;

	@Input()
	public userName: string;

	//public authentication: AuthResponse;
	public id: string;

	public language: string;
	public country: string;
	public isLoggedIn = false;
	public isAdmin = false;
	public authentication: AuthResponse = null;
	public isCustomer = false;
	public locales: LocaleLookupModel[];

	updateSettings(settings: CustomizationSettings) {
		this.language = settings.locale.identifier;
		this.country = settings.locale.countryIso2;
	}

	constructor(
		private alertService: AlertService,
		private customizationService: CoreCustomizationService,
		protected authenticationService: CoreAuthenticationService,
		private router: Router,
		private localeService: CoreLocaleLookupService) {
		this.authentication = this.authenticationService.getAuthentication();
		this.authenticationService.authenticationChanged.subscribe(user => this.onAuthenticationChanged(user));

	}

	setLocale(locale: LocaleLookupModel) {
		this.customizationService.setLocale(locale, LocaleSource.UserSettings);
	}

	setLocaleKeys() {
		this.country = null;
		this.customizationService.showKeys = true;
	}

	doLogout() {
		this.authenticationService.logout().subscribe(
			data => {
				this.authenticationService.validate().subscribe(() => {
					this.router.navigate(['/']);
				});
			},
			error => {
				this.alertService.error(error);
			});
	}

	private onAuthenticationChanged(authentication: AuthResponse): void {
		this.isLoggedIn = authentication != null && authentication.user != null;
		this.isAdmin = authentication != null && authentication.adminPermissions &&
			authentication.adminPermissions.length > 0;
		this.authentication = authentication;
		this.isCustomer = false;
		if (authentication != null && authentication.party != null && authentication.party.permissions != null) {
			// tslint:disable-next-line:no-bitwise
			this.isCustomer = (authentication.party.category & PartyCategory.Customer) === PartyCategory.Customer;
		}
	}

	ngOnInit() {
		this.localeService.getItems().subscribe(response => {
			this.locales = response;
		}, error => { console.error(error); });
	}

	goTo(navigationRoutes: string[]) {
		this.router.navigate(navigationRoutes);
	}
}
