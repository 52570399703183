<div class="adn-form-field"
    [ngClass]="{'adn-form-field-invalid': !disabled && !errorStateMatcher.valid, 'adn-form-field-disabled': disabled}">
    <label>
        <span *ngIf="iconClass" class="fal fa-fw" [ngClass]="iconClass" aria-hidden="true">&nbsp;</span>{{label}}
        <ng-content select="app-text-label"></ng-content><span *ngIf="required">&nbsp;*</span>
    </label>
    <div cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="adn-form-field-content">
        <input class="input-style" type="text" [ngModel]="inputModel" (ngModelChange)="onInputChanged($event)"
           (click)="toggleCalendar()" [required]="required" [disabled]="disabled"
            [placeholder]="placeholder" autocomplete="none">
        <div class="input-icon" *ngIf="inputModel">
            <button mat-button mat-icon-button matSuffix *ngIf="inputModel && !required && !disabled" type="button"
                (click)="onClear()" title="{{ 'field.clear.label' | translate}}" tabindex="-1">
                <span class="fal fa-fw fa-times-circle"></span>
            </button>
        </div>
        <div class="input-icon">
            <button mat-button mat-icon-button matSuffix title="{{'button.viewCalendar' | translate}}" tabindex="-1"
                type="button" [disabled]="disabled" (click)="toggleCalendar()">
                <span class="fal fa-calendar"></span>
            </button>
        </div>
    </div>
</div>
<div class="adn-form-field-error" *ngIf="!disabled && !errorStateMatcher.valid && !showCalendar">
    <span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;
    <span class="error-text">{{errorStateMatcher.errorKey | translate}}</span>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showCalendar">
    <ngb-datepicker class="ngb-datepicker-background-white" *ngIf="showCalendar" [ngModel]="datePickerModel"
        [minDate]="minDateModel" [maxDate]="maxDateModel" [markDisabled]="markDisabled" [startDate]="getStartDate()"
        (dateSelect)=" onDateSelect($event)" [dayTemplate]="customDay" (clickOut)="clickOut()">
    </ngb-datepicker>
</ng-template>

<ng-template #customDay let-date="date" let-today="today" let-currentMonth="currentMonth" let-selected="selected"
    let-disabled="disabled">
    <span class="custom-day" [class.selected-day]="selected" [class.disabled-day]="disabled"
        [class.today-day]="today">{{date.day}}</span>
</ng-template>