<mat-card *ngIf="banner" class="adn-alert-banner-card">
    <mat-card-header>
        <div mat-card-avatar class="fal fa-fw fa-material fa-exclamation"></div>
        <mat-card-title>{{'banner.' + banner.type + '.title' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>{{'banner.' + banner.type + '.content' | translate}}</p>
        <p *ngIf="banner.autoAction">{{'banner.' + banner.type + '.autoAction' | translate: { secondsLeft:
            banner.secondsLeft } }}</p>
    </mat-card-content>
    <mat-card-actions align="end">
        <app-button-reload (action)="onBannerAction(banner)"></app-button-reload>
    </mat-card-actions>
</mat-card>