import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum ShipmentStatus {
	None = 0,
	Created = 100,
	Submitted = 200,
	Accepted = 300,
	Tracking = 301,
	Delivered = 500,
	Received = 600,
	Closed = 700,
	Rejected = 800,
}

@Pipe({
name: 'shipmentStatus'
})
export class ShipmentStatusPipe extends BasePipe<ShipmentStatus> implements PipeTransform {
	static prefix = 'Backend.Types.ShipmentStatus.';
	static keys = [
		ShipmentStatus.None,
		ShipmentStatus.Created,
		ShipmentStatus.Submitted,
		ShipmentStatus.Accepted,
		ShipmentStatus.Tracking,
		ShipmentStatus.Delivered,
		ShipmentStatus.Received,
		ShipmentStatus.Closed,
		ShipmentStatus.Rejected];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ShipmentStatus): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ShipmentStatus.None: return 'None';
			case ShipmentStatus.Created: return 'Created';
			case ShipmentStatus.Submitted: return 'Submitted';
			case ShipmentStatus.Accepted: return 'Accepted';
			case ShipmentStatus.Tracking: return 'Tracking';
			case ShipmentStatus.Delivered: return 'Delivered';
			case ShipmentStatus.Received: return 'Received';
			case ShipmentStatus.Closed: return 'Closed';
			case ShipmentStatus.Rejected: return 'Rejected';
			default: return null;
		}
	}
	transform(value: ShipmentStatus, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ShipmentStatusPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ShipmentStatusPipe
	],
	exports: [
		ShipmentStatusPipe
	],
	providers: [
		ShipmentStatusPipe
	]
})
export class ShipmentStatusModule { }
