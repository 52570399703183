import { EventEmitter } from '@angular/core';

export abstract class ButtonBaseComponent {

	public disabled: boolean = false; 

	public type: string

	public iconClass: string;

	public titleTranslateKey: string;

	public titleArgument: any = null;

	public hintTranslateKey: string;

	public color: string;

	public action: EventEmitter<ButtonBaseComponent>;

	constructor() {
	}

	onClick() {
		if (this.action) {
			this.action.emit(this);
		}
	}

}