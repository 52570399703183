import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageLookupHandlerModule } from 'app/generated/backend/localization/service/language-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreLanguageIso2Pipe } from './CoreLanguageIso2Pipe';
import { CoreLanguageLookupComponent } from './CoreLanguageLookupComponent';
import { CoreLanguageNamePipe } from './CoreLanguageNamePipe';
import { CoreLanguageService } from './CoreLanguageService';

@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		CommonMaterialModule,
		FormsModule,
		LanguageLookupHandlerModule,
		TranslateModule
	],
	exports: [CoreLanguageLookupComponent, CoreLanguageIso2Pipe, CoreLanguageNamePipe],
	declarations: [CoreLanguageLookupComponent, CoreLanguageIso2Pipe, CoreLanguageNamePipe],
	providers: [CoreLanguageService]
})
export class CoreLanguageModule { }
