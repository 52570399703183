import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreCurrencyLookupComponent } from "./CoreCurrencyLookupComponent";
import { CoreCurrencyLookupService } from "./CoreCurrencyLookupService";
import { CoreCurrencyIso3Pipe } from "./CoreCurrencyIso3Pipe";
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { CurrencyLookupHandlerModule } from 'app/generated/backend/currency/service/currency-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';

@NgModule({
    imports: [
        AutocompleteModule,
        CommonModule,
        FormsModule,
        CommonMaterialModule,
        TranslateModule,
        CurrencyLookupHandlerModule,
        HelpPopupModule
    ],
    exports: [CoreCurrencyLookupComponent, CoreCurrencyIso3Pipe],
    declarations: [CoreCurrencyLookupComponent, CoreCurrencyIso3Pipe],
    providers: [CoreCurrencyLookupService]
})
export class CoreCurrencyModule {
}
