import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PhoneNumberLookupHandlerModule } from 'app/generated/backend/comm/service/phone-number-lookup-handler';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreCountryModule } from '../country/CoreCountryModule';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { CorePhoneNumberInputComponent } from './CorePhoneNumberInputComponent';


@NgModule({
	imports: [
		CommonMaterialModule,
		CommonModule,
		CoreCountryModule,
		FormsModule,
		HelpPopupModule,
		PhoneNumberLookupHandlerModule,
		TranslateModule,
	],
	exports: [CorePhoneNumberInputComponent],
	declarations: [CorePhoneNumberInputComponent]
})
export class CorePhoneNumberModule { }
