import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreIncotermsLookupComponent } from './CoreIncotermsLookupComponent';
import { CoreIncotermsCodePipe } from './CoreIncotermsCodePipe';
import { CoreIncotermsLookupService } from './CoreIncotermsLookupService';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { IncotermsLookupHandlerModule } from 'app/generated/backend/trade/service/incoterms-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';


@NgModule({
	imports: [
		AutocompleteModule,
		CommonModule,
		FormsModule,
		CommonMaterialModule,
		TranslateModule,
		IncotermsLookupHandlerModule,
		HelpPopupModule
	],
	exports: [
		CoreIncotermsLookupComponent,
		CoreIncotermsCodePipe
	],
	declarations: [
		CoreIncotermsLookupComponent,
		CoreIncotermsCodePipe
	],
	providers: [CoreIncotermsLookupService]
})
export class CoreIncotermsModule { }
