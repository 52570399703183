import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { CoreCustomizationService } from '../customization/CoreCustomizationService';

const noop = () => {
	// This is intentional
};

export class MeasureUnit {
	factor: number;
	name: string;
	metric: boolean;
	offset = 0;
}

@Component({ template: '' })
export abstract class CoreMeasureBaseComponent {

	@Input()
	required = false;

	@Input()
	disabled: boolean;

	@Input()
	min: number;

	@Input()
	helpUri: string;

	@Input()
	label: string;

	@Input()
	placeholder: string = '';

	@Input()
	hint: string;

	@Input()
	iconClass: string;

	@Input()
	suffix: string;

	@Input()
	decimals = 3;

	@Input()
	public color = 'accent';

	@Output() blur = new EventEmitter<number>();

	@Output() focus = new EventEmitter<number>();

	public abstract units: MeasureUnit[];

	public abstract unit: MeasureUnit;

	public get scaledMin(): number {
		if (!this.min || !this.unit.factor) {
			return null;
		}

		return this.min / this.unit.factor;
	}

	// The internal data model
	public scaledValue: number;

	public errorStateMatcher = new SimpleErrorStateMatcher();
	public loading = true;
	public isMetric = false;
	//private _max: number;

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	constructor(private customizationService: CoreCustomizationService) {
		this.isMetric = this.customizationService?.getLocale()?.metric;
	}

	public validate(c: UntypedFormControl): ValidationErrors | null {
		if ((this.scaledValue || this.scaledValue === 0) && (this.min || this.min === 0)) {
			if (this.scaledValue < this.scaledMin) {
				this.errorStateMatcher.valid = false;
				this.errorStateMatcher.errorKey = 'error.numberLessThanMinimum';
				return {
					jsonParseError: {
						valid: false,
					},
				};
			}
		}
		if (this.value == undefined && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				required: true
			};
		}
		if (this.value || this.value === 0 || !this.required) {
			this.errorStateMatcher.valid = true;
			return null;
		}
		this.errorStateMatcher.valid = false;
		this.errorStateMatcher.errorKey = 'error.required';
		return {
			jsonParseError: {
				valid: false,
			}
		};

	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	validateIsTemperature(name) {
		return name === "°F" ||name === "°C";
	}

	// get accessor
	get value(): number {
		if (this.scaledValue === null) {
			return null;
		}
		let output = parseFloat(((this.scaledValue - this.unit.offset) * this.unit.factor).toFixed(this.decimals));
		if (this.validateIsTemperature(this.unit.name)) {
			output = parseFloat(output.toFixed(1));
		}
		if (isNaN(output)) return null;
		return output;
	};

	// From ControlValueAccessor interface
	writeValue(value: number) {
		let scaledValue: number = null;
		// if(value) would be false even value = 0
		if (value !== null && value !== undefined && !isNaN(value)) {
			this.unit = this.selectBestUnit(value);
			scaledValue = value / this.unit.factor + this.unit.offset;
			scaledValue = parseFloat(scaledValue.toFixed(this.decimals));
		} else if (this.min) {
			this.unit = this.selectBestUnit(this.min);
		} else {
			this.unit = this.selectBestUnit(1);
		}
		if (scaledValue !== this.scaledValue) {
			this.scaledValue = scaledValue;
			this.validate(null);
		}
	}

	// set accessor including call the onchange callback
	set value(v: number) {
		if (v !== this.scaledValue) {
			this.scaledValue = v;
			this.onChangeCallback(v);
		}
	}

	selectUnit(unit: MeasureUnit) {
		if (unit !== this.unit) {
			this.unit = unit;
			this.onChangeCallback(this.value);
		}
		this.blur.emit(this.value);
	}

	selectBestUnit(value: number) {
		let selectedUnit = this.units[0];
		for (var unit of this.units) {
			if (unit.metric === this.isMetric ) {
				if (this.validateIsTemperature(unit.name)) {
					selectedUnit = unit;
				} else if (value / unit.factor >= 1) {
					selectedUnit = unit;
				}
			}
		}
		return selectedUnit;
	}


	onChange(evt: number) {
		this.scaledValue = evt;
		this.onChangeCallback(this.value);
	}

	onClear(): void {
		this.scaledValue = null;
		this.onChangeCallback(this.value);
	}

	onBlur() {
		this.blur.emit(this.value);
	}

	onFocus() {
		this.focus.emit(this.value);
	}


	ngOnInit() {
	}

}
