import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PhoneNumberLookupResult } from 'app/generated/backend/comm/api/phone-number-lookup-result';

@Injectable()
export class PhoneNumberLookupHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		number: string
	): Observable<JsonResourceResponse<PhoneNumberLookupResult>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (number!==null && number!==undefined) {
			params = params.set('number', number);
		}
		return this.http.get<JsonResourceResponse<PhoneNumberLookupResult>>('api/comm/phoneNumberLookup', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		PhoneNumberLookupHandlerService
	]
})
export class PhoneNumberLookupHandlerModule { }
