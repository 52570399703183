<div class="adn-form-field"
    [ngClass]="{'adn-form-field-invalid': !errorStateMatcher.valid, 'adn-form-field-disabled': disabled}">
    <label>
        <span *ngIf="iconClass" class="fal fa-fw" [ngClass]="iconClass" aria-hidden="true">&nbsp;</span>{{label}}
        <ng-content select="app-text-label"></ng-content><span *ngIf="required">&nbsp;*</span>
    </label>
    <div class="adn-form-field-content">
        <input class="input-style" [placeholder]="placeholder" [(ngModel)]="model" [appAutocomplete]="autocomplete"
            (keydown)="onKeyDownEvent($event)" autocomplete="none" [required]="required"
            [disabled]="disabled || loading" (ngModelChange)="modelChange()" title="{{value?.path}}">
        <div *ngIf="loading" class="adn-input-icon-spinner-container">
            <i class="adn-input-icon-spinner-loader"></i>
        </div>
        <div class="input-icon" *ngIf="value">
            <button mat-button mat-icon-button matSuffix *ngIf="value && !required && !disabled" type="button"
                (click)="onClear()" title="{{ 'field.clear.label' | translate}}" tabindex="-1">
                <span class="fal fa-fw fa-times-circle"></span>
            </button>
        </div>
        <app-help-popup-button class="input-icon" *ngIf="helpUri" [uri]="helpUri"></app-help-popup-button>
    </div>
</div>
<div class="adn-form-field-error" *ngIf="!errorStateMatcher.valid">
    <span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;<span
        class="error-text">{{errorStateMatcher.errorKey | translate}}</span>
</div>

<app-autocomplete #autocomplete="appAutocomplete" (selectItem)="onItemSelected($event)" (closeOption)="closeOption()">
    <ng-template appAutocompleteContent>
        <ng-container *ngIf="allItems">
            <app-option *ngFor="let option of allItems" [value]="option" id="{{option.id}}"
                title="{{toTitleText(option)}}"
                [ngClass]="{'adn-dropdwon-active': option.id === currentSelectedId, 'adn-dropdwon-inactive': option.id !== currentSelectedId}">
                <span *ngIf="option.category===-3">&nbsp;
                    <app-locality-icon [size]="option.size" [locationFunction]="option.function">
                    </app-locality-icon>&nbsp;
                </span>
                <img *ngIf="option.category==-1" alt="" src="/assets/flags/4x3/{{option.code | lowercase}}.svg"
                    width="20" height="15" style="margin-left: 0.5em; margin-right: 0.5em;" />
                {{toOptionText(option)}}
            </app-option>
            <app-option class="no-result" *ngIf="!allItems.length">{{'field.noResults.label' | translate}}</app-option>
        </ng-container>
    </ng-template>
</app-autocomplete>