<div class="country-group-section">
  <div class="adn-content-block">
    <div class="row">
      <div class="col-12">
        <div class="adn-top-bottom-space">
          <h2>Real-Time Updated Shipping Prices and Port Information for Available Trade Lanes</h2>

          <div class="row">
            <div class="col-12">
              <div class="countryGroup">
                <mat-tab-group animationDuration="0ms">
                  <mat-tab id="Import" label="{{'footer.import.title' | translate}}">

                    <div *ngFor="let country of importCountries">

                      <div class="countryGroupLists">
                        <ul class="countryName">
                          <li> <a [routerLink]="[country.uri]" rel="noopener">
                              {{'country.' + country.iso2 | translate}}</a></li>
                        </ul>
                      </div>
                    </div>
                  </mat-tab>


                  <mat-tab id="Export" label="{{'footer.export.title' | translate}}">

                    <div *ngFor="let country of exportCountries">
                      <div class="countryGroupLists">
                        <ul class="countryName">
                          <li><a [routerLink]="[country.uri]" rel="noopener">
                              {{'country.' + country.iso2 | translate}}</a></li>
                        </ul>
                      </div>
                    </div>
                  </mat-tab>

                </mat-tab-group>


              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>


<div class="footer-bottom-section">
  <div class="row centered-maxwidth-container">
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-4 col-md-6 col-sm-4 col-xs-4 align-self-center">
          <p>
            <app-adnavem-logo></app-adnavem-logo>
          </p>
        </div>
        <div class="col-xl-12 ml-lg-auto col-lg-3 ml-md-auto col-md-3 ml-sm-auto col-sm-6 col-xs-12 align-self-center">
          <p class="font-icons">
            <a href="https://www.linkedin.com/company/adnavem/" target="_blank" rel="noopener"><i
                class="fab fa-linkedin-in"></i></a>
            <a href="https://www.youtube.com/channel/UCkQhxLBcuK41rL3LbBnyFOw" target="_blank" rel="noopener"><i
                class="fab fa-youtube"></i></a>
            <a href="https://www.facebook.com/ADNAVEM/" target="_blank" rel="noopener"><i
                class="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/logi_start_up/" target="_blank" rel="noopener"><i
                class="fab fa-instagram"></i></a>
            <a href="https://twitter.com/ADNAVEM" target="_blank" rel="noopener"><i class="fab fa-twitter"></i></a>
          </p>
        </div>
      </div>
    </div>


    <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row ">
        <div *ngFor="let model of models" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">

          <div class="footerHeader">{{model.title}}</div>
          <div *ngFor="let child of model.children" class="footerContent">
            <a [routerLink]="[child.uri]">{{child.title}}</a>
          </div>
        </div>
      </div>
    </div>




  </div>
</div>
<div class="footer-bottom-bar">
  &nbsp;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <span *ngIf="version">
    &nbsp; Version&nbsp;{{version}}
  </span>
  <span>
    &nbsp;©&nbsp;Copyright&nbsp;{{now | date : 'yyyy'}}&nbsp;ADNAVEM AB
  </span>
</div>