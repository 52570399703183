import { Injectable } from '@angular/core';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { LocaleLookupModel } from 'app/generated/backend/localization/api/locale-lookup-model';
import { LocaleLookupHandlerService } from 'app/generated/backend/localization/service/locale-lookup-handler';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
@Injectable()
export class CoreLocaleLookupService extends CachedEntityService<LocaleLookupModel> {
	constructor(
		localeLookupHandlerService: LocaleLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(localeLookupHandlerService, cacheService.getLocales());
	}
	transformItem(item: LocaleLookupModel): LocaleLookupModel {
		if (item) {
			item.match = item.identifier.toLowerCase();
			item.match += ' ';
			item.match += item.name.toLowerCase();
		}
		return item;
	}
}
