import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { ContainerEquipmentInputComponent } from './ContainerEquipmentInputComponent';
import { ContainerEquipmentModule } from 'app/generated/backend/types/container-equipment';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
@NgModule({
    imports: [
        AutocompleteModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        CommonMaterialModule,
        ContainerEquipmentModule,
        HelpPopupModule
    ],
    exports: [ContainerEquipmentInputComponent],
    declarations: [ContainerEquipmentInputComponent]
})
export class ContainerEquipmentInputModule {
}
