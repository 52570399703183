
import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { CoreMapPolylinePointComponent } from './CoreMapPolylinePointComponent';

declare const BMap: any;

class Point {
    lng: number;
    lat: number;
}

@Component({
    selector: 'app-core-map-polyline',
    template: ``
})
export class CoreMapPolylineComponent {

    @ContentChildren(CoreMapPolylinePointComponent) points: QueryList<CoreMapPolylinePointComponent>;
    @Input() strokeColor: string;
    @Input() geodesic: boolean = false;

    getPointsArray(): Array<Point> {
        let polylinePointsArray: Point[] = [];
        this.points.forEach(polylinePoint => {
            polylinePointsArray.push(new BMap.Point(polylinePoint.longitude, polylinePoint.latitude));
        });
        return polylinePointsArray;
    }
} 