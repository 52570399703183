import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { CoreLocaleIdentifierPipe } from 'app/core/locale/CoreLocaleIdentifierPipe';
import { CoreLocaleLocalizationService } from 'app/core/locale/CoreLocaleLocalizationService';
import { LocaleLookupHandlerModule } from 'app/generated/backend/localization/service/locale-lookup-handler';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { CoreLocaleLookupComponent } from "./CoreLocaleLookupComponent";
import { CoreLocaleLookupService } from "./CoreLocaleLookupService";

@NgModule({
    imports: [
        AutocompleteModule,
        CommonModule,
        FormsModule,
        CommonMaterialModule,
        TranslateModule,
        LocaleLookupHandlerModule,
        HelpPopupModule
    ],
    exports: [CoreLocaleLookupComponent, CoreLocaleIdentifierPipe],
    declarations: [CoreLocaleLookupComponent, CoreLocaleIdentifierPipe],
    providers: [CoreLocaleLookupService, CoreLocaleLocalizationService]
})
export class CoreLocaleModule {
}
