import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { LocalityLookupHandlerService } from 'app/generated/backend/locality/service/locality-lookup-handler';
import { LocalityLookupModel } from 'app/generated/backend/types/locality-lookup-model';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Subscription } from 'rxjs';

const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreLocalityLookupComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreLocalityLookupComponent),
	multi: true
};
@Component({
	selector: 'app-core-locality-lookup',
	templateUrl: './CoreLocalityLookupComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreLocalityLookupComponent implements ControlValueAccessor, Validator, OnInit {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string = 'fa-map-marker-alt';

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Input()
	public type = 0;

	@Input()
	public locationFunction = 0;

	@Output()
	itemSelected: EventEmitter<LocalityLookupModel> = new EventEmitter<LocalityLookupModel>();

	public loading = false;
	private _innerValue: LocalityLookupModel;
	public errorStateMatcher = new SimpleErrorStateMatcher();
	protected subscriptions = new Array<Subscription>();
	public allItems: LocalityLookupModel[] = [];
	private error: string;
	public model: string = '';
	public currentIndex = null;
	public currentSelectedId = null;

	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): LocalityLookupModel {
		return this._innerValue;
	};

	// set accessor including call the onchange callback
	set value(v: LocalityLookupModel) {
		if (v !== this._innerValue) {
			this._innerValue = v;
			this.model = this.toDisplayText(this._innerValue);
		}
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	// From ControlValueAccessor interface
	writeValue(value: LocalityLookupModel) {
		if (value && value !== this._innerValue) {
			this._innerValue = value;
			this.model = this.toDisplayText(this._innerValue);
		}
		if (!value) {
			this.onClear();
		}
		this.validate(null);
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: UntypedFormControl): ValidationErrors | null {
		if (this.error) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = this.error;
			return {
				jsonParseError: {
					valid: false,
				}
			};
		}
		if (!this.value && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				jsonParseError: {
					valid: false,
				}
			};
		}
		this.errorStateMatcher.valid = true;
		return null;
	}

	toDisplayText(item: LocalityLookupModel): string {
		if (!item) {
			return null;
		} else if (item.name) {
			return item.name;
		} else {
			return item.code;
		}
	}

	toTitleText(item: LocalityLookupModel): string {
		return this.toDisplayText(item);
	}

	toOptionText(item: LocalityLookupModel): string {
		let str = '';
		if (item) {
			if (item.name) {
				str += item.name;
			}
			if (item.code) {
				if (str) {
					str += ' ('
					str += item.code
					str += ')'
				} else {
					str += item.code
				}
			}
			if (item.path) {
				if (str) {
					str += ' ('
					str += item.path
					str += ')'
				} else {
					str += item.path
				}
			}
		}
		if (str) {
			return str;
		}
		return null;
	}

	onItemSelected(option: LocalityLookupModel) {
		if (option) {
			this.value = option;
			this.error = null;
			this.validate(null);
			this.onChangeCallback(this._innerValue);
			this.itemSelected.emit(this._innerValue);
		}
	}

	modelChange() {
		this.error = null;
		if (this.model.trim() === '') {
			this.value = null;
		}
		this.verifyValidOption();
		this.subscriptions.push(this.service.get(30, this.type, this.locationFunction, this.model).subscribe(response => {
			if (response.data) {
				this.allItems = response.data;
			}
			this.validate(null);
			this.currentIndex = null;
			this.currentSelectedId = null;
			this.onChangeCallback(this._innerValue);
			this.loading = false;
		},
			error => {
				const httpErrorResponse = error as HttpErrorResponse;
				if (httpErrorResponse && httpErrorResponse.status) {
					this.error = "Http.Status." + httpErrorResponse.status.toString() + ".label";
				} else {
					this.error = "Http.Status.500.label";
				}
				this.allItems = [];
				this.validate(null);
				this.currentIndex = null;
				this.currentSelectedId = null;
				this.onChangeCallback(this._innerValue);
				this.loading = false;
			}));
	}

	constructor(
		private service: LocalityLookupHandlerService
	) { }

	load() {
		this.loading = true;
		this.error = null;
		this.errorStateMatcher.valid = true;
		this.currentIndex = null;
		this.currentSelectedId = null;
		this.subscriptions.push(this.service.get(30, this.type, this.locationFunction, '').subscribe(response => {
			if (response.data) {
				this.allItems = response.data;
			}
			this.validate(null);
			this.loading = false;
		},
			error => {
				const httpErrorResponse = error as HttpErrorResponse;
				if (httpErrorResponse && httpErrorResponse.status) {
					this.error = "Http.Status." + httpErrorResponse.status.toString() + ".label";
				} else {
					this.error = "Http.Status.500.label";
				}
				this.allItems = [];
				this.validate(null);
				this.loading = false;
			}));
	}

	closeOption() {
		if (this.model && this.model.trim() !== '' && this.allItems && this.allItems.length) {
			if (this.value) {
				for (let i = 0; i < this.allItems.length; i++) {
					if (this.allItems[i].id === this.value.id) {
						this.value = this.allItems[i];
						this.verifyValidOption();
						return;
					}
				}
			}
			this.value = this.allItems[0];
			this.verifyValidOption();
		}
	}

	verifyValidOption() {
		this.error = null;
		if (this.model) {
			if (this.toDisplayText(this.value) !== this.model) {
				this.error = 'error.notAValidOption';
			}
		}
		this.validate(null);
		this.currentIndex = null;
		this.currentSelectedId = null;
		this.onChangeCallback(this._innerValue);
	}

	ngOnInit() {
		this.load();
	}

	onClear() {
		this.value = null;
		this.model = '';
		this.currentIndex = null;
		this.currentSelectedId = null;
	}

	onKeyDownEvent(event: any) {
		let items: LocalityLookupModel[];
		items = this.allItems;
		if (event.code === 'ArrowDown') {
			if (this.currentIndex === null) {
				this.currentIndex = 0;
			} else if (items.length && items.length - 1 > this.currentIndex) {
				this.currentIndex = this.currentIndex + 1;
			}
		} else if (event.code === 'ArrowUp') {
			if (this.currentIndex > 0) {
				this.currentIndex = this.currentIndex - 1;
			}
		}
		this.HandleItemSelection(event, items);
	}

	private HandleItemSelection(event: any, items: LocalityLookupModel[]) {
		if (this.currentIndex !== null) {
			if ((event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Tab')) {
				this.value = items[this.currentIndex];
				this.itemSelected.emit(items[this.currentIndex]);
			}
			if (items.length) {
				this.currentSelectedId = items[this.currentIndex].id;
				const element = document.getElementById(this.currentSelectedId);
				if (element) {
					element.scrollIntoView({ block: 'nearest' });
				}
			}
		}
	}

}
