<form #form="ngForm" name="contactUs">
    <div *ngIf="!success" class="row form-group">
        <app-text-input class="col-sm-12" name="name" [(ngModel)]="model.name" minlength="1"
            label="{{'field.fullName.label' | translate}}" helpUri="help/contactUs/name" [required]="true"
            [disabled]="disableUser">
        </app-text-input>


        <app-email-input class="col-sm-12" name="email" [(ngModel)]="model.email"
            label="{{'field.email.label' | translate}}" helpUri="help/contactUs/email" [required]="true"
            [disabled]="disableUser">
        </app-email-input>
        <app-text-input class="col-sm-12" name="company" [(ngModel)]="model.company" minlength="1"
            label="{{'field.company.label' | translate}}" helpUri="help/contactUs/company" [required]="true"
            [disabled]="disableParty">
        </app-text-input>


        <app-core-country-lookup class="col-sm-12" label="{{'field.country.label' | translate}}" name="countryId"
            [(ngModel)]="model.countryId" [required]="true">
        </app-core-country-lookup>

        <app-text-input class="col-sm-12" name="jobTitle" [(ngModel)]="model.jobTitle" minlength="1"
            label="{{'field.jobTitle.label' | translate}}" helpUri="help/contactUs/jobTitle"></app-text-input>
        <app-phone-number-input class="col-sm-12" name="phone" [(ngModel)]="model.phone"
            label="{{'field.officePhone.label' | translate}}" [disabled]="disableUser">
        </app-phone-number-input>


        <app-text-input class="col-sm-12" name="message" [(ngModel)]="model.message" minlength="1"
            label="{{'field.message.label' | translate}}" helpUri="help/contactUs/message" [required]="true" [rows]="4">
        </app-text-input>
    </div>

    <div class="form-group row" *ngIf="!success && !disableUser">
        <app-text-input class="col-sm-12" name="captcha" label="{{'field.captcha.label' | translate}}"
            [(ngModel)]="model.captcha" maxlength="255" hint="{{'field.captcha.hint' | translate}}" [required]="true">
        </app-text-input>
        <app-core-captcha-image class="col-12"></app-core-captcha-image>
    </div>

    <div *ngIf="!success" class="form-group row">
        <div class="col-sm-12 adn-buttons-horizontal text-right">
            <app-button-submit (action)="onSubmit()">
            </app-button-submit>
        </div>
    </div>

    <p *ngIf="success">{{'field.contactUsSuccess.label' | translate }}</p>

</form>