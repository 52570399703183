import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalityLookupModel } from 'app/generated/backend/types/locality-lookup-model';

@Injectable()
export class LocalityLookupHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		limit: number,
		filterCategory: number,
		filterFunction: number,
		filterText: string
	): Observable<JsonResourceResponse<LocalityLookupModel[]>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (limit!==null && limit!==undefined) {
			params = params.set('limit', limit.toString());
		}
		if (filterCategory!==null && filterCategory!==undefined) {
			params = params.set('filterCategory', filterCategory.toString());
		}
		if (filterFunction!==null && filterFunction!==undefined) {
			params = params.set('filterFunction', filterFunction.toString());
		}
		if (filterText!==null && filterText!==undefined) {
			params = params.set('filterText', filterText);
		}
		return this.http.get<JsonResourceResponse<LocalityLookupModel[]>>('api/v1/locality/localities/lookup', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		LocalityLookupHandlerService
	]
})
export class LocalityLookupHandlerModule { }
