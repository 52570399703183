<div *ngIf="notFound" id="content">
  <div class="card nopadding">
    <div class="card-body">
      <h1>OOPS</h1>
      <p><b>404 - Page not found</b></p>
      The page {{uri}} you are looking for might have been removed,
      had its name changed, or is temporarily unavailable.
    </div>
  </div>
</div>

<app-loading [loading]="loading"></app-loading>

<app-content [uri]="uri" [page]="true" (loaded)="onLoaded($event)" (redirect)="onRedirect($event)"
  (error)="onError($event)"></app-content>