import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { ButtonBaseComponent } from 'app/core/button/button-base.component';
import { ButtonAnalyticsBaseComponent } from './button-analytics-base.component';


@Component({
	selector: 'app-core-button-talk-to-expert',
	templateUrl: './button-analytics-base.component.html'
})
export class CoreButtonTalkToExpertComponent extends ButtonAnalyticsBaseComponent {
	@HostBinding('class') class = 'adn-material-button';

	constructor(
		protected angulartics2: Angulartics2,
		protected router: Router
	) {
		super(angulartics2, router);
	}

	@Input()
	public href = '/company/contact'

	@Input()
	public category = 'company/contact';

	@Input()
	public disabled: boolean = false;

	@Input()
	public type = 'button';

	@Input()
	public iconClass: string;

	@Input()
	public titleTranslateKey = 'button.talkToAnExpert'

	@Input()
	public hintTranslateKey: string;

	@Input()
	public color = 'warn';

	@Output()
	action: EventEmitter<ButtonBaseComponent>;
}
